import { Bloc, Flex } from 'blocjs';
import React from 'react';
import { Text } from '../../components';
import { formatOrAbbreviateNumber } from '../../utils';

const MetricsContainer = ({ status, metrics }) => {
  const isSuccessfulMetric = status === 'success';
  const totalMessages = metrics ? Object.keys(metrics)[0] : null;

  const totalSuccess = formatOrAbbreviateNumber(metrics?.totalSuccess);
  const totalFailed = formatOrAbbreviateNumber(metrics?.totalFailed);

  const variants = {
    totalSuccess: {
      bg: '#AEFFC6',
      contrastText: '#10756D',
    },
    succeeded: {
      bg: '#00BD34',
      contrastText: 'white',
    },
    onTheWay: {
      bg: '#D4F724',
      contrastText: 'black',
    },
    pending: {
      bg: '#FAC915',
      contrastText: 'black',
    },
    totalFailed: {
      bg: '#F07C89',
      contrastText: '#FFF',
    },

    failed: {
      bg: '#E9153D',
      contrastText: 'white',
    },
    undelivered: {
      bg: '#FFE4E4',
      contrastText: 'black',
    },
    expired: {
      bg: '#E4DEDD',
      contrastText: 'black',
    },
    rejected: {
      bg: '#C7C2C1',
      contrastText: 'black',
    },
  };

  // Check if totalTitle is defined before accessing properties
  const bg =
    totalMessages && variants[totalMessages] && variants[totalMessages].bg;

  const contrastText =
    totalMessages &&
    variants[totalMessages] &&
    variants[totalMessages].contrastText;

  return (
    <Bloc
      display="flex"
      width="100%"
      flexDirection="column"
      style={{
        columnGap: '12px',
      }}
    >
      <Bloc
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        style={{
          rowGap: '8px',
        }}
      >
        <Bloc
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={['12px', '10px', '14px']}
          py={['14px', '10px', '20px', '20px']}
          borderRadius="8px"
          width="100%"
          // TODO : Play with this
          height={['100%', '120px', '160px', '140px']}
          style={{
            rowGap: '1px',
          }}
          bg={bg}
        >
          <Text color={contrastText} as="p" fontSize={[12, 12, 14]}>
            {isSuccessfulMetric
              ? 'Total messages projected to succeed'
              : 'Total messages didn’t reach '}
          </Text>
          <Text
            as="p"
            fontSize={[50, 42, 50]}
            fontWeight="bold"
            color={contrastText}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {isSuccessfulMetric ? totalSuccess : totalFailed}
          </Text>

          <Text as="p" fontSize={[10, 9, 10]} color={contrastText}>
            {isSuccessfulMetric
              ? 'Should be delivered with no hussle'
              : 'Did not reach due to technical reasons'}
          </Text>
        </Bloc>
        <Bloc
          display="grid"
          height={['100%', 'fit-content', '100%']}
          style={{
            gap: '8px',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          }}
        >
          {metrics
            ? Object.entries(metrics?.cards).map(([card, digits]) => {
                let title;
                const formattedDigit = formatOrAbbreviateNumber(digits);

                switch (card) {
                  case 'succeeded':
                    title = {
                      title: 'Succeeded',
                      subTitle: 'Successfully reached the recipients',
                    };
                    break;
                  case 'onTheWay':
                    title = {
                      title: 'On the Way',
                      subTitle: 'En-route to the destination',
                    };
                    break;

                  case 'pending':
                    title = {
                      title: 'Pending queue',
                      subTitle: 'Waiting to be sent',
                    };
                    break;
                  case 'failed':
                    title = {
                      title: 'Failed',
                      subTitle: 'Failed to delivery to destination',
                    };
                    break;
                  case 'undelivered':
                    title = {
                      title: 'Undelivered',
                      subTitle: 'Destination couldn’t be reached',
                    };
                    break;
                  case 'expired':
                    title = {
                      title: 'Expired',
                      subTitle: 'After multiple attempt telco stopped',
                    };
                    break;
                  case 'rejected':
                    title = {
                      title: 'Rejected',
                      subTitle: 'Rejected by the carrier',
                    };
                    break;
                  default: // Do nothing
                }

                return (
                  <Bloc
                    bg={variants[card].bg}
                    borderRadius="8px"
                    px={['12px', '10px', '14px']}
                    minHeight="52px"
                    height={['100%', '96px', '100%']}
                    py={['14px', '1px', '1px', '12px']}
                    key={card}
                  >
                    <Flex
                      height="100%"
                      justifyContent="space-between"
                      alignItems="stretch"
                    >
                      <Bloc
                        display="flex"
                        style={{
                          rowGap: '4px',
                        }}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Bloc>
                          <Text
                            color={variants[card].contrastText}
                            fontSize={[12, 9, 10, 12]}
                          >
                            {title.title}
                          </Text>
                        </Bloc>

                        <Bloc display={['block', 'block', 'block', 'none']}>
                          <Text
                            fontWeight="bold"
                            fontSize={20}
                            style={{
                              textTransform: 'uppercase',
                            }}
                            color={variants[card].contrastText}
                            as="p"
                          >
                            {formattedDigit}
                          </Text>
                        </Bloc>

                        <Bloc size="90%">
                          <Text
                            as="p"
                            color={variants[card].contrastText}
                            fontSize={[10, '9px', '8px', 9]}
                          >
                            {title.subTitle}
                          </Text>
                        </Bloc>
                      </Bloc>
                      <Bloc
                        align
                        display={['none', 'none', 'none', 'block']}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Text
                          fontWeight="bold"
                          color={variants[card].contrastText}
                          as="p"
                          style={{
                            textTransform: 'uppercase',
                          }}
                          fontSize={[14, 20]}
                        >
                          {formattedDigit}
                        </Text>
                      </Bloc>
                    </Flex>
                  </Bloc>
                );
              })
            : null}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default MetricsContainer;
