import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Box, Flex, Bloc } from 'blocjs';

import UserAPI from '../../services';
import { Inputfield, Button, Layout, Text, Toast } from '../../components';

const Forgot = () => {
  const userAPI = new UserAPI();
  const { push } = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const { mutate, data, status, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.forgot(payload),
  });

  const onSubmit = async (dataInputs) => {
    const { email } = dataInputs;
    await mutate(email, {
      onSuccess: () => {
        setTimeout(() => {
          push(`/recovery?email=${email}`);
        }, 3000);
      },
    });
  };

  const getEmailError = () => {
    let emailError;

    if (errors && errors?.email?.type === 'required') {
      emailError = 'Without email — We cannot recover your account';
    }
    if (errors && errors?.email?.type === 'pattern') {
      emailError =
        'Email format provided is truely alien — Please double check';
    }
    return emailError;
  };

  return (
    <Layout pt={[3, '10vh']}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message={data?.data?.message} status="success" />
      )}
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
      >
        <Bloc mb={0} width={[1, 1, 415]}>
          <Text variant="h3" as="h1">
            {' '}
            Forgot password{' '}
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            {' '}
            Instant communication <Bloc as="br" /> platform for you!{' '}
          </Text>
        </Bloc>
        <Bloc
          mt={[4, null]}
          width={[1, 1, 512]}
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
          borderRadius="8px"
          bg="white"
          padding="48px 32px"
        >
          <Bloc as="form" onSubmit={handleSubmit(onSubmit)}>
            <Inputfield
              name="Email"
              ref={register({
                required: true,
                pattern: /\S+@\S+\.\S+/,
              })}
              hint="We prefer your work email, but will work with all you have"
              error={getEmailError()}
              data-testid="email"
            />
            <Box mt={40} />
            <Button profile="accentLight" size="normal" loading={isPending}>
              Recover password
            </Button>
          </Bloc>
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Forgot;
