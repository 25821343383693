import React, { useContext, useEffect, useRef, useState } from 'react';

import { Bloc, Box } from 'blocjs';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { pdf } from '@react-pdf/renderer';
import { AddFunds, Icon, Layout, Pagination, Toast } from '../../components';
import { useFetchOrgTransactions } from '../../hooks';
import { CurrentUserContext } from '../../contexts';
import { formatBalance, slugify } from '../../utils';
import ReceiptPDF from '../../components/pdf/receiptPDF';
import BillingHistory from './billingHistory';

const CreditingPage = () => {
  const itemsPerPage = 25;

  const { currentUser, profile } = useContext(CurrentUserContext);
  const mainUserId = currentUser?.accountId || null;
  const [pageNumber, setPageNumber] = useState(1);

  const { data: transactionsData, isLoading } = useFetchOrgTransactions(
    mainUserId,
    pageNumber,
    itemsPerPage
  );

  const [showToast, setShowToast] = useState(null);
  const { location, push } = useHistory();
  const [hideCreditAccountForm, setHideCreditAccountForm] = useState(true);
  const [getRowData, setGetRowData] = useState(null);
  const [selectedBillingDate, setSelectedBillingDate] = useState(null);
  const [exportOption, setExportOption] = useState('');

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const makeFileName = (details) => {
    const title = slugify(details);
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  const handleExportOption = useRef(() => {});

  handleExportOption.current = async () => {
    const { fileName } = makeFileName(
      `Receipt for ${selectedBillingDate} Period`
    );

    if (exportOption === 'pdf') {
      const doc = <ReceiptPDF profile={profile} selectedBilling={getRowData} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${fileName}.pdf`);
    }
    setSelectedBillingDate(null);
  };

  useEffect(() => {
    if (transactionsData) {
      if (selectedBillingDate) {
        if (exportOption) {
          handleExportOption.current(getRowData);
        }
      }
    }
  }, [exportOption, transactionsData, selectedBillingDate, getRowData]);

  useEffect(() => {
    if (location.state) {
      setHideCreditAccountForm(false);
    }
  }, [location.state]);
  // const itemsPerPage = 25;

  const handleHideAccountForm = () => {
    setHideCreditAccountForm(true);
    // replace({ pathname: '/crediting', state: { isCreditAccountOpen: false } });
  };

  const handleShowAccountForm = () => {
    setHideCreditAccountForm(false);
  };

  const showAddFunds = !hideCreditAccountForm;

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected + 1);
  //   setIsFetchEnabled(true);
  // };

  const handleDownload =
    (optionFormat = 'pdf', rowData) =>
    (e) => {
      const creditDate = format(new Date(rowData.created_at), 'MMM dd, yyyy');

      e.stopPropagation();
      setExportOption(optionFormat);
      setGetRowData(rowData);
      setSelectedBillingDate(creditDate);
    };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row) => {
          const dateTime = format(new Date(row.created_at), 'MMM, dd, yyyy');
          return <Bloc>{dateTime}</Bloc>;
        },
      },
      {
        Header: 'Amount',
        floatRight: false,
        Cell: ({ row: { original } }) => formatBalance(original.amount),
      },
      {
        Header: 'Payment Method',
        floatRight: false,
        Cell: ({ row: { original } }) => original.payment_processor,
      },

      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            display="flex"
            justifyContent="flex-end"
            width={1}
            data-test="check-col"
          >
            <Bloc onClick={handleDownload('pdf', row)}>
              <Icon
                name="download"
                dimension="16x17"
                color="#16C1AD"
                size={16}
              />
            </Bloc>
          </Bloc>
        ),
      },
    ],
    []
  );

  const callToast = (message, messageStatus, timeOut = 8000) => {
    setShowToast({ message, status: messageStatus });
    setTimeout(() => {
      setShowToast(null);
    }, timeOut);
  };

  const onRowClick = (row, e) => {
    e.stopPropagation();
    push({
      pathname: '/receipt',
      state: {
        receiptData: row.original,
      },
    });
  };

  return (
    <Layout>
      {showToast && showToast.message && showToast.status && (
        <Toast message={showToast.message} status={showToast.status} />
      )}

      {showAddFunds && (
        <AddFunds
          callToast={callToast}
          handleHideAccountForm={handleHideAccountForm}
        />
      )}
      {showAddFunds && <Box mt={5} />}
      {transactionsData && !isLoading && (
        <BillingHistory
          columns={columns}
          handleShowAccountForm={handleShowAccountForm}
          transactions={transactionsData.transactions}
          onRowClick={onRowClick}
          showAddFunds={showAddFunds}
        />
      )}

      {transactionsData && transactionsData.pages.pages !== 0 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          pageNumber={pageNumber}
          pages={transactionsData?.pages}
          pageCount={transactionsData?.pages?.pages}
          changePage={changePage}
          isFetching={isLoading}
          isLoading={isLoading}
          ml={0}
        />
      )}

      <Box mt={200} />
    </Layout>
  );
};

export default CreditingPage;
