import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useFetchPublishableKey = (token) => {
  const { data, isLoading: isPublishableKeyLoading } = useQuery({
    queryKey: ['publishable_key', token],
    queryFn: () => userAPI.getPublishableKey(token),
    placeholderData: keepPreviousData,

    enabled: !!token,
  });

  return { data, isPublishableKeyLoading };
};

export default useFetchPublishableKey;
