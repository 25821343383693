export const GITHUB_CLIENT_ID = '58561435f432f2f69a54';
export const REDIRECT_URI = 'https://app.pindo.io/login';
export const GOOGLE_CLIENT_ID =
  '656418332828-2dkhnig8tf46olg412pt6rja4vurm52f.apps.googleusercontent.com';

/*  Given a number of SMS above zero, we calculate the value of each step that will be used in plotting the chart.
    e.g.
    -  If total number of SMS is 700, the range number is 100. Then the plot on Y-axis will be 0 100 200 300 400 500 600 700.
    -  If total number of SMS is 4000, the range number is 1000. Then the plot on Y-axis will be 0 1000 2000 3000 4000.
*/
export const setRangeOnYAxis = (totalNumber) => {
  if (totalNumber) {
    const stringNumber = `${totalNumber}`;
    const firstDigit = Number(stringNumber[0]);
    const rangeNumber =
      totalNumber > 0
        ? (firstDigit * 10 ** (stringNumber.length - 1)) / firstDigit
        : 0;
    return rangeNumber;
  }
  return 0;
};

export const senderIDStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Denied', value: 'denied' },
];

export const dealStatuses = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const countriesStatuses = [
  { label: 'All', value: 'all' },
  { label: 'Country Name', value: 'country_name' },
  { label: 'MCC', value: 'mcc' },
  { label: 'Region Code', value: 'region_code' },
  { label: 'Country Code', value: 'country_code' },
];

export const balanceStatuses = [
  { label: 'Low Balance', value: false },
  { label: 'Enough Funds', value: true },
];

export const servicesStatuses = [
  { label: 'Global', value: 'global' },
  { label: 'Partial', value: 'Partial' },
];

export const portabilityStatuses = [
  { label: 'Portability', value: true },
  { label: 'Not Portability', value: false },
];

export const senderIDRegulated = [
  { label: 'Regulated', value: true },
  { label: 'Not Regulated', value: false },
];

export const requestMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const calculateInitialPage = (pageNumber) =>
  pageNumber <= 1 ? 0 : pageNumber - 1;
