/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ContactContainer from './container';
import Text from '../text';
import { Inputfield } from '../inputs';
import Button from '../button';
import UserAPI from '../../services';
import ContactDropDown from '../contactDropDown';
import { formatPhone, invertObject } from '../../utils';
import CustomToast from '../toast/customToast';

const AddNewContactComponent = ({ handleSwitchPage, activePage = false }) => {
  const userAPI = new UserAPI();
  const { location } = useHistory();

  const queryClient = useQueryClient();
  const { data: fieldsData, isLoading } = useQuery({
    queryKey: ['get_all_fields'],
    queryFn: () => userAPI.getContactFields(),
  });
  const contactToUpdate = location.state && location.state;

  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState({});
  const [showNameError, setShowNameError] = useState(null);
  const [showPhoneNumberError, setPhoneNumberError] = useState(null);

  const {
    mutate: createNewContact,
    isError: isCreateNewContactError,
    isPending: isCreateNewContactPending,
    error: createNewContactError,
    status: createNewContactStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.createContact(payload),
  });

  const {
    mutate: updateContact,
    isError: isUpdateContactError,
    isPending: isUpdateContactPending,
    error: updateContactError,
    status: updateContactStatus,
  } = useMutation({
    mutationFn: (payload) =>
      userAPI.updateContact(payload, contactToUpdate?.id),
  });

  const onChangeInput = (e) => {
    const { value } = e.target;
    const validTel = value.replace(/[^\d.+]/g, '');
    const newTel = formatPhone(validTel);

    if (isValidPhoneNumber(newTel)) {
      setRequiredFieldsFilled((prev) => ({
        ...prev,
        phone_number: newTel,
      }));
      setPhoneNumberError(null);
    } else {
      setPhoneNumberError('Invalid phone number');
    }
  };

  const onChangeInputName = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > 3) {
      setRequiredFieldsFilled((prev) => ({
        ...prev,
        name: e.target.value,
      }));
      setShowNameError(null);
    } else {
      setShowNameError('Name must be more than 3 characters');
    }
  };

  const handleSubmit = () => {
    if (requiredFieldsFilled.name && requiredFieldsFilled.phone_number) {
      // Submit the form
      const extraFields = invertObject(requiredFieldsFilled);

      const payload = {
        name: requiredFieldsFilled.name,
        phone_number: requiredFieldsFilled.phone_number,
        extra_fields: extraFields,
        state: 'active',
      };
      if (!contactToUpdate) {
        createNewContact(payload, {
          onSuccess: () => {
            setShowNotificationMessage(true);
            queryClient.invalidateQueries({ queryKey: ['get_all_contacts'] });
            queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

            setTimeout(() => {
              handleSwitchPage(null);
            }, 5500);
          },
          onError: () => {
            setShowNotificationMessage(true);
          },
        });
      } else {
        updateContact(payload, {
          onSuccess: () => {
            setShowNotificationMessage(true);
            queryClient.invalidateQueries({ queryKey: ['get_all_contacts'] });
            queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

            setTimeout(() => {
              handleSwitchPage(null);
            }, 5500);
          },
          onError: () => {
            setShowNotificationMessage(true);
          },
        });
      }
    } else {
      if (!requiredFieldsFilled.name) {
        setShowNameError('Name is required');
      }

      if (!requiredFieldsFilled.phone_number) {
        setPhoneNumberError('Phone number is required');
      }
    }
  };
  const contactUpdateSuccessfully = updateContactStatus === 'success';
  const newContactCreatedSuccessfully = createNewContactStatus === 'success';

  return (
    <ContactContainer
      toast={
        showNotificationMessage && (
          <>
            {isCreateNewContactError && (
              <CustomToast
                message={createNewContactError?.response?.data?.error?.message}
                handleHideToast={() => setShowNotificationMessage(false)}
                status="error"
                showToast={showNotificationMessage}
                closed
              />
            )}
            {isUpdateContactError && (
              <CustomToast
                message={updateContactError?.response?.data?.error?.message}
                handleHideToast={() => setShowNotificationMessage(false)}
                status="error"
                showToast={showNotificationMessage}
                closed
              />
            )}

            {contactUpdateSuccessfully && (
              <CustomToast
                message="Contact updated successfully"
                handleHideToast={() => setShowNotificationMessage(false)}
                status="success"
                showToast={showNotificationMessage}
                closed
              />
            )}
            {newContactCreatedSuccessfully && (
              <CustomToast
                message="New Contact created successfully"
                handleHideToast={() => setShowNotificationMessage(false)}
                status="success"
                showToast={showNotificationMessage}
                closed
              />
            )}
          </>
        )
      }
      defaultToast={false}
    >
      <Box p="20px">
        <Flex flexDirection="column">
          <Bloc
            pb="20px"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Text
              as="h1"
              fontSize={['16px', '28px']}
              style={{
                fontWeight: '500',
              }}
            >
              {contactToUpdate ? 'Edit' : 'New'} Contact
            </Text>
          </Bloc>
          {fieldsData?.contact_fields && !isLoading && (
            <NewInputContactComponent
              setRequiredFieldsFilled={setRequiredFieldsFilled}
              fieldsData={fieldsData?.contact_fields}
              requiredFieldsFilled={requiredFieldsFilled}
              onChangeInput={onChangeInput}
              activePage={activePage}
              onChangeInputName={onChangeInputName}
              contactToUpdate={contactToUpdate}
              showNameError={showNameError}
              showPhoneNumberError={showPhoneNumberError}
            />
          )}

          <Flex
            pt="20px"
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.06)',
              columnGap: '6px',
            }}
          >
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={handleSubmit}
              loading={isCreateNewContactPending || isUpdateContactPending}
            >
              Save Contact
            </Button>
            <Button
              profile="secondary"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
              }}
              onClick={() => handleSwitchPage(null)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Box>
    </ContactContainer>
  );
};

export default AddNewContactComponent;

const NewInputContactComponent = ({
  fieldsData,
  contactToUpdate,
  setRequiredFieldsFilled,
  onChangeInput,
  activePage,
  onChangeInputName,
  showNameError,
  showPhoneNumberError,
}) => {
  const isMobile = useMedia('(min-width: 768px)');

  const handleInputChange = (fieldName, e) => {
    const inputValue = e.target.value;

    setRequiredFieldsFilled((prevState) => ({
      ...prevState,
      [fieldName.toLowerCase()]: inputValue,
    }));
  };
  useEffect(() => {
    if (activePage) {
      const extraFields = contactToUpdate.extra_fields;
      setRequiredFieldsFilled({
        name: contactToUpdate.name,
        phone_number: contactToUpdate.phone_number,
        ...extraFields,
      });
    }
  }, [activePage]);

  return (
    <Bloc
      display="grid"
      py="15px"
      style={{
        gap: '15px',
        gridTemplateColumns: isMobile
          ? 'repeat(2,minmax(0,1fr))'
          : 'repeat(1,minmax(0,1fr))',
      }}
    >
      <Flex
        width="100%"
        style={{
          gap: '6px',
        }}
        flexDirection="column"
      >
        <Text
          style={{
            textTransform: 'capitalize',
          }}
        >
          Name
          <Bloc
            as="span"
            pl="2px"
            fontSize="14px"
            style={{
              color: '#FF1D38',
            }}
          >
            *
          </Bloc>
        </Text>
        <Bloc>
          <Inputfield
            size="contact"
            style={{
              textTransform: 'capitalize',
            }}
            required
            name="text"
            defaultValue={activePage ? contactToUpdate.name : ''}
            // value={fieldText}
            placeholder="Name"
            onChange={(e) => {
              onChangeInputName(e);
            }}
            hasLabel={false}
            hasHint={false}
          />
          {showNameError && (
            <Bloc mt="-2px">
              <Text fontSize="10px" color="red">
                {showNameError}
              </Text>
            </Bloc>
          )}
        </Bloc>
      </Flex>
      <Flex
        width="100%"
        style={{
          gap: '6px',
        }}
        flexDirection="column"
      >
        <Text
          style={{
            textTransform: 'capitalize',
          }}
        >
          Phone Number
          <Bloc
            as="span"
            pl="2px"
            fontSize="14px"
            style={{
              color: '#FF1D38',
            }}
          >
            *
          </Bloc>
        </Text>
        <Bloc>
          <Inputfield
            size="contact"
            style={{
              textTransform: 'capitalize',
            }}
            required
            name="text"
            autoComplete="off"
            // value={fieldText}
            defaultValue={activePage ? contactToUpdate.phone_number : ''}
            placeholder="eg: +250709988998"
            onChange={(e) => onChangeInput(e)}
            hasLabel={false}
            hasHint={false}
          />
          {showPhoneNumberError && (
            <Bloc mt="-2px">
              <Text fontSize="10px" color="red">
                {showPhoneNumberError}
              </Text>
            </Bloc>
          )}
        </Bloc>
      </Flex>

      {fieldsData.slice(2).map((field) => {
        const options =
          field?.options && field?.options.map((item) => item.toLowerCase());

        return (
          <Flex
            key={field.name}
            width="100%"
            style={{
              gap: '6px',
            }}
            flexDirection="column"
          >
            <Text
              style={{
                textTransform: 'capitalize',
              }}
            >
              {field.name}
              {(field.name === 'name' || field.name === 'phone_number') && (
                <Bloc
                  as="span"
                  pl="2px"
                  fontSize="14px"
                  style={{
                    color: '#FF1D38',
                  }}
                >
                  *
                </Bloc>
              )}
            </Text>

            {field.input_type === 'select' && options ? (
              <Bloc>
                <ContactDropDown
                  dropDownItems={options}
                  step={activePage !== false}
                  activeItemIndex={
                    activePage
                      ? options.indexOf(
                          contactToUpdate.extra_fields[
                            field.name?.toLowerCase()
                          ]
                        )
                      : 0
                  }
                  isNewContact
                  onSelectionChange={(newField) =>
                    setRequiredFieldsFilled((prev) => ({
                      ...prev,
                      [field.name]: newField,
                    }))
                  }
                />
              </Bloc>
            ) : (
              <Bloc>
                <Inputfield
                  size="contact"
                  style={{
                    textTransform: 'capitalize',
                  }}
                  required
                  name="text"
                  defaultValue={
                    activePage
                      ? contactToUpdate.extra_fields[field.name.toLowerCase()]
                      : ''
                  }
                  // value={fieldText}
                  placeholder={field.name}
                  onChange={(e) => {
                    handleInputChange(field.name, e);
                  }}
                  hasLabel={false}
                  hasHint={false}
                />
              </Bloc>
            )}
          </Flex>
        );
      })}
    </Bloc>
  );
};
