/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import logo from '../../assets/images/logo.png';
import SGRegularWOFF2 from '../../assets/fonts/space-grotesk.medium.ttf';
import SGBoldWOFF2 from '../../assets/fonts/space-grotesk.bold.ttf';
import { aggregateData } from '../../lib/sumInvoiceRates';

Font.register({
  family: 'Space Grotesk',
  fonts: [
    { src: SGRegularWOFF2 }, // font-style: normal, font-weight: normal
    { src: SGBoldWOFF2, fontStyle: 'bold', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    padding: '30px 31px',
    fontFamily: 'Space Grotesk',
  },
  headerSection: {
    fontSize: '18px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  divideSections: {
    width: '100%',
    height: '0.7px',
    margin: '15px 0px',
    backgroundColor: '#D4DEE8',
  },
  topMargin: {
    marginTop: '15px',
    fontSize: '12px',
    fontWeight: 'normal',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  summarySubTitle: {
    fontSize: '12px',
    marginBottom: 5,
  },
  summaryTableSubTitle: {
    fontSize: '8px',
    fontWeight: 700,
  },
  summaryTableGray: {
    fontSize: '8px',
    color: '#6E717D',
  },
  text: {
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    marginBottom: '5px',
  },
  boldText: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  summarySection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    margin: '6px 0px',
  },
  summaryContainer: {
    width: '30%',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  summaryTitle: {
    marginBottom: '6px',
  },
  summaryTextBold: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  summaryText: {
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  summaryTotalDue: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  summarySecondTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  breakdownContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  breakDownEnd: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 12,
  },

  logo: {
    padding: 0,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'inline-block',
    width: '100%',
  },
  logoImage: {
    width: 30,
    height: 30,
    margin: 0,
    padding: 0,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  subtitle: {
    marginBottom: 5,
    padding: 0,
    fontSize: 10,
    fontWeight: 700,
  },
  summaryTable: {
    display: 'table',
    width: 'auto',
    backgroundColor: '#FFFFFF',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: '30px',
    fontSize: '9px',
    textAlign: 'left',
    color: '#757F84',
  },
  sectionText: {
    fontSize: '12px',
    color: '#0EB6A2',
    fontWeight: 700,
  },
  messageLink: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 10,
  },
  link: {
    fontSize: 9,
    fontWeight: 700,
    textAlign: 'right',
    color: '#757F84',
    textDecoration: 'none',
  },
  alignElement: {
    flex: '1 1 1 0%',
  },
  alignText: {
    textAlign: 'right',
    fontSize: '8px',
  },
});

const BillingPDF = ({
  selectedBilling,
  billingRates,
  profile,
  summedData,
  date: displayDte,
}) => {
  const invoice = {
    company: 'Pindo Ltd',
    recipient: {
      orgName: profile.username || '',
      email: profile.email || '-',
      address: profile.address || '',
      phoneNumber: profile.phonenumber || '',
    },

    from: 'Kigali, Rwanda',
    address: 'Norrsken House Kigali, C2, 3rd Floor, 1 KN 78 Street, Kigali',
    dueDate: 'February 30, 2023',
    summary: {
      total: selectedBilling ? selectedBilling.consumedAmount : '0',
      VAT: '$5,996',
      totalDue: selectedBilling ? selectedBilling.consumedAmount : '0',
    },
  };

  const rates = billingRates;
  const groupedByCountry = aggregateData(rates);
  const date = format(new Date(), 'dd/LL/yyyy hh:mm:ss');

  const totalAmountConsumed =
    summedData && summedData.reduce((acc, item) => acc + item.sumAmount, 0);

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.headerSection}>
          <View style={styles.logo}>
            <Image source={logo} style={styles.logoImage} />
          </View>
          <Text style={styles.title}>Invoice</Text>
        </View>
        <View style={styles.divideSections} />

        <View style={styles.summarySection}>
          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>From</Text>
            <Text style={styles.summaryText}>{invoice.company}</Text>
            <Text style={styles.summaryText}>{invoice.from}</Text>
            <Text style={styles.summaryText}>{invoice.address}</Text>
          </View>

          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>To</Text>
            <Text style={styles.summaryText}>{invoice.recipient.orgName}</Text>
            <Text style={styles.summaryText}>{invoice.recipient.email}</Text>
            <Text style={styles.summaryText}>{invoice.recipient.address}</Text>
            <Text style={styles.summaryText}>
              {invoice.recipient.phoneNumber}
            </Text>
          </View>

          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>Details</Text>
            <Text style={styles.summaryText}>Billing Period</Text>
            <Text style={styles.summaryText}>{displayDte}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.summarySecondTitle}>Summary</Text>
          <View style={styles.divideSections} />
          <View style={styles.breakdownContainer}>
            <Text style={styles.summaryTotalDue}>Total Due</Text>
            <Text style={styles.summaryTextBold}>
              $
              {typeof invoice.summary.totalDue === 'number'
                ? Number(invoice.summary.totalDue.toFixed(2))
                : invoice.summary.totalDue}
            </Text>
          </View>
        </View>
        <View style={styles.divideSections} />
        <View style={styles.section}>
          <Text style={styles.text}>
            Detailed breakdown per product down below
          </Text>
        </View>

        <View style={styles.section}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: '20px',
              marginBottom: '10px',
            }}
          >
            <Text style={styles.sectionText}>SMS</Text>
            <View style={styles.divideSections} />
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent: 'space-between',
            }}
          >
            <View style={styles.alignElement}>
              <Text style={styles.summaryTableSubTitle}>Country</Text>
            </View>
            <View style={styles.alignElement}>
              <Text style={styles.summaryTableSubTitle}>SMS</Text>
            </View>
            <View style={styles.alignElement}>
              <Text style={styles.summaryTableSubTitle}>Message Items</Text>
            </View>

            <View style={styles.alignElement}>
              <Text style={styles.summaryTableSubTitle}>Retry count</Text>
            </View>
            <View style={styles.alignElement}>
              <Text style={styles.alignText}>Total</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'column',
              gap: '6px',
              marginBottom: '5px',
            }}
          >
            {summedData.map((summary, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <View key={index} style={styles.breakdownContainer}>
                <View style={styles.alignElement}>
                  <Text style={styles.summaryTableGray}>{summary.country}</Text>
                </View>
                <View style={styles.alignElement}>
                  <Text style={styles.summaryTableGray}>
                    {summary.sumSmsCount}
                  </Text>
                </View>
                <View style={styles.alignElement}>
                  <Text style={styles.summaryTableGray}>
                    {summary.sumSmsItemCount}
                  </Text>
                </View>
                <View style={styles.alignElement}>
                  <Text style={styles.summaryTableGray}>
                    {summary.sumSmsItemRetries}
                  </Text>
                </View>
                <View style={styles.alignElement}>
                  <Text style={styles.alignText}>
                    ${summary.sumAmount.toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                fontSize: '10px',
                fontWeight: 700,
              }}
            >
              Subtotal: ${totalAmountConsumed.toFixed(2)}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.summaryText}>
            Detailed usage information is available in the breakdown section per
            product
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          data-testid="pagination"
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <Text
          style={{
            fontSize: '9px',
            position: 'absolute',
            right: '30px',
            bottom: 30,
            color: '#757F84',
          }}
          fixed
        >
          Invoice generated by Pindo, {date}
        </Text>
      </Page>
      {rates && rates.length > 0 && (
        <Page style={styles.page}>
          <View style={styles.headerSection}>
            <View style={styles.logo}>
              <Image source={logo} style={styles.logoImage} />
            </View>
            <Text style={styles.title}>Invoice</Text>
          </View>
          <View style={styles.divideSections} />
          <View
            style={{
              margin: '10px 0px',
            }}
          >
            <Text style={styles.summarySubTitle}>SMS Breakdown</Text>
          </View>
          <View style={styles.section}>
            <View
              style={{
                flexDirection: 'column',
                gap: '6px',
                marginBottom: '5px',
              }}
            >
              {Object.entries(groupedByCountry).map(([key, summary]) => {
                const sumDetails = summary.reduce(
                  (acc, item) => acc + item.amount,
                  0
                );
                return (
                  <View key={key}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      <Text style={styles.sectionText}>{key}</Text>
                      <View style={styles.divideSections} />
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginBottom: '5px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <View style={styles.alignElement}>
                        <Text style={styles.summaryTableSubTitle}>Country</Text>
                      </View>
                      <View style={styles.alignElement}>
                        <Text style={styles.summaryTableSubTitle}>SMS</Text>
                      </View>
                      <View style={styles.alignElement}>
                        <Text style={styles.summaryTableSubTitle}>
                          Message Items
                        </Text>
                      </View>

                      <View style={styles.alignElement}>
                        <Text style={styles.summaryTableSubTitle}>
                          Retry count
                        </Text>
                      </View>
                      <View style={styles.alignElement}>
                        <Text style={styles.summaryTableSubTitle}>Rate</Text>
                      </View>

                      <View
                        style={{
                          width: '10%',
                        }}
                      >
                        <Text style={styles.alignText}>Total</Text>
                      </View>
                    </View>
                    {summary.map((detail, index) => (
                      <View key={index}>
                        <View style={styles.breakdownContainer}>
                          <View style={styles.alignElement}>
                            <Text style={styles.summaryTableGray}>
                              {key} - {detail.telco}
                            </Text>
                          </View>
                          <View style={styles.alignElement}>
                            <Text style={styles.summaryTableGray}>
                              {detail.sms_count}
                            </Text>
                          </View>
                          <View style={styles.alignElement}>
                            <Text style={styles.summaryTableGray}>
                              {detail.sms_item_count}
                            </Text>
                          </View>

                          <View style={styles.alignElement}>
                            <Text style={styles.summaryTableGray}>
                              {detail.sms_item_retries}
                            </Text>
                          </View>
                          <View style={styles.alignElement}>
                            <Text style={styles.summaryTableGray}>
                              {detail.discountedPrice?.toFixed(4)}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: '10%',
                            }}
                          >
                            <Text style={styles.alignText}>
                              ${detail.amount.toFixed(4)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ))}
                    <View
                      style={{
                        textAlign: 'right',
                        marginTop: '6px',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: '9px',
                          fontWeight: 700,
                        }}
                      >
                        Subtotal: ${sumDetails.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            data-testid="pagination"
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
            fixed
          />
          <Text
            style={{
              fontSize: '9px',
              position: 'absolute',
              right: '30px',
              bottom: 30,
              color: '#757F84',
            }}
            fixed
          >
            Invoice generated by Pindo, {date}
          </Text>
        </Page>
      )}
    </Document>
  );
};

export default BillingPDF;
