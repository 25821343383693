/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Bloc, Box, Flex } from 'blocjs';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Icon, Text, Toast } from '../../../../components';
import UserAPI from '../../../../services';

const Table = ({
  columns,
  data,
  onRowClick,
  onCheckBox,
  setCheckbox,
  onEditService,
  selectedServices,
  setSelectedServices,
  isAddOrUpdateService,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const [isRemoving, setIsRemoving] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const {
    mutate: mutateRemove,
    isLoading: isRemoveLoading,
    status: removeStatus,
    error: removeError,
  } = useMutation({
    mutationFn: (payload) => userAPI.removeIntlService(payload),
  });

  const onRemoveService = (e) => {
    e.stopPropagation();
    setIsRemoving(true);
  };

  const handleRemoveService = async (e) => {
    e.stopPropagation();

    if (selectedServices.length > 0) {
      const Service = selectedServices.map((value) => value.id);
      await mutateRemove(Service[0], {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['intlServices'] });
          setTimeout(() => {
            setSelectedServices([]);
            setShowToast(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy
    );

  const sortColumnIcon = (isColumnSorted, isColumnSortedDesc) => {
    if (isColumnSorted) {
      if (isColumnSortedDesc) {
        return (
          <Icon
            name="accordion"
            color="accents.8"
            size={16}
            dimension="24x24"
          />
        );
      }
      return (
        <Icon
          name="accordion"
          color="accents.8"
          size={16}
          dimension="24x24"
          style={{
            transform: 'rotate(180deg) translateX(0px) translateY(2px)',
          }}
        />
      );
    }
    return (
      <Icon name="accordion" color="accents.8" size={16} dimension="24x24" />
    );
  };

  return (
    <Bloc
      ml={[0, '16%']}
      border="1px solid"
      borderColor="#D4DEE8"
      borderRadius="10px"
      paddding={0}
      bg="surfaces.0"
      overflowX="auto"
      mb={15}
    >
      {!isAddOrUpdateService && selectedServices.length === 1 && (
        <Box pt={15} px={5}>
          {!isRemoving && (
            <Flex justifyContent="end">
              <Bloc mr={['auto', 4]}>
                <Button
                  profile="accentLight"
                  size="small"
                  onClick={() => onEditService(selectedServices[0].id)}
                  data-test="update-btn"
                >
                  Edit Details
                </Button>
              </Bloc>

              <Bloc>
                <Button
                  profile="primary"
                  size="small"
                  type="button"
                  data-testid="refresh-token"
                  onClick={(e) => onRemoveService(e)}
                >
                  Remove Service
                </Button>
              </Bloc>
            </Flex>
          )}

          {isRemoving && (
            <Bloc
              display="flex"
              alignItems="center"
              backgroundColor="warning"
              borderRadius={2}
              px={4}
              py={22}
              justifyContent="space-between"
            >
              {removeStatus === 'error' && (
                <Toast
                  message={removeError?.response?.data?.error?.message}
                  status="error"
                />
              )}
              {removeStatus === 'success' && showToast && (
                <Toast message="Removed successfully" status="success" />
              )}
              <Text fontSize={12} color="accents.8">
                Are you sure you want to delete this Service ?
              </Text>

              <Flex alignItems="center">
                <Button
                  profile="primary"
                  size="small"
                  type="button"
                  loading={isRemoveLoading}
                  onClick={handleRemoveService}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  data-testid="yes-remove"
                >
                  Yes
                </Button>

                <Button
                  profile="accentLight"
                  size="small"
                  type="button"
                  onClick={() => {
                    setIsRemoving(false);
                    setSelectedServices([]);
                  }}
                  data-testid="no-remove"
                >
                  No
                </Button>
              </Flex>
            </Bloc>
          )}
        </Box>
      )}

      <Box mt={4} />
      <Bloc
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <Bloc as="thead">
          {headerGroups.map((headerGroup, indx) => (
            <Bloc
              key={indx}
              as="tr"
              height="30"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Bloc
                  key={column.id}
                  as="td"
                  py={3}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  data-testid={column.render('Header')}
                  style={{
                    whiteSpace: 'nowrap',
                    float: column.alignRight ? 'right' : 'initial',
                  }}
                >
                  {column.hideHeader === false ? null : (
                    <Flex alignItems="center">
                      {column.render('Header')}
                      <Bloc as="span" ml={3}>
                        {sortColumnIcon(column.isSorted, column.isSortedDesc)}
                      </Bloc>
                    </Flex>
                  )}
                </Bloc>
              ))}
              <Bloc as="td" py={3} textAlign="right">
                Selected
              </Bloc>
            </Bloc>
          ))}
        </Bloc>
        <Bloc as="tbody" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Bloc
                key={row.id + row.original.id}
                as="tr"
                textAlign="left"
                borderBottom="1px solid #DFDBDB"
                {...row.getRowProps({
                  style: { cursor: 'pointer' },
                  onClick: (e) => onRowClick(row, e),
                })}
                data-test="data-row"
                data-testid="data-row"
              >
                {row.cells.map((cell) => (
                  <Bloc
                    as="td"
                    py={3}
                    {...cell.getCellProps()}
                    key={cell.column.id}
                    style={{
                      float: cell.column.alignRight ? 'right' : 'initial',
                    }}
                  >
                    {cell.render('Cell')}
                  </Bloc>
                ))}

                <Bloc
                  as="td"
                  py={3}
                  onClick={(e) => e.stopPropagation()}
                  data-test="check-col"
                >
                  <Bloc
                    as="span"
                    style={{ cursor: 'pointer', float: 'right' }}
                    data-test="check-box"
                    data-testid="check-box"
                    onClick={(e) => onCheckBox(row.original, e)}
                  >
                    {setCheckbox(row.original.id)}
                  </Bloc>
                </Bloc>
              </Bloc>
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default Table;
