import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';
import { sortDashboardDataByOrder } from '../utils';

const userAPI = new UserAPI();

const useFetchBilling = (start, timePeriod, timezone, token) => {
  const {
    data: billingStatusDate,
    status,
    error,
    isFetching,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['userBillingHistory', { start, timePeriod, timezone }],
    queryFn: () =>
      userAPI.getBillingHistory({ start, timePeriod, timezone, token }),
    placeholderData: keepPreviousData,
    select: (data) => {
      const dataAfterStored = sortDashboardDataByOrder(data, timePeriod, false);
      return dataAfterStored;
    },
    // staleTime: 30 * 1000, // 30 secs
  });
  return { billingStatusDate, status, error, isFetching, isLoading, isSuccess };
};

export default useFetchBilling;
