import React, { useState, useContext, useMemo, useRef, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import { CurrentUserContext } from '../../../../contexts';
import { Layout, Text, Pagination, Icon } from '../../../../components';
import FilterForm from './filterform';
import { formatDate, senderIDStatuses } from '../../../../utils';
import { useFetchSenderIDsWithUsers } from '../../../../hooks';
import Table from './table';

const OrganizationName = ({ orgName }) => (
  <Bloc>{orgName || 'Not defined Yet'}</Bloc>
);

const SenderIDs = () => {
  const location = useLocation();
  const history = useHistory();
  const defaultStatus = location.state?.status || '';

  const [pageNumber, setPageNumber] = useState(1);
  const [, setStatusInput] = useState(defaultStatus);
  const [statusValue, setStatusValue] = useState(defaultStatus);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredStartDate, setFilteredStartDate] = useState(null);
  const [filteredEndDate, setFilteredEndDate] = useState(null);
  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [searchedName, setSearchedName] = useState('');
  const [senderIDsList, setSenderIDsList] = useState([]);
  const { currentUser } = useContext(CurrentUserContext);

  const itemsPerPage = 25;
  const dataQuery = useFetchSenderIDsWithUsers(
    searchedName,
    statusValue,
    pageNumber,
    itemsPerPage,
    filteredStartDate,
    filteredEndDate,
    isFetchEnabled
  );

  const {
    data: filteredSenderIDs,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
  } = dataQuery;

  useEffect(() => {
    if (filteredSenderIDs && filteredSenderIDs.sender_ids) {
      setSenderIDsList(filteredSenderIDs.sender_ids);
    }
  }, [filteredSenderIDs]);

  let debounceTimer;

  const searchByName = (e) => {
    e.preventDefault();
    const itemsList = filteredSenderIDs?.sender_ids || [];
    const inputValue = e.target.value;
    setSearchedName(inputValue);
    setIsFetchEnabled(false);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setIsFetchEnabled(true);
      const searchInput = inputValue.trim().toLowerCase();
      if (searchInput.length > 0) {
        const filterMatches = itemsList.filter(
          (item) =>
            // Check if the search term is found in "name," "org_name," or "main_user_name"
            (item.name && item.name.toLowerCase().includes(searchInput)) ||
            (item.org_name &&
              item.org_name.toLowerCase().includes(searchInput)) ||
            (item.main_user_name &&
              item.main_user_name.toLowerCase().includes(searchInput))
        );
        setSenderIDsList(filterMatches);
      } else {
        setSenderIDsList(itemsList);
      }
      // Enable fetching after debounce time
    }, 1500);
  };

  const filterMessages = () => {
    setStatusInput(statusValue);
    setIsFetchEnabled(true);
  };

  const handleFilterFormDates = (timeStatus, date) => {
    const formatedDate = formatDate(date);
    if (timeStatus === 'start') {
      setStartDate(date);
      setFilteredStartDate(formatedDate);
    } else if (timeStatus === 'end') {
      setEndDate(date);
      setFilteredEndDate(formatedDate);
    }
    setIsFetchEnabled(false);
  };

  const makeFileName = useRef(() => {});

  makeFileName.current = () => {
    const title = currentUser?.username;
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  const filterSenderIDs = () => {
    setPageNumber(1);
    setIsFetchEnabled(true);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    setIsFetchEnabled(true);
  };

  let statusColor = 'darkGray';
  let statusBg = 'white';
  if (statusValue === 'approved') {
    statusColor = 'accentDark';
    statusBg = '#EBF9F5';
  } else if (statusValue === 'denied') {
    statusColor = '#FF3049';
    statusBg = '#FFD6DB';
  } else if (statusValue === 'pending') {
    statusColor = '#757F84';
    statusBg = '#FFEEBA';
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Sender ID',
        accessor: 'name',
      },
      {
        Header: 'Organization',
        Cell: ({ row: { original } }) => (
          <OrganizationName
            orgName={original.org_name}
            mainUserName={original.main_user_name}
          />
        ),
      },
      {
        Header: 'Default',
        hideHeader: false,
        Cell: ({ row: { original } }) => (
          <Bloc>
            {original.is_default && (
              <Flex alignItems="center">
                <Bloc as="span" fontSize={12} color="lightDark" mr={2}>
                  Default
                </Bloc>
                <Icon
                  name="check-broken-circle"
                  height={14}
                  color="black"
                  size={12}
                  dimension="24x24"
                />
              </Flex>
            )}
          </Bloc>
        ),
      },
      {
        Header: 'Status',
        Cell: () => (
          <Bloc
            as="span"
            color={statusColor}
            bg={statusBg}
            padding="4px 10px"
            borderRadius={27}
            fontSize={10}
            style={{ textTransform: 'uppercase' }}
            fontWeight="bold"
            display="flex"
            boxSizing="border-box"
            width="fit-content"
            alignItems="center"
            justifyContent="center"
          >
            {statusValue}
          </Bloc>
        ),
      },
      {
        Header: 'Created Date',
        alignRight: true,
        Cell: ({ row: { original } }) => (
          <Bloc
            display="flex"
            height="100%"
            alignItems="center"
            padding="4px 0px"
          >
            {original.created_at && (
              <Flex alignItems="center">
                <Bloc as="span" fontSize={12} color="lightDark">
                  {format(new Date(original.created_at), 'MMM, dd, yyyy')}
                </Bloc>
              </Flex>
            )}
          </Bloc>
        ),
      },
    ],
    [statusBg, statusColor, statusValue]
  );

  const senderIDsData = useMemo(() => senderIDsList, [senderIDsList]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    const theOrgId = row.original.org_id;
    const theSenderId = row.original.id;
    const searchParams = new URLSearchParams(location.search);
    // Add a new search parameter
    searchParams.set('orgId', theOrgId);
    searchParams.set('senderId', theSenderId);
    const urlWithSearchParams = `/admin/manage/organizations?${searchParams.toString()}`;
    history.push(urlWithSearchParams, { theSenderId, theOrgId });
  };

  const hasInputFilled = !!(
    statusValue ||
    searchedName ||
    startDate ||
    endDate
  );

  return (
    <Layout>
      <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            Sender IDs
          </Text>
        </Box>
      </Flex>
      <Box mt={0} />
      <FilterForm
        setStatusInput={setStatusValue}
        startDate={startDate}
        endDate={endDate}
        handleFilterFormDates={handleFilterFormDates}
        filterSenderIDs={filterSenderIDs}
        isFetching={isFilterFetching}
        isLoading={isFilterLoading}
        filterMessages={filterMessages}
        hasInputFilled={hasInputFilled}
        setIsFetchEnabled={setIsFetchEnabled}
        statusInput={statusValue}
        searchedName={searchedName}
        searchByName={searchByName}
        senderIDStatuses={senderIDStatuses}
      />
      <Table
        columns={columns}
        data={senderIDsData}
        onRowClick={onRowClick}
        statusInput={statusValue}
      />
      {filteredSenderIDs && filteredSenderIDs.pages && (
        <Pagination
          itemsPerPage={itemsPerPage}
          pageNumber={pageNumber}
          pages={filteredSenderIDs?.pages}
          pageCount={filteredSenderIDs?.pages?.pages}
          changePage={changePage}
          isFetching={isFilterFetching}
          isLoading={isFilterLoading}
        />
      )}
      <Bloc mt={4} />
    </Layout>
  );
};

export default SenderIDs;
