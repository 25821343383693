import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMedia } from 'react-use';
import { Chart, Text } from '../../components';
import OverallSummery from './overallSummery';
import MetricsContainer from './metricsContainer';
import DashboardSkeleton from '../../components/skeletons/dashboardSkeleton';
import ChevronCardSwitch from '../../components/ChevronCardSwitch';

const UsageStats = ({
  statsLabels,
  successfulMetrics,
  amountConsumed,
  unSuccessfulMetrics,
  statsData,
  messages,
  isFetching,
}) => {
  const isMobile = useMedia('(min-width: 768px)');
  const [isFirstCardSelected, setIsFirstCardSelected] = useState(true);

  const legend = [
    {
      label: 'Accepted',
      color: '#D4F724',
    },
    {
      label: 'Delivered',
      color: '#00BD34',
    },
    {
      label: 'Pending queue',
      color: '#FAC915',
    },
    {
      label: 'Failed',
      color: '#E9153D',
    },
    {
      label: 'Expired',
      color: '#e4dedd',
    },
    {
      label: 'Rejected',
      color: '#C7C2C1',
    },
    {
      label: 'Undelivered',
      color: '#FFE4E4',
    },
  ];

  const chartDesign = (smsStatus) => {
    let color;
    let bgColor;
    let textColor;
    switch (smsStatus) {
      case 'accepted':
        color = '#D4F724';
        bgColor = '#D4F724';
        textColor = '#088C7D';
        break;
      case 'delivered':
        color = '#00BD34';
        bgColor = '#00BD34';
        textColor = '#FFF';
        break;
      case 'undelivered':
        color = '#FFE4E4';
        bgColor = '#FFE4E4';
        textColor = '#f79c9c';
        break;
      case 'expired':
        color = '#e4dedd';
        bgColor = '#e4dedd';
        textColor = '#414040';
        break;
      case 'failed':
        color = '#e9153d';
        bgColor = '#e9153d';
        textColor = '#fff';
        break;
      default:
    }
    return [color, bgColor, textColor];
  };

  const accepted = [];
  const delivered = [];
  const expired = [];
  const undelivered = [];

  if (statsData) {
    Object.values(statsData).forEach((value) => {
      accepted.push(value?.status ? value.status?.ACCEPTED?.sms_count : 0);
      delivered.push(value?.status ? value.status?.DELIVRD?.sms_count : 0);
      expired.push(value?.status ? value.status?.EXPIRED?.sms_count : 0);
      undelivered.push(value?.status ? value.status?.UNDELIV?.sms_count : 0);
    });
  }

  const dateStatus = {
    accepted,
    delivered,
    expired,
    undelivered,
  };

  const datasets = Object.entries(dateStatus).map(([key, value]) => {
    let newDateset = [];

    newDateset = {
      data: value.map((item) => (item !== undefined ? item : 0)),
      lineTension: 0,
      borderWidth: 3,
      borderColor: chartDesign(key)[0],
      label: key,
      backgroundColor: 'transparent',
      pointHoverRadius: 7,
      pointHoverBackgroundColor: chartDesign(key)[0],
      toolTip: {
        backgroundColor: chartDesign(key)[1],
        labelTextColor: chartDesign(key)[2],
      },
    };
    return newDateset;
  });

  const chartsData = {
    labels: statsLabels,
    datasets,
  };

  return (
    <Box>
      <Flex
        width="100%"
        style={{
          columnGap: '12px',
        }}
        flexWrap={['wrap', 'no-wrap']}
        alignItems="stretch"
      >
        <Box
          width={['100%', 'fit-content']}
          minWidth={['fit-content', '185px', '238px']}
          maxWidth={['100%', '30%', '100%']}
        >
          <Bloc as="p" fontSize={14} fontWeight="bold" color="surfaces.3">
            Total
          </Bloc>
        </Box>
        <Box
          px={['12px', '10px', '14px']}
          display="flex"
          hide={[true, null]}
          flex={1}
        >
          <Bloc as="p" fontSize={14} fontWeight="bold" color="surfaces.3">
            Successful
          </Bloc>
        </Box>
        <Box
          px={['12px', '10px', '14px']}
          display="flex"
          hide={[true, null]}
          flex={1}
        >
          <Bloc as="p" fontSize={14} fontWeight="bold" color="surfaces.3">
            Unsuccessful
          </Bloc>
        </Box>
      </Flex>
      {isFetching ? (
        <DashboardSkeleton isMobile={isMobile} />
      ) : (
        <>
          <Box ml={[0, 0, 0, 0]}>
            <Flex
              style={{
                columnGap: '10px',
              }}
              height="fit-content"
              flexWrap={['wrap', 'no-wrap']}
              alignItems="stretch"
            >
              <OverallSummery
                amountConsumed={amountConsumed}
                messages={messages}
              />
              <ChevronCardSwitch
                isDashboard
                cardOneTitle="Successful"
                secondCardTittle="UnSuccessful"
                setIsFirstCardSelected={setIsFirstCardSelected}
                isFirstCardSelected={isFirstCardSelected}
              />
              <Bloc
                display="flex"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                {isMobile ? (
                  <>
                    <MetricsContainer
                      status="success"
                      metrics={successfulMetrics}
                    />
                    <MetricsContainer
                      metrics={unSuccessfulMetrics}
                      status="unsuccess"
                    />
                  </>
                ) : (
                  <>
                    {isFirstCardSelected ? (
                      <MetricsContainer
                        status="success"
                        metrics={successfulMetrics}
                      />
                    ) : (
                      <MetricsContainer
                        metrics={unSuccessfulMetrics}
                        status="unsuccess"
                      />
                    )}
                  </>
                )}
              </Bloc>
            </Flex>
          </Box>

          <Box mt={8} />
          <Text color="surfaces.3" mb={20} fontSize={21} fontWeight="bold">
            SMS Delivery Status
          </Text>
          <Box mt={20} />
          <Box
            width={1}
            overflowY="hidden"
            overflowX="auto"
            p="20px"
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.32)',
            }}
          >
            <Box
              display={['none', 'flex']}
              alignItems="center"
              style={{
                columnGap: '10px',
              }}
              justifyContent="end"
            >
              {legend.map((item) => (
                <Bloc
                  display="flex"
                  style={{
                    columnGap: '5px',
                  }}
                  alignItems="stretch"
                  key={item.color}
                >
                  <Bloc display="flex" alignItems="center">
                    <Bloc
                      bg={item.color}
                      width="8px"
                      height="8px"
                      borderRadius="100%"
                    />
                  </Bloc>
                  <Bloc
                    style={{
                      columnGap: '5px',
                    }}
                  >
                    <Bloc
                      as="p"
                      style={{
                        columnGap: '5px',
                      }}
                      m={0}
                      fontSize="12px"
                    >
                      {item.label}
                    </Bloc>
                  </Bloc>
                </Bloc>
              ))}
            </Box>
            <Box width={[1100, '100%']} overflowY="hidden" overflowX="auto">
              <Chart type="line" data={chartsData} totalSMS={0} />
            </Box>
          </Box>
        </>
      )}

      <Box mt={8} />
    </Box>
  );
};

export default UsageStats;
