/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import styled from 'styled-components';
import Dropdown from './dropdown';
import Text from '../../text';
import Spinner from '../../spinner';
import { reverseExtraFieldsOrder } from '../../../utils';

const filterByElem = [
  {
    id: 1,
    title: 'Active Contact',
    name: 'active',
  },
  {
    id: 2,
    title: 'Archived Contact',
    name: 'archived',
  },
];

const ContactMessageDropdown = ({
  statistic,
  setShowDropdown,
  setFetchEnable,
  handleResetInputField,
  showDropdown,
  handleSearch,
  searchContactRef,
  contactsData,
  groupsData,
  isLoading,
  handleContactSelection,
  handleFileUpload,
  isAnyContactPresent,
  setShowContactsInfo,
  showContactsInfo,
  fileName,
  importedContactLength,
  progressPercentage,
}) => {
  const [activeTab, setActiveTab] = useState('active');
  const reArrangeContactData = reverseExtraFieldsOrder(contactsData || []);

  return (
    <Dropdown
      fileName={fileName}
      importedContactLength={importedContactLength}
      handleSearch={handleSearch}
      isAnyContactPresent={isAnyContactPresent}
      searchContactRef={searchContactRef}
      isDropdownOpen={showDropdown}
      contactsLength={contactsData?.length}
      setIsDropdownOpen={setShowDropdown}
      setFetchEnable={setFetchEnable}
      handleFileUpload={handleFileUpload}
      setShowContactsInfo={setShowContactsInfo}
      showContactsInfo={showContactsInfo}
      progressPercentage={progressPercentage}
    >
      <Flex
        px="10px"
        py="15px"
        style={{
          gap: '15px',
        }}
        flexDirection="column"
      >
        <Bloc>
          <Flex
            alignItems="center"
            style={{
              gap: '5px',
            }}
          >
            <Text fontSize={1}>Filter by: </Text>
            <Flex
              alignItems="center"
              style={{
                gap: '5px',
              }}
            >
              {filterByElem.map((elem) => {
                const isActiveContacts = activeTab === elem.name;

                return (
                  <Bloc
                    key={elem.id}
                    bg={isActiveContacts ? '#5EEBDA30' : '#F9F9F9'}
                    borderRadius="50px"
                    px="10px"
                    py="5px"
                    fontSize="9px"
                    color={isActiveContacts ? 'accents.10' : 'surfaces.3'}
                    border={
                      isActiveContacts
                        ? '1px solid #0EB6A2'
                        : '1px solid #E0E0E0'
                    }
                    fontWeight={isActiveContacts ? '500' : '400'}
                    style={{
                      whiteSpace: 'nowrap',
                      textTransform: 'initial',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setActiveTab(elem.name);
                      handleContactSelection(elem);
                    }}
                  >
                    {elem.title}
                  </Bloc>
                );
              })}
            </Flex>
          </Flex>
        </Bloc>

        {filterByElem
          .filter((item) => item.name === activeTab)
          .map((elem) => (
            <HoverContact
              key={elem.id}
              display="flex"
              flexDirection="column"
              bg={
                elem.name === 'active' && statistic?.active_count === 0
                  ? '#F9F9F9'
                  : elem.name === 'archived' && statistic?.archived_count === 0
                  ? '#F9F9F9'
                  : '#5eebda26'
              }
              style={{
                cursor:
                  elem.name === 'active' && statistic?.active_count === 0
                    ? 'not-allowed'
                    : elem.name === 'archived' &&
                      statistic?.archived_count === 0
                    ? 'not-allowed'
                    : 'pointer',
              }}
              borderRadius="5px"
              px="12px"
              onClick={() => {
                setShowDropdown(false);
                if (elem.name === 'active' && statistic?.active_count === 0)
                  return;
                if (elem.name === 'archived' && statistic?.archived_count === 0)
                  return;
                handleContactSelection({
                  contact_type: elem.name,
                });
              }}
              py="5px"
            >
              <Text fontSize="12px" fontWeight="500">
                All {elem.title}
              </Text>
              <Text fontSize="12px">
                {elem.name === 'active'
                  ? statistic?.active_count
                  : statistic?.archived_count}{' '}
                contacts
              </Text>
            </HoverContact>
          ))}
      </Flex>

      {isLoading ? (
        <Flex py="15px" width={1} justifyContent="center" alignItems="center">
          <Spinner size="large" />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          style={{
            gap: '15px',
          }}
          overflowY="auto"
          px="10px"
          pb="15px"
        >
          {groupsData
            ?.filter((contact) => contact?.contacts_count !== 0)
            .map((group) => (
              <HoverContact
                key={group.id}
                display="flex"
                flexDirection="column"
                px="12px"
                py="5px"
                borderRadius="5px"
                onClick={() => {
                  setShowDropdown(false);
                  handleResetInputField();
                  handleContactSelection({
                    name: group?.name,
                    group_id: group?.id,
                    contacts_count: group?.contacts_count,
                  });
                }}
              >
                <Text
                  fontSize="12px"
                  textTransform="capitalize"
                  fontWeight="500"
                >
                  {group.name}
                </Text>
                <Text fontSize="12px" fontWeight="300" color="accents.10">
                  {group.contacts_count} contacts
                </Text>
              </HoverContact>
            ))}
          {reArrangeContactData?.map((contact) => (
            <HoverContact
              key={contact.id}
              display="flex"
              flexDirection="column"
              onClick={() => {
                setShowDropdown(false);
                handleResetInputField();
                handleContactSelection({
                  ...contact?.extra_fields,
                  name: contact?.name,
                  phone_number: contact?.phone_number,
                });
              }}
              px="12px"
              py="5px"
              borderRadius="5px"
            >
              <Text fontSize="12px" fontWeight="500">
                {contact.name}
              </Text>
              <Text fontSize="12px" fontWeight="300" color="accents.10">
                {contact.phone_number}
              </Text>
            </HoverContact>
          ))}
        </Flex>
      )}
    </Dropdown>
  );
};

export default ContactMessageDropdown;

const HoverContact = styled(Bloc)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#5EEBDA30',
    color: 'white',
    fontWeight: '400',
  },
});
