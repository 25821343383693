import { useQuery } from '@tanstack/react-query';
import { differenceInHours, parseISO, formatISO } from 'date-fns';

const apikey = process.env.REACT_APP_EXCHANGE_RATE_API_KEY;

const fetchExchangeRate = async () => {
  const response = await fetch(
    'https://api.apilayer.com/exchangerates_data/convert?to=Rwf&from=USD&amount=1',
    {
      method: 'GET',
      headers: {
        apikey,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const useExchangeRate = () =>
  useQuery({
    queryKey: ['exchange'],
    queryFn: async () => {
      const lastFetchTime = localStorage.getItem('lastFetchTime');
      const currentTime = new Date();

      if (lastFetchTime) {
        const parsedLastFetchTime = parseISO(lastFetchTime);
        const hoursDifference = differenceInHours(
          currentTime,
          parsedLastFetchTime
        );

        if (hoursDifference < 24) {
          const cachedData = localStorage.getItem('cachedData');
          if (cachedData) {
            return JSON.parse(cachedData);
          }
        }
      }

      const data = await fetchExchangeRate();
      localStorage.setItem('cachedData', JSON.stringify(data));
      localStorage.setItem('lastFetchTime', formatISO(currentTime));

      return data;
    },
    staleTime: Infinity, //  Prevents refetching
    cacheTime: Infinity, //  Keeps the cache forever
  });

export default useExchangeRate;
