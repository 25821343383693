export const tabs = {
  active: {
    name: 'Active',
    total: 0,
  },
  archived: {
    name: 'Archived',
    total: 0,
  },
  groups: {
    name: 'Groups',
    total: 0,
  },
  contactField: {
    name: 'Contact fields',
    total: 0,
  },
};

export const contactDataStructure = {
  dataType: ['text', 'select', 'number'],
  inputType: ['date', 'text', 'numerical', 'select'],
  contactField: [
    {
      fieldName: 'fieldName',
    },
    {
      fieldName: 'fieldType',
    },
    {
      fieldName: 'dataType',
    },
  ],
  groupFields: [
    {
      fieldName: 'group',
    },
    {
      fieldName: 'date last updated',
    },
    {
      fieldName: 'contacts count',
    },
  ],
};
