import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Link,
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import logo from '../../assets/images/logo.png';
import SGRegularWOFF2 from '../../assets/fonts/space-grotesk.medium.ttf';
import SGBoldWOFF2 from '../../assets/fonts/space-grotesk.bold.ttf';

Font.register({
  family: 'Space Grotesk',
  fonts: [
    { src: SGRegularWOFF2 }, // font-style: normal, font-weight: normal
    { src: SGBoldWOFF2, fontStyle: 'bold', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    padding: '30px 31px',
    fontFamily: 'Space Grotesk',
  },
  headerSection: {
    fontSize: '18px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  logo: {
    padding: 0,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'inline-block',
    width: '100%',
  },
  logoImage: {
    width: 30,
    height: 30,
    margin: 0,
    padding: 0,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  divideSections: {
    width: '100%',
    height: '0.7px',
    margin: '15px 0px',
    backgroundColor: '#D4DEE8',
  },
  summarySection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryContainer: {
    width: '30%',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  summarySubTitle: {
    fontSize: '12px',
    marginBottom: 5,
  },
  summaryTextBold: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  summaryText: {
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  section: {
    margin: '15px 0px',
  },
  summarySecondTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  breakdownContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '8px',
    letterSpacing: '0.20px',
    lineHeight: '2px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 10,
    right: 0,
    fontSize: 9,
    textAlign: 'left',
    color: '#757F84',
  },
  messageLink: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 10,
  },
  link: {
    fontSize: 9,
    fontWeight: 700,
    textAlign: 'right',
    color: '#757F84',
    textDecoration: 'none',
  },
});

const ReceiptPDF = ({ selectedBilling, profile }) => {
  const timestamp = new Date().getTime();

  const receiptDoc = {
    company: 'Pindo Ltd',
    recipient: {
      orgName: profile.username || '',
      email: profile.email || '-',
      address: profile.address || '',
      phoneNumber: profile.phonenumber || '',
    },
    invoiceNumber: timestamp,
    dateGenerated: format(timestamp, 'MMMM dd, yyyy'),
    PaymentMethod: selectedBilling.payment_processor,
    from: 'Kigali, Rwanda',
    dateOfIssue: 'February 1, 2023',
    address: 'Norrsken House Kigali, C2, 3rd Floor, 1 KN 78 Street, Kigali',

    summary: {
      totalCredit: selectedBilling ? selectedBilling.amount : '0',
    },
  };

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.headerSection}>
          <View style={styles.logo}>
            <Image source={logo} style={styles.logoImage} />
          </View>
          <Text style={styles.title}>Receipt</Text>
        </View>
        <View style={styles.divideSections} />

        <View style={styles.summarySection}>
          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>From</Text>
            <Text style={styles.summaryText}>
              {receiptDoc.recipient.orgName}
            </Text>
            <Text style={styles.summaryText}>{receiptDoc.recipient.email}</Text>
            <Text style={styles.summaryText}>
              {receiptDoc.recipient.address}
            </Text>
            <Text style={styles.summaryText}>
              {receiptDoc.recipient.phoneNumber}
            </Text>
          </View>

          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>To</Text>
            <Text style={styles.summaryText}>{receiptDoc.company}</Text>
            <Text style={styles.summaryText}>{receiptDoc.from}</Text>
            <Text style={styles.summaryText}>{receiptDoc.address}</Text>
          </View>

          <View style={styles.summaryContainer}>
            <Text style={styles.summarySubTitle}>Details</Text>
            <Text style={styles.summaryText}>
              Receipt number: {receiptDoc.recipient.phoneNumber}
            </Text>
            <Text style={styles.summaryText}>
              Crediting date: {receiptDoc.dateGenerated}
            </Text>
            <Text style={styles.summaryText}>
              Payment method: {receiptDoc.PaymentMethod}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.summarySecondTitle}>Summary</Text>
          <View style={styles.divideSections} />
          <View style={styles.breakdownContainer}>
            <Text style={styles.summaryTextBold}>Total credited</Text>
            <Text style={styles.summaryTextBold}>
              ${receiptDoc.summary.totalCredit}
            </Text>
          </View>
          <View style={styles.divideSections} />
        </View>

        <Text style={styles.summaryText}>
          This is your receipt for loading your account, be sure to keep it in a
          safe place for further reference. Thank you for using Pindo!
        </Text>

        <Text
          style={styles.pageNumber}
          data-testid="pagination"
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <Text style={styles.messageLink} fixed>
          <Link to="https://app.pindo.io/billing" style={styles.link}>
            https://app.pindo.io/billing
          </Link>
        </Text>
      </Page>
    </Document>
  );
};

export default ReceiptPDF;
