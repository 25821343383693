import React from 'react';
import { Link } from 'react-router-dom';
import { Bloc } from 'blocjs';
import Text from '../text';
import Spinner from '../spinner';
import { formatBalance, textTruncate } from '../../utils';
import { useFetchOrg } from '../../hooks';

const Balance = ({ isRootPath, isAccountOn, currentUser, isAdmin }) => {
  const { data, status } = useFetchOrg({ enabled: !isAdmin });

  const inUseOrgData = data?.orgs.filter((org) => org.in_use)[0];
  const orgName = inUseOrgData?.name || currentUser?.username;
  const formattedBalance = formatBalance(inUseOrgData?.wallet_plus_bonus || 0);

  return (
    <Bloc>
      {status === 'loading' && (
        <Bloc marginLeft="4px" marginRight="12px" lineHeight="40px">
          <Spinner size="large" />
        </Bloc>
      )}
      {status === 'success' && (
        <Bloc as={Link} to="/crediting">
          <Bloc display="flex" flexDirection="column" justifyContent="center">
            {orgName && (
              <Text
                color={
                  // eslint-disable-next-line no-nested-ternary
                  isAccountOn ? '#0EB6A2' : !isRootPath ? 'black' : 'white'
                }
                fontSize={['14px', '12px']}
                style={{
                  textTransform: 'capitalize',
                  paddingTop: '5px',
                  lineHeight: '4px',
                  display: 'block',
                }}
              >
                {textTruncate(orgName, 11)}
                {!inUseOrgData?.name && ' Org'}
              </Text>
            )}

            <Bloc pt={[orgName ? '6px' : '0px', orgName ? '6px' : '0px']}>
              <Text
                color={!isRootPath ? '#6E717D' : 'white'}
                fontSize={['14px', '12px']}
                style={{
                  textTransform: 'capitalize',

                  fontWeight: '400',
                  display: 'block',
                }}
              >
                Balance: {formattedBalance}
              </Text>
            </Bloc>
          </Bloc>
        </Bloc>
      )}
    </Bloc>
  );
};

export default Balance;
