import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  Text,
  Card,
  Button,
  Inputfield,
  Toast,
  ToggleSwitch,
} from '../../../../components';
import UserAPI from '../../../../services';
import { removeUndefinedKeys } from '../../../../utils';

const EditCountry = ({ setIsEditCountry, countryData = undefined }) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();
  const { mutate, status, error } = useMutation({
    mutationFn: (payload) => userAPI.updateCountry(payload),
  });
  const [hasPhoneNumberPortability, setHasPhoneNumberPortability] = useState(
    countryData?.has_phone_number_portability || false
  );
  const [isSenderIdRegulated, setIsSenderIdRegulated] = useState(
    countryData?.is_sender_id_regulated || false
  );

  const onFormSubmit = async (data) => {
    if (data) {
      const inputObj = {
        name:
          data.country_name !== countryData.name
            ? data.country_name
            : undefined,
        mcc: data.mcc !== countryData.mcc ? data.mcc : undefined,
        country_code: Number(data.country_code),
        region_code:
          data.region_code !== countryData.region_code
            ? data.region_code
            : undefined,
        has_phone_number_portability: hasPhoneNumberPortability,
        is_sender_id_regulated: isSenderIdRegulated,
      };
      const payload = removeUndefinedKeys(inputObj);
      const { id } = countryData;
      await mutate(
        { id, bodyData: payload },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['countryRoutes', id],
            });
            await queryClient.invalidateQueries({ queryKey: ['countries'] });
            setTimeout(() => {
              setIsEditCountry(false);
            }, 3000);
          },
        }
      );
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message="Country updated successfully" status="success" />
      )}
      <Box ml={[0, '16%']} overflowX="auto" mb={15}>
        <Card>
          <Flex justifyContent="space-between">
            <Box pb={18}>
              <Text variant="h5"> Edit Details</Text>
            </Box>
          </Flex>
          <Flex justifyContent="start">
            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Country name"
                  defaultValue={countryData?.name || ''}
                  placeholder="Country name"
                  hint="Fill in name of the Country"
                  ref={register({ required: 'Country name is required' })}
                  error={errors?.country_name?.message}
                />
              </Box>
              <Box width={1} mb={6}>
                <Inputfield
                  type="text"
                  name="Region Code"
                  defaultValue={countryData?.region_code || ''}
                  placeholder="Region Code"
                  hint=""
                  ref={register({ required: 'Region Code is required' })}
                  error={errors?.region_code?.message}
                />
              </Box>

              <Box width={1} mb={6}>
                <ToggleSwitch
                  label="Phone Number Portability"
                  id="has_phone_number_portability"
                  checked={hasPhoneNumberPortability}
                  onChange={(e) =>
                    setHasPhoneNumberPortability(e.target.checked)
                  }
                />
              </Box>
            </Bloc>

            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1} mb={6}>
                <Inputfield
                  type="text"
                  name="MCC"
                  defaultValue={countryData?.mcc || ''}
                  placeholder="mcc"
                  hint=""
                  ref={register({ required: 'MCC is required' })}
                  error={errors?.mcc?.message}
                />
              </Box>

              <Box width={1} mb={6}>
                <Inputfield
                  type="text"
                  name="Country Code"
                  defaultValue={`+${countryData?.country_code}` || ''}
                  placeholder="Country Code"
                  hint=""
                  ref={register({ required: 'Country Code is required' })}
                  error={errors?.country_code?.message}
                />
              </Box>

              <Box width={1} mb={6}>
                <ToggleSwitch
                  label="Is SenderId Regulated"
                  id="isSenderIdRegulated"
                  checked={isSenderIdRegulated}
                  onChange={(e) => setIsSenderIdRegulated(e.target.checked)}
                />
              </Box>
            </Bloc>
          </Flex>
          <Flex
            mt={4}
            flexWrap="wrap"
            style={{
              columnGap: '15px',
            }}
          >
            <Box mb={[3, 0]}>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                profile="accentLight"
                size="small"
                type="submit"
                data-testid="updateDetailsBtn"
              >
                Save changes
              </Button>
            </Box>
            <Box mb={0}>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                profile="secondary"
                size="small"
                type="button"
                onClick={() => {
                  setIsEditCountry(false);
                }}
                data-testid="cancel-show-details"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Card>
      </Box>
    </Bloc>
  );
};

export default EditCountry;
