/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import ContactContainer from './container';
import Text from '../text';
import { Inputfield } from '../inputs';
import ContactDropDown from '../contactDropDown';
import { contactDataStructure } from './dummyData';
import Button from '../button';
import UserAPI from '../../services';
import CustomToast from '../toast/customToast';
import { slugifyWithUnderscore } from '../../utils';

const EditFieldComponent = ({ handleSwitchPage, fieldState, activePage }) => {
  const { location } = useHistory();
  const [checkedBox, setCheckedBox] = useState(fieldState?.featured);
  const [optionInput, setOptionInput] = useState();
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [optionInputArrayError, setOptionInputArrayError] = useState(null);
  const [fieldTextError, setFieldTextError] = useState(null);

  const isNewField = activePage;
  const fieldData = location.state && location.state;

  const userAPI = new UserAPI();

  const {
    mutate: createNewField,
    isError: isCreateNewFieldError,
    isPending: isCreateNewFieldPending,
    error: createNewFieldError,
    status: createNewFieldStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.createContactField(payload),
  });

  const {
    mutate: updateField,
    isError: isUpdateFieldError,
    error: updateFieldError,
    isPending: isUpdateFieldPending,
    status: updateFieldStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateContactField(payload, fieldData.id),
  });

  const [fieldText, setFieldText] = useState(fieldState?.name || '');
  const [selectedDataType, setSelectedDataType] = useState(
    fieldState?.input_type === 'select'
      ? 'select'
      : fieldState?.data_type || 'text'
  );
  const [optionInputArray, setOptionInputArray] = useState(
    fieldData?.options || []
  );

  const onCheckBox = (e) => {
    e.stopPropagation();
    setCheckedBox(!checkedBox);
  };

  const onChangeInput = (e) => {
    const { value } = e.target;

    if (value && value.length >= 30) {
      setOptionInputArrayError('Option name should not exceed 30 characters');
    } else {
      setOptionInput(value);
      setOptionInputArrayError(null);
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.keyCode === 9 || // tab
      e.keyCode === 13 || // enter
      e.keyCode === 188 // comma
    ) {
      e.preventDefault();
      e.stopPropagation();
      const { value } = e.target;
      if (value && value.length >= 30) {
        setOptionInputArrayError('Option name should not exceed 30 characters');
      } else {
        const checkIfSelected = optionInputArray.includes(value);
        if (checkIfSelected) {
          return;
        }
        setOptionInputArray((prev) => [...prev, value]);
      }
      setOptionInput('');
      setOptionInputArrayError(null);
    }
  };

  const setCheckbox = () => {
    if (checkedBox) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16} display="flex">
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const handleInputTextField = (e) => {
    e.preventDefault();
    const inputText = e.target.value;
    if (fieldText.length >= 30) {
      setFieldTextError('Field  should not exceed 30 characters');
    } else {
      setFieldTextError(null);
    }
    setFieldText(inputText);
  };

  const handleRemoveSelection = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    const filterSelection = optionInputArray.filter(
      (option) => item !== option
    ); // remove selection
    setOptionInputArray(filterSelection);
  };

  const handleSelectionChange = (chosenItem) => {
    setSelectedDataType(chosenItem);
  };

  const handleSaveChanges = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const check = fieldText.length > 3 && !optionInputArrayError;

    if (fieldText.length < 3) {
      setFieldTextError('Field Name should have at least 3 characters');
    }
    setShowNotificationMessage(false);

    if (check) {
      const payload = {
        name: slugifyWithUnderscore(fieldText),
        data_type:
          selectedDataType === 'select'
            ? 'text'
            : selectedDataType.toLowerCase(),
        input_type: selectedDataType === 'select' ? 'select' : 'input',
        featured: checkedBox,
      };
      if (selectedDataType === 'select') {
        payload.options = optionInputArray;
      }

      if (isNewField) {
        updateField(payload, {
          onSuccess: async () => {
            setShowNotificationMessage(true);
            setFieldText('');
            setOptionInputArray([]);
            setTimeout(() => {
              handleSwitchPage(null);
            }, 5500);
          },
          onError: () => {
            setShowNotificationMessage(true);
          },
        });
        // createNewField(payload);
      } else {
        // update field
        createNewField(payload, {
          onSuccess: async () => {
            setShowNotificationMessage(true);
            setFieldText('');
            setOptionInputArray([]);
            setTimeout(() => {
              handleSwitchPage(null);
              setShowNotificationMessage(false);
            }, 5500);
          },
          onError: () => {
            setShowNotificationMessage(true);
          },
        });
      }
    }
  };
  const fieldCreateSuccessfully = createNewFieldStatus === 'success';
  const fieldUpdateSuccessfully = updateFieldStatus === 'success';

  return (
    <ContactContainer
      defaultToast={false}
      toast={
        showNotificationMessage && (
          <Bloc>
            {isCreateNewFieldError && (
              <CustomToast
                message={createNewFieldError?.response?.data?.error?.message}
                handleHideToast={() => setShowNotificationMessage(false)}
                status="error"
                showToast={showNotificationMessage}
                closed
              />
            )}
            {isUpdateFieldError && (
              <CustomToast
                message={updateFieldError?.response?.data?.error?.message}
                handleHideToast={() => setShowNotificationMessage(false)}
                status="error"
                showToast={showNotificationMessage}
                closed
              />
            )}

            {fieldUpdateSuccessfully && (
              <CustomToast
                message="Field updated successfully"
                handleHideToast={() => setShowNotificationMessage(false)}
                status="success"
                showToast={showNotificationMessage}
                closed
              />
            )}

            {fieldCreateSuccessfully && (
              <CustomToast
                message="Field created successfully"
                handleHideToast={() => setShowNotificationMessage(false)}
                status="success"
                showToast={showNotificationMessage}
                closed
              />
            )}
          </Bloc>
        )
      }
    >
      <Bloc as="form" p="20px">
        <Flex flexDirection="column">
          <Bloc
            pb="20px"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Text
              as="h1"
              fontSize={['16px', '28px']}
              style={{
                fontWeight: '500',
              }}
            >
              {!isNewField ? 'New Field' : 'Edit Field'}
            </Text>
          </Bloc>
          <Flex
            flexDirection="column"
            py="15px"
            style={{
              rowGap: '15px',
            }}
          >
            <Flex
              flexWrap={['wrap', 'nowrap']}
              style={{
                columnGap: '15px',
                rowGap: '15px',
              }}
            >
              <Flex
                width="100%"
                style={{
                  gap: '6px',
                }}
                flexDirection="column"
              >
                <Text>Field name</Text>

                <Inputfield
                  size="contact"
                  required
                  name="text"
                  py="8px"
                  style={{
                    border: '1px solid #D4DEE8',
                  }}
                  value={fieldText}
                  placeholder="Field Name"
                  onChange={(e) => handleInputTextField(e)}
                  hasLabel={false}
                  hasHint={false}
                />
              </Flex>
              <Flex
                style={{
                  gap: '6px',
                }}
                width="100%"
                flexDirection="column"
              >
                <Bloc>Data Type</Bloc>
                <Bloc>
                  <ContactDropDown
                    step={activePage !== false}
                    dropDownItems={contactDataStructure.inputType}
                    activeItemIndex={contactDataStructure.inputType.indexOf(
                      selectedDataType
                    )}
                    onSelectionChange={(newField) =>
                      handleSelectionChange(newField)
                    }
                  />
                </Bloc>
              </Flex>
            </Flex>
            {fieldTextError && (
              <Bloc mt="-15px">
                <Text fontSize="10px" color="red">
                  {fieldTextError}
                </Text>
              </Bloc>
            )}
            {selectedDataType === 'select' && (
              <Bloc
                borderRadius="8px"
                py="4px"
                px="16px"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                }}
              >
                {optionInputArray.length > 0 && (
                  <>
                    <Text fontSize="12px" style={{}}>
                      Options
                    </Text>
                    <Flex
                      marginBottom="8px"
                      style={{
                        columnGap: '8px',
                      }}
                    >
                      {optionInputArray.map((option, index) => (
                        <Flex
                          key={index}
                          justifyContent="space-between"
                          alignItems="center"
                          px="4px"
                          py="4px"
                          style={{
                            marginTop: '6px',
                            backgroundColor: '#BDFBF3',
                            columnGap: '6px',
                            borderRadius: '999px',
                            fontSize: '12px',
                          }}
                        >
                          <Bloc>{option}</Bloc>

                          <Flex
                            onClick={(e) => handleRemoveSelection(e, option)}
                            alignItems="center"
                            justifyContent="center"
                            width="16px"
                            height="16px"
                            style={{
                              borderRadius: '999px',
                              backgroundColor: '#0A9080',
                              cursor: 'pointer',
                            }}
                          >
                            <Icon name="close" color="white" size={6} />
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </>
                )}

                <Bloc
                  as="input"
                  type="text"
                  value={optionInput}
                  placeholder="insert Option Name, press enter to add"
                  onChange={(e) => onChangeInput(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  width="100%"
                  backgroundColor="transparent"
                  border="0"
                  fontSize="14px"
                  fontWeight={400}
                  padding="5px"
                  name="phoneNumber"
                  style={{
                    fontFamily: 'Space Grotesk Variable',
                  }}
                />
                {optionInputArrayError && (
                  <Text fontSize="10px" color="red">
                    {optionInputArrayError}
                  </Text>
                )}
              </Bloc>
            )}
            <Flex
              onClick={(e) => {
                onCheckBox(e);
              }}
              alignItems="center"
              style={{
                cursor: 'pointer',
                columnGap: '6px',
              }}
            >
              <Bloc as="span">{setCheckbox(fieldState?.featured)}</Bloc>
              <Bloc fontSize="14px">Mark as featured</Bloc>
            </Flex>
          </Flex>
          <Flex
            pt="20px"
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.06)',
              columnGap: '6px',
            }}
          >
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={(e) => handleSaveChanges(e)}
              loading={isUpdateFieldPending || isCreateNewFieldPending}
            >
              Save changes
            </Button>
            <Button
              disabled={optionInputArrayError}
              profile="secondary"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
              }}
              onClick={() => handleSwitchPage(null)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Bloc>
    </ContactContainer>
  );
};

export default EditFieldComponent;
