import React from 'react';
import { Box, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import { StatsCard } from '../../../components';

const UsageStats = ({
  totalPending,
  isPendigSenderIDsLoading,
  totalLowBalance,
  isOrgsLoading,
}) => {
  const { push } = useHistory();

  const goToSenderIDs = () => {
    push({ pathname: '/admin/manage/senderids', state: { status: 'pending' } });
  };

  const goToBilling = () => {
    push('/admin/manage/billing?status=low_balance', {
      label: 'Low Balance',
      value: false,
    });
  };

  const cardWidth = '205px';
  const largeCardWidth = 'calc((100% / 4) - 32px)';

  return (
    <Box>
      <Box ml={[0, 0, 0, 0]}>
        <Flex
          flexWrap={['wrap', 'no-wrap']}
          style={{ gap: '12px' }}
          justifyContent="space-between"
        >
          <StatsCard
            ml={[null, 0, 0]}
            width={[1, cardWidth, largeCardWidth]}
            variant="accentDarkSecond"
            title="Earnings"
            digits={5000000}
            unit="$"
          />

          <StatsCard
            width={[1, cardWidth, largeCardWidth]}
            variant="warningDark"
            title="Pending SenderID Requests"
            digits={!isPendigSenderIDsLoading && totalPending}
            handleOnClick={goToSenderIDs}
          />

          <StatsCard
            width={[1, cardWidth, largeCardWidth]}
            variant="palePink"
            title="Low Balance Organization"
            digits={!isOrgsLoading && totalLowBalance}
            handleOnClick={goToBilling}
          />

          <StatsCard
            width={[1, cardWidth, largeCardWidth]}
            variant="primaryLight"
            title="Failing Services"
            digits={12}
          />
        </Flex>
      </Box>

      <Box mt={8} />
    </Box>
  );
};

export default UsageStats;
