import { Bloc } from 'blocjs';
import React from 'react';

const RadioBox = ({ selectedMethod, value, handleChangeMethod }) => (
  <Bloc
    as="label"
    width="26px"
    height="26px"
    display="flex"
    style={{
      cursor: 'pointer',
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Bloc
      as="input"
      id={value}
      name="payment-method"
      value={selectedMethod.name}
      type="radio"
      checked={value === selectedMethod.name}
      onChange={handleChangeMethod}
    />
    <Bloc as="span" className="helping-el" />
  </Bloc>
);

export default RadioBox;
