import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
// import { saveAs } from 'file-saver';
// import { pdf } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button, Layout, Text } from '../../components';
import { slugify } from '../../utils';
import BillingPDF from '../../components/pdf/billingPDF';
import { CurrentUserContext } from '../../contexts';
import ReceiptPDF from '../../components/pdf/receiptPDF';
import { sumInvoiceRates } from '../../lib/sumInvoiceRates';

const InvoicePage = () => {
  const { location, push } = useHistory();
  const invoiceRates = location.state && location.state.rates;
  const summedData = location.state && location.state.summedData;
  const isReceipt = location.pathname.includes('receipt');
  const invoiceData = location.state && location.state.invoiceData;
  const receiptData = location.state && location.state.receiptData;
  const singleDateBreakdown = summedData ?? sumInvoiceRates(invoiceRates);

  const timestamps = new Date().getTime();
  const [getRowData, setGetRowData] = useState(null);
  const [selectedBillingDate, setSelectedBillingDate] = useState(null);
  const [exportOption, setExportOption] = useState('');
  const { profile } = useContext(CurrentUserContext);
  const startDateString = localStorage.getItem('startDate');
  const startDateObject = JSON.parse(startDateString);
  const displayDate = format(
    new Date(startDateObject?.dateFrom || new Date()),
    'MMM dd, yyyy'
  );

  const date =
    startDateObject?.period === 'Today' && !summedData
      ? `${displayDate} at ${invoiceData?.date}`
      : invoiceData?.date || '';

  const invoiceDoc = {
    company: 'Pindo Ltd',
    date,
    recipient: {
      orgName: profile?.username || '',
      email: profile?.email || '-',
      address: profile?.address || '',
      phoneNumber: profile?.phonenumber || '',
    },
    address: 'Norrsken House Kigali, C2, 3rd Floor, 1 KN 78 Street, Kigali',

    invoiceNumber: timestamps,
    dateGenerated: format(timestamps, 'MMMM dd, yyyy'),
    from: 'Kigali, Rwanda',
    summary: {
      totalDue: invoiceData ? invoiceData.consumedAmount : 0,
    },
  };

  const receiptDoc = {
    company: 'Pindo Ltd',
    recipient: {
      orgName: profile?.username || '',
      email: profile?.email || '-',
      address: profile?.address || '',
      phoneNumber: profile?.phonenumber || '',
    },
    invoiceNumber: timestamps,
    dateGenerated: format(timestamps, 'MMMM dd, yyyy'),
    PaymentMethod: receiptData?.payment_processor || '',
    from: 'Kigali, Rwanda',
    dateOfIssue: 'February 1, 2023',
    address: 'Norrsken House Kigali, C2, 3rd Floor, 1 KN 78 Street, Kigali',

    summary: {
      totalCredit: receiptData ? receiptData.amount : '0',
    },
  };

  const makeFileName = (details) => {
    const title = slugify(details);
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  const handleExportOption = useRef(() => {});

  handleExportOption.current = async () => {
    const { fileName } = makeFileName(
      `${isReceipt ? 'Receipt' : 'Invoice'} for ${selectedBillingDate} ${
        isReceipt ? '' : 'billing'
      } period`
    );

    if (exportOption === 'pdf') {
      const doc = isReceipt ? (
        <ReceiptPDF profile={profile} selectedBilling={getRowData} />
      ) : (
        <BillingPDF
          date={date}
          profile={profile}
          summedData={singleDateBreakdown}
          billingRates={invoiceRates || getRowData.rates}
          selectedBilling={getRowData}
        />
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${fileName}.pdf`);
    }
    setSelectedBillingDate(null);
  };

  useEffect(() => {
    if (invoiceData || receiptData) {
      if (selectedBillingDate) {
        if (exportOption) {
          handleExportOption.current(isReceipt ? receiptData : invoiceData);
        }
      }
    }
  }, [
    exportOption,
    invoiceData,
    selectedBillingDate,
    getRowData,
    receiptData,
    isReceipt,
  ]);

  const handleDownload =
    (optionFormat = 'pdf', rowData) =>
    (e) => {
      e.stopPropagation();
      setExportOption(optionFormat);
      setGetRowData(rowData);
      setSelectedBillingDate(
        isReceipt
          ? format(new Date(rowData?.created_at), 'MMM dd, yyyy')
          : rowData.date
      );
    };

  return (
    <Layout>
      <Flex
        flexWrap="wrap"
        alignItems="center"
        style={{
          gap: '12px',
        }}
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          <Text as="h1" fontSize={28}>
            {isReceipt ? 'Receipt' : 'Invoice'}
          </Text>
        </Box>
        <Box>
          <Flex
            style={{
              columnGap: '12px',
            }}
            alignItems="center"
          >
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                fontSize: '12px',
              }}
              profile="accentLight"
              size="dashboard"
              onClick={handleDownload(
                'pdf',
                isReceipt ? receiptData : invoiceData
              )}
            >
              Download
            </Button>
            <Button
              profile="secondary"
              style={{
                textTransform: 'capitalize',
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
              }}
              size="dashboard"
              onClick={() => {
                push({
                  pathname: isReceipt ? '/crediting' : '/billing',
                });
              }}
            >
              Back to {isReceipt ? 'Crediting' : 'Dashboard'}
            </Button>
          </Flex>
        </Box>
      </Flex>
      <Box mt={20} />
      {isReceipt && <ReceiptPreview receiptDoc={receiptDoc} />}
      {!isReceipt && (
        <InvoicePreview
          invoiceDoc={invoiceDoc}
          invoiceRates={singleDateBreakdown}
        />
      )}

      <Box mt={200} />
    </Layout>
  );
};

export default InvoicePage;

const ReceiptPreview = ({ receiptDoc }) => (
  <Bloc
    borderRadius="20px"
    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    bg="white"
    py="24px"
    px="30px"
  >
    <Flex alignItems="center" justifyContent="space-between">
      <Bloc>
        <Text fontWeight="bold">Pindo</Text>
      </Bloc>
      <Bloc>
        <Text fontWeight="bold">Receipt</Text>
      </Bloc>
    </Flex>
    <Box
      my="15px"
      width="100%"
      style={{
        backgroundColor: '#D4DEE8',
        borderRadius: '20px',
        height: '0.9px',
      }}
    />
    <Flex
      flexWrap="wrap"
      pb="15px"
      style={{
        gap: '27px',
      }}
    >
      <Flex
        flexDirection="column"
        style={{
          gap: '4px',
        }}
      >
        <Bloc pb="6px">
          <Text fontWeight="bold" fontSize="14px">
            From
          </Text>
        </Bloc>
        <Text fontSize="12px">{receiptDoc.recipient.orgName}</Text>
        <Text fontSize="12px">{receiptDoc.recipient.address}</Text>
        <Text fontSize="12px">{receiptDoc.recipient.email}</Text>
        <Text fontSize="12px">{receiptDoc.recipient.phoneNumber}</Text>
      </Flex>
      <Flex
        flexDirection="column"
        maxWidth="200px"
        style={{
          gap: '4px',
        }}
      >
        <Bloc pb="6px">
          <Text fontWeight="bold" fontSize="14px">
            To
          </Text>
        </Bloc>

        <Text fontSize="12px">{receiptDoc.company}</Text>
        <Text fontSize="12px">{receiptDoc.from}</Text>
        <Text fontSize="12px">{receiptDoc.address}</Text>
      </Flex>
      <Flex
        flexDirection="column"
        style={{
          gap: '4px',
        }}
      >
        <Bloc pb="6px">
          <Text fontWeight="bold" fontSize="14px">
            Details
          </Text>
        </Bloc>
        <Text fontSize="12px">
          {' '}
          Receipt number: {receiptDoc.recipient.phoneNumber}
        </Text>
        <Text fontSize="12px">Crediting date: {receiptDoc.dateGenerated}</Text>
        <Text fontSize="12px"> Payment method: {receiptDoc.PaymentMethod}</Text>
      </Flex>
    </Flex>
    <Text fontWeight="bold" fontSize="14px">
      Summary
    </Text>

    <Box
      my="15px"
      width="100%"
      style={{
        backgroundColor: '#D4DEE8',
        borderRadius: '20px',
        height: '0.9px',
      }}
    />
    <Flex alignItems="stretch" py="10px" justifyContent="space-between">
      <Text fontSize="14px" fontWeight="bold">
        Total credited
      </Text>

      <Text fontSize="14px" fontWeight="bold">
        ${receiptDoc.summary.totalCredit}
      </Text>
    </Flex>
    <Box
      my="15px"
      width="100%"
      style={{
        backgroundColor: '#D4DEE8',
        borderRadius: '20px',
        height: '0.9px',
      }}
    />

    <Text as="p" fontSize="12px" fontWeight="normal" fontStyle="normal">
      This is your receipt for loading your account, be sure to keep it in a
      safe place for further reference. Thank you for using Pindo!
    </Text>
  </Bloc>
);

const InvoicePreview = ({ invoiceDoc, invoiceRates }) => {
  const totalAmountConsumed =
    invoiceRates && invoiceRates.reduce((acc, item) => acc + item.sumAmount, 0);
  return (
    <Bloc
      borderRadius="20px"
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
      bg="white"
      py="24px"
      px="30px"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Bloc>
          <Text fontWeight="bold">Pindo</Text>
        </Bloc>
        <Bloc>
          <Text fontWeight="bold">Invoice</Text>
        </Bloc>
      </Flex>
      <Box
        my="15px"
        width="100%"
        style={{
          backgroundColor: '#D4DEE8',
          borderRadius: '20px',
          height: '0.9px',
        }}
      />
      <Flex
        flexWrap="wrap"
        pb="15px"
        style={{
          gap: '27px',
        }}
      >
        <Flex flexDirection="column">
          <Bloc pb="6px">
            <Text fontWeight="bold" fontSize="14px">
              From
            </Text>
          </Bloc>
          <Text fontSize="12px">{invoiceDoc.company}</Text>
          <Text fontSize="12px">{invoiceDoc.from}</Text>
          <Text fontSize="12px">{invoiceDoc.address}</Text>
        </Flex>
        <Flex
          flexDirection="column"
          maxWidth="200px"
          style={{
            gap: '4px',
          }}
        >
          <Bloc pb="6px">
            <Text fontWeight="bold" fontSize="14px">
              To
            </Text>
          </Bloc>
          <Text fontSize="12px">{invoiceDoc.recipient.orgName}</Text>
          <Text fontSize="12px">{invoiceDoc.recipient.address}</Text>
          <Text fontSize="12px">{invoiceDoc.recipient.email}</Text>
          <Text fontSize="12px">{invoiceDoc.recipient.phoneNumber}</Text>
        </Flex>
        <Flex flexDirection="column">
          <Bloc pb="6px">
            <Text fontWeight="bold" fontSize="14px">
              Details
            </Text>
          </Bloc>
          <Text fontSize="12px">Billing Period:</Text>
          <Text fontSize="12px">{invoiceDoc.date}</Text>
        </Flex>
      </Flex>
      <Text fontWeight="bold" fontSize="14px">
        Summary
      </Text>
      <Box
        my="15px"
        width="100%"
        style={{
          backgroundColor: '#D4DEE8',
          borderRadius: '20px',
          height: '0.9px',
        }}
      />
      <Flex alignItems="stretch" py="10px" justifyContent="space-between">
        <Text fontSize="14px" fontWeight="bold">
          Total Due
        </Text>

        {invoiceDoc.summary && (
          <Text fontSize="14px" fontWeight="bold">
            ${invoiceDoc.summary.totalDue.toFixed(2)}
          </Text>
        )}
      </Flex>

      <Box
        my="15px"
        width="100%"
        style={{
          backgroundColor: '#D4DEE8',
          borderRadius: '20px',
          height: '0.9px',
        }}
      />

      {invoiceRates && invoiceRates.length > 0 && (
        <>
          <Bloc mb="15px">
            <Text fontSize="12px">
              Detailed breakdown per product down below
            </Text>
          </Bloc>
          <Flex
            alignItems="center"
            style={{
              gap: '20px',
            }}
            py="20px"
          >
            <Text fontSize="14px" color="#0EB6A2">
              SMS
            </Text>
            <Bloc height="1px" width={1} bg="#D4DEE8" />
          </Flex>

          <Bloc hide={[true, null]}>
            <Flex
              mb="10px"
              style={{
                columnGap: '10px',
              }}
            >
              <Bloc
                width={['30%', '40%', '50%']}
                fontSize="13px"
                fontWeight="bold"
              >
                country
              </Bloc>
              <Flex flex={1} justifyContent="space-between">
                <Bloc flex="1" fontSize="13px" fontWeight="bold">
                  Total SMS
                </Bloc>
                <Bloc flex="1" fontSize="13px" fontWeight="bold">
                  Message items
                </Bloc>
                <Bloc flex="1" fontSize="13px" fontWeight="bold">
                  Retry count
                </Bloc>
              </Flex>

              <Bloc
                display="flex"
                justifyContent="flex-end"
                width="10%"
                fontSize="13px"
                fontWeight="bold"
              >
                Total
              </Bloc>
            </Flex>

            <Flex
              style={{
                gap: '10px',
              }}
              flexDirection="column"
            >
              {invoiceRates &&
                invoiceRates.map((item, index) => (
                  <Flex
                    style={{
                      columnGap: '10px',
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Bloc width={['30%', '40%', '50%']} fontSize="13px">
                      {item.country}
                    </Bloc>
                    <Flex flex={1} justifyContent="space-between">
                      <Bloc width={1} fontSize="13px">
                        {item.sumSmsCount}
                      </Bloc>
                      <Bloc width={1} fontSize="13px">
                        {item.sumSmsItemCount}
                      </Bloc>
                      <Bloc width={1} fontSize="13px">
                        {item.sumSmsItemRetries}
                      </Bloc>
                    </Flex>
                    <Bloc
                      width="10%"
                      display="flex"
                      justifyContent="flex-end"
                      fontSize="13px"
                    >
                      ${item.sumAmount.toFixed(2)}
                    </Bloc>
                  </Flex>
                ))}
            </Flex>
            <Bloc mt="20px" />
            <Flex justifyContent="flex-end">
              <Bloc fontWeight="bold" fontSize="13px">
                Subtotal: ${totalAmountConsumed.toFixed(2)}
              </Bloc>
            </Flex>
          </Bloc>
        </>
      )}
      <Bloc hide={[null, true]}>
        <Flex mb="10px">
          <Bloc width={['30%', '40%', '50%']} fontSize="10px" fontWeight="bold">
            Country
          </Bloc>
          <Flex flex={1} justifyContent="space-between">
            <Bloc flex="1" fontSize="9px" fontWeight="bold">
              Total SMS
            </Bloc>
            <Bloc flex="1" fontSize="9px" fontWeight="bold">
              Message items
            </Bloc>
            <Bloc flex="1" fontSize="9px" fontWeight="bold">
              Retry count
            </Bloc>
          </Flex>

          <Bloc
            display="flex"
            justifyContent="flex-end"
            width="10%"
            fontSize="9px"
            fontWeight="bold"
          >
            Total
          </Bloc>
        </Flex>
        <Flex
          style={{
            gap: '10px',
          }}
          flexDirection="column"
        >
          {invoiceRates &&
            invoiceRates.map((item, index) => (
              <Flex
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Bloc width={['30%', '40%', '50%']} fontSize="10px">
                  {item.country}
                </Bloc>
                <Flex flex={1} justifyContent="space-between">
                  <Bloc width={1} fontSize="10px">
                    {item.sumSmsCount}
                  </Bloc>
                  <Bloc width={1} fontSize="10px">
                    {item.sumSmsItemCount}
                  </Bloc>
                  <Bloc width={1} fontSize="10px">
                    {item.sumSmsItemRetries}
                  </Bloc>
                </Flex>
                <Bloc
                  width="10%"
                  display="flex"
                  justifyContent="flex-end"
                  fontSize="10px"
                >
                  ${item.sumAmount.toFixed(2)}
                </Bloc>
              </Flex>
            ))}
        </Flex>
      </Bloc>

      <Bloc mb="20px" />

      <Text as="p" style={{ lineHeight: '12px' }} fontSize={['10px', '12px']}>
        Detailed usage information is available in the breakdown section per
        product
      </Text>
    </Bloc>
  );
};
