import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';
import getUserLocation from '../services/thirdParty/location';

const userAPI = new UserAPI();

export const useFetchUserSelf = (token) => {
  const { data, status, isLoading, error } = useQuery({
    queryKey: ['currentUser', token],
    queryFn: () => userAPI.getCurrentUser(token),
    placeholderData: keepPreviousData,
    enabled: !!token,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  return { data, status, isLoading, error };
};

export const useFetchUserProfile = (token, currentUser) => {
  let isEnabled = false;
  if (currentUser && !currentUser.force && token) isEnabled = true;
  const {
    data: profileData,
    isLoading: isProfileLoading,
    isFetching: isProfileFetching,
  } = useQuery({
    queryKey: ['selfProfile', token],
    queryFn: () => userAPI.getSelfProfile(token),
    placeholderData: keepPreviousData,

    enabled: isEnabled,
  });
  return { profileData, isProfileLoading, isProfileFetching };
};

export const useFetchUserLocation = () => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['userLocation'],
    queryFn: () => getUserLocation(),
  });
  return { data, status, isLoading };
};
