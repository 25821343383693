import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex } from 'blocjs';
import { Spinner } from '../../components';

const InviteAccepted = ({ isAuth }) => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const orgId = new URLSearchParams(location.search).get('org_id');
  const { push } = useHistory();

  useEffect(() => {
    if (token && orgId) {
      let redirectPath = '/';
      if (isAuth) {
        redirectPath = '/dashboard';
      } else {
        redirectPath = '/login';
      }
      push({ pathname: redirectPath, inviteData: { token, orgId } });
    } else {
      push('/unauthorized');
    }
  });

  return (
    <Flex alignItems="center" justifyContent="center" height={500} width={1}>
      <Spinner size="extraLarge" />
    </Flex>
  );
};

export default InviteAccepted;
