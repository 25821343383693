import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Spinner from '../spinner';

const StyledButton = styled('button')(
  {
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    outline: 'none',
    color: 'black',
    textTransform: 'uppercase',
  },
  variant({
    prop: 'profile',
    scale: 'buttons',
    variants: {
      primary: {
        color: 'surface',
        bg: 'primary',
      },
      accent: {
        color: 'surface',
        bg: 'accentDark',
      },

      warning: {
        color: 'surface',
        bg: 'warning',
      },
    },
  }),
  variant({
    prop: 'size',
    variants: {
      large: {
        fontSize: 14,
        borderRadius: 6,
        px: 6,
        py: 4,
      },

      datepicker: {
        fontSize: 0,
        borderRadius: '9999px',
        px: 5,
        py: '12px',
      },
      normal: {
        fontSize: 1,
        borderRadius: 5,
        px: '20px',
        py: '12px',
      },
      dashboard: {
        fontSize: ['12px', '14px'],
        borderRadius: 5,
        px: '23px',
        py: '12px',
      },
      contact: {
        fontSize: '12px',
        fontWeight: 'normal',
        textTransform: 'initial',
        borderRadius: 5,
        px: '23px',
        py: '11px',
      },
      contactSmall: {
        fontSize: 0,
        fontWeight: 'normal',
        textTransform: 'initial',
        borderRadius: 4,
        px: '16px',
        py: '10px',
      },
      crediting: {
        fontSize: ['12px', '14px'],
        borderRadius: '9999px',
        px: '23px',
        py: '12px',
      },
      small: {
        fontSize: 0,
        borderRadius: 4,
        px: 5,
        py: '10px',
      },
    },
  })
);

const Button = ({ children, loading = false, ...rest }) => {
  const getSpinner = (isLoading) =>
    isLoading && <Spinner size={rest.size} color="primaryLight" />;
  return (
    <StyledButton disabled={loading} {...rest}>
      {children}
      {getSpinner(loading)}
    </StyledButton>
  );
};

export default Button;
