import React from 'react';
import { Bloc } from 'blocjs';
import { Layout } from '../../components';

const TermsOfUse = () => (
  <Layout pt={[3, 8]} pb={8} data-testid="terms-of-use">
    <Bloc as="h1">Pindo Service Level Agreement (SLA)</Bloc>

        <Bloc as="p">A lot of companies rely on Pindo. Just like you, Pindo is our company&apos;s lifeline. It’s where we make decisions, share designs, debate ideas, broadcast company-wide announcements, and keep up to date on what everyone&apos;s working on. That&apos;s why we guarantee 99.99% monthly uptime to teams on Pindo.</Bloc>

        <Bloc as="h2">What is “uptime”?</Bloc>

        <Bloc as="p">“Uptime” refers to the period of time when Pindo is up and running.</Bloc>

        <Bloc as="h2">What happens if it’s below 99.99% (Service Commitment)?</Bloc>

        <Bloc as="p">Our Ops team is working so that we won’t find ourselves in that situation. But stuff happens, so if our uptime drops below 99.99%, we will provide an automatic credit under our SLA to our customers on their next billing cycle. For any given day where an outage exceeds 1 hour, we’ll credit your account 10x for a full day that is equal to the average SMS you use on a daily basis on Pindo. </Bloc>
        <Bloc as="p">If during any month throughout the Term for which Customer has purchased the Pindo programmable SMS Solution, the SMS API fails to achieve a Successful Connection (as defined below) rate of at least, 1500 requests per second then in addition to any Service Credits calculated regarding the Monthly Uptime Percentage Threshold, Customer shall be eligible to receive a Service Credit for such package, That is the full limit of our liability under this SLA. Now you can get back to your work with peace of mind knowing that we’ve got you covered. </Bloc>

        <Bloc as="h2">How do we track that uptime?</Bloc>

        <Bloc as="p">We track uptime by measuring the server-side error rate along with using server monitoring software to look at results from ping tests, web server tests, TCP port tests, and website tests.</Bloc>

        <Bloc as="h2">Will this change in the future?</Bloc>

        <Bloc as="p">Pindo may update this SLA once in a blue moon — we’ll notify you about significant changes by emailing the account owner or by placing a prominent notice on our site.</Bloc>


        <Bloc as="h2">What if I have questions about this SLA?</Bloc>

        <Bloc as="p">Please get in touch with our friendly support team and we’ll be happy to answer them!</Bloc>

  </Layout>
);

export default TermsOfUse;
