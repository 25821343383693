import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Bloc, Flex } from 'blocjs';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserAPI from '../../../../services';
import { countriesList, USERNAME_REGEX } from '../../../../utils';
import {
  Icon,
  Button,
  Inputfield,
  CheckedBox,
  Countries,
  Text,
} from '../../../../components';
import CustomToast from '../../../../components/toast/customToast';

const AddOrUpdateSenderID = ({
  setIsAddOrUpdateSenderID,
  isEditingSenderID,
  senderIDData = undefined,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    formState,
    setValue,
  } = useForm();

  const [showToast, setShowToast] = useState(false);
  const [checkedBox, setCheckedBox] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryError, setCountryError] = useState(null);
  const [aggrementError, setAggrementError] = useState('');
  const [senderIdName, setSenderIdName] = useState('');
  const [, setOverallError] = useState(false);

  const { mutate, status, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.requestSenderID(payload),
  });
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({ mutationFn: (payload) => userAPI.updateSenderID(payload) });

  useEffect(() => {
    if (isEditingSenderID && senderIDData) {
      const senderIDName = senderIDData.name;
      setSenderIdName(senderIDName);
      const senderIDCountries = senderIDData.countries || [];
      if (senderIDCountries.length > 0) {
        const senderIDCountriesFormatted = countriesList.filter((obj) =>
          senderIDCountries.some(
            (existingCountry) => existingCountry.iso_code === obj.code
          )
        );
        setSelectedCountries(senderIDCountriesFormatted);
      }
    }
  }, [senderIDData, isEditingSenderID]);

  const onCheckBox = (e) => {
    e.stopPropagation();
    setCheckedBox(!checkedBox);
    setAggrementError('');
  };

  const setCheckbox = () => {
    if (checkedBox) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16} display="flex">
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  useEffect(() => {
    if (selectedCountries < 1 || !checkedBox) {
      setOverallError(true);
    } else {
      setOverallError(false);
    }
  }, [checkedBox, formState.isValid, selectedCountries]);

  const onFormSubmit = async (dataInputs) => {
    if (selectedCountries < 1 || !checkedBox) {
      if (selectedCountries < 1) setCountryError('Select a country');
      if (!checkedBox)
        setAggrementError(
          'Agree to the terms and conditions before requesting'
        );
      return;
    }

    const codeCountries = selectedCountries.map((country) => country.code);

    const parsedSenderID = dataInputs.sender_id;
    const payload = {
      sender_id: parsedSenderID,
      countries: codeCountries,
    };

    if (isEditingSenderID) {
      const { id } = senderIDData;
      const request = { bodyReq: payload, id };
      await mutateUpdate(request, {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setTimeout(async () => {
            setShowToast(false);
            setIsAddOrUpdateSenderID(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    } else {
      await mutate(payload, {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setTimeout(async () => {
            setShowToast(false);
            setIsAddOrUpdateSenderID(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  // Function to handle sender ID input change
  const handleSenderIDChange = (e) => {
    if (e.target.value.length > 11) {
      setError('sender_id', {
        type: 'maxLength',
        message: 'Sender ID must be 11 characters or less',
      });
      setValue('sender_id', e.target.value);
      setOverallError(true);
    } else {
      setOverallError(false);
      clearError('sender_id');
      setValue('sender_id', e.target.value);
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Box mt={2} />
      {status === 'error' && showToast && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          message={error?.response?.data?.error?.message}
          status="error"
          closed
        />
      )}
      {status === 'success' && showToast && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          closed
          message="A request has been sent successfully"
          status="success"
        />
      )}
      {statusUpdate === 'error' && showToast && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          closed
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          closed
          message="A request has been sent successfully"
          status="success"
        />
      )}
      <Bloc
        fontSize="18px"
        fontWeight="500"
        borderBottom="1px solid #E5E5E5"
        pb="20px"
        color="black"
      >
        New sender ID request
      </Bloc>
      <Flex flexDirection={['column', 'row']}>
        <Box pt="20px" width={[1, 350]}>
          <Inputfield
            name="Sender ID"
            hint="Sender ID should be 11 characters max."
            defaultValue={senderIdName}
            onChange={handleSenderIDChange}
            ref={register({
              required: 'Sender ID is required',
              maxLength: {
                value: 11, // Maximum length validation
                message: 'Sender ID must be 11 characters or less',
              },
              pattern: {
                value: USERNAME_REGEX,
                message: 'Enter a valid Sender ID',
              },
            })}
            error={
              errors?.sender_id?.message || errors?.sender_id?.types?.message
            }
          />
        </Box>
      </Flex>
      <Flex flexDirection={['column', 'row']}>
        <Box
          minWidth={['100%', 350]}
          maxWidth={['100%', selectedCountries.length > 1 ? 724 : '100%']}
        >
          <Bloc my={3} fontSize={14} display="inline-block" as="label">
            Country
          </Bloc>
          <Countries
            hasSearch
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            hasError={countryError || false}
            setHasError={setCountryError}
          />
          {countryError && (
            <Bloc as="p" color="surfaces.4" fontSize={12} mt={3} mb={0}>
              {countryError}
            </Bloc>
          )}
        </Box>
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Box mt="20px">
          <Bloc fontSize={1} fontWeight="500" my={3} as="label">
            Terms and Conditions:
          </Bloc>
          <Flex
            style={{
              cursor: 'pointer',
            }}
            alignItems="center"
            mt="15px"
          >
            <Bloc
              as="span"
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(e)}
            >
              {setCheckbox(true)}
            </Bloc>
            <Bloc
              style={{
                cursor: 'pointer',
              }}
              width="100%"
              fontSize={12}
              color="#757F84"
              ml={12}
            >
              <Text
                color="accents.1"
                as={Link}
                fontWeight="normal"
                fontSize={1}
                to="/senderid-policies"
                target="_blank"
              >
                I agree to abide by the sender ID usage policies and guidelines
              </Text>
            </Bloc>
          </Flex>
          {aggrementError && (
            <Bloc as="p" color="surfaces.4" fontSize={12} mt={3} mb={0}>
              {aggrementError}
            </Bloc>
          )}
        </Box>
      </Flex>

      <Flex
        mt="20px"
        style={{
          borderTop: '1px solid #E5E5E5',
          paddingTop: '20px',
          columnGap: '10px',
        }}
        flexWrap="wrap"
      >
        <Box>
          <Button
            disable={!formState.isValid || isPending}
            profile="accentLight"
            size="contact"
            type="submit"
            loading={isPending}
            data-testid="update-btn"
          >
            Send Request
          </Button>
        </Box>
        <Box>
          <Button
            profile="secondary"
            size="contact"
            onClick={() => setIsAddOrUpdateSenderID(false)}
            data-test="cancel-btn"
          >
            Cancel
          </Button>
        </Box>
      </Flex>
    </Bloc>
  );
};

export default AddOrUpdateSenderID;
