import { Bloc, Flex } from 'blocjs';
import React from 'react';
import { Inputfield } from '../inputs';
import DefaultDropdownWithSearch from '../dropDown';
import Icon from '../icon';

const services = [
  {
    name: 'Filter',
    id: 1,
  },
  {
    name: 'All',
    id: 1,
  },
  {
    name: 'Active',
    id: 2,
  },
];

const SearchAndFilterBar = ({
  handleSearch,
  handleFilter,
  searchInputRef,
  activeTab,
  isItemsDropdown,
  setIsItemsDropdown,
}) => (
  <Bloc
    style={{
      borderBottom: '1px solid #E0DBDB',
    }}
    py="15px"
    px="23px"
  >
    <Flex justifyContent="space-between">
      <Flex
        alignItems="center"
        style={{
          border: '1px solid #E0DBDB',
          borderRadius: '4px',
          padding: '0 10px',
        }}
      >
        <Icon name="search" color="#9DA1A8" size={16} dimension="24x24" />
        <Inputfield
          ref={searchInputRef}
          size="contact"
          style={{
            border: 'none',

            textTransform: 'capitalize',
          }}
          required
          name="text"
          placeholder={`Search ${
            activeTab === 'groups' ? 'groups' : 'contacts'
          }`}
          onChange={handleSearch}
          hasLabel={false}
          hasHint={false}
        />
      </Flex>
      {activeTab !== 'archived' &&
        activeTab !== 'groups' &&
        activeTab !== 'active' && (
          <Bloc>
            <DefaultDropdownWithSearch
              isItemsDropdown={isItemsDropdown}
              setIsItemsDropdown={setIsItemsDropdown}
              handleSelectedItem={handleFilter}
              services={services}
              serviceInUseObj={services[0]}
            />
          </Bloc>
        )}
    </Flex>
  </Bloc>
);

export default SearchAndFilterBar;
