import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Bloc } from 'blocjs';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useHistory } from 'react-router-dom';
import { Pagination } from '../../components';

import {
  AddNewContactComponent,
  ContactContainer,
  ContactDetailsForTab,
  EditFieldComponent,
  EditGroupComponent,
  HeaderContainer,
  ImportContactsComponents,
  PreviewGroups,
  PreviewSingleContact,
  TabsComponent,
  RenderToaster,
  SearchAndFilterBar,
} from '../../components/contact';
import { tabs } from '../../components/contact/dummyData';
import UserAPI from '../../services';

const ContactPage = () => {
  const userAPI = new UserAPI();
  const itemsPerPage = 25;

  const location = useLocation();
  const state = location.state && location.state;
  const { push } = useHistory();
  const activeTab = new URLSearchParams(location.search).get('tab');
  const activePage = new URLSearchParams(location.search).get('page');
  const searchInputRef = useRef();

  const [action, setAction] = useState({});
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const [, setTabName] = useState(activeTab);
  const [searchValue, setSearchValue] = useState('');
  const [fetchEnable, setFetchEnable] = useState(true);
  const [groupFetchEnable, setGroupFetchEnable] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);

  const fetchContactsStatistics = () => userAPI.getContactStats();

  const { data: fieldsData } = useQuery({
    queryKey: ['get_all_fields'],
    queryFn: () => userAPI.getContactFields(),
  });

  const { data: statistics } = useQuery({
    queryKey: ['contactsStatistics'],
    queryFn: fetchContactsStatistics,
  });

  const { data: contactsData, isLoading: contactsDataIsFetching } = useQuery({
    queryKey: ['get_all_contacts', { pageNumber }, activePage],
    queryFn: () =>
      userAPI.getContacts({
        pageNumber: searchValue ? 1 : pageNumber,
        itemsPerPage,
        groupId: state?.group_id,
        name: searchValue,
      }),
    enabled: fetchEnable,
  });

  const { data: archivedContactsData, isLoading: archivedContactsIsFetching } =
    useQuery({
      queryKey: ['get_all_archived'],
      queryFn: () => userAPI.getArchivedContacts(),
    });

  const { data: groupsData, isLoading: isGroupeDataFetching } = useQuery({
    queryKey: ['get_all_groups'],
    queryFn: () =>
      userAPI.getContactGroups({
        name: searchValue,
      }),
    enabled: groupFetchEnable,
  });

  const handleSetAction = useCallback(
    ({ trigger, result, toggleToast = '' }) => {
      setAction({
        toggleToast,
        trigger,
        result,
      });
    },
    []
  );

  const handleActions = (trigger, result, toggleToast) => {
    handleSetAction({ trigger, result, toggleToast });
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  tabs.active.total = statistics?.active_count || 0;
  tabs.archived.total = statistics?.archived_count || 0;
  tabs.groups.total = groupsData?.contact_groups.length || 0;
  tabs.contactField.total = fieldsData?.contact_fields.length - 2 || 0;

  const handleSwitchTab = (path, pathname) => {
    const searchParams = new URLSearchParams(location.search);
    setTabName(pathname);
    searchParams.set('tab', path);
    push({
      search: searchParams.toString(),
    });
  };

  const handleSelectedItem = (e, activeItem) => {
    const selected = activeItem === 'All' ? '' : activeItem;
    e.stopPropagation();
    setIsItemsDropdown(selected);
  };

  let debounceTimer;

  const handleSearch = (e) => {
    const query = e.target.value;
    e.preventDefault();
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      if (activeTab === 'groups') {
        setGroupFetchEnable(false);
      } else {
        setFetchEnable(false);
      }
      setTimeout(() => {
        setSearchValue(query);
        if (activeTab === 'groups') {
          setGroupFetchEnable(true);
        } else {
          setFetchEnable(true);
        }
        setTimeout(() => {
          setSearchValue('');
        }, 0);
      }, 500);
    }, 1000);
  };

  const handleSwitchPage = (path, stateData) => {
    const searchParams = new URLSearchParams(location.search);

    if (path) {
      searchParams.set('page', path);
    } else {
      searchParams.delete('page');
    }
    push({
      search: searchParams.toString(),
      state: stateData,
    });
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, [activeTab]);

  switch (activePage) {
    case 'add-contact':
      return <AddNewContactComponent handleSwitchPage={handleSwitchPage} />;
    case 'view-contact':
      return <PreviewSingleContact handleSwitchPage={handleSwitchPage} />;
    case 'edit-contact':
      return (
        <AddNewContactComponent
          activePage={activePage}
          handleSwitchPage={handleSwitchPage}
        />
      );
    case 'view-group':
      return (
        <PreviewGroups
          handleSwitchPage={handleSwitchPage}
          handleActions={handleActions}
          action={action}
        />
      );
    case 'import':
      return (
        <ImportContactsComponents
          handleAction={handleActions}
          handleSwitchPage={handleSwitchPage}
        />
      );

    case 'edit-group':
      return (
        <EditGroupComponent
          handleSwitchPage={handleSwitchPage}
          groupState={state}
        />
      );

    case 'edit-field':
      return (
        <EditFieldComponent
          handleSwitchPage={handleSwitchPage}
          fieldState={state}
          activePage={activePage}
        />
      );
    case 'new-field':
      return (
        <EditFieldComponent
          handleSwitchPage={handleSwitchPage}
          fieldState={state}
        />
      );

    default:
      return (
        <ContactContainer
          overflow
          footer={
            activeTab !== 'contactField' && (
              <Bloc pt="10px">
                {!contactsDataIsFetching && !isGroupeDataFetching && (
                  <RenderPagination
                    activeTab={activeTab}
                    contactsDataIsFetching={contactsDataIsFetching}
                    pageNumber={pageNumber}
                    itemPerPage={itemsPerPage}
                    changePage={changePage}
                    archivedContactsData={archivedContactsData}
                    archivedContactsIsFetching={archivedContactsIsFetching}
                    contactsData={contactsData}
                    groupContactsData={groupsData}
                  />
                )}
              </Bloc>
            )
          }
          toast={
            <RenderToaster
              handleAction={handleActions}
              action={action}
              handleSwitchPage={handleSwitchPage}
            />
          }
        >
          <HeaderContainer
            activeTab={activeTab}
            action={action}
            handleSwitchPage={handleSwitchPage}
            handleActions={handleActions}
          />
          <TabsComponent
            handleSwitchTab={handleSwitchTab}
            activeTab={activeTab}
          />
          {activeTab !== 'contactField' && (
            <SearchAndFilterBar
              handleFilter={handleSelectedItem}
              activeTab={activeTab}
              searchInputRef={searchInputRef}
              handleSearch={handleSearch}
              setIsItemsDropdown={setIsItemsDropdown}
              isItemsDropdown={isItemsDropdown}
            />
          )}

          <RenderContactChildren>
            <ContactDetailsForTab
              archivedContactsData={archivedContactsData?.contacts}
              archivedContactsIsFetching={archivedContactsIsFetching}
              contactsData={contactsData}
              contactsDataIsFetching={contactsDataIsFetching}
              activeTab={activeTab}
              groupsData={groupsData}
              isGroupeDataFetching={isGroupeDataFetching}
              handleSwitchPage={handleSwitchPage}
              handleActions={handleActions}
            />
          </RenderContactChildren>
        </ContactContainer>
      );
  }
};

export default ContactPage;

const RenderContactChildren = ({ children }) => <Bloc>{children}</Bloc>;

const RenderPagination = ({
  archivedContactsData,
  archivedContactsIsFetching,
  activeTab,
  contactsDataIsFetching,
  pageNumber,
  contactsData,
  itemPerPage,
  groupContactsData,
  changePage,
}) => {
  switch (activeTab) {
    case 'active':
      return (
        <Pagination
          itemsPerPage={itemPerPage}
          pageNumber={pageNumber}
          pages={contactsData?.pages}
          pageCount={contactsData?.pages?.pages}
          ml={0}
          isFetching={contactsDataIsFetching}
          isLoading={contactsDataIsFetching}
          changePage={changePage}
        />
      );
    case 'archived':
      return (
        <Pagination
          itemsPerPage={itemPerPage}
          pageNumber={pageNumber}
          pages={archivedContactsData?.pages}
          pageCount={archivedContactsData?.pages?.pages}
          ml={0}
          isFetching={archivedContactsIsFetching}
          isLoading={archivedContactsIsFetching}
          changePage={changePage}
        />
      );
    case 'groups':
      return (
        <Pagination
          itemsPerPage={itemPerPage}
          pageNumber={pageNumber}
          pages={groupContactsData?.pages}
          pageCount={groupContactsData?.pages?.pages}
          ml={0}
          isFetching={false}
          isLoading={false}
          changePage={changePage}
        />
      );
    default:
      return null;
  }
};
