import React, { useEffect, useRef } from 'react';
import { Bloc, Flex } from 'blocjs';
import { dropdownLayout, inputSelectBox } from './style';
import { Chevron } from '../carets';

const styles = {
  dropdownLayout,
  inputSelectBox,
};

function Dropdown({
  children,
  isDashboard = false,
  isDropdownOpen,
  activeItem,
  setIsDropdownOpen,
  itemsList,
  disabled,
  hasError,
  height,
  hasPlaceholder,
}) {
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  return (
    <Bloc
      ref={ref}
      aria-hidden="true"
      position="relative"
      ml={0}
      style={styles.inputSelectBox}
    >
      <Bloc
        data-testid="select-dropdown"
        aria-hidden="true"
        borderColor={hasError ? 'surfaces.4' : 'surfaces.2'}
        borderStyle="solid"
        borderWidth="1px"
        borderRadius="6px"
        height={height}
        display="flex"
        backgroundColor={disabled ? '#F7F9FB' : 'white'}
        alignItems="center"
        justifyContent="space-between"
        boxSizing="border-box"
      >
        <Bloc display="flex" alignItems="center" overflow="hidden" width={1}>
          <Bloc
            display="flex"
            alignItems="center"
            minWidth={32}
            height="100%"
            justifyContent="space-between"
            px={3}
            boxSizing="border-box"
            id="select-box"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
            style={{ cursor: 'pointer', gap: '6px' }}
            width={1}
          >
            <Flex
              alignItems="center"
              style={{
                gap: '6px',
              }}
            >
              <Flex alignItems="center">
                <Bloc
                  style={{ textTransform: 'capitalize' }}
                  mr={2}
                  as="p"
                  fontSize="13px"
                  color={hasPlaceholder ? '#e0d1bdb' : '##3E484D'}
                >
                  {isDashboard ? (
                    <Bloc as="p" fontSize="12px" color="#757F84">
                      {activeItem?.name}
                    </Bloc>
                  ) : (
                    activeItem?.name
                  )}
                </Bloc>
              </Flex>
            </Flex>
            <Chevron
              color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
              size={16}
            />
          </Bloc>
        </Bloc>
      </Bloc>

      {!disabled && isDropdownOpen && (
        <Bloc
          position="absolute"
          stop={60}
          width={1}
          right={0}
          zIndex={100}
          backgroundColor="white"
          boxSizing="border-box"
          overflow="auto"
          px="11px"
          borderStyle="solid"
          borderColor="#DEE1E7"
          borderRadius="6px"
          height={itemsList.length > 0 ? 'auto' : 126}
          style={styles.dropdownLayout}
          marginTop={2}
          maxHeight={200}
          boxShadow="0px 2px 4px rgba(27, 29, 34, 0.05), 0px 4px 4px rgba(27, 29, 34, 0.02), 0px 8px 8px rgba(27, 29, 34, 0.04), 0px 16px 16px rgba(27, 29, 34, 0.02), 0px 24px 30px rgba(27, 29, 34, 0.02)"
        >
          {children}
        </Bloc>
      )}
    </Bloc>
  );
}

export default Dropdown;
