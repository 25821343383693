import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useLocation, useHistory, Link } from 'react-router-dom';
import {
  Text,
  Button,
  Layout,
  Spinner,
  Inputfield,
} from '../../../../components';
import {
  useFetchAllOrgs,
  useFetchGivenOrg,
  useFetchOrgSenderIDs,
} from '../../../../hooks';
import ListOrganisations from './listOrganisations';
import OrgDetails from './details';
import { textTruncate } from '../../../../utils';

const AdminManageOrg = () => {
  const location = useLocation();
  const history = useHistory();
  const orgDetailParamId = new URLSearchParams(location.search).get('orgId');
  const [searchOrgName, setSearchOrgName] = useState('');
  const [enableFetch, setEnableFetch] = useState(true);

  const [selectedOrganization, setSelectedOrganization] = useState(
    orgDetailParamId ?? null
  );
  const [selectedSenderIDs, setSelectedSenderIDs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const itemsPerPage = 25;

  const {
    data: orgsData,
    isLoading: isOrgsLoading,
    isFetching: isOrgsFetching,
  } = useFetchAllOrgs(
    pageNumber,
    itemsPerPage,
    undefined,
    undefined,
    undefined,
    enableFetch,
    searchOrgName
  );

  const { data: orgData, isLoading: isOrgLoading } =
    useFetchGivenOrg(selectedOrganization);

  const { data: orgSenderIDs, isLoading: isOrgSenderIDsLoading } =
    useFetchOrgSenderIDs(selectedOrganization);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedSenderIDs.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const goToOrgDetails = (org, e) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);

    // Add a new search parameter
    searchParams.set('orgId', org.original.id);
    // setShowOrgDetails(true);
    setSelectedOrganization(org.original.id);

    // Update the URL with the new search params
    history.push({
      search: searchParams.toString(),
    });
  };

  const handleBackToOrganizations = () => {
    setSelectedSenderIDs([]);
    setSelectedOrganization(null);
    const searchParams = new URLSearchParams(location.search);
    // Delete the specific search parameter
    searchParams.delete('orgId');
    searchParams.delete('senderId');
    // Update the URL with the modified search params
    history.push({
      search: searchParams.toString(),
    });
  };

  let debounceTimer;

  const handleSearchOrgName = (e) => {
    e.preventDefault();
    const query = e.target.value;
    setSearchOrgName(query);
    setEnableFetch(false);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setEnableFetch(true); // Enable fetching after debounce time
    }, 1500);
  };

  return (
    <Layout>
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        style={{
          gap: '16px',
        }}
        alignItems="center"
        mb={[6, 0]}
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={[0, 6]}>
          <Text
            as="h1"
            style={{
              lineHeight: '32px',
              fontWeight: '500',
              textTransform: 'capitalize',
            }}
            fontSize={[24, 28]}
          >
            {orgDetailParamId && orgData
              ? textTruncate(orgData.name, 23)
              : 'Organizations'}{' '}
            {isOrgsLoading && <Spinner size="large" />}
          </Text>
        </Box>
        {!orgDetailParamId ? (
          <Flex justifyContent="end">
            <Bloc mr={5} mb={5} as={Link} to="/admin/manage/billing">
              <Button profile="accentLight" size="small" data-test="update-btn">
                Billing
              </Button>
            </Bloc>
            <Bloc as={Link} mb={5} to="/admin/manage/senderids">
              <Button profile="accentLight" size="small" data-test="update-btn">
                Sender ID
              </Button>
            </Bloc>
          </Flex>
        ) : (
          <Flex justifyContent="end" mb={[0, 6]}>
            <Box style={{ float: 'right' }} width="100%">
              <Button
                profile="accentLight"
                size="contact"
                style={{ float: 'right' }}
                onClick={handleBackToOrganizations}
                data-testid="go-back-to-organizations"
              >
                Go back to all organizations
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
      {!orgDetailParamId && (
        <Bloc
          ml={[0, '16%']}
          justifyContent="space-between"
          backgroundColor="white"
          flexWrap="wrap"
          borderRadius="8px"
          border="1px solid #DEE1E7"
          alignItems="center"
          mb={6}
          padding="16px 24px"
        >
          <Flex
            alignItems="center"
            style={{
              columnGap: '20px',
            }}
          >
            <Bloc>
              <Inputfield
                name="text"
                placeholder="Search by name"
                hasHint={false}
                value={searchOrgName}
                onChange={handleSearchOrgName}
                hasLabel={false}
              />
            </Bloc>
            <Bloc>
              <Button
                profile="accentLight"
                size="normal"
                data-test="filter-btn"
                onClick={() => {
                  setEnableFetch(true);
                }}
              >
                Search
              </Button>
            </Bloc>
          </Flex>
        </Bloc>
      )}
      {orgDetailParamId && (
        <Box mb={0} display="inline-block" width="100%">
          <OrgDetails
            orgData={orgData}
            isOrgLoading={isOrgLoading}
            orgSenderIDs={orgSenderIDs}
            isOrgSenderIDsLoading={isOrgSenderIDsLoading}
          />
        </Box>
      )}

      {!orgDetailParamId && !isOrgsLoading && (
        <ListOrganisations
          isLoading={isOrgsLoading}
          isFetching={isOrgsFetching}
          changePage={changePage}
          itemsPerPage={itemsPerPage}
          pageNumber={pageNumber}
          data={orgsData}
          setRowBackgroundColor={setRowBackgroundColor}
          goToOrgDetails={goToOrgDetails}
        />
      )}
      <Box mt={100} />
    </Layout>
  );
};

export default AdminManageOrg;
