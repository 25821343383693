import { Bloc, Flex } from 'blocjs';
import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Chevron } from '../carets';
import Icon from '../icon';
import { search, searchInput } from '../selection/style';

const styles = {
  search,
  searchInput,
};

const NextLevelSelection = ({
  subject,
  width,
  minWidth,
  height = [32, 40],
  padding = '4px 10px',
  tab = false,
  contentChildren,
  dropdownItems,
  onSelectionChange,
  activeItemIndex,
  hasSearch,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [, setSearchedItem] = useState('');
  const [newList, seNewtList] = useState(dropdownItems);
  const [selectedItem, setSelectedItem] = useState(dropdownItems[0]);

  useEffect(() => {
    seNewtList(dropdownItems);
    setSelectedItem(dropdownItems[activeItemIndex]);
  }, [activeItemIndex, dropdownItems]);

  const searchItemName = (e, listOfItems, setSearchItem, setList) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setSearchItem(inputValue);

    const searchedInput = inputValue.trim().toLowerCase();

    if (searchedInput.length > 0) {
      const filterMatches = listOfItems.filter((el) =>
        el.value.toLowerCase().includes(searchedInput)
      );
      setList(filterMatches);
    } else {
      setList(listOfItems);
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsMenuOpen(false);
      }}
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Flex
        height={height}
        justifyContent="space-between"
        alignItems="center"
        px="12px"
        width={width}
        minWidth={minWidth}
        style={{
          borderRadius: '4px',
          gap: '10px',
          backgroundColor: 'white',
          border: '1px solid #d3d3d3',
          cursor: 'pointer',
          fontSize: '13px',
        }}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <Flex
          style={{
            gap: '5px',
          }}
        >
          {subject && <Bloc fontWeight="500">{subject} :</Bloc>}
          <Bloc
            style={{
              color: '#757F84',
            }}
          >
            {selectedItem?.value}
          </Bloc>
        </Flex>

        <Chevron
          rotate={isMenuOpen}
          color={isMenuOpen ? 'accents.8' : 'surfaces.3'}
          size={16}
        />
      </Flex>

      {isMenuOpen && (
        <Bloc
          position="absolute"
          top={42}
          width="100%"
          right={0}
          display="flex"
          flexDirection="column"
          padding={padding}
          zIndex={999}
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            gap: '10px',
            boxShadow:
              '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
          }}
        >
          {contentChildren}
          {hasSearch && (
            <Bloc
              display="flex"
              height={30}
              style={{
                border: '1px solid #d3d3d3',
                borderRadius: '100px',
              }}
              alignItems="center"
              padding="0px 12px"
            >
              <Icon name="search" color="#9DA1A8" size={12} dimension="24x24" />
              <Bloc
                as="input"
                type="text"
                onChange={(e) =>
                  searchItemName(e, dropdownItems, setSearchedItem, seNewtList)
                }
                placeholder="Search"
                margin={0}
                padding={0}
                style={styles.searchInput}
                color="#9DA1A8"
              />
            </Bloc>
          )}
          {newList.length > 0 ? (
            <Bloc as="ul" padding={0} margin={0}>
              {newList.map((item, index) => (
                <Bloc
                  as="li"
                  display="flex"
                  aria-hidden="true"
                  padding={12}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    cursor: 'pointer',
                    gap: '5px',
                    whiteSpace: 'nowrap',
                    borderRadius: '6px',
                  }}
                  alignItems="center"
                  className="hoverDefault"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsMenuOpen(false);
                    setSelectedItem(item.value);
                    onSelectionChange(e, item, tab);
                  }}
                >
                  <Bloc fontWeight="500" fontSize="12px">
                    {item.value}
                  </Bloc>
                  <Flex>
                    {item.value === selectedItem?.value && (
                      <Icon color="#0EB6A2" name="check" size={12} />
                    )}
                  </Flex>
                </Bloc>
              ))}
            </Bloc>
          ) : (
            <Bloc
              display="flex"
              flexDirection="column"
              alignItems="center"
              py="10px"
              justifyContent="center"
              style={{
                gap: '4px',
              }}
              fontSize="12px"
            >
              <Bloc as="span" bold fontWeight="bold">
                No result found
              </Bloc>
              <Bloc as="span" color="#9DA1A8" fontSize={12}>
                {' '}
                Try a different search.{' '}
              </Bloc>
            </Bloc>
          )}
        </Bloc>
      )}
    </ClickAwayListener>
  );
};

export default NextLevelSelection;
