import React, { useCallback, useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import ContactContainer from './container';
import Icon from '../icon';
import Button from '../button';
import RenderToaster from './renderToaster';

const PreviewSingleContact = ({ handleSwitchPage }) => {
  const { location } = useHistory();
  const [action, setAction] = useState({});

  const contact = location.state && location.state;
  const extraFields = contact?.extra_fields;

  const handleSetAction = useCallback(
    ({ trigger, result, toggleToast = '' }) => {
      setAction({
        toggleToast,
        trigger,
        result,
      });
    },
    []
  );

  const handleActions = useCallback(
    (trigger, result, toggleToast) => {
      handleSetAction({ trigger, result, toggleToast });
    },
    [handleSetAction]
  );

  return (
    <ContactContainer
      toast={
        <RenderToaster
          handleAction={handleActions}
          action={action}
          handleSwitchPage={handleSwitchPage}
        />
      }
    >
      <Box
        p="20px"
        style={{
          width: '100%',
        }}
      >
        <Flex
          flexWrap={['wrap', 'nowrap']}
          style={{
            width: '100%',
            gap: '10px',
          }}
          alignItems="center"
          justifyContent="space-between"
          pb="20px"
        >
          <Flex
            alignItems="center"
            style={{
              columnGap: '10px',
            }}
          >
            <Bloc hide={[true, null]}>
              <Icon name="user" color="black" size={20} />
            </Bloc>
            <Bloc
              width={['200px', '150px', '330px']}
              p={0}
              m={0}
              as="p"
              fontWeight={500}
              fontSize={['16px', '28px']}
              style={{
                whiteSpace: 'nowrap',
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {contact.name}
            </Bloc>
          </Flex>
          <Flex
            alignItems="center"
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              profile="accentLight"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              onClick={() => {
                handleSwitchPage('edit-contact', contact);
              }}
            >
              <Bloc as="span" hide={[null, true, null]}>
                Edit
              </Bloc>{' '}
              <Bloc as="span" hide={[true, null]}>
                Contact
              </Bloc>
            </Button>
            <Button
              profile="secondary"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
              }}
              onClick={() => {
                handleActions('active-tab', [contact], 'move-to-archived');
              }}
            >
              Archived
            </Button>
            <Button
              profile="secondary"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
              }}
              onClick={() => {
                handleSwitchPage();
              }}
            >
              <Bloc as="span" hide={[true, null]}>
                Go
              </Bloc>{' '}
              <Bloc as="span">back</Bloc>{' '}
              <Bloc as="span" hide={[true, null]}>
                to contacts
              </Bloc>
            </Button>
          </Flex>
        </Flex>
        <Flex
          alignItems="start"
          flexWrap={['wrap', 'nowrap']}
          pt="20px"
          style={{
            columnGap: '15px',
            rowGap: '12px',
          }}
          justifyContent="space-between"
        >
          <Bloc
            display="flex"
            alignItems="center"
            width="100%"
            style={{
              columnGap: '15px',
            }}
            bg="#F9F9F9"
            p="15px"
            borderRadius="10px"
          >
            <Bloc p="12px" borderRadius="9999px" bg="rgba(94, 235, 219, 0.2)">
              <Icon size={20} color="#0EB6A2" name="phone" dimension="15x15" />
            </Bloc>
            <Box>
              <Bloc as="p" p={0} m={0} fontSize="14px" fontWeight="700">
                Phone
              </Bloc>
              <Bloc as="p" p={0} m={0} fontSize="14px">
                {contact.phone_number}
              </Bloc>
            </Box>
          </Bloc>
          {extraFields && (
            <Bloc
              display="flex"
              flexDirection="column"
              style={{
                rowGap: '14px',
              }}
              width="100%"
              bg="#F9F9F9"
              p="15px"
              borderRadius="10px"
            >
              {Object.entries(extraFields || []).map(([fieldType, field]) => (
                <Bloc
                  key={fieldType}
                  as="p"
                  p={0}
                  m={0}
                  display="flex"
                  style={{
                    columnGap: '5px',
                  }}
                  fontSize="14px"
                >
                  <Bloc
                    textTransform="initial"
                    as="span"
                    style={{
                      textTransform: 'capitalize',
                    }}
                    fontWeight="500"
                  >
                    {fieldType}
                  </Bloc>
                  {' : '}{' '}
                  <Bloc
                    as="span"
                    style={{
                      textTransform: 'capitalize',
                    }}
                    fontColor="#6E717D"
                  >
                    {field}
                  </Bloc>
                </Bloc>
              ))}
            </Bloc>
          )}
        </Flex>
      </Box>
    </ContactContainer>
  );
};

export default PreviewSingleContact;
