import { Bloc, Flex } from 'blocjs';
import React from 'react';
import { Text } from '../../components';
import { formatOrAbbreviateNumber } from '../../utils';

const MetricContainer = ({ status, metrics }) => {
  const isMessageItems = status === 'messageItems';

  const messageItems = formatOrAbbreviateNumber(metrics?.totalMessageItems);
  const messageSent = formatOrAbbreviateNumber(metrics?.totalMessageSent);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      style={{
        columnGap: '12px',
      }}
    >
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        style={{
          rowGap: '8px',
        }}
      >
        <Bloc
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={['12px', '10px', '14px']}
          py={['14px', '10px', '20px', '20px']}
          borderRadius="8px"
          width="100%"
          height={['100%', '110px']}
          boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.05)"
          bg="white"
        >
          <Text as="p" fontSize={[12, 12, 14]}>
            {isMessageItems ? 'Total message items' : 'Total message sent'}
          </Text>
          <Text
            as="p"
            fontSize={[50, 42, 50]}
            color="#0EB6A2"
            fontWeight="bold"
            style={{
              textTransform: 'uppercase',
            }}
          >
            {isMessageItems ? messageItems : messageSent}
          </Text>
        </Bloc>
        <Bloc
          display="grid"
          style={{
            gap: '8px',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          }}
        >
          {metrics.cards
            ? Object.entries(metrics.cards).map(([card, digits]) => {
                let title;
                const formattedDigit = formatOrAbbreviateNumber(digits);
                switch (card) {
                  case 'initial':
                    title = {
                      title: 'Initial',
                      subTitle: 'Sent at first items',
                    };
                    break;
                  case 'retries':
                    title = {
                      title: 'Retries',
                      subTitle: 'Sent at first items',
                    };
                    break;
                  case 'itemInitial':
                    title = {
                      title: 'Initial',
                      subTitle: 'Sent at first items',
                    };
                    break;
                  case 'itemRetries':
                    title = {
                      title: 'Retries',
                      subTitle: 'Sent at first items',
                    };
                    break;
                  default: // Do nothing
                }

                return (
                  <Bloc
                    borderRadius="8px"
                    px={['12px', '10px', '14px']}
                    minHeight="52px"
                    height={['100%']}
                    py={['14px', '12px', '14px']}
                    key={card}
                    boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.05)"
                    bg="white"
                  >
                    <Flex
                      height="100%"
                      justifyContent="space-between"
                      alignItems="stretch"
                    >
                      <Flex
                        display="flex"
                        style={{
                          rowGap: '4px',
                        }}
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Bloc
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Text
                            as="p"
                            fontWeight="bold"
                            fontSize={[12, 9, 10, 12]}
                          >
                            {title.title}
                          </Text>
                          <Bloc display={['block', 'block', 'none']}>
                            <Text
                              as="p"
                              fontSize={[25, 16, 25]}
                              fontWeight="bold"
                              style={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {formattedDigit}
                            </Text>
                          </Bloc>
                        </Bloc>
                        <Bloc display={['none', 'none', 'block']}>
                          <Text
                            fontSize={[25, 16, 25]}
                            style={{
                              textTransform: 'uppercase',
                            }}
                            fontWeight="bold"
                          >
                            {formattedDigit}
                          </Text>
                        </Bloc>
                      </Flex>
                    </Flex>
                  </Bloc>
                );
              })
            : null}
        </Bloc>
      </Flex>
    </Flex>
  );
};

export default MetricContainer;
