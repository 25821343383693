import React from 'react';
import { Flex, Bloc } from 'blocjs';

const Pricing = () => (
  <Flex data-testid="pricing" flexWrap="wrap" width={1} mx="5.55555%">
    <Flex flexWrap="wrap" width={1} maxWidth={1024} mx="auto" my={6} mb={96}>
      <Flex width={1} flexWrap="wrap" justifyContent="center">
        <Bloc as="h2" id="pricing" fontSize={5}>
          Pricing
        </Bloc>
      </Flex>
      <Flex
        flexWrap="wrap"
        width={[1, 1, 5 / 6]}
        alignItems="center"
        mx="auto"
        justifyContent="center"
      >
        <Bloc
          color="surfaces.0"
          bg="accentDark"
          p={8}
          mx="auto"
          mb={-86}
          minWidth={240}
          borderRadius={32}
          textAlign="center"
          zIndex={1}
        >
          <Bloc as="h5" width={1} my={2}>
            Starting from
          </Bloc>
          <Bloc as="h1" fontSize={5} width={1} my={0}>
            $0.01
          </Bloc>
          <Bloc as="h6" width={1} my={2}>
            per SMS
          </Bloc>
        </Bloc>

        <Flex flexWrap="wrap">
          <Bloc
            bg="surfaces.0"
            borderColor="accents.5"
            borderRadius={4}
            width={[1, 1, 4 / 5]}
            minHeight={360}
            p={56}
            textAlign="center"
            mx="auto"
            boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
            border="none"
          >
            <Bloc as="h2" fontSize={5} mt={72} mb={16}>
              Unified pricing
              <br />
              accross all telcos
            </Bloc>
            <Bloc as="hr" width="64px" height="0px" border="1px solid #8EF3E7" />
            <Bloc as="p" fontSize={3}>
              Currently operates in Rwanda.
              <Bloc as="br" />
              Soon will expand in East Africa and the rest of the continent
            </Bloc>
          </Bloc>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default Pricing;
