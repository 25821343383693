import React from 'react';
import { Flex, Bloc } from 'blocjs';
import ReactPaginate from 'react-paginate';
import Spinner from '../spinner';
import { calculateInitialPage } from '../../utils';

const Pagination = ({
  pageNumber,
  itemsPerPage,
  pages,
  pageCount,
  changePage,
  isFetching,
  isLoading,
  ml = '16%',
}) => {
  let totalItemsOnPage = itemsPerPage * pageNumber;
  const initialPage = calculateInitialPage(pageNumber);

  const totalPages = pages?.total || totalItemsOnPage;

  totalItemsOnPage =
    totalItemsOnPage >= totalPages ? totalPages : itemsPerPage * pageNumber;

  return (
    <Bloc ml={[0, ml]}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Bloc>
          <Bloc
            as="span"
            fontSize={9}
            color="accents.8"
            style={{ textTransform: 'uppercase' }}
          >
            Page {pageNumber} - {totalItemsOnPage} of {totalPages}
            {(isLoading || isFetching) && (
              <Spinner size="large" style={{ verticalAlign: 'sub' }} />
            )}
          </Bloc>
        </Bloc>
        {!isLoading && (
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            initialPage={initialPage} // React-paginate uses 0-based index (meaning its start counting from zero)
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName="paginationBttns"
            previousLinkClassName="previousBttn"
            nextLinkClassName="nextBttn"
            disabledClassName="paginationDisabled"
            activeClassName="paginationActive"
          />
        )}
      </Flex>
    </Bloc>
  );
};

export default Pagination;
