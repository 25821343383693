import React from 'react';
import { Bloc, Flex } from 'blocjs';
import Text from '../text';
import Icon from '../icon';

const EmailSentComponent = ({ email, setSentEmail }) => (
  <Bloc
    borderRadius="8px"
    boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
    bg="white"
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    }}
    padding="40px 32px"
  >
    <Bloc
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="56px"
      height="56px"
      borderRadius="50%"
      bg="accentLight"
    >
      <Icon name="email" color="accentLight" size={24} dimension="24x24" />
    </Bloc>
    <Text fontWeight="500" fontSize="18px">
      Check your email !
    </Text>

    <Flex
      flexDirection="column"
      style={{
        gap: '12px',
      }}
    >
      <Text fontSize="16px">
        An email has been sent to{' '}
        <Bloc as="span" fontWeight="600">
          {email}
        </Bloc>{' '}
        with a magic link. for secure access to your account.
      </Text>
      <Text color="#6E717D">
        Can&apos;t find your code? Check your spam folder!
      </Text>
      <Text
        onClick={() => {
          setSentEmail(false);
        }}
        color="accents.1"
      >
        Use a different email?
      </Text>
    </Flex>
  </Bloc>
);

export default EmailSentComponent;
