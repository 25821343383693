import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Text, Spinner, Layout, Button } from '../../components';
import { useFetchSenderIDDetails } from '../../hooks';
import {
  convertUTCToLocalTime,
  countriesList,
  textTruncate,
} from '../../utils';
import UserAPI from '../../services';
import CustomToast from '../../components/toast/customToast';

const SenderIDDetails = () => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const location = useLocation();
  const history = useHistory();
  const senderIdData = location && location.state && location.state;
  const [showToast, setShowToast] = React.useState(false);

  useEffect(() => {
    if (!senderIdData?.id) {
      history.push('/account?tab=organization_details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderIdData?.id]);

  const { data, isLoading } = useFetchSenderIDDetails(senderIdData?.id);
  const {
    mutate: mutateSetDefault,
    isPending: isSetDefaultLoading,
    status: setDefaultStatus,
    error: setDefaultError,
  } = useMutation({ mutationFn: (id) => userAPI.setDefaultSenderID(id) });

  const goBankToSenderIDs = () => {
    history.push('/account?tab=organization_details');
    setTimeout(() => {
      const section = document.getElementById('senderIds');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleSetDefault = async (e) => {
    e.stopPropagation();
    if (senderIdData?.id) {
      mutateSetDefault(senderIdData?.id, {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({
            queryKey: ['senderIDDetails'],
          });
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  const countries = data?.countries || [];

  if (!senderIdData) return null;

  return (
    <Layout>
      {setDefaultStatus === 'error' && showToast && (
        <CustomToast
          handleHideToast={() => setShowToast(false)}
          closed
          showToast={showToast}
          message={setDefaultError?.response?.data?.error?.message}
          status="error"
        />
      )}

      {setDefaultStatus === 'success' && showToast && (
        <CustomToast
          handleHideToast={() => setShowToast(false)}
          closed
          showToast={showToast}
          message="The sender ID is set default successfully"
          status="success"
        />
      )}
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        mb={[6, 0]}
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={[0, 6]}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            Sender ID details {isLoading && <Spinner size="large" />}
          </Text>
        </Box>
        <Flex
          style={{
            gap: '10px',
          }}
          flexWrap="wrap"
          justifyContent="space-between"
          mb={[0, 6]}
        >
          {!data?.is_default && (
            <Button
              profile="accentLight"
              size="contact"
              style={{ float: 'right' }}
              onClick={handleSetDefault}
              data-testid="go-back-to-account"
              loading={isSetDefaultLoading}
            >
              Make default
            </Button>
          )}

          <Button
            profile="secondary"
            size="contact"
            style={{ float: 'right' }}
            onClick={goBankToSenderIDs}
            data-testid="go-back-to-account"
          >
            Back to dashboard
          </Button>
        </Flex>
      </Flex>

      {!isLoading && (
        <Flex
          style={{
            gap: '20px',
          }}
          flexDirection="column"
          mb={0}
          display="inline-block"
          width="100%"
        >
          <Bloc
            borderRadius=" 7.741px"
            bg="white"
            boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
          >
            <Flex
              px="20px"
              py="14px"
              justifyContent="space-between"
              style={{
                borderBottom: '1px solid #DFDBDB',
              }}
            >
              <Text fontSize={1} fontWeight="700">
                Sender ID name
              </Text>
              <Text fontSize={1} fontWeight="700">
                Date created
              </Text>
            </Flex>
            <Flex px="20px" py="14px" justifyContent="space-between">
              <Text fontSize={1}>{data.name}</Text>
              <Text fontSize={1}>
                {(data?.created_at &&
                  format(
                    convertUTCToLocalTime(data.created_at),
                    'MMM dd, yyyy'
                  )) ||
                  ''}
              </Text>
            </Flex>
          </Bloc>
          <Bloc
            borderRadius=" 7.741px"
            bg="white"
            boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
          >
            <Bloc
              mb={0}
              as="table"
              width="100%"
              style={{ borderCollapse: 'collapse' }}
            >
              <Bloc as="thead">
                <Bloc
                  as="tr"
                  fontWeight="bold"
                  textAlign="left"
                  borderBottom="1px solid #DFDBDB"
                >
                  <Bloc
                    as="td"
                    fontSize={12}
                    py={3}
                    pl="20px"
                    width={['auto', 'auto', 250]}
                  >
                    Country
                  </Bloc>
                  <Bloc
                    as="td"
                    fontSize={12}
                    py={3}
                    pr="20px"
                    textAlign="right"
                  >
                    Status
                  </Bloc>
                </Bloc>
              </Bloc>
              <Bloc as="tbody" pb={3}>
                {countries.map((country) => {
                  let statusBg;
                  let statusText;

                  const countryObj = countriesList.find(
                    (item) => item.code === country.iso_code
                  );
                  switch (country.status) {
                    case 'approved':
                      statusBg = '#16c1ad';
                      statusText = 'Approved';
                      break;

                    case 'denied':
                      statusBg = '#FFD6DB';
                      statusText = 'Rejected';
                      break;

                    default:
                      statusBg = '#ffe79c';
                      statusText = 'Pending Approval';
                      break;
                  }
                  return (
                    <Bloc
                      as="tr"
                      textAlign="left"
                      borderBottom="1px solid #DFDBDB"
                      id="table-details"
                      key={country.iso_code}
                    >
                      <Bloc as="td" pl={0} color="rgb(62, 72, 77)">
                        <Bloc
                          pl="20px"
                          color="rgb(62, 72, 77)"
                          style={{ boxSizing: 'content-box' }}
                        >
                          <Bloc hide={[true, null]} fontSize={12}>
                            {countryObj.name}
                          </Bloc>
                          <Bloc hide={[null, true]} fontSize={12}>
                            {textTruncate(countryObj.name, 10)}
                          </Bloc>
                        </Bloc>
                      </Bloc>

                      <Bloc
                        as="td"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="rgb(62, 72, 77)"
                        style={{
                          float: 'right',
                        }}
                        pr="20px"
                      >
                        <Bloc
                          as="span"
                          borderRadius={27}
                          fontSize={10}
                          style={{ textTransform: 'capitalize', gap: '5px' }}
                          display="flex"
                          boxSizing="border-box"
                          width="fit-content"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Bloc
                            width="6px"
                            height="6px"
                            borderRadius="999px"
                            bg={statusBg}
                          />
                          <Bloc as="span">{statusText}</Bloc>
                        </Bloc>
                      </Bloc>
                    </Bloc>
                  );
                })}
              </Bloc>
            </Bloc>
          </Bloc>
        </Flex>
      )}

      <Box mt={100} />
    </Layout>
  );
};

export default SenderIDDetails;
