import React, { useState, useEffect, useRef } from 'react';
import { Bloc } from 'blocjs';
import { Link } from 'react-router-dom';

import Text from '../text';
import Icon from '../icon';
import withDropdown from '../avatarWithDropdown/withDropdown';
import Dropdown from '../avatarWithDropdown/dropdown';

const BurgerWithDropdown = () => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Bloc hide={[null, true]} ref={ref}>
      <Bloc
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        background="transparent"
        border="none"
        position="absolute"
        right="5.55555%"
        top="20px"
        height="30px"
        lineHeight="30px"
        style={{ cursor: 'pointer' }}
        data-testid="bugger"
      >
        <Bloc ml="20px">
          {isMenuOpen ? (
            <Icon
              name="close"
              color="surfaces.0"
              size={12}
              data-testid="b-close"
            />
          ) : (
            <Icon
              name="hamburger"
              color="surfaces.0"
              size={20}
              data-testid="b-open"
            />
          )}
        </Bloc>
      </Bloc>
      {isMenuOpen && (
        <Dropdown isAuthenticated={false}>
          <Bloc>
            <Bloc py={4} borderBottom="1px solid #d5dae2">
              <Bloc as="a" href="/#pricing">
                <Text as="span" hoverAsLink variant="normal">
                  {' '}
                  Pricing{' '}
                </Text>
              </Bloc>
            </Bloc>
            <Bloc py={4} borderBottom="1px solid #d5dae2">
              <Bloc
                as="a"
                href="https://github.com/pindoio/pindo-cli"
                target="_blank"
              >
                <Text as="span" hoverAsLink variant="normal">
                  {' '}
                  Docs{' '}
                </Text>
              </Bloc>
            </Bloc>
            <Bloc py={4}>
              <Bloc as={Link} to="/login" onClick={() => setIsMenuOpen(false)}>
                <Text as="span" hoverAsLink variant="normal">
                  {' '}
                  Login{' '}
                </Text>
              </Bloc>
            </Bloc>
          </Bloc>
        </Dropdown>
      )}
    </Bloc>
  );
};

export default withDropdown(BurgerWithDropdown);
