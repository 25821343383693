import { ExportToCsv } from 'export-to-csv';

export const exportOptions = [
  { label: 'CSV', value: 'csv' },
  { label: 'JSON', value: 'json' },
];

export const exportCSV = (data) => {
  const csvExporter = new ExportToCsv(data.options);
  csvExporter.generateCsv(data.data);
};

export const exportJSON = async (data, fileName) => {
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
