import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const getDateFormat = (view) => {
  switch (view) {
    case 'Today':
      return 'MMM dd, yyyy';
    case 'Week':
      return 'MMM dd,yyyy';
    case 'Month':
      return 'MMM yyyy';
    case 'Year':
      return 'yyyy';
    case 'Quarter':
      return 'QQQ yyyy';
    default:
      return 'dd MMM';
  }
};

const DatePickerContainer = styled.div`
  ${(props) =>
    props.week &&
    `
.react-datepicker__week:hover {
 
  border-radius: 3px;
  color: white;
  border-radius: 3px;
  background:#0EB6A21A;
  .react-datepicker__week-number {
    border-radius: 3px;
  color:white;
  background-color: #19c1ac;
  }
}

.react-datepicker__day:hover {
  background-color: transparent;
  border-radius: 3px;
}

.react-datepicker__day--selected{
  background-color: transparent !important;
  color:black;
}

.react-datepicker__week-number--selected {
  border-radius: 3px;
  color:white;
  background-color: #19c1ac;
}

.react-datepicker__day--selected:hover {
  background-color: #19c1ac;
  border-radius: 4.8px;
}

`}
`;

const CustomDatePicker = ({
  customButton,
  view,
  startDate,
  handleChangePeriod,
}) => (
  <DatePickerContainer week={view === 'Week'}>
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        handleChangePeriod(date, view.toLowerCase());
      }}
      customInput={customButton}
      showWeekNumbers={view === 'Week'}
      // calculateWeek
      dateFormat={getDateFormat(view)}
      maxDate={new Date()}
      showWeekPicker={view === 'Week'}
      showMonthYearPicker={view === 'Month'}
      showYearPicker={view === 'Year'}
      yearItemNumber={6}
      showQuarterYearPicker={view === 'Quarter'}
      calendarStartDay={1}
    />
  </DatePickerContainer>
);

const DatePeriodSwitch = ({
  period,
  customButton,
  startDate,
  handleChangePeriod,
}) => {
  switch (period) {
    case 'Week':
      return (
        <CustomDatePicker
          view={period}
          handleChangePeriod={handleChangePeriod}
          startDate={startDate}
          customButton={customButton}
        />
      );
    case 'Month':
      return (
        <CustomDatePicker
          handleChangePeriod={handleChangePeriod}
          startDate={startDate}
          view={period}
          customButton={customButton}
        />
      );
    case 'Quarter':
      return (
        <CustomDatePicker
          handleChangePeriod={handleChangePeriod}
          view={period}
          startDate={startDate}
          customButton={customButton}
        />
      );
    case 'Year':
      return (
        <CustomDatePicker
          startDate={startDate}
          handleChangePeriod={handleChangePeriod}
          view={period}
          customButton={customButton}
        />
      );
    default:
      return (
        <CustomDatePicker
          startDate={startDate}
          handleChangePeriod={handleChangePeriod}
          view={period}
          customButton={customButton}
        />
      );
  }
};
export default DatePeriodSwitch;
