import React, { useEffect, useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useHistory } from 'react-router-dom';

import { Text, Spinner, Button, Toast, Icon } from '../../../../components';
import countriesList from '../../../../utils/countries';
import SelectionInput from '../../../../components/selection';
import { useFetchSenderIDDetails } from '../../../../hooks';
import { convertUTCToLocalTime, textTruncate } from '../../../../utils';
import UserAPI from '../../../../services';
import { senderIDStatus } from '../../../../constants';

const SenderIDDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const senderId = new URLSearchParams(location.search).get('senderId');

  const { data, isLoading } = useFetchSenderIDDetails(senderId ?? null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const userAPI = new UserAPI();
  const {
    mutate,
    status,
    error,
    isLoading: isReviewLoading,
  } = useMutation({ mutationFn: (payload) => userAPI.reviewSenderID(payload) });

  useEffect(() => {
    if (data && data.countries) {
      const { countries } = data;
      setSelectedStatuses(countries.map((country) => country.status));
    }
  }, [data]);

  const handleSelectionChange = (chosenItemIndex, chosenItem) => {
    const updatedStatuses = [...selectedStatuses];
    updatedStatuses[chosenItemIndex] = chosenItem.name;
    setSelectedStatuses(updatedStatuses);
  };
  const handleShowSenderIDDetails = () => {
    const searchParams = new URLSearchParams(location.search);
    // Delete the specific search parameter
    searchParams.delete('senderId');
    // Update the URL with the modified search params
    history.push({
      search: searchParams.toString(),
    });
  };

  const updateSenderID = async (e) => {
    e.preventDefault();
    if (data && data.countries) {
      const { countries } = data;
      const updatedCountriesList = countries.map((country, index) => ({
        ...country,
        status: selectedStatuses[index],
      }));
      // Now, updatedCountriesList contains the updated statuses
      const pendingCountriesObj = updatedCountriesList.filter(
        (country) => country.status === 'pending'
      );
      const approvedCountriesObj = updatedCountriesList.filter(
        (country) => country.status === 'approved'
      );
      const deniedCountriesObj = updatedCountriesList.filter(
        (country) => country.status === 'denied'
      );
      const pending = pendingCountriesObj.map((country) => country.iso_code);
      const approved = approvedCountriesObj.map((country) => country.iso_code);
      const denied = deniedCountriesObj.map((country) => country.iso_code);
      const bodyData = {
        pending_for: pending,
        approved_for: approved,
        denied_for: denied,
      };

      // Filter out properties with empty arrays
      const filteredBodyData = Object.entries(bodyData).reduce(
        (acc, [key, value]) => {
          if (value.length > 0) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const payload = { bodyData: filteredBodyData, senderId };
      await mutate(payload, {
        onSuccess: () => {
          setTimeout(() => {
            handleShowSenderIDDetails();
          }, 2000);
        },
      });
    }
  };

  const countries = data?.countries || [];

  let statusColor;
  let statusBg;

  return (
    <Bloc py={5}>
      <Flex px={5} justifyContent="space-between">
        <Box pb={18}>
          <Text variant="h5">
            {' '}
            Sender ID details {isLoading && <Spinner size="large" />}{' '}
          </Text>
        </Box>
        {!isEditable && !isChanging && (
          <Bloc>
            <Button
              profile="accentLight"
              size="small"
              onClick={() => setIsEditable(true)}
              data-test="update-btn"
            >
              Update Details
            </Button>
          </Bloc>
        )}
      </Flex>

      <Flex
        justifyContent="flex-end"
        flexWrap="wrap"
        alignItems="center"
        width={1}
        px={5}
      >
        {isChanging && (
          <Bloc
            display="flex"
            alignItems="center"
            backgroundColor="warning"
            borderRadius={2}
            px={4}
            py={22}
            justifyContent="space-between"
            width={1}
            mb={24}
          >
            <Text fontSize={12} color="accents.8">
              You are about to change the sender Id status on these countries,
              Are you sure you want to proceed?{' '}
            </Text>
            <Flex alignItems="center">
              <Button
                profile="accentLight"
                size="small"
                type="button"
                loading={isReviewLoading}
                onClick={(e) => updateSenderID(e)}
                style={{ marginLeft: '10px', marginRight: '10px' }}
                data-testid="yes-remove"
              >
                Yes
              </Button>
              <Button
                profile="primary"
                size="small"
                type="button"
                onClick={() => {
                  setIsChanging(false);
                  setIsEditable(false);
                }}
                data-testid="no-remove"
              >
                No
              </Button>
            </Flex>
          </Bloc>
        )}
      </Flex>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message="Status updated successfully" status="success" />
      )}
      <Box mt={4} />
      {!isLoading && data && (
        <Box mb={0} display="inline-block" width="100%">
          <Bloc display="flex" flexDirection="column">
            <Bloc px={5} display="flex" pb={32}>
              <Bloc pl={0} color="rgb(62, 72, 77)" fontSize={16} width="200px">
                <Bloc as="b">SenderID</Bloc>
              </Bloc>
              <Bloc pl={0} color="rgb(62, 72, 77)" fontSize={16} width="200px">
                <Bloc>{data.name}</Bloc>
              </Bloc>
              {data.is_default && (
                <Flex alignItems="center">
                  <Bloc
                    pl={0}
                    color="rgb(62, 72, 77)"
                    fontSize={16}
                    style={{ whiteSpace: 'initial' }}
                    mr={2}
                  >
                    <Text color="darkGray">Default</Text>
                  </Bloc>
                  <Icon
                    name="check-broken-circle"
                    height={14}
                    color="black"
                    size={12}
                    dimension="24x24"
                  />
                </Flex>
              )}
            </Bloc>

            <Bloc display="flex" flexDirection="column" pb={32}>
              <Bloc px={5} pb={4} color="rgb(62, 72, 77)" fontSize={16}>
                <Bloc as="b">Countries</Bloc>
              </Bloc>
              <Bloc zIndex={1}>
                <Bloc
                  mb={0}
                  as="table"
                  width="100%"
                  style={{ borderCollapse: 'collapse' }}
                >
                  <Bloc as="thead">
                    <Bloc
                      as="tr"
                      fontWeight="bold"
                      textAlign="left"
                      borderBottom="1px solid #DFDBDB"
                    >
                      <Bloc
                        as="td"
                        fontSize={9}
                        px={5}
                        py={3}
                        width={['auto', 'auto', 250]}
                      >
                        Name
                      </Bloc>
                      <Bloc
                        as="td"
                        display="flex"
                        justifyContent="end"
                        fontSize={9}
                        px={5}
                        py={3}
                      >
                        Status
                      </Bloc>
                    </Bloc>
                  </Bloc>
                  <Bloc as="tbody" pb={3}>
                    {countries.map((country, index) => {
                      switch (country.status) {
                        case 'approved':
                          statusColor = 'accentDark';
                          statusBg = '#EBF9F5';
                          break;

                        case 'denied':
                          statusColor = '#FF3049';
                          statusBg = '#FFD6DB';
                          break;

                        case 'pending':
                          statusColor = '#757F84';
                          statusBg = '#FFEEBA';
                          break;

                        default:
                          statusColor = 'darkGray';
                          statusBg = 'white';
                          break;
                      }
                      const countryObj = countriesList.find(
                        (item) => item.code === country.iso_code
                      );
                      const activeItemIndex = senderIDStatus.findIndex(
                        (item) => item.name === country.status
                      );
                      return (
                        <Bloc
                          as="tr"
                          textAlign="left"
                          borderBottom="1px solid #DFDBDB"
                          font
                          id="table-details"
                          key={country.iso_code}
                        >
                          <Bloc as="td" pl={5} py={3} color="rgb(62, 72, 77)">
                            <Bloc
                              pl={0}
                              color="rgb(62, 72, 77)"
                              mr={35}
                              style={{ boxSizing: 'content-box' }}
                            >
                              <Bloc hide={[true, null]} fontSize={12}>
                                {countryObj.name}
                              </Bloc>
                              <Bloc hide={[null, true]} fontSize={12}>
                                {textTruncate(countryObj.name, 10)}
                              </Bloc>
                            </Bloc>
                          </Bloc>

                          <Bloc
                            as="td"
                            alignItems="center"
                            justifyContent="end"
                            display="flex"
                            pr={5}
                            color="rgb(62, 72, 77)"
                          >
                            <Bloc
                              display="flex"
                              justifyContent="left"
                              fontSize={14}
                            >
                              <Bloc>
                                {isEditable ? (
                                  <SelectionInput
                                    width={140}
                                    dropDownItems={senderIDStatus}
                                    activeItemIndex={
                                      activeItemIndex > -1 ? activeItemIndex : 0
                                    }
                                    onSelectionChange={(newStatus) =>
                                      handleSelectionChange(index, newStatus)
                                    }
                                    disabled={!isEditable}
                                  />
                                ) : (
                                  <Bloc
                                    as="span"
                                    color={statusColor}
                                    bg={statusBg}
                                    padding="4px 10px"
                                    borderRadius={27}
                                    fontSize={10}
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                    fontWeight="bold"
                                    display="flex"
                                    boxSizing="border-box"
                                    width="fit-content"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {country.status}
                                  </Bloc>
                                )}
                              </Bloc>
                            </Bloc>
                          </Bloc>
                        </Bloc>
                      );
                    })}
                  </Bloc>
                </Bloc>
              </Bloc>
            </Bloc>

            <Bloc px={5} display="flex" pb={isEditable && !isChanging && 32}>
              <Bloc pl={0} color="rgb(62, 72, 77)" fontSize={16} width="200px">
                <Bloc as="b">Created Date</Bloc>
              </Bloc>
              <Bloc pl={0} color="rgb(62, 72, 77)" fontSize={16} width="200px">
                <Text>
                  {data?.created_at
                    ? format(
                        convertUTCToLocalTime(data.created_at),
                        'MMM dd, yyyy'
                      )
                    : ''}
                </Text>
              </Bloc>
            </Bloc>
          </Bloc>
          {isEditable && !isChanging && (
            <Flex px={5} mt={4} flexWrap="wrap">
              <Box mb={[3, 0]} mr={30}>
                <Button
                  profile="accentLight"
                  size="small"
                  onClick={() => setIsChanging(true)}
                  data-test="update-btn"
                >
                  Save Changes
                </Button>
              </Box>
              <Box mb={0}>
                <Button
                  profile="primary"
                  size="small"
                  onClick={handleShowSenderIDDetails}
                  data-test="cancel-btn"
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </Bloc>
  );
};

export default SenderIDDetails;
