/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Bloc, Box } from 'blocjs';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import ContactTable from '../table/contactTable';
import { reverseExtraFieldsOrder } from '../../utils';
import useGetContactFields from '../../hooks/useGetContactFields';

const ArchivedContactTable = ({ archivedTableData, handleActions }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { fieldHeaders, isLoading } = useGetContactFields({
    isMessageForm: false,
  });

  const reArrangeContactTableData = reverseExtraFieldsOrder(archivedTableData);

  useEffect(() => {
    if (selectedContacts.length < 1) {
      handleActions(null, null);
    }
  }, [selectedContacts.length]);

  const onCheckBox = useCallback(
    (contact, e) => {
      e.stopPropagation();
      const items = [...selectedContacts];
      const index = items.findIndex((item) => item.id === contact.id);
      if (index > -1) {
        if (items.length < 1) {
          handleActions();
        }
        items.splice(index, 1);
        setSelectedContacts(items);
      } else {
        handleActions('archived-tab', [...items, contact]);
        setSelectedContacts([...items, contact]);
      }
    },
    [handleActions, selectedContacts]
  );

  const toggleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedContacts([]);
      handleActions('archived-tab', []);
    } else {
      handleActions('archived-tab', reArrangeContactTableData);
      setSelectedContacts(reArrangeContactTableData);
    }
    setSelectAll(!selectAll);
  }, [selectAll, reArrangeContactTableData]);

  const extraFields =
    !isLoading && fieldHeaders?.length > 0
      ? fieldHeaders.map((field) => ({
          Header: field,
          accessor: (row) => <Bloc>{row.extra_fields[field]}</Bloc>,
        }))
      : [];

  const onRowClick = () => {};

  const setRowBackgroundColor = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return '#BDFBF3';
      }
      if (selectedContacts.includes(rowId)) {
        return '#E4FFFC';
      }
      return 'transparent';
    },
    [selectedContacts]
  );

  const setCheckbox = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return <CheckedBox color="accentDark" size={16} />;
      }
      return (
        <Box position="relative" width={16} height={16}>
          <Icon name="square" color="#959DA2" size={16} />
        </Box>
      );
    },
    [selectedContacts]
  );

  const column = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => <Bloc>{row.name}</Bloc>,
      },

      {
        Header: 'Phone',
        accessor: (row) => <Bloc>{row.phone_number}</Bloc>,
      },
      ...extraFields,
      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(row, e)}
            >
              {setCheckbox(row.id)}
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [onCheckBox, setCheckbox]
  );

  return (
    <Bloc>
      <ContactTable
        setRowBackgroundColor={setRowBackgroundColor}
        columns={column}
        toggleSelectAll={toggleSelectAll}
        selectAll={selectAll}
        selectedContacts={selectedContacts}
        data={reArrangeContactTableData}
        onRowClick={onRowClick}
      />
    </Bloc>
  );
};

export default ArchivedContactTable;
