import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';
import processDeals from '../utils/processDeals';
import { sortById } from '../utils';

const userAPI = new UserAPI();

export const useFetchOrg = ({ enabled = true }) => {
  const { data, status, isFetched, isFetching } = useQuery({
    queryKey: ['selfOrg'],
    queryFn: (payload) => userAPI.getSelfOrg(payload),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
  });

  return { data, status, isFetched, isFetching };
};

export const useFetchOrgMembers = () => {
  const { data, status, isLoading, isFetched } = useQuery({
    queryKey: ['listMembers'],
    queryFn: (payload) => userAPI.getOrgMembers(payload),
  });
  return { data, status, isLoading, isFetched };
};

export const useFetchOrgInboundNumbers = () => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['inboundNumbers'],
    queryFn: (payload) => userAPI.getInboundNumbers(payload),
    select: (returnData) => {
      const sortedData = sortById(returnData.codes);
      return {
        ...returnData,
        codes: sortedData,
      };
    },
  });
  return { data, status, isLoading };
};

export const useFetchAllOrgs = (
  pageNumber,
  itemsPerPage,
  startDateFormatted,
  endDateFormatted,
  balanceStatus,
  isFetchEnabled,
  orgName
) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      ['orgs'],
      {
        pageNumber,
        itemsPerPage,
        start: startDateFormatted,
        end: endDateFormatted,
        balanceStatus,
        orgName,
      },
    ],
    queryFn: () =>
      userAPI.getAllOrgs({
        pageNumber,
        itemsPerPage,
        start: startDateFormatted,
        end: endDateFormatted,
        balanceStatus,
        name: orgName,
      }),

    enabled: isFetchEnabled,
    placeholderData: keepPreviousData,
  });
  return { data, isLoading, isFetching };
};

export const useFetchServices = () => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['local_services'],
    queryFn: (payload) => userAPI.getServices(payload),
  });
  return { data, isLoading, isFetching };
};

export const useFetchMainUserOrg = (orgId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['mainUserOrg', orgId],
    queryFn: () => userAPI.getMainUserOrg(orgId),
    placeholderData: keepPreviousData,
  });
  return { data, status, isLoading };
};

export const useFetchGivenOrg = (orgId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['org', orgId],
    queryFn: () => userAPI.getOrg(orgId),
    placeholderData: keepPreviousData,
    enabled: !!orgId,
  });
  return { data, status, isLoading };
};

export const useFetchOrgTransactions = (userId, pageNumber, itemsPerPage) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['orgTransactions', userId, pageNumber, itemsPerPage],
    queryFn: () =>
      userAPI.getOrgTransactions({ userId, pageNumber, itemsPerPage }),
    placeholderData: keepPreviousData,
    enabled: !!userId,
  });
  return { data, status, isLoading };
};

export const useFetchSenderIDsByStatus = (
  statusInput,
  pageNumber = 1,
  itemsPerPage = 25,
  startDateFormatted = null,
  endDateFormatted = null,
  isFetchEnabled = true
) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'senderIDsByStatus',
      {
        status: statusInput,
        pageNumber,
        itemsPerPage,
        startDateFormatted,
        endDateFormatted,
      },
    ],
    queryFn: () =>
      userAPI.getSenderIDsByStatus({
        status: statusInput,
        pageNumber,
        itemsPerPage,
        start: startDateFormatted,
        end: endDateFormatted,
      }),
    enabled: isFetchEnabled,
    placeholderData: keepPreviousData,
  });
  return { data, isLoading, isFetching };
};

export const useFetchOrgSenderIDs = (orgId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['orgSenderIDs', orgId],
    queryFn: () => userAPI.getOrgSenderIDs(orgId),
    placeholderData: keepPreviousData,
    enabled: !!orgId,
  });
  return { data, status, isLoading };
};

// Create an array of queries for data based on each ID (This combines 2 associated queries)
export const useFetchSenderIDsWithUsers = (
  name,
  statusInput,
  pageNumber = 1,
  itemsPerPage = 25,
  startDateFormatted = null,
  endDateFormatted = null,
  isFetchEnabled = true
) =>
  useQuery({
    queryKey: [
      'allSenderIDsWithUsers',
      {
        status: statusInput,
        pageNumber,
        itemsPerPage,
        startDateFormatted,
        endDateFormatted,
      },
    ],
    queryFn: async () => {
      const itemsResponse = await userAPI.getSenderIDsByStatus({
        status: statusInput,
        name,
        pageNumber,
        itemsPerPage,
        start: startDateFormatted,
        end: endDateFormatted,
      });
      return itemsResponse;
    },
    enabled: !!isFetchEnabled,
    placeholderData: keepPreviousData,
  });

// Organization Deals

export const useOrgDeals = (orgId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['org_deal', orgId],
    queryFn: () => userAPI.getOrgSenderIDs(orgId),
    placeholderData: keepPreviousData,
    enabled: !!orgId,
  });
  return { data, status, isLoading };
};

export const useFetchOrgDeals = ({ payload, enableFetch, pageNumber }) => {
  const {
    data: returnData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['orgDeals', { pageNumber }],
    queryFn: () => userAPI.getOrgDeals({ payload, pageNumber }),
    enabled: enableFetch,
    select: (data) => {
      const sortedData = processDeals(data.deals, 'asc');
      return {
        ...data,
        deals: sortedData,
      };
    },
  });
  return { data: returnData, isLoading, isFetching };
};
