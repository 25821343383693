import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';

import { Button, Inputfield, Spinner } from '../../../../components';
import { selectStatusStyles } from '../../../../utils';

const FilterForm = ({
  setStatusInput,

  startDate,
  endDate,
  isLoading,
  filterMessages,
  isFetching,
  hasInputFilled,
  setIsFetchEnabled,
  handleFilterFormDates,
  searchedName,
  searchByName,
  senderIDStatuses,
  statusInput,
}) => {
  const { push } = useHistory();

  const activeItemIndex = senderIDStatuses.findIndex(
    (item) => item.value === statusInput
  );

  const handleFilterStatus = (option) => {
    setIsFetchEnabled(false);
    if (option && option.value) {
      const { value } = option;
      push({
        pathname: '/admin/manage/senderids',
        state: { status: 'pending' },
      });

      setStatusInput(value);
      setTimeout(() => {
        setIsFetchEnabled(true);
      }, 1000);
    } else {
      setStatusInput('');
      setTimeout(() => {
        setIsFetchEnabled(true);
      }, 1000);
    }
  };

  return (
    <Bloc ml={[0, '16%']}>
      <Bloc
        mb={6}
        backgroundColor="white"
        borderRadius="8px"
        border="1px solid #DEE1E7"
        boxSadow="none"
      >
        <Flex
          flexWrap={['wrap', 'no-wrap']}
          justifyContent="space-between"
          alignItems="center"
          padding="16px 24px"
        >
          <Box
            display="flex"
            justifyContent="center"
            width={[1, 'calc(50% - 8px)', 'calc(36% - 115px)']}
          >
            <Inputfield
              name="text"
              placeholder="Search by name"
              value={searchedName}
              onChange={(e) => searchByName(e)}
              hasLabel={false}
            />
          </Box>
          <Box
            ml={[0, 0, 4]}
            mt={[3, 0, 0]}
            width={[1, 'calc(50% - 8px)', 'calc(24% - 18px)']}
          >
            <Select
              isClearable
              options={senderIDStatuses}
              placeholder="Status"
              onChange={handleFilterStatus}
              styles={selectStatusStyles}
              value={senderIDStatuses[activeItemIndex]}
            />
          </Box>
          <Box
            ml={[0, 0, 4]}
            mt={[3, 3, 0]}
            height={40}
            width={[1, 'calc(50% - 8px)', 'calc(20% - 18px)']}
            style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
          >
            <DatePicker
              placeholderText="Start date"
              selected={startDate}
              selectsStart
              maxDate={new Date()}
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleFilterFormDates('start', date)}
              calendarClassName="calendar"
            />
          </Box>
          <Box
            ml={[0, 0, 4]}
            mt={[3, 3, 0]}
            height={40}
            width={[1, 'calc(50% - 8px)', 'calc(20% - 18px)']}
            style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
          >
            <DatePicker
              placeholderText="End date"
              selected={endDate}
              selectsEnd
              startDate={startDate}
              minDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleFilterFormDates('end', date)}
              calendarClassName="calendar"
              calendarStartDay={1}
            />
          </Box>
          <Box ml={[0, 0, 4]} mt={[3, 3, 0]} width={[1, 1, 'auto']}>
            <Button
              profile="accentLight"
              disabled={!hasInputFilled}
              size="normal"
              onClick={() => {
                if (hasInputFilled) filterMessages();
              }}
              data-test="filter-btn"
              style={{
                width: '95px',
                height: '40px',
                padding: 0,
                float: 'right',
              }}
            >
              {!isLoading && !isFetching ? (
                'Search'
              ) : (
                <Spinner
                  size="large"
                  style={{ marginLeft: 0, marginBottom: '-4px' }}
                />
              )}
            </Button>
          </Box>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default FilterForm;
