import { Bloc } from 'blocjs';
import React, { useEffect, useRef } from 'react';
import { dropdownLayout, inputSelectBox } from '../selection/style';
import { Chevron } from '../carets';
import Text from '../text';

const styles = {
  dropdownLayout,
  inputSelectBox,
};

const Dropdown = ({
  children,
  isDropdownOpen,
  isSelect = false,
  activeItem,
  setIsDropdownOpen,
  itemsList,
  disabled = false,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  return (
    <Bloc
      ref={ref}
      aria-hidden="true"
      width="100%"
      position="relative"
      p={0}
      ml={0}
    >
      <Bloc
        aria-hidden="true"
        display="flex"
        backgroundColor={disabled ? '#F7F9FB' : 'white'}
        opacity={disabled ? 0.4 : 1}
        alignItems="center"
        justifyContent="space-between"
        boxSizing="border-box"
        borderRadius={2}
        height={40}
        padding={!isSelect ? '8px 16px' : '5px 16px'}
        style={{
          border: '1px solid #D4DEE8',
        }}
      >
        <Bloc
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          overflow="hidden"
          width={1}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDropdownOpen(!isDropdownOpen);
          }}
          style={{ cursor: 'pointer', gap: '6px' }}
        >
          <Text
            fontSize="12px"
            style={{
              textTransform: 'capitalize',
            }}
          >
            {activeItem.name}
          </Text>

          <Bloc>
            <Chevron
              color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
              size={16}
            />
          </Bloc>
        </Bloc>
      </Bloc>

      {!disabled && isDropdownOpen && (
        <Bloc
          position="absolute"
          stop={60}
          width={1}
          right={0}
          zIndex={1000}
          backgroundColor="white"
          boxSizing="border-box"
          overflow="auto"
          padding={0}
          borderRadius="6px"
          height={itemsList.length > 0 ? 'auto' : 126}
          style={styles.dropdownLayout}
          marginTop={2}
          maxHeight={200}
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)"
        >
          {children}
        </Bloc>
      )}
    </Bloc>
  );
};

export default Dropdown;
