import React, { useRef, memo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Chartjs from 'chart.js';

import { setRangeOnYAxis } from '../../utils';

const Chart = memo(({ type, data: chartData, totalSMS }) => {
  const canvasRef = useRef(null);
  const rangeOnYAxis  = setRangeOnYAxis(totalSMS);

  const barChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        stacked: true,
      }],
      yAxes: [{
        ticks: {
          stepSize: rangeOnYAxis,
          fontColor: '#0BAF9C',
          fontSize: 12,
          padding: 20,
          min: 0
        },
        stacked: true,
      }],
    },
  };
  
  const lineChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        ticks: {
          stepSize: rangeOnYAxis,
          fontColor: '#0BAF9C',
          fontSize: 12,
          padding: 20,
          min: 0
        },
      }],
    },
  };

  useDeepCompareEffect(() => {
    const optionsMapping = {
      bar: barChartOptions,
      line: lineChartOptions,
    };

    const data = {
      type,
      data: chartData,
      options: {
        ...optionsMapping[type],
        tooltips: {
          displayColors: false,
          caretSize: 0,
          bodyFontSize: 9,
          bodyFontStyle: 'bold',
          xPadding: 15,
          yPadding: 10,
          cornerRadius: 18,
          height: 32,
          custom(tooltipModel) {
            const tooltip = tooltipModel;
            if (tooltip.opacity !== 0 && chartData.labels.length
                 && chartData.datasets.length) {
              const dataSetIndex = tooltip.dataPoints[0].datasetIndex;
              tooltip.backgroundColor = chartData.datasets[dataSetIndex].toolTip.backgroundColor;
            }
          },
          callbacks: {
            labelTextColor(tooltipItem) {
              return chartData.datasets[tooltipItem.datasetIndex].toolTip.labelTextColor;
            },
            title() {},
          },
        },
      },
    };


    const canvas = canvasRef.current;

    const ctx = canvas.getContext('2d');

    const chart = new Chartjs(ctx, data);
    return () => {
      chart.destroy();
    };
  }, [chartData, type]);

  return <canvas ref={canvasRef} />;
});

export default Chart;
