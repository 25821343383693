import { useLocation } from 'react-router-dom';

const useIsPathsOn = (paths) => {
  const { pathname } = useLocation();

  return paths.reduce((acc, path) => {
    acc[path] = pathname.includes(path);
    return acc;
  }, {});
};

export default useIsPathsOn;
