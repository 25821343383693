import { Bloc, Flex } from 'blocjs';
import React, { useState } from 'react';
import UpdateOrgSettingsForm from '../../account/organization/settings/updateSettings';
import { useFetchOrg } from '../../../hooks';
import { ListSenderIDs, Members } from '../../account/organization';
import AccountContainer from '../container';
import { Button, Icon, Text } from '../../../components';
import useRemoveSenderId from './useRemoveSenderId';
import CustomToast from '../../../components/toast/customToast';
import useRemoveMember from './useRemoveMember';

const OrgSettings = ({ isMainUser }) => {
  const { data } = useFetchOrg({ enabled: true });
  const inUseOrgData = data?.orgs.filter((org) => org.in_use)[0];
  const orgName = inUseOrgData?.name;

  const [showToast, setShowToast] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedSenderIDs, setSelectedSenderIDs] = useState([]);
  const [isSettingDefault, setIsSettingDefault] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isMemberRemoving, setIsMemberRemoving] = useState(false);
  const [showMemberToast, setShowMemberToast] = React.useState(false);

  const { handleRemoveSenderID, isError, isPending, isSuccess, removeError } =
    useRemoveSenderId({
      setIsSettingDefault,
      selectedSenderIDs,
      setSelectedSenderIDs,
      setShowToast,
      setIsRemoving,
    });

  const {
    handleRemoveMember,
    memberIsError,
    memberIsPending,
    memberIsSuccess,
    removeMemberError,
  } = useRemoveMember({
    selectedMembers,
    setSelectedMembers,
    setShowMemberToast,
    setIsMemberRemoving,
  });

  const sendersText =
    selectedSenderIDs.length > 1 ? 'these sender IDs' : 'this sender ID';
  const membersText = selectedMembers.length > 1 ? 'members' : 'a member';

  return (
    <Flex
      flexDirection="column"
      style={{
        gap: '40px',
      }}
    >
      {isSuccess && (
        <CustomToast
          handleHideToast={() => setShowToast(false)}
          closed
          showToast={showToast}
          message="The sender ID removed successfully"
          status="success"
        />
      )}

      {memberIsSuccess && (
        <CustomToast
          handleHideToast={() => setShowMemberToast(false)}
          closed
          showToast={showMemberToast}
          message="Removed successfully"
          status="success"
        />
      )}
      {memberIsError && (
        <CustomToast
          handleHideToast={() => setShowMemberToast(false)}
          closed
          showToast={showMemberToast}
          message={removeMemberError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {isError && (
        <CustomToast
          handleHideToast={() => setShowToast(false)}
          closed
          showToast={showToast}
          message={removeError?.response?.data?.error?.message}
          status="error"
        />
      )}
      <UpdateOrgSettingsForm
        data={inUseOrgData}
        isMainUser={isMainUser}
        orgName={orgName}
      />
      {isRemoving && (
        <RemoveModal
          handleRemove={handleRemoveSenderID}
          setIsRemovingModal={setIsRemoving}
          setSelected={setSelectedSenderIDs}
          text={sendersText}
          isPending={isPending}
        />
      )}
      <Bloc
        position="relative"
        style={{
          opacity: isRemoving ? 0.2 : 1,
        }}
      >
        {isRemoving && (
          <Bloc
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          />
        )}
        <ListSenderIDs
          selectedSenderIDs={selectedSenderIDs}
          setSelectedSenderIDs={setSelectedSenderIDs}
          setIsSettingDefault={setIsSettingDefault}
          isSettingDefault={isSettingDefault}
          setShowToast={setShowToast}
          showToast={showToast}
          isRemoving={isRemoving}
          setIsRemoving={setIsRemoving}
        />
      </Bloc>

      {isMemberRemoving && (
        <RemoveModal
          handleRemove={handleRemoveMember}
          setIsRemovingModal={setIsMemberRemoving}
          setSelected={setSelectedMembers}
          text={membersText}
          isPending={memberIsPending}
        />
      )}

      <Bloc
        position="relative"
        style={{
          opacity: isMemberRemoving ? 0.2 : 1,
        }}
      >
        {isMemberRemoving && (
          <Bloc
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          />
        )}

        <Members
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          isRemoving={isMemberRemoving}
          setIsRemoving={setIsMemberRemoving}
          isRemoveLoading={memberIsPending}
          membersText={membersText}
          inUseOrgData={inUseOrgData}
        />
      </Bloc>
    </Flex>
  );
};

export default OrgSettings;

const RemoveModal = ({
  handleRemove,
  setIsRemovingModal,
  setSelected,
  text,
  isPending,
}) => (
  <AccountContainer pt={0}>
    <Flex
      p="20px"
      flexDirection="column"
      style={{
        gap: '15px',
      }}
    >
      <Flex alignItems="center">
        <Bloc width="24px" height="24px">
          <Icon name="warning" color="#FFB132" dimension="14x14" size={14} />
        </Bloc>

        <Text fontSize="14px" fontWeight={700}>
          You are about to delete {text}
        </Text>
      </Flex>

      <Bloc fontSize="14px">
        {!text.includes('sender') &&
          `You are about to remove ${text} in your organization,`}{' '}
        Do you want to proceed?
      </Bloc>

      <Flex
        style={{
          columnGap: '10px',
        }}
      >
        <Button
          style={{
            textTransform: 'initial',
            fontWeight: 'normal',
          }}
          profile="accentLight"
          size="contact"
          loading={isPending}
          onClick={handleRemove}
        >
          Proceed
        </Button>
        <Button
          style={{
            textTransform: 'initial',
            fontWeight: 'normal',
          }}
          profile="secondary"
          size="contact"
          onClick={() => {
            setIsRemovingModal(false);
            setSelected([]);
          }}
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  </AccountContainer>
);
