import React from 'react';
import { Bloc } from 'blocjs';

import Input from './input';

const Inputfield = React.forwardRef(
  (
    {
      name,
      label,
      size = 'normal',
      hasLabel = true,
      hint,
      error,
      hasHint = true,
      ...rest
    },
    ref
  ) => {
    const hasError = error !== undefined;
    const slugify = (slug, sep) => slug.replace(/\s/g, sep).toLowerCase();
    const slug = slugify(name, '_');
    const uniqInputId = Math.random().toString(26).slice(3);

    return (
      <>
        {hasLabel && (
          <Bloc
            mb={3}
            fontSize={14}
            fontWeight={500}
            display="inline-block"
            htmlFor={uniqInputId}
            style={{
              textTransform: 'capitalize',
            }}
            as="label"
          >
            {label || name}
          </Bloc>
        )}
        <Input
          id={uniqInputId}
          name={slug}
          placeholder={name}
          size={size}
          error={error}
          ref={ref}
          {...rest}
        />
        {hasHint && (
          <Bloc
            my={3}
            color={hasError ? 'surfaces.4' : 'surfaces.2'}
            fontSize={1}
            as="p"
          >
            {hasError ? error : hint}
          </Bloc>
        )}
      </>
    );
  }
);

export default Inputfield;
