import { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';

const END_DATE = new Date('2024-09-30');
const BANNER_DISPLAY_KEY = 'banner-notification-displayed';

const useBannerNotification = () => {
  const [showBanner, setShowBanner] = useState(false);

  const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, value.toString());
  };

  useEffect(() => {
    const currentTime = new Date();

    if (isAfter(currentTime, END_DATE)) {
      setLocalStorageItem(BANNER_DISPLAY_KEY, 'false');
      setShowBanner(false);
      return;
    }

    setLocalStorageItem(BANNER_DISPLAY_KEY, 'true');
    setShowBanner(true);
  }, []);

  return {
    showBanner,
    expiringDate: END_DATE,
  };
};

export default useBannerNotification;
