import { useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useFetchBalance = (token) => {
  const { data, status } = useQuery({
    queryKey: ['userBalance'],
    queryFn: () => userAPI.getBalance(token),
  });
  return { data, status };
};

export default useFetchBalance;
