import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFetchCountries = (
  pageNumber,
  itemsPerPage,
  portabilityOption,
  regulatedOption,
  crossAttr,
  isFetchEnabled
) => {
  const { data, status, error, isFetching, isLoading, isSuccess } = useQuery({
    queryKey: [
      'countries',
      {
        pageNumber,
        itemsPerPage,
        portabilityOption,
        regulatedOption,
        crossAttr,
      },
    ],
    queryFn: () =>
      userAPI.getCountries({
        pageNumber,
        itemsPerPage,
        portabilityOption,
        regulatedOption,
        crossAttr,
      }),
    enabled: isFetchEnabled,
    placeholderData: keepPreviousData,
  });
  return { data, status, error, isFetching, isLoading, isSuccess };
};

export const useFetchCountryRoutes = (id) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['countryRoutes', id],
    queryFn: () => userAPI.getCountryRoutes(id),
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
  return { data, status, isLoading };
};

export const useFetchCountry = (id) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['country', id],
    queryFn: () => userAPI.getCountry(id),
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
  return { data, status, isLoading };
};

export const useFetchCountryTelcos = (countryId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['countryTelcos', countryId],
    queryFn: () => userAPI.getCountryTelcos(countryId),
    keepPreviousData: true,
    enabled: !!countryId,
  });
  return { data, status, isLoading };
};

export const useFetchLocalServices = (countryId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['localServices', countryId],
    queryFn: () => userAPI.getLocalServices(countryId),
    placeholderData: keepPreviousData,
    enabled: !!countryId,
  });
  return { data, status, isLoading };
};
