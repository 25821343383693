import React, { useEffect, useRef } from 'react';
import { Bloc } from 'blocjs';
import { dropdownLayout, inputSelectBox } from './style';
import { Chevron } from '../carets';
import { Input } from '../inputs';

const styles = {
    dropdownLayout, inputSelectBox
};

function Dropdown({ children, isDropdownOpen, chosenItem, setIsDropdownOpen, handleInputTelChange, phoneInput,
    itemsList, disabled, hasError }) {

    const ref = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isDropdownOpen, setIsDropdownOpen]);

    return (
        <Bloc
            ref={ref}
            aria-hidden="true"
            position='relative'
            ml={0}
            style={styles.inputSelectBox}
        >
            <Bloc
                data-testid="select-dropdown"
                aria-hidden="true"
                borderColor={hasError ? "surfaces.4": "#DEE1E7"}
                borderStyle='solid'
                borderWidth='1px'
                borderRadius='4px'
                height={40}
                display='flex'
                backgroundColor={disabled ?  '#fafafa' : 'white'}
                alignItems='center'
                justifyContent='space-between'
                boxSizing='border-box'
            >
                <Bloc display='flex' alignItems="center" overflow='hidden'>
                    <Bloc display="flex" alignItems="center" minWidth={64} height={40} justifyContent="space-between"
                        borderRight='0.5px solid rgba(0, 0, 0, 0.15)'
                        px={3} boxSizing='border-box'
                        id="select-box"
                        onClick={(e) => { 
                            e.preventDefault(); e.stopPropagation(); 
                            setIsDropdownOpen(!isDropdownOpen);
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        
                        <Bloc display="flex" alignItems="center" width={24}>
                            {chosenItem?.image && <Bloc as="img" src={chosenItem?.image || ''} width={24} alt=""/>}
                        </Bloc>
                        <Chevron color={isDropdownOpen ? 'accents.8' : 'surfaces.3'} size={16} />
                    </Bloc>
                    <Bloc>
                        <Input 
                            type="tel"
                            height={36} 
                            border='none' 
                            color="surfaces.3"
                            value={phoneInput || ''}
                            onChange={(e) => handleInputTelChange(e)}
                            disabled={disabled}
                            data-testid="Phone Number"
                        />
                    </Bloc>
                </Bloc>
            </Bloc>

            {!disabled && isDropdownOpen && (
                <Bloc 
                    position='absolute'
                    stop={60}
                    width={1}
                    right={0}
                    zIndex={8}
                    backgroundColor='white'
                    boxSizing='border-box'
                    overflow='auto'
                    padding={0}
                    borderStyle='solid'
                    borderColor="#DEE1E7"
                    borderRadius="6px"
                    height={itemsList.length > 0 ? 200 : 126}
                    style={styles.dropdownLayout}
                    marginTop={2}
                    boxShadow='0px 2px 4px rgba(27, 29, 34, 0.05), 0px 4px 4px rgba(27, 29, 34, 0.02), 0px 8px 8px rgba(27, 29, 34, 0.04), 0px 16px 16px rgba(27, 29, 34, 0.02), 0px 24px 30px rgba(27, 29, 34, 0.02)'
                >
                    {children}
                </Bloc>
            )}
        </Bloc>
    );
}

export default Dropdown;
