import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useSavedPhones = () => {
  const { data: savedPhones, isLoading: isLoadingSavedPhones } = useQuery({
    queryKey: ['rememberedPhones'],
    queryFn: () => userAPI.fetchRememberedPhones(),
    placeholderData: keepPreviousData,
  });

  return { savedPhones, isLoadingSavedPhones };
};

export default useSavedPhones;
