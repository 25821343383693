export const extractDateLabels = (data) => {
  const labels = Object.keys(data).map((key) => key);

  labels.pop();
  return labels;
};
export const extractDateLabelsBilling = (data) => {
  const labels = Object.keys(data).map((key) => key);
  return labels;
};

export const sortDashboardDataByOrder = (
  data,
  timePeriod,
  isDashboard = true
) => {
  const dataToOrder = isDashboard ? data : data?.sms;

  const daysOrder = {
    week: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    month: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  };

  const sortDataByOrder = (returnData, order) => {
    if (!isDashboard) {
      if (
        timePeriod === 'week' ||
        timePeriod === 'quarter' ||
        timePeriod === 'year'
      ) {
        return Object.fromEntries(
          Object.entries(returnData)
            .filter(([key]) => order.includes(key)) // Filter out entries not in the specified order
            .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
        );
      }
      return returnData;
    }

    return Object.fromEntries(
      Object.keys(returnData)
        .filter((key) => key !== 'consumption')
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map((key) => [key, returnData[key]])
    );
  };

  const sortedData =
    timePeriod === 'quarter' || timePeriod === 'year'
      ? sortDataByOrder(dataToOrder, daysOrder.month)
      : sortDataByOrder(dataToOrder, daysOrder.week);

  if (isDashboard) {
    sortedData.consumption = data.consumption;

    return sortedData;
  }
  const returnData = {
    rates: data.rates,
    sms: sortedData,
  };

  return returnData;
};
