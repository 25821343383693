import React from 'react';
import { Bloc } from 'blocjs';

import { format } from 'date-fns';
import { convertUTCToLocalTime } from '../../../../utils';
import { Spinner } from '../../../../components';

const ListMembers = ({
  data,
  isLoading,
  onCheckBox,
  setCheckbox,
  setRowBackgroundColor,
  isMainUser,
}) => {
  const renderMembers = () =>
    data.members.map((member) => {
      let statusBg = 'white';
      switch (member.status) {
        case 'accepted':
          statusBg = '#16c1ad';
          break;
        case 'denied':
          statusBg = '#FFD6DB';
          break;
        case 'pending':
          statusBg = '#ffe79c';
          break;
        default:
          break;
      }

      return (
        <Bloc
          as="tr"
          key={member.username}
          textAlign="left"
          borderBottom={
            member.id === data.members[data.members.length - 1].id
              ? ''
              : '1px solid #DFDBDB'
          }
          background={setRowBackgroundColor(member.id)}
        >
          <Bloc as="td" py={3}>
            {member.email || ''}
          </Bloc>
          <Bloc as="td" py={3}>
            {member.username || ''}
          </Bloc>
          <Bloc as="td" py={3}>
            <Bloc
              as="span"
              borderRadius={27}
              fontSize={10}
              style={{ textTransform: 'capitalize', gap: '5px' }}
              display="flex"
              boxSizing="border-box"
              width="fit-content"
              alignItems="center"
              justifyContent="center"
            >
              <Bloc
                width="6px"
                height="6px"
                borderRadius="999px"
                bg={statusBg}
              />
              <Bloc as="span">{member?.status}</Bloc>
            </Bloc>
          </Bloc>
          <Bloc
            display="flex"
            alignItems="center"
            style={{
              float: isMainUser && data.members.length > 1 ? 'left' : 'right',
            }}
            as="td"
            textAlign={isMainUser ? 'initial' : 'right'}
          >
            {format(convertUTCToLocalTime(member.created_at), 'MMM, dd, yyyy')}
          </Bloc>
          {isMainUser && data.admin !== member.id && (
            <Bloc
              as="td"
              py={3}
              onClick={(e) => e.stopPropagation()}
              data-test="check-col"
            >
              {data.admin !== member.id && (
                <Bloc
                  as="span"
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                  }}
                  data-test="check-box"
                  data-testid="check-box"
                  onClick={(e) => onCheckBox(member, e)}
                >
                  {setCheckbox(member.id)}
                </Bloc>
              )}
            </Bloc>
          )}
        </Bloc>
      );
    });

  return (
    <Bloc overflowX="auto">
      {!isLoading && data?.members && (
        <Bloc
          mb={0}
          as="table"
          width="100%"
          style={{ borderCollapse: 'collapse' }}
        >
          <Bloc as="thead">
            <Bloc
              as="tr"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
            >
              <Bloc as="td" py="14px" width={['auto', 'auto', 250]}>
                EMAIL
              </Bloc>
              <Bloc as="td" py="14px" width={['auto', 'auto', 300]}>
                USERNAME
              </Bloc>
              <Bloc as="td" py="14px" width={['auto', 'auto', 300]}>
                STATUS
              </Bloc>
              <Bloc
                as="td"
                py="14px"
                style={{
                  float:
                    isMainUser && data.members.length > 1 ? 'left' : 'right',
                }}
                textAlign={isMainUser ? 'initial' : 'right'}
              >
                Created date
              </Bloc>
              {isMainUser && data.members.length > 1 && (
                <Bloc as="td" py="14px" textAlign="right">
                  SELECT
                </Bloc>
              )}
            </Bloc>
          </Bloc>
          <Bloc as="tbody">
            {!isLoading && data ? renderMembers() : <Spinner size="large" />}
          </Bloc>
        </Bloc>
      )}
    </Bloc>
  );
};

export default ListMembers;
