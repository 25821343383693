import React from 'react';
import { Box, Flex, Bloc } from 'blocjs';
import Icon from '../icon';

const Pod = ({ title, status = false, ready = false }) => {

  const stylePodBlock = () => {
    const bg = 'surfaces.0';
    const boxShadow = '0px 0px 2px rgba(160, 143, 143, 0.32)';
    if(ready) return { bg: 'accents.1', boxShadow: '0 0px 2px rgba(0,0,0,.32), 0 8px 16px rgba(0,0,0,.16), 0 16px 32px rgba(0,0,0,.08), 0px 32px 64px rgba(0,0,0,.16)' };
    if(status) return { bg: 'accents.6', boxShadow: '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16)' }; 
    return { bg, boxShadow };
  }

  const stylePodIcon = () => {
    const name = 'circle';
    const color = 'primaries.6';
    if(ready) return { name: 'twilight', color: 'accents.6' };
    if(status) return { name: 'triangle', color: 'accents.0' }; 
    return { name, color };
  }

  const stylePodParagraph = () => {
    const color = 'primaries.6';
    const text = 'Analysing Pattern';
    if(ready) return { color: 'accents.6', text: title };
    if(status) return { color: 'accentDark', text: title }; 
    return { color, text };
  }

  const styleLastPodParagraph = () => {
    const color = 'primaries.5';
    const text = 'Searching';
    if(ready) return { color: 'accents.5', text: 'Ready' };
    if(status) return { color: 'accents.3', text: 'Under Development' }; 
    return { color, text };
  }

  return (
    <Bloc
        width={[1, 160]}
        height={180}
        bg={stylePodBlock().bg}
        m={4}
        borderColor="accents.5"
        borderRadius={2}
        boxShadow={stylePodBlock().boxShadow}
        border="none"
      >
        <Flex flexWrap="wrap" p={24}>
          <Bloc
            width={24}
            height={24}
            mb={16}
            mt={2}
          >
            <Icon
              name={stylePodIcon().name}
              color={stylePodIcon().color}
              size={24}
            />
          </Bloc>
          <Bloc
            as="p"
            fontSize={16}
            width={1}
            color={stylePodParagraph().color}
          >
            <strong>
              { stylePodParagraph().text}
            </strong>
          </Bloc>
          <Bloc as="p" fontSize="9px" fontWeight="bold" color={styleLastPodParagraph().color}>
            {styleLastPodParagraph().text}
          </Bloc>
        </Flex>
      </Bloc>
  )
};

const Podium = () => (
  <Flex data-testid="podium" flexWrap="wrap" width={1}>
    <Flex flexWrap="wrap" maxWidth={1130} mx="auto" my={6} px={[5, 10, 10, 0]}>
      <Flex width={[1, 1, 1 / 3, 1.3 / 3]} minHeight={[0, 0, 800]} alignItems="center">
        <Box pr={4}>
          <Bloc as="h2" fontSize={5} textAlign={['center', 'left']} m="16px 0">
            API Pods
          </Bloc>
          <Bloc as="hr" width="64px" height="0px" border="1px solid #8EF3E7" m="0" />
          <Bloc as="p" fontSize={3} textAlign={['center', 'left']}>
            As we identify API call patterns we define Presets
            to better the developer experience
          </Bloc>
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        justifyContent={['center', 'center', 'flex-end']}
        width={[1, 1, 2 / 3, 1.7 / 3]}
        minHeight={[0, 0, 800]}
      >
        <Flex
          height={[0, '100%']}
          flexWrap={['wrap', 'no-wrap']}
          flexDirection={['row', 'column']}
          justifyContent={['top', 'top', 'center']}
        >
          <Pod title="One-Time Password" status />
          <Pod />
        </Flex>
        <Flex
          height={[null, '100%']}
          flexWrap={['wrap', 'no-wrap']}
          flexDirection={['row', 'column']}
          justifyContent={['top', 'top', 'center']}
        >
          <Pod />
          <Pod />
          <Pod title="Phone number Lookup" ready />
        </Flex>
        <Flex
          height={[null, '100%']}
          flexWrap={['wrap', 'no-wrap']}
          flexDirection={['row', 'column']}
          justifyContent={['top', 'top', 'center']}
        >
          <Pod />
          <Pod title="Two-Factor Authentication" status />
          <Pod />
          <Pod />
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default Podium;
