/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Bloc, Box, Flex } from 'blocjs';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import Text from '../text';
import { Inputfield } from '../inputs';

const ImportContactThirdStep = () => {
  const location = useLocation();
  const { push } = useHistory();

  const { contact_fields: contactFields, contacts } =
    location.state && location.state;

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('page', 'import');
  searchParams.set('tab', 'active');

  const onlySelectionType =
    contactFields &&
    contactFields.filter(
      (item) => item.input_type === 'select' && item.isSelected
    );

  const [reorganize] = useState(onlySelectionType);
  const [fieldSelected, setFieldSelected] = useState([]);
  const [checkedBox, setCheckedBox] = useState(false);
  const [listGroupName, setListGroupName] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState(null);

  useEffect(() => {
    push({
      search: searchParams.toString(),
      state: {
        contacts,
        contact_fields: contactFields,
        contact_groups: fieldSelected,
        groupListSelected: checkedBox,
        groupListName: listGroupName,
      },
    });
  }, [fieldSelected, listGroupName, checkedBox]);

  const onCheckBox = useCallback(
    (field, e) => {
      e.stopPropagation();

      const updatedFields = structuredClone(fieldSelected);
      const index = updatedFields.findIndex((f) => f.id === field.id);

      if (index > -1) {
        updatedFields.splice(index, 1);
        setFieldSelected(updatedFields);
      } else {
        setFieldSelected([...updatedFields, field]);
      }
    },
    [fieldSelected]
  );

  const handleInputTextField = (e) => {
    e.preventDefault();
    const inputText = e.target.value;
    if (inputText.length < 3) {
      setInputErrorMessage('Group name must be at least 3 characters long');
    } else {
      setInputErrorMessage(null);
    }
    setListGroupName(inputText);
  };

  const setCheckbox = (id) => {
    if (fieldSelected.some((item) => item.id === id)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16} display="flex">
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const onGroupByFileCheckBox = (e) => {
    e.stopPropagation();
    setInputErrorMessage(null);
    setListGroupName('');
    setCheckedBox(!checkedBox);
  };

  const setGroupByFileCheckBox = () => {
    if (checkedBox) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16} display="flex">
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };
  return (
    <Bloc
      borderRadius="6px"
      py="20px"
      px="15px"
      style={{
        border: '1px solid rgba(0, 0, 0, 0.06)',
      }}
    >
      <Bloc as="p" p={0} m={0} fontWeight={500}>
        Automatically group by:
      </Bloc>
      <Flex
        mt="10px"
        flexDirection="column"
        style={{
          rowGap: '15px',
        }}
      >
        {reorganize &&
          reorganize.map((field) => (
            <Flex
              key={field.id}
              alignItems={['start', 'center']}
              flexDirection={['column', 'row']}
              justifyContent={['space-between', 'flex-start']}
              style={{
                columnGap: '15px',
                rowGap: '10px',
              }}
            >
              <Flex
                width={['100%', '100%', '20%']}
                style={{
                  columnGap: '6px',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                alignItems="center"
                onClick={(e) =>
                  !field.isDefault ? onCheckBox(field, e) : null
                }
              >
                <Bloc as="span">{setCheckbox(field.id)}</Bloc>
                <Bloc>
                  <Text
                    fontWeight="normal"
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {field.new_name || field.name}
                  </Text>
                </Bloc>
              </Flex>
            </Flex>
          ))}
      </Flex>
      <Flex
        alignItems={['start', 'center']}
        flexDirection={['column', 'row']}
        justifyContent={['space-between', 'flex-start']}
        style={{
          columnGap: '15px',
          rowGap: '10px',
          marginTop: '15px',
        }}
      >
        <Flex
          alignItems={['start', 'center']}
          flexDirection={['column', 'row']}
          style={{
            gap: '10px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              columnGap: '6px',
            }}
            onClick={(e) => onGroupByFileCheckBox(e)}
          >
            <Bloc as="span">{setGroupByFileCheckBox()}</Bloc>
            <Bloc
              style={{
                cursor: 'pointer',
              }}
              width="100%"
              fontSize="14px"
              color="#757F84"
            >
              <Text fontWeight="normal" fontSize="14px">
                Create a group from new file
              </Text>
            </Bloc>
          </Flex>
          <Flex
            width={['100%', '100%', 'fit-content']}
            py="7px"
            px="16px"
            fontSize="14px"
            style={{
              border: '1px solid #D4DEE8',
              borderRadius: '4px',
              opacity: !checkedBox && 0.4,
            }}
          >
            <Flex
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 300,
              }}
            >
              <Text fontSize="13px">Group Name:</Text>
            </Flex>
            <Flex>
              <Inputfield
                size="contact"
                disabled={!checkedBox}
                required
                py={0}
                fontSize="14px"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  textTransform: 'initial',
                }}
                value={listGroupName}
                name="text"
                placeholder="Group name"
                onChange={(e) => handleInputTextField(e)}
                hasLabel={false}
                hasHint={false}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Bloc ml={[0, '222px']}>
        {inputErrorMessage && (
          <Text color="red" fontSize="10px">
            {inputErrorMessage}
          </Text>
        )}
      </Bloc>
    </Bloc>
  );
};

export default ImportContactThirdStep;
