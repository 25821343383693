import React from 'react';
import { Bloc } from 'blocjs';
import Icon from '../icon';

const Chevron = ({ color, size = 16, rotate, rotation = 0 }) => (
  <Bloc
    as="span"
    display="flex"
    style={{
      transform: `rotate(${rotate ? '180' : `${rotation}`}deg)`,
      transition: `all .25s ease-${rotate ? 'out' : 'in'}`,
    }}
  >
    <Icon name="accordion" color={color} size={size} dimension="24x24" />
  </Bloc>
);

export default Chevron;
