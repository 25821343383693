import { startOfMonth, startOfQuarter, startOfWeek } from 'date-fns';

const periodFunctions = {
  day: (date) => date,
  week: (date) => startOfWeek(date, { weekStartsOn: 1 }),
  month: (date) => startOfMonth(date),
  quarter: (date) => startOfQuarter(date),
  year: (date) => date,
};

function getStartDateOfPeriod(date, period) {
  if (!(period in periodFunctions)) {
    return null;
  }
  const startDateFunction = periodFunctions[period];
  return startDateFunction(date);
}

export default getStartDateOfPeriod;
