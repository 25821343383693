/* eslint-disable no-restricted-globals */
export default () => {
  self.onmessage = async (message) => {
    const { data, fileName } = message.data;
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    data.forEach(e => {delete e.id});
    postMessage({ blob, fileName });
  } 
};
