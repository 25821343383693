import React, { useRef, useState } from 'react';

import { Bloc, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressiveImage from 'react-progressive-graceful-image';
import UserAPI from '../../services';
import { Text, Button, Spinner, Icon } from '../../components';
import ImageCropper from './imageCropper';
import noProfilePic from '../../assets/images/no-profile-picture.png';
import CustomToast from '../../components/toast/customToast';
import { getCroppedImg, refreshProfileImg } from '../../utils';

const MainAccount = ({
  user,
  setShowAvatarForm,
  profile,
  isProfileLoading,
  isProfileFetching,
  uniqueKey,
  setUniqueKey,
}) => {
  const profileImg = refreshProfileImg(profile, uniqueKey);
  const firstLetterOfUsername = (user?.username || '')[0];

  const userAPI = new UserAPI();
  const { mutate, status, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateAvatarPhoto(payload),
  });
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(null);
  const queryClient = useQueryClient();
  const uploadButtonRef = useRef(null);

  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const profileImage = profileImg || noProfilePic;

  const updateProfilePhoto = async (e) => {
    e.preventDefault();

    const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels);

    const blobFile = await fetch(croppedImage).then((r) => r.blob());
    const imageFile = new File([blobFile], 'profile-image.jpg', {
      type: blobFile.type,
    });

    const formData = new FormData();
    formData.append('avatar', imageFile);

    if (croppedImage) {
      const payload = formData;
      mutate(payload, {
        onSuccess: async (res) => {
          setShowToast(true);
          setUniqueKey(Date.now());
          if (res) {
            await queryClient.invalidateQueries({ queryKey: ['selfProfile'] });
            setImageToCrop(undefined);
            setShowAvatarForm(false);
            setTimeout(() => {
              setShowToast(false);
              setShowAvatarForm(false);
            }, 3000);
          }
        },
        onError: (err) => {
          setShowErrorToast(err?.response?.data?.error?.message);
          setTimeout(() => {
            setShowErrorToast(false);
            setImageToCrop(undefined);
            setShowAvatarForm(false);
          }, 2000);
        },
      });
    }

    return true;
  };

  const callErrorMessage = (message) => {
    setShowErrorToast(message);
  };

  const validateFile = (fileUploaded) => {
    // check the type
    const allowedFileTypes = ['image/png', 'image/jpeg'];
    if (fileUploaded && allowedFileTypes.indexOf(fileUploaded.type) === -1) {
      callErrorMessage('Invalid Image File Type');
      return false;
    }
    // check the size
    const maxSizeInBytes = 5e6; // 5MB
    if (fileUploaded && fileUploaded.size > maxSizeInBytes) {
      callErrorMessage('File too large, should not be more than 5MB in size');
      return false;
    }
    return true;
  };

  const getUploadedFile = (uploadedfile) => {
    if (validateFile(uploadedfile)) {
      setShowErrorToast(null);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = reader.result;
        setImageToCrop(image);
        setShowAvatarForm(true);
      });
      reader.readAsDataURL(uploadedfile);
    }
  };

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      getUploadedFile(event.target.files[0]);
    }
  };
  return (
    <Bloc>
      {status === 'error' && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => {
            setShowErrorToast(false);
          }}
          message={error?.response?.data?.error?.message}
          status="error"
        />
      )}

      {status === 'success' && showToast && (
        <CustomToast
          margin={['0px auto']}
          showToast={showToast}
          handleHideToast={() => {
            setShowErrorToast(false);
          }}
          message="Profile Image updated successfully"
          status="success"
        />
      )}

      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
      >
        {imageToCrop && (
          <Text
            style={{
              borderBottom: '1px solid #DFDBDB',
              paddingBottom: '20px',
            }}
            color="black"
            fontSize="18px"
            fontWeight="500"
          >
            Adjust your profile picture
            {(isProfileLoading || isProfileFetching) && (
              <Spinner size="small" />
            )}{' '}
          </Text>
        )}
        <Bloc>
          {imageToCrop ? (
            <Bloc
              position="relative"
              width={[1, '320px']}
              height={['350px', '320px']}
            >
              <ImageCropper
                imageToCrop={imageToCrop}
                setCroppedAreaPixels={setCroppedAreaPixels}
              />
            </Bloc>
          ) : (
            <Bloc
              width="fit-content"
              data-testid="update-btn"
              onClick={() => uploadButtonRef.current?.click()}
            >
              <Flex
                alignItems="center"
                style={{ cursor: 'pointer' }}
                width="fit-content"
                position="relative"
              >
                <Bloc
                  bg="accents.2"
                  borderRadius="50%"
                  width={20}
                  position="absolute"
                  bottom={0}
                  right={0}
                  height={20}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p="2"
                  color="white"
                >
                  <Icon name="pencil" dimension="24x24" size="15px" />
                </Bloc>
                <ProgressiveImage src={profileImage}>
                  {(src, loading) =>
                    loading ? (
                      <Bloc
                        as="span"
                        width={64}
                        height={64}
                        bg="accents.6"
                        color="accents.0"
                        display="inline-flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="50%"
                        fontWeight="bold"
                        fontSize="21px"
                        boxShadow="0px 0px 2px rgba(160, 143, 143, 0.32)"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {firstLetterOfUsername}
                      </Bloc>
                    ) : (
                      <Bloc
                        as="img"
                        width={64}
                        height={64}
                        borderRadius="50%"
                        style={{ objectFit: 'cover' }}
                        src={src}
                        alt="avatar"
                      />
                    )
                  }
                </ProgressiveImage>
              </Flex>
              <Bloc
                as="input"
                type="file"
                ref={uploadButtonRef}
                hidden
                onChange={onUploadFile}
                accept="image/png, image/jpeg"
              />
            </Bloc>
          )}
        </Bloc>

        {showErrorToast && (
          <Bloc
            border="1px solid #DFDBDB"
            left={12}
            color={showErrorToast ? 'error' : 'surfaces.2'}
            fontSize={1}
          >
            {showErrorToast}
          </Bloc>
        )}

        {imageToCrop && (
          <Flex
            flexWrap="wrap"
            justifyContent="end"
            pt="20px"
            style={{
              borderTop: '1px solid #DFDBDB',
              gap: '10px',
            }}
          >
            <Button
              profile="accentLight"
              size="contact"
              onClick={updateProfilePhoto}
              disabled={!croppedAreaPixels || isPending}
              loading={isPending}
              data-testid="upload-avatar"
            >
              Set new profile picture
            </Button>
            <Button
              profile="secondary"
              size="contact"
              onClick={() => {
                setShowAvatarForm(false);
                setImageToCrop(undefined);
                setShowAvatarForm(false);
              }}
              data-testid="cancel-upload"
            >
              Cancel
            </Button>
          </Flex>
        )}
      </Flex>
    </Bloc>
  );
};

export default MainAccount;
