import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'blocjs';
import { useLocation } from 'react-router-dom';
import {
  Text,
  Layout,
  Spinner,
  CheckedBox,
  Icon,
} from '../../../../components';
import { useFetchAllOrgs } from '../../../../hooks';
import ListOrganisations from './listOrganisations';
import { balanceStatuses, formatDate } from '../../../../utils';
import AddFunds from './addFunds';

const ManageBilling = () => {
  const location = useLocation();

  const [showAddFunds, setShowAddFunds] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredStartDate, setFilteredStartDate] = useState(null);
  const [filteredEndDate, setFilteredEndDate] = useState(null);
  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [balanceStatus, setBalanceStatus] = useState(location.state);
  const [orgsList, setOrgsList] = useState([]);

  const [searchedName, setSearchedName] = useState('');

  const itemsPerPage = 25;
  const {
    data: orgsData,
    isLoading: isOrgsLoading,
    isFetching: isOrgsFetching,
  } = useFetchAllOrgs(
    pageNumber,
    itemsPerPage,
    filteredStartDate,
    filteredEndDate,
    balanceStatus,
    isFetchEnabled,
    searchedName
  );

  useEffect(() => {
    if (
      location.state &&
      location.state.theOrgId &&
      location.state.theSenderId
    ) {
      setShowAddFunds(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (orgsData && orgsData.orgs) {
      setOrgsList(orgsData);
    }
  }, [orgsData]);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedOrgs.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const goToOrgDetails = (org, e) => {
    e.stopPropagation();
    // setSelectedOrg(org.original.id);
  };

  let debounceTimer;

  const searchByName = (e) => {
    e.preventDefault();
    const itemsList = orgsData?.orgs || [];
    const inputValue = e.target.value;
    setSearchedName(inputValue);
    const searchInput = inputValue.trim().toLowerCase();
    setIsFetchEnabled(false);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      if (searchInput.length > 1) {
        setIsFetchEnabled(true);
        const filterMatches = itemsList.filter(
          (item) =>
            // Check if the search term is found in "name," "org_name," or "main_user_name"
            item.name && item.name.toLowerCase().includes(searchedName)
        );

        setOrgsList({ orgs: filterMatches });
      }
    }, 1500);
  };

  const handleFilterFormDates = (timeStatus, date) => {
    setIsFetchEnabled(false);
    const formatedDate = formatDate(date);
    if (timeStatus === 'start') {
      setStartDate(date);
      setFilteredStartDate(formatedDate);
    } else if (timeStatus === 'end') {
      setEndDate(date);
      setFilteredEndDate(formatedDate);
    }
  };

  const filterOrgs = () => {
    setIsFetchEnabled(true);
  };

  const onAddFunds = () => {
    setShowAddFunds(true);
    setIsFetchEnabled(true);
  };

  const cancelAddFunds = () => {
    setSelectedOrgs([]);
    setShowAddFunds(false);
  };

  const onCheckBox = (org, e) => {
    e.stopPropagation();
    const items = [...selectedOrgs];
    const index = items.findIndex((item) => item.id === org.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedOrgs(items);
    } else {
      setSelectedOrgs([org]);
    }
  };

  const handleBalanceStatus = (option) => {
    if (option) {
      setBalanceStatus(option);
    } else {
      setBalanceStatus(null);
    }
    setIsFetchEnabled(true);
  };

  const setCheckbox = (rowId) => {
    if (selectedOrgs.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const hasInputFilled = !!(searchedName || startDate || endDate);
  // const orgsResult = useMemo(() => orgsList, [orgsList]);

  return (
    <Layout>
      <>
        {!showAddFunds && (
          <Flex
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            alignItems="center"
          >
            <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
              <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
                Billing {isOrgsLoading && <Spinner size="large" />}
              </Text>
            </Box>
          </Flex>
        )}
        {!showAddFunds && (
          <ListOrganisations
            isLoading={isOrgsLoading}
            isFetching={isOrgsFetching}
            changePage={changePage}
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            data={orgsList}
            setRowBackgroundColor={setRowBackgroundColor}
            goToOrgDetails={goToOrgDetails}
            searchByName={searchByName}
            handleFilterFormDates={handleFilterFormDates}
            filterOrgs={filterOrgs}
            startDate={startDate}
            endDate={endDate}
            searchedName={searchedName}
            setIsFetchEnabled={setIsFetchEnabled}
            onAddFunds={onAddFunds}
            onCheckBox={onCheckBox}
            setCheckbox={setCheckbox}
            showAddFunds={showAddFunds}
            selectedOrgs={selectedOrgs}
            handleBalanceStatus={handleBalanceStatus}
            balanceStatuses={balanceStatuses}
            balanceStatus={balanceStatus}
            hasInputFilled={hasInputFilled}
          />
        )}
      </>
      {showAddFunds && selectedOrgs.length > 0 && (
        <AddFunds
          orgData={selectedOrgs[0].original}
          cancelAddFunds={cancelAddFunds}
          setShowAddFunds={setShowAddFunds}
          data={orgsData}
          setSelectedOrganizations={setSelectedOrgs}
        />
      )}
      <Box mt={100} />
    </Layout>
  );
};

export default ManageBilling;
