import { Bloc } from 'blocjs';
import React, { useState } from 'react';

import Content from './content';
import Dropdown from './dropdown';


function Telcos({ hasSearch = false, disabled = false, hasError=false, 
  setHasError=undefined, selectedTelcos, setSelectedTelcos}) {
  const [isTelcosDropdown, setIsTelcosDropdown] = useState(false);
  const [searchedCountry, setSearchedCountry] = useState('');

  const handleSelectedCountry = (selectedItem) => {
    const index = selectedTelcos.findIndex(key => key.name === selectedItem.name);
    if(index === -1) {
        setSelectedTelcos([...selectedTelcos, selectedItem]);
    } 
    setIsTelcosDropdown(false);
    setSearchedCountry('');
    if(setHasError) {
      setHasError(null);
    }
  };

    const removeCountry = (inputName, e) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        const currentSelectedTelcos = [...selectedTelcos];
        const countryIndex = currentSelectedTelcos.findIndex(key => key.name === inputName);
        if (countryIndex !== -1) {
            currentSelectedTelcos.splice(countryIndex, 1); // Remove the recipient
        }
        setSelectedTelcos(currentSelectedTelcos);
    }

  return (
    <Bloc display="flex" alignItems="center">
      <Dropdown 
        chosenItems={selectedTelcos} 
        isDropdownOpen={isTelcosDropdown} 
        setIsDropdownOpen={setIsTelcosDropdown}
        itemsList={selectedTelcos}
        disabled={disabled}
        hasError={hasError}
        removeItem={removeCountry}
      >
        <Content
          handleSelectedItem={handleSelectedCountry} 
          searchItemName={() => {}}
          searchedTerm={searchedCountry} 
          itemsList={selectedTelcos} 
          hasSearch={hasSearch}
        />
      </Dropdown>
    </Bloc>
  );
}

export default Telcos;
