import React, { useState, useRef, useEffect } from 'react';
import useTypewriter from 'react-typewriter-hook';
import { Box, Flex, Bloc } from 'blocjs';

import anthena from '../../assets/images/cloud_anthena.png';

const introMessages = ['for humans.', 'for machines.', 'for communities.'];
let index = 0;

const Intro = () => {
  const [introMessage, setIntroMessage] = useState('for you.');
  const intervalRef = useRef({});
  const message = useTypewriter(introMessage);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      index = index > 2 ? 0 : 1 + index;
      setIntroMessage(introMessages[index]);
    }, 4000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [introMessage]);

  return (
    <Flex
      data-testid="intro"
      flexWrap="wrap"
      maxWidth={['100%', 768, 968]}
      height={816}
      mx="auto"
    >
      <Box width={[1, 1, 2 / 3]}>
        <Flex height="100%" alignItems="center">
          <Bloc
            as="h1"
            width={1}
            color="surface"
            fontSize={[5, 5, 6]}
            textAlign={['center', 'center', 'left']}
          >
            Instant communication
            {' '}
            <br />
            {' '}
            {message}
&nbsp;
          </Bloc>
        </Flex>
      </Box>

      <Box width={[1, 1, 1 / 3]}>
        <Bloc
          width="100%"
          height="100%"
          backgroundImage={`url(${anthena})`}
          backgroundSize="contain"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        />
      </Box>
    </Flex>
  );
};

export default Intro;
