export const defaultBaseName = '/';
export const ghBaseName = '/pindo-web';
export const ghLocationHost = 'pindoio.github.io';
export const senderIDStatus = [
  {
    id: 1,
    name: 'pending',
  },
  {
    id: 2,
    name: 'approved',
  },
  {
    id: 3,
    name: 'denied',
  },
];

export const dashboardProductOptions = [{ id: 1, name: 'SMS' }];
export const downloadOptions = [
  {
    id: 1,
    name: 'PDF',
  },
  {
    id: 2,
    name: 'CSV',
  },
];
