import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { Text } from '../../components';
import { formatOrAbbreviateNumber } from '../../utils';

const OverallSummery = ({ messages }) => {
  const formattedMessages = formatOrAbbreviateNumber(messages);
  // const formattedAmountConsumed = formatOrAbbreviateNumber(amountConsumed);

  return (
    <Bloc
      display="flex"
      flexDirection="column"
      width={['100%', 'fit-content']}
      maxWidth={['100%', '30%', '100%']}
      style={{
        columnGap: '12px',
      }}
    >
      <Bloc
        height={['122px', '120px', '137px']}
        boxShadow=" 0px 8px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.32)"
        px={['12px', '10px', '14px']}
        py={['15px', '20px']}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          height="100%"
          flexDirection={['row', 'column']}
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Bloc
            display="flex"
            width="100%"
            flexDirection="column"
            // pb={['0px', '14px']}
            // borderBottom={['none', 'solid #b5b5b580']}
          >
            <Text as="p" fontSize={[12, 12, 14]}>
              Messages
            </Text>
            <Text
              as="p"
              fontSize={[40, 42, 50]}
              fontWeight="bold"
              style={{
                textTransform: 'uppercase',
              }}
              color="surfaces.3"
            >
              {formattedMessages}
            </Text>

            <Bloc size="77%">
              <Text as="p" fontSize={[10, 9, 10]} color="surfaces.3">
                SMSs expected to be sent with the amount
              </Text>
            </Bloc>
          </Bloc>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default OverallSummery;
