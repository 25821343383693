import React, { useState, useMemo, useCallback } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as XLSX from 'xlsx';

import {
  Text,
  Pagination,
  CheckedBox,
  Icon,
  Button,
  Toast,
} from '../../../../../components';
import AddOrEditPrice from './addOrUpdatePrice';
import PricingTable from './table';
import {
  addUUIDToObjectArray,
  countriesList,
  separateData,
  slugify,
} from '../../../../../utils';
import UserAPI from '../../../../../services';
import FilterForm from './filterform';

const Telcos = ({ telcos, areAllTelcosDisplayed, handleText, original }) => {
  const allTelcos = telcos && telcos.split(/,\s|,/); // Split the telcos string into an array using either ', ' or ',' as a separator
  return (
    <Flex data-testid="bloc-telcos" alignItems="center" width={250}>
      {areAllTelcosDisplayed ? (
        <Flex
          style={{ whiteSpace: areAllTelcosDisplayed ? 'initial' : 'nowrap' }}
          width={1}
          flexWrap="wrap"
        >
          {allTelcos &&
            allTelcos.map((telco) => (
              <Bloc
                as="span"
                color="#54616B"
                bg="#E7EBEF"
                mr={3}
                my={2}
                py="3px"
                px="10px"
                borderRadius={20}
                style={{ whiteSpace: 'nowrap' }}
                key={telco + original.id}
              >
                {telco}
              </Bloc>
            ))}
        </Flex>
      ) : (
        <Bloc
          as="span"
          color="#54616B"
          bg="#E7EBEF"
          mr={3}
          my={2}
          py="3px"
          px="10px"
          borderRadius={20}
          style={{ whiteSpace: 'nowrap' }}
        >
          {allTelcos && allTelcos[0]}
        </Bloc>
      )}

      {allTelcos && allTelcos.length > 1 && (
        <Bloc
          as="button"
          className="dots-btn"
          onClick={(e) => handleText(original.id, e)}
          data-testid="handlelongtext"
          width={36}
          height={16}
          background="#E4FFFC"
          border="none"
          borderRadius={3}
          marginLeft="10px"
          lineHeight="0px"
          style={{ cursor: 'pointer' }}
        >
          <Box as="span" className="dot" />
          <Box as="span" className="dot" />
          <Box as="span" className="dot" />
        </Bloc>
      )}
    </Flex>
  );
};

const Pricing = ({ currentService, setShowPricing }) => {
  const pricingWithUUID = addUUIDToObjectArray(currentService?.pricing || []);

  const pricingData = pricingWithUUID.map((item) => {
    const countryObj = countriesList.find(
      (country) => country.code === item.country
    );
    return { ...item, country_name: countryObj?.name || '' };
  });

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const itemsPerPage = 25;
  const [isAddOrEditPrice, setIsAddOrEditPrice] = useState(false);
  const [displayedTelcos, setDisplayedTelcos] = useState([]);
  const [isEditPricing, setIsEditPricing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showRecipientsError, setShowRecipientsError] = useState(null);
  const [newUploadedData, setNewUploadedData] = useState([]);
  const [searchedMany, setSearchedMany] = useState('');
  const [filteredPricing, setFilteredPricing] = useState(pricingData);
  const [duplicates, setDuplicates] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const inputFileKey = Date.now();

  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateIntService(payload),
  });

  const saveUploadedPrices = async (e) => {
    e.preventDefault();
    if (currentService) {
      const currentPricing = currentService.pricing;
      const payload = {
        name: currentService.name,
        username: currentService.username,
        password: currentService.password,
        url: currentService.url,
        is_operational: currentService.is_operational,
        backup_position: currentService.backup_position,
        pricing: [...currentPricing, ...newUploadedData],
      };

      const { id } = currentService;
      const request = { bodyReq: payload, serviceId: id };
      await mutateUpdate(request, {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['intlServices'] });
          setTimeout(async () => {
            setShowToast(false);
            setShowPricing(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  const onAddPrice = () => {
    setIsEditPricing(false);
    setIsAddOrEditPrice(true);
  };

  const onEditPrice = () => {
    setIsEditPricing(true);
    setIsAddOrEditPrice(true);
  };

  const handleText = useCallback(
    (telcoId, e) => {
      e.stopPropagation();
      const items = [...displayedTelcos];
      const index = items.indexOf(telcoId);
      if (index > -1) {
        items.splice(index, 1);
        setDisplayedTelcos(items);
      } else {
        setDisplayedTelcos([...items, telcoId]);
      }
    },
    [displayedTelcos]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Country Name',
        floatRight: false,
        accessor: (row) => row.country_name,
      },
      {
        Header: 'Price',
        floatRight: false,
        accessor: (row) => row.price,
      },
      {
        Header: 'Telcos',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <Telcos
            telcos={original.telcos}
            handleText={handleText}
            areAllTelcosDisplayed={displayedTelcos.includes(original.id)}
            original={original}
          />
        ),
      },
      {
        Header: 'Country Code',
        floatRight: false,
        accessor: (row) => row.country,
      },
    ],
    [handleText, displayedTelcos]
  );

  const onCheckBox = (service, e) => {
    e.stopPropagation();
    const items = [...selectedPrices];
    const index = items.findIndex((item) => item.id === service.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedPrices(items);
    } else {
      setSelectedPrices([service]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedPrices.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const onRowClick = (row, e) => {
    e.stopPropagation();
  };

  const cancelEditing = () => {
    setIsEditPricing(false);
    setIsAddOrEditPrice(false);
    setSelectedPrices([]);
  };

  const displayErrorMessage = (errorMessage) => {
    setShowRecipientsError(errorMessage);
    setTimeout(() => {
      setShowRecipientsError(null);
    }, 8000);
  };

  const processCSVData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i += 1) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j += 1) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columnsList = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    // const keysToCheck = ['country', 'price', 'telcos'];
    if (columnsList.length > 0) {
      const areDataValid = columnsList.some((header) => {
        const slugifiedHeader = slugify(header.name);
        if (
          slugifiedHeader === slugify('country') ||
          slugifiedHeader === slugify('price') ||
          slugifiedHeader === slugify('telcos')
        ) {
          return true;
        }
        return false;
      });

      if (areDataValid) {
        if (list.length > 0) {
          // validate the data and retrieve duplicates of new data in the existing data
          // check the country code and the telcos of the new upload data if they already exist
          const existingData = pricingData;
          const formattedList = list.map((obj) => ({
            ...obj,
            price: Number(obj.price),
          }));
          const { dataWithoutDuplicates, dataWithDuplicates } = separateData(
            existingData,
            formattedList
          );
          setNewUploadedData(dataWithoutDuplicates);
          setDuplicates(dataWithDuplicates);
        }
      } else {
        displayErrorMessage(
          'The file is missing a column with header “country, price and telcos"'
        );
      }
    }
    setIsUploading(false);
  };

  const readFileUploaded = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataArray = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processCSVData(dataArray);
    };
    if (file) reader.readAsBinaryString(file);
  };

  const validateFile = (fileUploaded) => {
    const validExts = ['.csv', '.xls', '.xlsx'];
    const allowedFileTypes = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    if (fileUploaded && allowedFileTypes.indexOf(fileUploaded.type) === -1) {
      displayErrorMessage(
        `Invalid File Type, valid files are of ${validExts.toString()} types`
      );
      return false;
    }
    // check the size
    const maxSizeInBytes = 15e6; // 15MB
    if (fileUploaded && fileUploaded.size > maxSizeInBytes) {
      displayErrorMessage(
        'File too large, should not be more than 15MB in size'
      );
      return false;
    }
    return true;
  };

  const processTheValidFileData = (fileToRead) => {
    if (validateFile(fileToRead)) {
      setIsUploading(true);
      readFileUploaded(fileToRead);
    }
  };

  const clearAllUploadedData = () => {
    setShowRecipientsError(null);
    setNewUploadedData([]);
    setDuplicates([]);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    clearAllUploadedData();
    processTheValidFileData(file);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const searchByMany = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setSearchedMany(inputValue);
    const searchInput = inputValue.trim().toLowerCase();
    if (searchInput.length > 0) {
      const filterMatches = pricingData.filter(
        (item) =>
          (item.country_name &&
            item.country_name.toLowerCase().includes(searchInput)) ||
          (item.country && item.country.toLowerCase().includes(searchInput)) ||
          (item.price && item.price.toString().includes(searchInput)) ||
          (item.telcos && item.telcos.toLowerCase().includes(searchInput))
      );
      setFilteredPricing(filterMatches);
    } else {
      setFilteredPricing(pricingData);
    }
  };

  const totalItemsToUpload = newUploadedData.length;
  const totalDuplicates = duplicates.length;

  const totalData = filteredPricing.length;
  const pageCount = Math.ceil(totalData / itemsPerPage);
  const telcosPrices = {
    pages: {
      page: pageNumber,
      pages: pageCount,
      per_page: itemsPerPage,
      total: totalData,
    },
  };

  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredPricing.slice(startIndex, endIndex);

  let allCountryTelcos = [];
  if (selectedPrices.length > 0) {
    allCountryTelcos = filteredPricing.filter(
      (item) => item.country === selectedPrices[0].country
    );
  }

  const countryTelcosData = isEditPricing ? allCountryTelcos : undefined;

  return (
    <>
      {showRecipientsError && (
        <Toast message={showRecipientsError} status="success" closed />
      )}
      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast message="Prices added successfully" status="success" />
      )}
      {isAddOrEditPrice && (
        <AddOrEditPrice
          currentService={currentService}
          cancelEditing={cancelEditing}
          isEditPricing={isEditPricing}
          setIsAddOrUpdateService={setShowPricing}
          countryTelcosData={countryTelcosData}
        />
      )}
      <>
        {!isAddOrEditPrice && (
          <>
            <Flex
              justifyContent="space-between"
              width="100%"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box style={{ float: 'left' }} minWidth="16%">
                <Text
                  as="h1"
                  style={{ lineHeight: '32px' }}
                  fontSize={[24, 28]}
                >
                  {currentService?.name || ''}
                </Text>
              </Box>

              <Flex justifyContent="end">
                {totalItemsToUpload > 0 || totalDuplicates > 0 ? (
                  <>
                    <Bloc mr={4} display="flex" alignItems="center">
                      <Text fontSize={14}>
                        Total uploaded: {totalItemsToUpload + totalDuplicates}
                      </Text>
                    </Bloc>
                    {totalDuplicates > 0 && (
                      <Bloc mr={4} display="flex" alignItems="center">
                        <Text fontSize={14} color="primary">
                          Duplicates: {totalDuplicates}
                        </Text>
                      </Bloc>
                    )}
                    {totalItemsToUpload > 0 && (
                      <Bloc mr={4}>
                        <Button
                          profile="accentLight"
                          size="small"
                          type="button"
                          onClick={(e) => saveUploadedPrices(e)}
                          isLoading={isLoadingUpdate}
                        >
                          Save
                        </Button>
                      </Bloc>
                    )}
                    <Bloc>
                      <Button
                        profile="primary"
                        size="small"
                        onClick={() => clearAllUploadedData(false)}
                      >
                        Clear
                      </Button>
                    </Bloc>
                  </>
                ) : (
                  <>
                    <Bloc mr={5}>
                      <Button
                        profile="accentLight"
                        size="small"
                        type="button"
                        onClick={handleClick}
                        data-test="handle-file-upload"
                        disabled={isUploading}
                      >
                        {isUploading ? 'Uploading ...' : 'Upload'}
                      </Button>
                      <Bloc
                        as="input"
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        ref={hiddenFileInput}
                        onChange={handleFileUpload}
                        key={inputFileKey}
                        style={{ display: 'none' }}
                        data-testid="fileUpload"
                      />
                    </Bloc>
                    <Bloc mr={5}>
                      <Button
                        profile="accentLight"
                        size="small"
                        onClick={onAddPrice}
                      >
                        Add
                      </Button>
                    </Bloc>
                    <Bloc>
                      <Button
                        profile="accentLight"
                        size="small"
                        onClick={() => setShowPricing(false)}
                      >
                        Back
                      </Button>
                    </Bloc>
                  </>
                )}
              </Flex>
            </Flex>
            <Box mt={6} />
            <FilterForm
              searchedMany={searchedMany}
              searchByMany={searchByMany}
            />
          </>
        )}
        <Box mt={isAddOrEditPrice ? 6 : 2} />
        {!isEditPricing && (
          <>
            <PricingTable
              columns={columns}
              data={paginatedData}
              onRowClick={onRowClick}
              setCheckbox={setCheckbox}
              onCheckBox={onCheckBox}
              onEditPrice={onEditPrice}
              selectedPrices={selectedPrices}
            />
            {telcosPrices && telcosPrices.pages && (
              <Pagination
                itemsPerPage={itemsPerPage}
                pageNumber={pageNumber}
                pages={telcosPrices?.pages}
                pageCount={telcosPrices?.pages?.pages}
                changePage={changePage}
                isFetching={false}
                isLoading={false}
              />
            )}
          </>
        )}
      </>
      <Bloc mt={4} />
    </>
  );
};

export default Pricing;
