import { Flex } from 'blocjs';
import React from 'react';
import DisplayStepperPosition from './displayStepperPosition';
import ImportContactFirstStep from './importContactFirstStep';
import ImportContactSecondStep from './importContactSecondStep';
import ImportContactThirdStep from './importContactThirdStep';

const ImportContactStepper = ({
  currentStep,
  clearAllContacts,
  setShowContactsInfo,
  showContactsInfo,
  getDataTypes,
  setDataTypes,
  showContactError,
  fileName,
  dispatch,
  headerMentions,
  isUploading,
  handleFileUpload,
  progressPercentage,
  importedContactLength,
}) => {
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ImportContactFirstStep
            setShowContactsInfo={setShowContactsInfo}
            showContactsInfo={showContactsInfo}
            showContactError={showContactError}
            clearAllContacts={clearAllContacts}
            isUploading={isUploading}
            fileName={fileName}
            importedContactLength={importedContactLength}
            progressPercentage={progressPercentage}
            handleFileUpload={handleFileUpload}
            dispatch={dispatch}
          />
        );
      case 2:
        return (
          <ImportContactSecondStep
            headerMentions={headerMentions}
            setDataTypes={setDataTypes}
            getDataTypes={getDataTypes}
          />
        );
      case 3:
        return <ImportContactThirdStep headerMentions={headerMentions} />;
      default:
        return null;
    }
  };

  return (
    <Flex
      py="15px"
      flexDirection="column"
      style={{
        rowGap: '15px',
      }}
    >
      <DisplayStepperPosition
        position={currentStep}
        isUploading={isUploading}
      />
      {renderStep()}
    </Flex>
  );
};

export default ImportContactStepper;
