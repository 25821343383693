import React, { useContext } from 'react';
import { Bloc, Flex } from 'blocjs';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import withDropdown from '../avatarWithDropdown/withDropdown';
import Balance from '../balance';
import Divider from '../divider';
import NavigationButton from '../navigationLink';
import useIsPathsOn from '../../hooks/useCheckPathname';
// import Chevron from '../carets/chevron';
import { useFetchOrg } from '../../hooks';

import UserAPI from '../../services';
import { CurrentUserContext } from '../../contexts';
import { textTruncate } from '../../utils';

const LinksWithDropdown = ({ isAdmin, setIsMenuOpen }) => {
  const userAPI = new UserAPI();
  const { currentUser } = useContext(CurrentUserContext);
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const { data: orgsData } = useFetchOrg({ enabled: !isAdmin });
  const inUseOrgData = orgsData?.orgs.filter((org) => org.in_use)[0];

  const { mutate: switchOrg } = useMutation({
    mutationFn: (payload) => userAPI.switchOrg(payload),
  });

  const checkAccountLength = orgsData?.orgs.length > 1;

  const {
    dashboard: isDashboardOn,
    billing: isBillingOn,
    messages: isMessagesOn,
    organizations: isOrganizationsOn,
    countries: isCountriesOn,
    international: isInternationalOn,
    contacts: isContactOn,
    deals: isDealsOn,
  } = useIsPathsOn([
    'dashboard',
    'billing',
    'messages',
    'account',
    'contacts',
    'organizations',
    'countries',
    'international',
    'deals',
  ]);

  return (
    <Bloc>
      {!isAdmin ? (
        <Flex
          style={{
            rowGap: '15px',
          }}
          flexDirection="column"
        >
          <Bloc hide={[null, true]}>
            <Balance />
          </Bloc>
          <Bloc hide={[null, true]}>
            <Divider />
          </Bloc>

          <Bloc
            as={Link}
            to="/dashboard"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isDashboardOn ? 'accent' : 'primary'}
              size="normal"
            >
              Dashboard
            </NavigationButton>
          </Bloc>

          <Bloc
            onClick={() => setIsMenuOpen(false)}
            as={Link}
            to="/messages"
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isMessagesOn ? 'accent' : 'primary'}
              size="normal"
            >
              Messages{' '}
            </NavigationButton>
          </Bloc>
          <Bloc
            onClick={() => setIsMenuOpen(false)}
            as={Link}
            to="/contacts?tab=active"
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isContactOn ? 'accent' : 'primary'}
              size="normal"
            >
              Contacts{' '}
            </NavigationButton>
          </Bloc>

          <Bloc
            as={Link}
            to="/billing"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isBillingOn ? 'accent' : 'primary'}
              size="normal"
            >
              Billing
            </NavigationButton>
          </Bloc>

          <Bloc
            as="a"
            href="https://github.com/pindoio/pindo-cli"
            target="_blank"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton fontSize="14px" profile="primary" size="normal">
              Docs
            </NavigationButton>
          </Bloc>
          <Bloc>
            <Bloc hide={[null, true]} fontWeight="700" fontSize="14px">
              Organizations
            </Bloc>
            <Flex
              flexDirection="column"
              pt="12px"
              pb="6px"
              style={{
                rowGap: '12px',
              }}
            >
              {orgsData?.orgs.map((org) => {
                const orgName = org?.name || currentUser?.username;
                return (
                  <NavigationButton
                    profile={org.in_use ? 'accent' : 'primary'}
                    fontSize="12px"
                    size="normal"
                    onClick={() => {
                      if (checkAccountLength && inUseOrgData.id !== org.id) {
                        switchOrg(org.id, {
                          onSuccess: async () => {
                            await queryClient.invalidateQueries({
                              queryKey: ['selfOrg'],
                            });
                            await queryClient.invalidateQueries({
                              queryKey: ['currentUser'],
                            });
                            await queryClient.invalidateQueries({
                              queryKey: ['listMembers'],
                            });
                            await queryClient.invalidateQueries({
                              queryKey: ['listSenderIDs'],
                            });
                            push('/account?tab=personal_details');
                          },
                        });
                      } else {
                        push('/account?tab=personal_details');
                      }
                    }}
                    style={{
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    key={org.id}
                  >
                    <Flex
                      alignItems="center"
                      style={{
                        gap: '8px',
                      }}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          width: '32px',
                          height: '32px',
                          color: !org.in_use && '#4b4b4bca',
                          borderRadius: '20%',
                          backgroundColor: '#d3d3d32d',
                          fontWeight: '900',
                          fontSize: '15px',
                          border: '1px solid #E5E5E5',
                        }}
                      >
                        {orgName.charAt(0)}
                      </Flex>
                      <Bloc
                        as="span"
                        style={{
                          fontWeight: org.in_use ? 'bold' : 'normal',
                        }}
                      >
                        {textTruncate(orgName, 11)}
                        {!org?.name && ' Org'}
                      </Bloc>
                    </Flex>
                  </NavigationButton>
                );
              })}
            </Flex>
          </Bloc>
        </Flex>
      ) : (
        <Flex
          style={{
            rowGap: '15px',
          }}
          flexDirection="column"
        >
          <Bloc
            as={Link}
            to="/admin/dashboard"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isDashboardOn ? 'accent' : 'primary'}
              size="normal"
            >
              Dashboard
            </NavigationButton>
          </Bloc>
          <Bloc
            as={Link}
            to="/admin/manage/organizations"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isOrganizationsOn ? 'accent' : 'primary'}
              size="normal"
            >
              Organizations
            </NavigationButton>
          </Bloc>
          <Bloc
            as={Link}
            to="/admin/manage/deals"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isDealsOn ? 'accent' : 'primary'}
              size="normal"
            >
              Deals
            </NavigationButton>
          </Bloc>
          <Bloc
            as={Link}
            to="/admin/manage/international-services"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isInternationalOn ? 'accent' : 'primary'}
              size="normal"
            >
              International Services
            </NavigationButton>
          </Bloc>

          <Bloc
            as={Link}
            to="/admin/manage/countries"
            onClick={() => setIsMenuOpen(false)}
            hide={[null, true]}
          >
            <NavigationButton
              fontSize="14px"
              profile={isCountriesOn ? 'accent' : 'primary'}
              size="normal"
            >
              Countries
            </NavigationButton>
          </Bloc>
        </Flex>
      )}
    </Bloc>
  );
};

export default withDropdown(LinksWithDropdown);
