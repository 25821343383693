import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import posthog from 'posthog-js';

import { Button, PhoneInput, Spinner } from '../../components';
import { messageStatuses, selectStatusStyles } from '../../utils';

const FilterForm = ({
  setStatusInput,
  setPhoneNumber,
  startDate,
  endDate,
  isLoading,
  filterMessages,
  isFetching,
  hasInputFilled,
  setIsFetchEnabled,
  handleFilterFormDates,
  phoneNumber,
}) => {
  const handleFilterStatus = (option) => {
    setIsFetchEnabled(false);
    if (option && option.value) {
      const { value } = option;
      setStatusInput(value);
      posthog.capture('Filtered status', { property: value });
    } else {
      setStatusInput('');
    }
  };

  return (
    <Bloc>
      <Bloc
        mb={6}
        backgroundColor="white"
        borderRadius="8px"
        border="1px solid #DEE1E7"
        boxShadow="none"
      >
        <Flex
          flexWrap={['wrap', 'no-wrap']}
          justifyContent="space-between"
          padding="16px 24px"
        >
          <Box width={[1, 'calc(50% - 8px)', 'calc(36% - 115px)']}>
            <PhoneInput
              hasSearch
              phoneInput={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </Box>

          <Box
            ml={[0, 0, 4]}
            mt={[3, 0, 0]}
            width={[1, 'calc(50% - 8px)', 'calc(24% - 18px)']}
          >
            <Select
              isClearable
              options={messageStatuses}
              placeholder="Status"
              onChange={handleFilterStatus}
              styles={selectStatusStyles}
            />
          </Box>
          <Box
            ml={[0, 0, 4]}
            mt={[3, 3, 0]}
            height={40}
            width={[1, 'calc(50% - 8px)', 'calc(20% - 18px)']}
            style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
          >
            <DatePicker
              placeholderText="Start date"
              selected={startDate}
              selectsStart
              maxDate={new Date()}
              startDate={startDate}
              calendarStartDay={1}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleFilterFormDates('start', date)}
              calendarClassName="calendar"
            />
          </Box>
          <Box
            ml={[0, 0, 4]}
            mt={[3, 3, 0]}
            height={40}
            width={[1, 'calc(50% - 8px)', 'calc(20% - 18px)']}
            style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
          >
            <DatePicker
              placeholderText="End date"
              selected={endDate}
              selectsEnd
              maxDate={new Date()}
              startDate={startDate}
              calendarStartDay={1}
              minDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleFilterFormDates('end', date)}
              calendarClassName="calendar"
            />
          </Box>
          <Box ml={[0, 0, 4]} mt={[3, 3, 0]} width={[1, 1, 'auto']}>
            <Button
              profile="accentLight"
              disabled={!hasInputFilled}
              size="dashboard"
              onClick={() => {
                if (hasInputFilled) filterMessages();
              }}
              data-test="filter-btn"
              style={{
                float: 'right',
                fontWeight: 'normal',
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
            >
              {!isLoading && !isFetching ? (
                'Search'
              ) : (
                <Spinner
                  size="large"
                  style={{ marginLeft: 0, marginBottom: '-4px' }}
                />
              )}
            </Button>
          </Box>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default FilterForm;
