/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Bloc, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import posthog from 'posthog-js';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import ClickAwayListener from 'react-click-away-listener';
import UserAPI from '../../../../services';
import { requestMethod, urlValidation } from '../../../../utils';
import {
  Button,
  Inputfield,
  Inputgroup,
  ToggleSwitch,
  Text,
  Chevron,
} from '../../../../components';
import AccountContainer from '../../../userAccount/container';
import CustomToast from '../../../../components/toast/customToast';

const UpdateOrgSettingsForm = ({ data, isMainUser, orgName }) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [headerMethod, setHeaderMethod] = React.useState('');
  const [isMethodChanged, setIsMethodChanged] = React.useState(false);
  const [isHeadersChanged, setIsHeadersChanged] = React.useState(false);

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const [headers, setHeaders] = React.useState([{ key: '', value: '' }]);
  const { register, handleSubmit, errors, formState } = useForm();
  const { mutate, isPending, error, status } = useMutation({
    mutationFn: (payload) => userAPI.updateOrganization(payload),
  });

  const {
    mutate: changeOrgNameMutate,
    status: changeOrgNameStatus,
    error: changeOrgNameError,
    isPending: changeOrgNameIsPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateOrgName(payload),
  });

  const [showToast, setShowToast] = useState(false);

  let defaultEnableUsageNotif = true;
  if (data && data.enable_weekly_usage_notification !== undefined) {
    defaultEnableUsageNotif = data.enable_weekly_usage_notification;
  }

  useEffect(() => {
    if (data) {
      const formattedHeaders = Object.entries(
        data?.outbound_sms_webhook_headers || {}
      ).map(([key, value]) => ({ key, value }));
      setHeaderMethod(
        data?.outbound_sms_webhook_method?.split('.')[1] || 'GET'
      );
      setHeaders(formattedHeaders);
    }
  }, [data]);

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const [weeklyNotification, setWeeklyNotification] = useState(
    defaultEnableUsageNotif
  );

  const onWeeklyNotification = (e) => {
    setIsSwitchChecked(true);
    setWeeklyNotification(e.target.checked);
  };

  const handleHeaderChange = (index, event) => {
    setIsHeadersChanged(true);
    const newHeaders = headers.map((header, i) => {
      if (i === index) {
        return { ...header, [event.target.name]: event.target.value };
      }
      return header;
    });
    setHeaders(newHeaders);
  };

  const addHeaderField = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const onFormSubmit = async (dataInputs) => {
    const parsedSmsRetries = Number(dataInputs.maximum_sms_retries);
    const parsedRetryInterval = Number(dataInputs.retry_interval);
    const parsedBalanceThreshold = Number(dataInputs.balance_threshold);
    const payload = {
      dlr_sms_webhook_url: dataInputs.dlr_sms_webhook_url,
      sms_max_retries: parsedSmsRetries,
      sms_retry_interval_minute: parsedRetryInterval,
      balance_threshold: parsedBalanceThreshold,
      enable_weekly_usage_notification: weeklyNotification,
      outbound_sms_webhook_url: dataInputs.outbound_sms_webhook_url,
      outbound_sms_webhook_method: headerMethod.toLowerCase(),
      outbound_sms_webhook_headers: headers.reduce((acc, header) => {
        if (header.key && header.value) {
          acc[header.key] = header.value;
        }
        return acc;
      }, {}),
    };

    if (dataInputs.org_name !== orgName) {
      changeOrgNameMutate(
        { organizationName: dataInputs.org_name },
        {
          onSuccess: async () => {
            setTimeout(async () => {
              await queryClient.invalidateQueries({ queryKey: 'org' });
              await queryClient.invalidateQueries({ queryKey: ['selfOrg'] });
              push('/account?tab=personal_details');
            }, 3000);

            posthog.capture('updated organization name', {
              property: { organization_name: dataInputs.org_name },
            });
          },
        }
      );
    }

    mutate(payload, {
      onSuccess: async () => {
        setShowToast(true);
        setTimeout(async () => {
          await queryClient.invalidateQueries({ queryKey: 'org' });
          await queryClient.invalidateQueries({ queryKey: ['selfOrg'] });
          setIsSwitchChecked(false);
          setIsMethodChanged(false);
          setIsHeadersChanged(false);
          push('/account?tab=personal_details');
        }, 3000);

        posthog.capture('updated organization settings', { property: payload });
      },
    });
  };

  const statusErrors = status === 'error' || changeOrgNameStatus === 'error';
  const statusSuccess =
    status === 'success' || changeOrgNameStatus === 'success';
  const statusErrorsMessage =
    error?.response?.data?.error?.message ||
    changeOrgNameError?.response?.data?.error?.message;

  return (
    <AccountContainer pt={0}>
      {statusErrors && (
        <CustomToast
          message={statusErrorsMessage}
          status="error"
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}
      {statusSuccess && showToast && (
        <CustomToast
          message="Organization updated successfully"
          status="success"
          showToast
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}
      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
        p="20px"
      >
        <Text color="black" fontSize="18px" fontWeight="600">
          Account Info
        </Text>
        <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
          <Bloc
            display={['flex', 'grid']}
            flexDirection={['column', 'row']}
            pb="20px"
            style={{
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              columnGap: '20px',
            }}
          >
            <Bloc>
              <Inputfield
                name="Org Name"
                ref={register({
                  required: 'Organization name is required',
                  minLength: {
                    value: 2,
                    message:
                      'Organization name should at least be 2 characters',
                  },
                })}
                error={errors?.org_name?.message}
                hint="By default we uses your username for organization"
                defaultValue={orgName}
                disabled={!isMainUser}
              />
            </Bloc>
            <Bloc>
              <Inputfield
                name="DLR SMS Webhook URL"
                placeholder="Webhook to manage your deliver report"
                hint="For custom delivery report management"
                ref={register({
                  pattern: urlValidation,
                })}
                error={errors?.dlr_sms_webhook_url?.message}
                defaultValue={data && data.dlr_sms_webhook_url}
                data-testid="dlr_webhook_url"
              />
            </Bloc>

            <Bloc>
              <Inputfield
                name="Daily Average Consumption"
                placeholder="Daily Average Consumption"
                defaultValue={data && data.daily_avg_consumption}
                data-testid="daily_avg_consumption"
                disabled
              />
            </Bloc>
            <Bloc>
              <Inputfield
                name="Maximum SMS Retries"
                placeholder="Times to retry sending failed SMS"
                hint="Once SMS failed this give you a chance to retry"
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter a number for SMS Retries',
                  },
                })}
                error={errors?.maximum_sms_retries?.message}
                defaultValue={data && data.sms_max_retries}
                data-testid="maximum_sms_retries"
              />
            </Bloc>

            <Bloc>
              <Inputgroup
                name="Balance Threshold"
                placeholder="Balance Threshold"
                hint="When your balance is below the balance threshold,"
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter the amount for Balance Threshold',
                  },
                })}
                error={errors?.balance_threshold?.message}
                defaultValue={data && data.balance_threshold}
                data-testid="balance_threshold"
                groupLeftText="$"
              />
            </Bloc>
            <Bloc>
              <Inputgroup
                name="Retry interval"
                placeholder="Retry interval"
                hint=""
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter a number for Retry interval',
                  },
                })}
                error={errors?.retry_interval?.message}
                defaultValue={data && data.sms_retry_interval_minute}
                data-testid="retry_interval"
                groupRightText="Minutes"
              />
            </Bloc>

            <Flex
              width={1}
              style={{
                gap: '14px',
              }}
            >
              <Flex
                style={{
                  flex: '1 1 0%',
                }}
              >
                <Bloc width={1}>
                  <Inputfield
                    type="text"
                    placeholder="Outbound SMS Webhook URL"
                    name="Outbound SMS Webhook URL"
                    ref={register({
                      pattern: urlValidation,
                    })}
                    error={errors?.outbound_sms_webhook_url?.message}
                    defaultValue={data?.outbound_sms_webhook_url || ''}
                  />
                </Bloc>
              </Flex>
              <Bloc
                display="flex"
                flexDirection="column"
                style={{
                  gap: '9px',
                }}
              >
                <Bloc fontSize="14px" fontWeight="500">
                  Method
                </Bloc>
                <ClickAwayListener
                  onClickAway={() => setIsDropdownOpen(false)}
                  data-test="hide-dropdown"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Flex
                    height={40}
                    justifyContent="space-between"
                    alignItems="center"
                    px="6px"
                    style={{
                      borderRadius: '4px',
                      gap: '10px',
                      border: '1px solid #d3d3d3',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <Text fontSize="14px">
                      {headerMethod || 'Select Method'}
                    </Text>
                    <Chevron
                      color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
                      size={16}
                    />
                  </Flex>
                  {isDropdownOpen && (
                    <Bloc
                      position="absolute"
                      top={40}
                      width="100%"
                      right={0}
                      marginTop={2}
                      padding="14px 10px"
                      zIndex={999}
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow:
                          '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                      }}
                    >
                      {Object.entries(requestMethod).map(([value]) => (
                        <Bloc
                          key={value}
                          py={2}
                          onClick={() => {
                            setHeaderMethod(value);
                            setIsMethodChanged(true);
                            setIsDropdownOpen(false);
                          }}
                        >
                          <Text
                            hoverAsLink
                            variant="small"
                            style={{ cursor: 'pointer' }}
                          >
                            {value}
                          </Text>
                        </Bloc>
                      ))}
                    </Bloc>
                  )}
                </ClickAwayListener>
              </Bloc>
            </Flex>
            <Bloc
              display="flex"
              flexDirection="column"
              style={{
                gap: '8px',
              }}
              width={1}
            >
              <Bloc fontSize="14px" fontWeight="500">
                Outbound SMS webhook headers
              </Bloc>

              {headers.map((header, index) => (
                <Flex
                  style={{
                    gap: '10px',
                  }}
                  key={index}
                >
                  <Bloc width={1}>
                    <Inputfield
                      type="text"
                      hasLabel={false}
                      name="key"
                      onChange={(event) => handleHeaderChange(index, event)}
                      placeholder="Key"
                      value={header.key}
                    />
                  </Bloc>
                  <Bloc width={1}>
                    <Inputfield
                      type="text"
                      hasLabel={false}
                      name="Value"
                      value={header.value}
                      onChange={(event) => handleHeaderChange(index, event)}
                      placeholder="Value"
                    />
                  </Bloc>
                </Flex>
              ))}

              <Text
                onClick={addHeaderField}
                fontSize="12px"
                color="#0EB6A2"
                style={{
                  cursor: 'pointer',
                }}
              >
                + Add Header
              </Text>
            </Bloc>
            <Bloc pt="10px">
              <ToggleSwitch
                label="Enable Weekly Usage Notification"
                id="weeklyNotification"
                checked={weeklyNotification}
                onChange={onWeeklyNotification}
              />
            </Bloc>
          </Bloc>
          <Flex
            justifyContent="end"
            pt="20px"
            style={{
              gap: '6px',
              borderTop: '1px solid #E5E5E5',
            }}
          >
            <Button
              data-testid="update-btn"
              // profile="accentLight"
              profile={
                (!formState.dirty || formState.isSubmitted) &&
                !isSwitchChecked &&
                !isMethodChanged &&
                !isHeadersChanged
                  ? 'secondary'
                  : 'accentLight'
              }
              size="contact"
              loading={isPending || changeOrgNameIsPending}
              disabled={
                (isPending ||
                  changeOrgNameIsPending ||
                  !formState.dirty ||
                  formState.isSubmitted) &&
                !isMethodChanged &&
                !isSwitchChecked &&
                !isHeadersChanged
              }
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
            >
              Save changes
            </Button>
          </Flex>
        </Bloc>
      </Flex>
    </AccountContainer>
  );
};

export default UpdateOrgSettingsForm;
