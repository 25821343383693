import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import { Layout, Text } from '../../components';

const Recovered = () => (
  <Layout pt={[3, '10vh']}>
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="space-around"
      alignItems={[null, 'center']}
      maxWidth={['100%']}
      my={10}
    >
      <Bloc mb={0} maxWidth={300}>
        <Text variant="h3" as="h1">
          Recovered
        </Text>
        <Bloc width={64} height={2} my={4} bg="accentLight" />
        <Text variant="h5" as="span">
          Instant communication platform for you!
        </Text>
      </Bloc>
      <Bloc
        mt={[4, null]}
        width={[1, 1, 512]}
        boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
        borderRadius="8px"
        bg="white"
        padding="48px 32px"
      >
        <Bloc>
          You have successfully recovered your password. It is time to explore your account.
          {' '}
        </Bloc>
        <Box mt={18} />
        <Text
          color="accents.1"
          fontWeight="normal"
          style={{ whiteSpace: 'nowrap' }}
          href="/login"
        >
          login to access your account
        </Text>
      </Bloc>
    </Flex>
  </Layout>
);

export default Recovered;
