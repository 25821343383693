/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import Icon from '../icon';
import SampleFile from './sample.csv';
import truncateLongString from '../../utils/trucanteLongString';
import ExcelSvg from '../icon/excel';
import UploadContactFromSendMessage from './uploadContactFromSendMessage';

const ImportContactFirstStep = ({
  handleFileUpload,
  message = false,
  showContactError,
  setShowContactsInfo,
  showContactsInfo,
  fileName,
  importedContactLength,
  progressPercentage,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const fileC = React.useRef(null);

  const onFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file) {
        handleFileUpload(file);
        setShowContactsInfo(true);
        setTimeout(() => {
          if (message) {
            handleFileUpload();
            setShowContactsInfo(false);
          } else if (fileC.current) {
            fileC.current.value = '';
          }
        }, 3000);
      }
    },
    [handleFileUpload, setShowContactsInfo]
  );

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
      setShowContactsInfo(true);
      setTimeout(() => {
        if (message) {
          handleFileUpload();
          setShowContactsInfo(false);
        }
      }, 2000);
    }
  };

  const removeUploadedFile = () => {
    handleFileUpload();
    setShowContactsInfo(false);
  };

  const handleDownloadSample = () => {
    fetch(SampleFile).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sample.csv';
        a.click();
      });
    });
  };

  return (
    <>
      {message ? (
        <UploadContactFromSendMessage
          fileName={fileName}
          handleDownloadSample={handleDownloadSample}
          handleDragLeave={handleDragLeave}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          importedContactLength={importedContactLength}
          isDragActive={isDragActive}
          onFileChange={onFileChange}
          progressPercentage={progressPercentage}
          removeUploadedFile={removeUploadedFile}
          showContactsInfo={showContactsInfo}
          message={message}
        />
      ) : (
        <Flex
          flexDirection="column"
          style={{
            rowGap: '15px',
          }}
        >
          <Bloc
            py="20px"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            display="flex"
            htmlFor="fileElem"
            bg={isDragActive ? '#0eb6a230' : 'white'}
            justifyContent="center"
            alignItems="center"
            onClick={() => document.getElementById('fileInput').click()}
            style={{
              border: `1px dashed ${isDragActive ? '#0EB6A2' : 'black'}`,
              columnGap: '15px',
              borderRadius: '10px',
            }}
          >
            <Bloc
              width="40px"
              height="40px"
              borderRadius="9999px"
              bg={isDragActive ? '#0EB6A2' : 'black'}
              display="flex"
              color="white"
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="cloud_upload_2" size={20} dimension="40x40" />
            </Bloc>
            <Bloc fontSize="14px">
              Drop your csv file here or{' '}
              <Bloc
                as="span"
                fontWeight="500"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                choose a file
              </Bloc>
            </Bloc>
            <Bloc
              as="input"
              type="file"
              ref={fileC}
              accept=".csv, .xls, .xlsx"
              onChange={onFileChange}
              style={{ display: 'none' }}
              id="fileInput"
            />
          </Bloc>

          <Bloc as="p" m={0} p={0} fontSize={['10px', '14px']}>
            Upload a CSV file with your contacts, every contact is required to
            have a{' '}
            <Bloc fontWeight="600" as="span">
              phone number
            </Bloc>{' '}
            and a{' '}
            <Bloc as="span" fontWeight="600">
              name
            </Bloc>
            . Here is a{' '}
            <Bloc
              as="span"
              to="/contact?tab=active"
              onClick={handleDownloadSample}
              style={{
                cursor: 'pointer',
                color: '#0A9080',
                fontWeight: 400,
                textDecoration: 'underline',
              }}
            >
              template.csv
            </Bloc>{' '}
            to get you started :)
          </Bloc>
          {showContactsInfo && (
            <Bloc
              display="flex"
              flexDirection="column"
              style={{
                gap: '10px',
                backgroundColor: showContactError ? '#FF1D380D' : '#F9F9F9',
              }}
              borderRadius="10px"
              px="20px"
              pt="15px"
              pb="20px"
            >
              {showContactError ? (
                <Bloc fontSize="11px" color="#FF0000" fontWeight="600">
                  {showContactError}
                </Bloc>
              ) : (
                <>
                  <Flex justifyContent="space-between">
                    <Flex
                      alignItems="center"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <Bloc
                        py="10px"
                        px="8px"
                        borderRadius="5px"
                        style={{
                          border: '2px solid #E0E0E0',
                        }}
                        bg="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ExcelSvg
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </Bloc>
                      <Bloc>
                        <Bloc fontSize={1} fontWeight="500">
                          {truncateLongString(fileName, 19)}
                        </Bloc>
                        <Bloc as="span" fontSize="10px">
                          {importedContactLength} records
                        </Bloc>
                      </Bloc>
                    </Flex>

                    <Bloc
                      onClick={removeUploadedFile}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Icon name="close" color="black" size={12} />
                    </Bloc>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      gap: '20px',
                    }}
                  >
                    <Bloc width="100%" display="flex" alignItems="center">
                      <Bloc width="100%" bg="#E0E0E0" borderRadius="9999px">
                        <Bloc
                          width={`${progressPercentage}%`}
                          color="#fff"
                          backgroundColor="#0EB6A2"
                          px={3}
                          height="5px"
                          lineHeight="20px"
                          borderRadius="9999px"
                          textAlign="center"
                          fontSize="12px"
                          style={{
                            float: 'left',
                            WebkitTransition: 'width .6s ease',
                            OTransition: 'width .6s ease',
                            transition: 'width .6s ease',
                          }}
                          role="progressbar"
                          aria-valuenow={progressPercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </Bloc>
                    </Bloc>
                    <Bloc>
                      {progressPercentage < 100 ? (
                        <Bloc as="span"> {progressPercentage}%</Bloc>
                      ) : (
                        <Bloc
                          as="span"
                          width="16px"
                          height="16px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="9999px"
                          bg="#0EB6A2"
                        >
                          <Icon
                            name="complete_check"
                            color="white"
                            dimension="7x5"
                            size={6}
                          />
                        </Bloc>
                      )}
                    </Bloc>
                  </Flex>
                </>
              )}
            </Bloc>
          )}
        </Flex>
      )}
    </>
  );
};

export default ImportContactFirstStep;
