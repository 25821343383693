import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 


  html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    background: ${(props) => props.theme.colors.lightGray};
    font-family: ${(props) => props.theme.fonts.sans};
    color: ${(props) => props.theme.colors.surfaces[3]};
    margin: 0;
  }


  a {
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
  }

  #root {
    position: relative;
    min-height: 100vh;
  }

  button {
    white-space: nowrap;
  }

  input, textarea {
    background: transparent;
    fontFamily: 'Space Grotesk Variable' !important;
    outline: none;
  }

  input:disabled {
    background: #fafafa;
  }

  input::placeholder {
    color: #DFDBDB;
  }
  .mention-textarea::placeholder {
    color: #DFDBDB;
  }

  thead td {
    text-transform: uppercase;
    color: #3E484D;
    font-weight: bold;
    font-size: 9px;
    padding: 0 25px;
  }

  tbody td {
    font-size: 12px;
    color: #757F84;
    padding: 0 25px;
    white-space: nowrap;
    height: 48px;
    max-height: 48px;
    box-sizing: border-box;
  }

   tbody tr:hover {
    background-color: #E4FFFC;
  }

  tbody tr#table-details:hover {
    background-color: initial;
  }

  tbody tr#table-details td {
    vertical-align: middle;
  }
  
  .not-selectable tbody tr:hover {
    background-color: transparent;
  }

  tbody tr:last-child {}

  tbody tr#table-details:last-child td {
    border-bottom: initial;
  }

  h5 {
    line-height: initial !important
  }

  .place:nth-child(1) {
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate-reverse;  
  }

  .place:nth-child(2) {
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate;
  }

  @keyframes fade{
    0%,45% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(3px); /* Starting position */
    }
    50% {
      transform: translateY(-10px); /* Bouncing up */
    }
    100% {
      transform: translateY(0px); /* Coming back initial */
    }
  }

  .paginationBttns {
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .paginationBttns a {
    padding: 0px;
    margin: 4px;
    border-radius: 5px;
    color: #757F84;
    cursor: pointer;
    font-size: 9px;
  }

  .paginationBttns .previousBttn, .paginationBttns .nextBttn {
    border-bottom: 1px solid #8EF3E7;
    border-radius: 0;
    color: #0BAF9C;
    font-size: 12px;
  }

  .paginationBttns .previousBttn {
     margin-right: 16px;
  }

  .paginationBttns .nextBttn {
    margin-left: 16px;
  }

  .paginationActive a {
    color: #FFF9F9;
    padding: 4px 8px;
    background-color: #16C1AD;
  }

  .paginationDisabled .previousBttn, 
  .paginationDisabled .nextBttn {
    color: #BEC1C2;
    border-bottom: 1px solid #BEC1C2;
  }

  a.previousBttn {
      margin-left: 0;
  }

  .react-datepicker {
    font-family: 'Space Grotesk Variable';
    border: none;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-top-left-radius:none;
    border-top-right-radius:none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

.react-datepicker__navigation {
  top: 10px;
  height: 20px;
  width: 20px;
}
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 6px;
  width: 6px;
}

.react-datepicker__week-number--keyboard-selected {
  border-radius: 3px;
  color:#ccc;
  background-color: transparent;
}

  .react-datepicker__year-wrapper {
    display: grid;
    row-gap: 20px;
    padding: 12px 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

  }

  .react-datepicker__day--selected:hover{
    background-color: #19c1ac;
  }

  .react-datepicker__week-number--selected {
    border-radius: 3px;
  color:blue;
  background-color: red;
}
.react-datepicker__week--selected {
 
  border-radius: 3px;
  color: white;
  border-radius: 3px;
  background:#0EB6A21A;
  & ~ .react-datepicker__week-number {
    background-color: red;
  }
}



.react-datepicker__month {
  margin: 0.4rem;
  display: grid;
  padding: 12px 0px;
  row-gap:10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  text-align: center;
}

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    
    border-bottom-color: white;
}

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid #d0cfcf91;
    border-top-left-radius:none;
    border-top-right-radius:none;
  }


  .react-datepicker__input-container input {
    border: 1px solid;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-family: 'Space Grotesk Variable';
    padding: 10px 10px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    color: #3E484D;
  }

  .dots-btn:hover {
    background: #16C1AD;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 32%);
    border-radius: 8px;
    text-align: center;
    line-height: 0px;
    border: none;
  }

  .dot {
    width: 4px;
    height: 4px;
    background: #16C1AD;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px;
  }

  .dots-btn:hover .dot {
    background: #EEECEC;
  }

  .react-datepicker__day--selected, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: #19c1ac;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: transparent;
    
  }

   .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: #19c1ac;
      color: #fff;
  }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #e4213a;
  }

  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #e4213a;
  }

  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
      background-color: #19c1ac;
  }
  .react-datepicker__week-number--selected {
    border-radius: 3px;
    color:white;
    background-color: #19c1ac;
  }
  
  .select-audience-field {
    caret-color: #0EB6A2;
  }

  .google-login {
    width: 100%;
    border-radius: 5px !important;
    background-color: #F9F9F9 !important;
    justify-content: center;
    box-shadow: none !important;
  }

  .google-login:hover {
    background-color: #e7e7e7 !important;
  }

  .google-login span {
    font-weight: 400 !important;
    font-family: "Space Grotesk Variable";
    font-size: 15px;
  }
  .google-login div {
    background-color: transparent !important;
  }
 
  .reactEasyCrop_CropArea {
    color: rgba(250, 250, 250, 0.73) !important;
    border: 2px solid #16c1ad !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .toggle-switch {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .toggle-switch-label:focus {
      outline: none;
  }
  .toggle-switch-label:focus > span {
      box-shadow: 0 0 2px 5px red;
  }
  .toggle-switch-label > span:focus {
      outline: none;
  }
  .toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 38px;
    padding: 0;
    line-height: 38px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  .toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #16C1AD;
    color: #fff;
  }
  .toggle-switch-disabled {
      cursor: not-allowed;
  }
  .toggle-switch-disabled:before {
      cursor: not-allowed;
  }
      
  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #757F84;
    color: #fff;
    text-align: right;
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
      margin-left: 0;
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
      right: 0px;
  }

  .toggle-switch.small-switch {
      width: 40px;
  }

  .toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
  }

  .toggle-switch.small-switch .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
  }

  input[type="radio"] ~ .helping-el {
    background-color: #FFFFFF;
    border: 2px solid #E2E2E2;  
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    padding: 6px;
    position: relative;
    @media (max-width: 768px) {
      /* Additional styles for mobile devices when checked */
      padding: 9px;
    }
}

input[type="radio"] {
  opacity:0;
}

input[type="radio"]:checked ~ .helping-el {
  border: 5px solid #16C1AD;
  padding: 5px;
  @media (max-width: 768px) {
    /* Additional styles for mobile devices when checked */
    border-width: 5px;
  }
}


  @media screen and (max-width: 991px) {
    .toggle-switch {
      transform: scale(0.9);
    }
  }
  @media screen and (max-width: 767px) {
    .toggle-switch {
      transform: scale(0.825);
    }
  }
  @media screen and (max-width: 575px) {
    .toggle-switch {
      transform: scale(0.75);
    }
  }

  .countryItem:hover {
    background: #E4FFFC;
  }

  .hoverDefault:hover {
    background: #F9F9F9;
  }

  #senderIDPolicies ol, #senderIDPolicies ul {
    padding: 0;
  }

  #senderIDPolicies li {
    margin-left: 4%;
  }
`;

export default GlobalStyle;
