import React, { useState } from 'react';
import Dropdown from './dropdown';
import Content from '../selection/content';
// import Content from './content';

const DefaultDropdownWithSearch = ({
  services,
  serviceInUseObj,
  disabled,
  handleSelectedItem,
  setIsItemsDropdown,
  isItemsDropdown,
  hasSearch = false,
  isSelect,
}) => {
  const [searchedItem, setSearchedItem] = useState('');

  const [items, setItems] = useState(services);

  const searchItemName = (e, listOfItems, setSearchItem, setList) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setSearchItem(inputValue);

    const searchInput = inputValue.trim().toLowerCase();

    if (searchInput.length > 0) {
      const filterMatches = listOfItems.filter((el) =>
        el.name.toLowerCase().match(searchInput)
      );
      setList(filterMatches);
    } else {
      setList(listOfItems);
    }
  };

  return (
    <Dropdown
      activeItem={serviceInUseObj}
      isSelect={isSelect}
      disabled={disabled}
      isDropdownOpen={isItemsDropdown}
      itemsList={services}
      setIsDropdownOpen={setIsItemsDropdown}
    >
      <Content
        hasSearch={hasSearch}
        searchedTerm={searchedItem}
        searchItemName={(e) => {
          searchItemName(e, services, setSearchedItem, setItems);
        }}
        handleSelectedItem={handleSelectedItem}
        itemsList={items}
      />
    </Dropdown>
  );
};

export default DefaultDropdownWithSearch;
