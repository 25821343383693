import React from 'react';
import { Bloc } from 'blocjs';

import {
  Intro, Features, Podium, Pricing,
} from '../../components';

const Home = () => (
  <>
    <Bloc bg="primary" data-testid="vanguard" width={1} px="5.55555%">
      <Intro />
    </Bloc>
    <Features />
    <Podium />
    <Pricing />
  </>
);

export default Home;
