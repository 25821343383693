import React, { useContext, useState } from 'react';
// import { useForm } from 'react-hook-form';
import { Bloc, Box, Flex } from 'blocjs';
// import posthog from 'posthog-js';

import posthog from 'posthog-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ClickAwayListener from 'react-click-away-listener';
import { Button, Chevron, Icon, Inputfield, Text } from '../../components';
import { AuthContext, CurrentUserContext } from '../../contexts';
import ChangePasswordForm from './changePassword';
// import { copyToClipboard } from '../../utils';
import AccountContainer from '../userAccount/container';
import Otp from '../userAccount/otpComponent';
import { copyToClipboard } from '../../utils';
import UserAPI from '../../services';
import CustomToast from '../../components/toast/customToast';

const BANNER_DISPLAY_KEY = 'banner-notification-displayed';
const TIME_OPTIONS = ['hours', 'days'];

const Security = () => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { currentUser } = useContext(CurrentUserContext);

  const [isRevealed, setIsRevealed] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [copied, setCopied] = useState(false);
  const [, setMessage] = useState(null);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { token, loginType, addUserToken } = useContext(AuthContext);
  const [isTokenDropdownOpen, setIsTokenDropdownOpen] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const getInitialTimeUnit = (seconds) => {
    if (seconds >= 24 * 60 * 60) {
      return 'days';
    }
    return 'hours';
  };

  const getInitialTime = (seconds) => {
    if (seconds >= 24 * 60 * 60) {
      return Math.round(seconds / (24 * 60 * 60));
    }
    return Math.round(seconds / (60 * 60));
  };

  const initialTokenSwitchTime = getInitialTime(
    currentUser?.revoked_token_expiration || 3600
  );
  const initialTokenSwitchTimeSelected = getInitialTimeUnit(
    currentUser?.revoked_token_expiration || 3600
  );

  const [tokenSwitchTime, setTokenSwitchTime] = useState(
    initialTokenSwitchTime
  );
  const [tokenSwitchTimeSelected, setTokenSwitchTimeSelected] = useState(
    initialTokenSwitchTimeSelected
  );
  const [, setShowToast] = useState(false);
  const [showTokenFromMagicIsErrorToast, setShowTokenFromMagicIsErrorToast] =
    useState(false);
  const [showRefreshTOkenToast, setShowRefreshTOkenToast] = useState(false);

  const generateAsterisks = (length) => '*'.repeat(length);

  const changePassword = () => {
    setShowChangePasswordForm(true);
  };

  const { mutate } = useMutation({
    mutationFn: (payload) => userAPI.updateSelfTokenSwitchTime(payload),
  });

  const copyAPIAccessToken = (apiToken) => {
    copyToClipboard(apiToken);
    setCopied(true);
    setMessage('The Token has been successfully copied');
    setTimeout(() => {
      setMessage(null);
      setCopied(false);
    }, 2000);
    posthog.capture('copied API access token');
  };

  const {
    mutate: getTokenFromMagicLink,
    isError: getTokenFromMagicIsError,
    error: getTokenFromMagicError,
    isSuccess: getTokenFromMagicLinkIsSuccess,
    isPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.getTokenFromMagicLink(payload),
  });

  const {
    mutate: requestOTP,
    isPending: requestOTPIsPending,
    isError: requestOTPisError,
    error: requestOTPError,
    isSuccess: requestOTPisSuccess,
  } = useMutation({
    mutationFn: (payload) => userAPI.sendMagicLink(payload),
  });

  const handleOtpSubmit = (otp) => {
    getTokenFromMagicLink(
      {
        request_id: requestId,
        code: otp,
        email: currentUser.email,
      },
      {
        onSuccess: (data) => {
          const { token: returnToken } = data;
          addUserToken(returnToken);
          setShowTokenFromMagicIsErrorToast(true);
          setTimeout(() => {
            setShowOTPForm(false);
            localStorage.setItem(BANNER_DISPLAY_KEY, 'false');
            setShowTokenFromMagicIsErrorToast(false);
          }, 2000);
        },
        onError: () => {
          setShowTokenFromMagicIsErrorToast(true);
          setTimeout(() => {
            setShowTokenFromMagicIsErrorToast(false);
          }, 2000);
        },
      }
    );
  };

  const saveChanges = async () => {
    if (tokenSwitchTime > 0) {
      let expirationInSeconds = parseInt(tokenSwitchTime, 10);

      switch (tokenSwitchTimeSelected) {
        case 'days':
          expirationInSeconds *= 24 * 60 * 60;
          break;
        case 'hours':
          expirationInSeconds *= 60 * 60;
          break;
        // Add more cases here if needed in the future
        default:
          // Handle unexpected values or leave as is
          break;
      }

      const payload = {
        id: currentUser.accountId,
        payload: expirationInSeconds,
      };

      mutate(payload, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['currentUser'] });
          posthog.capture('updated token switch time', {
            value: expirationInSeconds,
          });
        },
      });
    }
  };

  const refreshTokenSubmit = async () => {
    if (currentUser.email) {
      requestOTP(
        {
          email: currentUser.email,
          type: 'account',
        },
        {
          onSuccess: (data) => {
            saveChanges();
            setRequestId(data?.data?.request_id);
            setShowOTPForm(true);
            setShowModal(false);
            if (!showModal) {
              setShowRefreshTOkenToast(true);
            }
            setTimeout(() => {
              setShowRefreshTOkenToast(false);
            }, 2000);
          },
          onError: () => {
            setShowRefreshTOkenToast(true);
            setTimeout(() => {
              setShowRefreshTOkenToast(false);
            }, 2000);
          },
        }
      );
    }
  };

  const handleTokenSwitchTimeChange = (e) => {
    const newValue = e.target.value.replace(/[^1-9]/g, '');
    setTokenSwitchTime(newValue);
  };

  const handleTokenSwitchTimeSelectedChange = (newSelected) => {
    setTokenSwitchTimeSelected(newSelected);
    setIsTokenDropdownOpen(false);
  };

  const allPending = isPending || requestOTPIsPending;

  return (
    <Flex
      flexDirection="column"
      style={{
        gap: '20px',
      }}
    >
      {getTokenFromMagicIsError && (
        <CustomToast
          message={getTokenFromMagicError?.response?.data?.error?.message}
          status="error"
          showToast={showTokenFromMagicIsErrorToast}
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}
      {getTokenFromMagicLinkIsSuccess && (
        <CustomToast
          message="API access token was successfully refreshed"
          status="success"
          showToast={showTokenFromMagicIsErrorToast}
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}
      {requestOTPisError && (
        <CustomToast
          message={requestOTPError?.response?.data?.error?.message}
          status="error"
          showToast={showRefreshTOkenToast}
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}

      {requestOTPisSuccess && !showModal && (
        <CustomToast
          message="Code sent successfully! check your mails"
          status="success"
          showToast={showRefreshTOkenToast}
          handleHideToast={() => setShowToast(false)}
          closed
        />
      )}

      {showOTPForm && (
        <AccountContainer pt={0}>
          <Otp
            setShowOTPForm={setShowOTPForm}
            isPending={allPending}
            refreshTokenSubmit={refreshTokenSubmit}
            length={6}
            onSubmit={handleOtpSubmit}
          />
        </AccountContainer>
      )}

      {showModal && (
        <AccountContainer pt={0}>
          <Flex
            p="20px"
            flexDirection="column"
            style={{
              gap: '15px',
            }}
          >
            <Flex
              alignItems="center"
              style={{
                gap: '10px',
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="40px"
                style={{
                  borderRadius: '50%',
                  backgroundColor: '#FFB1321A',
                }}
                height="40px"
              >
                <Icon
                  name="warning"
                  color="#FFB132"
                  dimension="14x14"
                  size={14}
                />
              </Flex>

              <Text fontSize="14px" fontWeight={700}>
                You are about to refresh your API access token
              </Text>
            </Flex>
            <Bloc fontSize="14px">
              This action is irreversible and will update your API Access Token.
              Use the field below to specify when your applications should
              switch to the new token. If left blank, the default switch time is
              1 hour.
            </Bloc>
            <Bloc>
              <Flex
                pt="2px"
                flexDirection="column"
                style={{
                  gap: '8px',
                }}
              >
                <Text fontWeight={500} fontSize={1}>
                  Switch token after
                </Text>
                <Flex
                  borderRadius={2}
                  width="fit-content"
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #d3d3d3',
                    position: 'relative',
                  }}
                >
                  <Bloc
                    as="input"
                    p="8px"
                    height={40}
                    border="none"
                    value={tokenSwitchTime}
                    borderRadius={2}
                    name="API Access Token"
                    fontWeight={400}
                    fontSize="12px"
                    placeholder={`E.g: 2 ${tokenSwitchTimeSelected}`}
                    type="number"
                    min={1}
                    onChange={handleTokenSwitchTimeChange}
                  />

                  <ClickAwayListener
                    onClickAway={() => setIsTokenDropdownOpen(false)}
                    style={{
                      position: 'relative',
                      marginLeft: '16px',
                      zIndex: 999,
                    }}
                  >
                    <Bloc
                      borderStyle="solid"
                      borderBottom="0px"
                      borderTop="0px"
                      borderRight="0.9px"
                      borderColor="surfaces.2"
                      bg="#F9F9F9"
                      width={['90px']}
                      height={40}
                      px={3}
                      style={{
                        fontWeight: 'normal',
                        fontSize: '13px',
                        borderRadius: '0px 3px 3px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                      }}
                      onClick={() =>
                        setIsTokenDropdownOpen(!isTokenDropdownOpen)
                      }
                    >
                      <Bloc
                        as="span"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {tokenSwitchTimeSelected}
                      </Bloc>
                      <Chevron
                        color={isTokenDropdownOpen ? 'accents.8' : 'surfaces.3'}
                        rotate={isTokenDropdownOpen ? 180 : 0}
                        size={16}
                      />
                    </Bloc>
                    {isTokenDropdownOpen && (
                      <Box
                        position="absolute"
                        top={40}
                        width="100%"
                        right={0}
                        marginTop={2}
                        padding="11px 8px"
                        borderColor="surfaces.2"
                        zIndex={999}
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '4px',
                          textAlign: 'right',
                          boxShadow:
                            '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                        }}
                      >
                        <Bloc
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                          }}
                        >
                          {TIME_OPTIONS.map((item) => (
                            <Bloc
                              py={2}
                              px={2}
                              key={item}
                              onClick={() =>
                                handleTokenSwitchTimeSelectedChange(item)
                              }
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: '4px',
                              }}
                            >
                              <Text
                                hoverAsLink
                                variant="small"
                                fontSize="13px"
                                color={
                                  tokenSwitchTimeSelected === item
                                    ? 'accents.1'
                                    : 'accents.8'
                                }
                                style={{
                                  cursor: 'pointer',

                                  textTransform: 'capitalize',
                                }}
                              >
                                {item}
                              </Text>
                              {tokenSwitchTimeSelected === item && (
                                <Icon
                                  name="new-check"
                                  color="transparent"
                                  size={13}
                                  dimension="13x12"
                                />
                              )}
                            </Bloc>
                          ))}
                        </Bloc>
                      </Box>
                    )}
                  </ClickAwayListener>
                </Flex>
              </Flex>
            </Bloc>

            <Flex
              style={{
                columnGap: '10px',
              }}
            >
              <Button
                style={{
                  textTransform: 'initial',
                  fontWeight: 'normal',
                }}
                profile="accentLight"
                size="contact"
                loading={allPending}
                onClick={refreshTokenSubmit}
              >
                Proceed
              </Button>
              <Button
                style={{
                  textTransform: 'initial',
                  fontWeight: 'normal',
                }}
                profile="secondary"
                size="contact"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </AccountContainer>
      )}

      {!showChangePasswordForm && (
        <AccountContainer pt={0}>
          <Flex
            flexDirection={['column', 'row']}
            p="20px"
            style={{
              gap: '28px',
              position: 'relative',
              opacity: showModal || showOTPForm ? 0.2 : 1,
            }}
          >
            {showModal ||
              (showOTPForm && (
                <Bloc
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                  }}
                />
              ))}
            {loginType === 'credential' && (
              <Bloc
                style={{
                  flex: '1 1 0%',
                }}
              >
                <Inputfield
                  name="Password"
                  type="password"
                  defaultValue="mypassword"
                  hint="Unique and memorable password"
                  disabled
                />
                <Bloc>
                  <Text
                    fontSize="12px"
                    fontWeight="600"
                    color="accents.1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => changePassword()}
                    data-testid="change-password"
                  >
                    Update password
                  </Text>
                </Bloc>
              </Bloc>
            )}

            <Box
              style={{
                flex: '1 1 0%',
              }}
            >
              <Flex
                pt="2px"
                flexDirection="column"
                style={{
                  gap: '8px',
                }}
              >
                <Text fontWeight={500} fontSize={1}>
                  API Access Token
                </Text>
                <Flex
                  borderRadius={2}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #d3d3d3',
                  }}
                >
                  <Bloc
                    as="input"
                    p="8px"
                    height={40}
                    border="none"
                    width={1}
                    borderRadius={2}
                    name="API Access Token"
                    type={isRevealed ? 'text' : 'password'}
                    disabled
                    value={isRevealed ? token : generateAsterisks(40)}
                  />

                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    px="8px"
                    style={{
                      backgroundColor: '#efefef4d',
                      gap: '10px',
                    }}
                  >
                    <Bloc
                      style={{
                        cursor: 'pointer',
                      }}
                      data-test="refresh-token"
                      onClick={() => setIsRevealed(!isRevealed)}
                    >
                      <Icon
                        name={`${isRevealed ? 'eye-slash' : 'eye'}`}
                        color="black"
                        size={16}
                        dimension="24x24"
                      />
                    </Bloc>
                    <Bloc
                      data-test="copy-access-token"
                      onClick={() => copyAPIAccessToken(token)}
                    >
                      <Icon
                        name={`${copied ? 'check' : 'clipboard'}`}
                        color={`${copied ? 'accentDark' : 'black'}`}
                        size={12}
                      />
                    </Bloc>
                  </Flex>
                </Flex>
                <Text
                  fontSize={1}
                  style={{
                    marginBottom: '8px',
                  }}
                  color="surfaces.2"
                >
                  API access token is generated. If compromised you can refresh
                  it
                </Text>
              </Flex>

              <Bloc marginTop="8px">
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  color="accents.1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModal(true)}
                  data-testid="change-password"
                >
                  Refresh token
                </Text>
              </Bloc>
            </Box>
          </Flex>
        </AccountContainer>
      )}

      {showChangePasswordForm && (
        <AccountContainer pt={0}>
          <Flex
            flexDirection="column"
            style={{
              gap: '20px',
            }}
            p="20px"
          >
            <ChangePasswordForm
              setShowChangePasswordForm={setShowChangePasswordForm}
            />
          </Flex>
        </AccountContainer>
      )}
    </Flex>
  );
};

export default Security;
