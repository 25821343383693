import React from 'react';
import { Bloc, Box } from 'blocjs';
import { Text, Spinner } from '../../../components';

const GeneralStats = ({ isLoading }) => (
    <Box>
      <Bloc width={1} flexWrap="wrap" display="flex" position="relative" justifyContent="space-between">
        <Box mr={[3, 3, 0]} mb={[4, 0]} style={{ float: 'left' }} minWidth={["100%", "16%"]}>
          <Text as="h1" style={{ lineHeight: 'initial' }} fontSize={28}> Dashboard {isLoading && <Spinner size="large" />}</Text>
        </Box>
      </Bloc>
    </Box>
  );

export default GeneralStats;
