/* eslint-disable react/no-array-index-key */
import { Bloc, Flex } from 'blocjs';
import React from 'react';
import Icon from '../icon';

const Content = ({ itemsList, handleSelectedItem, isSelect }) => (
  <Bloc as="ul" padding={0} margin={0}>
    {itemsList.map((item) => (
      <Bloc
        as="li"
        display="flex"
        alignItems="center"
        key={isSelect ? item.name : item ?? item.id}
        onClick={() => handleSelectedItem(item)}
        aria-hidden="true"
        padding={12}
        style={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          overflow: 'auto',
        }}
        className="countryItem"
      >
        <Flex alignItems="center">
          <Bloc
            as="span"
            fontSize="12px"
            mr="6px"
            style={{ textTransform: 'capitalize' }}
            color={item.placeholder ? '#e0dbdb' : 'initial'}
          >
            {isSelect ? item.name : item}
          </Bloc>
          {item.is_default && (
            <Icon
              name="check-broken-circle"
              height={14}
              color="#7A8794"
              size={12}
              dimension="24x24"
            />
          )}
        </Flex>
      </Bloc>
    ))}
  </Bloc>
);

export default Content;
