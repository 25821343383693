/* eslint-disable no-case-declarations */
/* eslint-disable no-useless-escape */
import { Bloc, Flex } from 'blocjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { createRegexRenderer, RichTextarea } from 'rich-textarea';

const MENTION_REG = /\B@([\-+\w\.]*)$/;

// Regex to match any mention that doesn't belong to the CHARACTERS array
// const MENTION_NON_MATCH_REG = /\B@([\w\.\-+]+)/g;

const CharactersRender = ({ chars, index, complete, isOneChar }) => {
  const charsList = chars;

  if (charsList.length === 0) {
    return (
      <Flex
        flexDirection="column"
        style={{
          fontSize: '10px',
          gap: '5px',
          maxWidth: '214px',
          backgroundColor: '#e5223a1c',
          borderRadius: '5px',
          padding: '6px',
        }}
      >
        <Bloc fontWeight="600">No data for this variable</Bloc>
        <Bloc>
          You can still send messages, this field will be skipped in the message
          received by the user
        </Bloc>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      style={{
        gap: '5px',
      }}
    >
      {chars.map((c, i) => (
        <Bloc
          className="hoverDefault"
          key={c}
          fontWeight="400"
          style={{
            padding: '6px 12px',
            borderRadius: '5px',

            fontSize: '10px',
            ...(index === i && {
              color: 'black',
              background: '#5EEBDA47',
            }),
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            complete(i);
          }}
        >
          {c}
        </Bloc>
      ))}
      {isOneChar && (
        <Flex
          flexDirection="column"
          style={{
            fontSize: '10px',
            gap: '5px',
            borderRadius: '5px',
            maxWidth: '214px',
            backgroundColor: '#006EE51A',
            padding: '4px 10px',
          }}
        >
          <Bloc color="#006EE5" fontWeight="600">
            Cant find a field ?
          </Bloc>
          <Bloc>
            Add the fields you need in the file to be able to use them here :)
          </Bloc>
        </Flex>
      )}
    </Flex>
  );
};

const Menu = ({ chars, index, top, left, complete, isOneChar }) => (
  <Bloc
    style={{
      position: 'fixed',
      top,
      left,
      fontSize: '12px',
      padding: '10px',
      border: 'solid 1px #EDEDED',
      borderRadius: '5px',
      background: 'white',
      cursor: 'pointer',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
    }}
  >
    <CharactersRender
      isOneChar={isOneChar}
      index={index}
      complete={complete}
      chars={chars}
    />
  </Bloc>
);

const RichTextareaComponent = ({
  headerMentions,
  handleBlur,
  handleFocus,
  textMessage,
  setTextMessage,
  hasApprovedSenderIDs,
}) => {
  const ref = useRef(null);
  const parRef = useRef(null);
  const [pos, setPos] = useState(null);
  const [index, setIndex] = useState(0);

  const updateHeaderMentions = headerMentions?.map((item) => `contact.${item}`);

  const MENTION_HIGHLIGHT_REG = new RegExp(
    `(${updateHeaderMentions
      ?.filter((item) => item !== '')
      .map((c) => `@${c}`)
      .join('|')})`,
    'g'
  );

  const MENTION_NON_MATCH_CHECK = new RegExp(
    `@contact\\.(?!(${updateHeaderMentions
      ?.filter((item) => item !== '')
      .map((c) => c.split('.')[1]) // Extract the part after "contact."
      .join('|')})\\b)[\\w]+`,
    'g'
  );

  const mentionRenderer = createRegexRenderer([
    [MENTION_HIGHLIGHT_REG, { color: '#0EB6A2' }],
    [
      MENTION_NON_MATCH_CHECK,
      {
        color: '#FF0000',
      },
    ],
  ]);

  useEffect(() => {
    // Wait until the DOM is fully rendered
    const timeoutId = setTimeout(() => {
      const childNode = parRef.current?.childNodes[0];
      if (childNode) {
        childNode.style.width = '100%';
      }
    }, 0);

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  const handleTextareaResize = () => {
    if (ref.current) {
      const textarea = ref?.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const targetText = pos ? textMessage.slice(0, pos.caret) : textMessage;
  const match = pos && targetText.match(MENTION_REG);
  const name = match?.[1] ?? '';
  const filtered = useMemo(
    () =>
      updateHeaderMentions
        .filter((c) => c.toLowerCase().startsWith(name.toLowerCase()))
        .filter((c) => c !== 'contact.phonenumbers'),
    [updateHeaderMentions, name]
  );
  const complete = (i) => {
    if (!ref.current || !pos) return;
    const selected = filtered[i];

    ref.current.setRangeText(
      `@${selected} `,
      pos.caret - name.length - 1,
      pos.caret,
      'end'
    );
    setPos(null);
    setIndex(0);
  };

  return (
    <Bloc
      style={{
        width: '100%',
        padding: '5px',
      }}
    >
      <RichTextarea
        ref={ref}
        rows={2}
        onInput={handleTextareaResize}
        onFocus={handleFocus}
        disabled={!hasApprovedSenderIDs}
        placeholder="Hi @contact.name, please use this @format for inserting your recipient name."
        onBlur={handleBlur}
        className="mention-textarea"
        style={{
          fontFamily: 'Space Grotesk Variable',
          width: '99.5%',
          fontWeight: '400',
          borderRadius: '10px',
          border: 'none',
          overflow: 'hidden',
          resize: 'none',
        }}
        onChange={(e) => setTextMessage(e.target.value)}
        value={textMessage}
        onKeyDown={(e) => {
          if (!pos || !filtered.length) return;
          switch (e.code) {
            case 'ArrowUp':
              e.preventDefault();
              const nextIndex = index <= 0 ? filtered.length - 1 : index - 1;
              setIndex(nextIndex);
              break;
            case 'ArrowDown':
              e.preventDefault();
              const prevIndex = index >= filtered.length - 1 ? 0 : index + 1;
              setIndex(prevIndex);
              break;
            case 'Enter':
              e.preventDefault();
              complete(index);
              break;
            case 'Escape':
              e.preventDefault();
              setPos(null);
              setIndex(0);
              break;
            default:
              break;
          }
        }}
        onSelectionChange={(r) => {
          if (
            r.focused &&
            MENTION_REG.test(textMessage.slice(0, r.selectionStart))
          ) {
            setPos({
              top: r.top + r.height,
              left: r.left,
              caret: r.selectionStart,
            });
            setIndex(0);
          } else {
            setPos(null);
            setIndex(0);
          }
        }}
      >
        {mentionRenderer}
      </RichTextarea>
      {pos &&
        createPortal(
          <Menu
            top={pos.top}
            left={pos.left}
            chars={filtered}
            index={index}
            isOneChar={textMessage.length < 2}
            complete={complete}
          />,
          document.body
        )}
    </Bloc>
  );
};

export default RichTextareaComponent;
