import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Inputfield, Spinner } from '../../../../components';
import { selectStatusStyles } from '../../../../utils';

const FilterForm = ({
  isLoading,
  filterCountries,
  isFetching,
  hasInputFilled,
  crossAttr,
  searchByCrossAttr,
  portabilityStatuses,
  senderIDRegulated,
  portabilityOption,
  regulatedOption,
  handlePortability,
  handleRegulated,
}) => (
  <Bloc ml={[0, '16%']}>
    <Bloc
      mb={6}
      backgroundColor="white"
      borderRadius="8px"
      border="1px solid #DEE1E7"
      boxSadow="none"
    >
      <Flex
        flexWrap={['wrap', 'no-wrap']}
        justifyContent="space-between"
        padding="16px 24px"
      >
        <Box width={[1, 'calc(50% - 80px)']}>
          <Inputfield
            name="text"
            placeholder="Search by country name, mcc or any code"
            value={crossAttr}
            onChange={(e) => searchByCrossAttr(e)}
            hasLabel={false}
          />
        </Box>
        <Box ml={[0, 0, '12px']} mt={[3, 0, 0]} width={[1, 'calc(25% - 40px)']}>
          <Select
            isClearable
            options={portabilityStatuses}
            placeholder="Portability"
            onChange={handlePortability}
            styles={selectStatusStyles}
            value={portabilityOption}
          />
        </Box>
        <Box ml={[0, 0, '12px']} mt={[3, 0, 0]} width={[1, 'calc(25% - 40px)']}>
          <Select
            isClearable
            options={senderIDRegulated}
            placeholder="SenderID"
            onChange={handleRegulated}
            styles={selectStatusStyles}
            value={regulatedOption}
          />
        </Box>
        <Box ml={[0, 0, '12px']} mt={[3, 3, 0]} width={[1, 1, 'auto']}>
          <Button
            profile="accentLight"
            disabled={!hasInputFilled}
            size="contact"
            onClick={() => {
              if (hasInputFilled) filterCountries();
            }}
            data-test="filter-btn"
            style={{
              textTransform: 'capitalize',
              height: '40px',
              float: 'right',
            }}
          >
            {!isLoading && !isFetching ? (
              'Search'
            ) : (
              <Spinner
                size="large"
                style={{ marginLeft: 0, marginBottom: '-4px' }}
              />
            )}
          </Button>
        </Box>
      </Flex>
    </Bloc>
  </Bloc>
);
export default FilterForm;
