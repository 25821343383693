import { Bloc, Flex } from 'blocjs';
import React from 'react';

const DisplayStepperPosition = ({ position }) => {
  const stepOne = position === 1;
  const stepTwo = position === 2;
  const stepThree = position === 3;

  return (
    <Flex
      alignItems="stretch"
      style={{
        columnGap: '10px',
      }}
    >
      <Flex
        alignItems="center"
        style={{
          columnGap: '10px',
        }}
      >
        <Flex
          flexDirection="column"
          style={{
            rowGap: '6px',
          }}
          alignItems="center"
        >
          <Bloc
            width="36px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="36px"
            fontSize="12px"
            style={{
              backgroundColor: stepOne ? '#0EB6A2' : '#EDEDED',
              borderRadius: '9999px',
              color: stepOne ? 'white' : 'black',
            }}
          >
            1
          </Bloc>
          <Bloc
            fontSize="12px"
            hide={[null, true]}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Upload
          </Bloc>
        </Flex>

        <Bloc
          fontSize="12px"
          hide={[true, null]}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Upload
        </Bloc>
      </Flex>
      <Flex width="100%">
        <Bloc
          height="1px"
          alignSelf={[null, 'center']}
          mt={['20px', 0]}
          width="100%"
          bg="rgba(0, 0, 0, 0.06)"
        />
      </Flex>
      <Flex
        alignItems="center"
        style={{
          columnGap: '10px',
        }}
      >
        <Flex
          flexDirection="column"
          style={{
            rowGap: '6px',
          }}
          alignItems="center"
        >
          <Bloc
            width="36px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="12px"
            height="36px"
            style={{
              backgroundColor: stepTwo ? '#0EB6A2' : '#EDEDED',
              borderRadius: '9999px',
              color: stepTwo ? 'white' : 'black',
            }}
          >
            2
          </Bloc>
          <Bloc
            as="p"
            hide={[null, true]}
            fontSize="12px"
            p={0}
            m={0}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Set contact fields
          </Bloc>
        </Flex>

        <Bloc
          as="p"
          p={0}
          m={0}
          fontSize="12px"
          hide={[true, null]}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Set contact fields
        </Bloc>
      </Flex>
      <Flex width="100%">
        <Bloc
          height="1px"
          mt={['20px', 0]}
          alignSelf={[null, 'center']}
          width="100%"
          bg="rgba(0, 0, 0, 0.06)"
        />
      </Flex>
      <Flex
        alignItems="center"
        style={{
          columnGap: '10px',
        }}
      >
        <Flex
          flexDirection="column"
          style={{
            rowGap: '6px',
          }}
          alignItems="center"
        >
          <Bloc
            display="flex"
            width="36px"
            fontSize="12px"
            justifyContent="center"
            alignItems="center"
            height="36px"
            style={{
              backgroundColor: stepThree ? '#0EB6A2' : '#EDEDED',
              borderRadius: '9999px',
              color: stepThree ? 'white' : 'black',
            }}
          >
            3
          </Bloc>
          <Bloc
            as="p"
            hide={[null, true]}
            fontSize="12px"
            p={0}
            m={0}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Set groups
          </Bloc>
        </Flex>

        <Bloc
          as="p"
          hide={[true, null]}
          fontSize="12px"
          p={0}
          m={0}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Set groups
        </Bloc>
      </Flex>
    </Flex>
  );
};

export default DisplayStepperPosition;
