import { Bloc, Flex } from 'blocjs';
import React from 'react';
import Button from '../../button';
import Text from '../../text';

const ActiveActionButtons = ({
  action = null,
  isTab = false,
  handleSwitchPage,
  handleActions,
}) => {
  const returnAction = action ? action?.trigger ?? null : null;
  switch (returnAction) {
    case 'active-tab':
      return (
        <Flex
          alignItems="center"
          style={{
            columnGap: '6px',
          }}
        >
          <Button
            style={{
              textTransform: 'initial',
              fontWeight: 'normal',
            }}
            profile="accentLight"
            size="contact"
            onClick={() =>
              handleActions('active-tab', action?.result, 'move-to-group')
            }
          >
            Move to Group
          </Button>

          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
              color: 'black',
            }}
            profile="secondary"
            size="contact"
            onClick={() =>
              handleActions('active-tab', action?.result, 'move-to-archived')
            }
          >
            Archive
          </Button>
        </Flex>
      );

    default:
      return (
        <Flex
          alignItems="center"
          style={{
            columnGap: '6px',
          }}
        >
          <Button
            style={{
              textTransform: 'initial',
              fontWeight: 'normal',
            }}
            profile="accentLight"
            size="contact"
            onClick={() => handleSwitchPage('add-contact')}
          >
            Add{' '}
            <Bloc as="span" hide={[true, null]}>
              a contact
            </Bloc>
          </Button>
          {!isTab && (
            <Text as="p" fontSize={1} fontWeight={600}>
              OR
            </Text>
          )}
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
              color: 'black',
            }}
            profile="secondary"
            size="contact"
            onClick={() => handleSwitchPage('import')}
          >
            Import
          </Button>
        </Flex>
      );
  }
};

export default ActiveActionButtons;
