import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Bloc } from 'blocjs';
import GithubIcon from 'mdi-react/GithubIcon';
import { GoogleLogin } from 'react-google-login';
import posthog from 'posthog-js';

import { AuthContext } from '../../contexts';
import UserAPI from '../../services';
import {
  EMAIL_REGEX,
  GITHUB_CLIENT_ID,
  REDIRECT_URI,
  GOOGLE_CLIENT_ID,
  USERNAME_REGEX,
} from '../../utils';
import {
  Inputfield,
  Button,
  Layout,
  Text,
  Toast,
  Spinner,
  Icon,
} from '../../components';
import { useFetchUserSelf } from '../../hooks';
import MagicLinkComponent from './magicLink';
import EmailSentComponent from '../../components/magicLink/emailSentState';

const Register = () => {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  const userAPI = new UserAPI();
  const { push } = useHistory();
  const [userAthProviderToken, setAthProviderToken] = useState(false);
  const [registerUsingEmail, setRegisterUsingEmail] = useState(true);
  const [emailInput, setEmailInput] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  const [manualRegistration, setManualRegistration] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { addUserToken, addLoginType } = useContext(AuthContext);
  const { mutate, isError, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.register(payload),
  });

  const {
    mutate: githubLoginMutate,
    isError: isGithubLoginError,
    error: githubLoginError,
    isLoading: isGithubLoginLoading,
  } = useMutation({
    mutationFn: (payload) => userAPI.loginWithGithub(payload),
  });

  const {
    mutate: googleLoginMutate,
    isError: isGoogleLoginError,
    error: googleLoginError,
    isLoading: isGoogleLoginLoading,
  } = useMutation({
    mutationFn: (payload) => userAPI.loginWithGoogle(payload),
  });

  const { data: userData, isLoading: userLoading } =
    useFetchUserSelf(userAthProviderToken);
  const { mutate: verifyUser } = useMutation({
    mutationFn: (payload) => userAPI.resendConfirmationEmail(payload),
  });

  useEffect(() => {
    if (userData && !userLoading) {
      const user = userData;
      if (userAthProviderToken) {
        if (!user.confirmed_at) {
          verifyUser(userAthProviderToken, {
            onSuccess: () => {
              push(`/confirmation?email=${user?.email}`);
            },
          });
        } else {
          addUserToken(userAthProviderToken);
        }
      }
    }
  }, [
    addUserToken,
    push,
    userAthProviderToken,
    userData,
    userLoading,
    verifyUser,
  ]);

  useEffect(() => {
    if (code) {
      const handleLoginWithGithub = async (tokenCode) => {
        if (tokenCode) {
          addLoginType('socials');

          const payload = {
            token: tokenCode,
          };
          await githubLoginMutate(payload, {
            onSuccess: (data) => {
              const res = data;
              if (res?.data?.token) {
                posthog.capture('Registered successful with Github', {
                  property: payload,
                });
                setAthProviderToken(res?.data?.token);
              }
            },
          });
        }
      };
      handleLoginWithGithub(code);
    }
  }, [addLoginType, addUserToken, code, githubLoginMutate, push]);

  const handleLoginWithGoogle = async (googleData) => {
    if (googleData && googleData.tokenId) {
      const payload = {
        token: googleData.tokenId,
      };
      await googleLoginMutate(payload, {
        onSuccess: (data) => {
          addLoginType('socials');

          const res = data;
          if (res?.data?.token) {
            setAthProviderToken(res?.data?.token);
            posthog.capture('Registered successful with Google', {
              property: payload,
            });
          }
        },
      });
    }
  };

  const onSubmit = async (data) => {
    await mutate(data, {
      onSuccess: (response) => {
        addLoginType('credential');

        if (response) {
          posthog.capture('Registered successful with credentials', {
            property: data,
          });
          push(`/confirmation?email=${data.email}`, { from: '/register' });
        }
      },
    });
  };

  return (
    <Layout pt={[3, '5vh']}>
      {isError && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {isGithubLoginError && (
        <Toast
          message={githubLoginError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {isGoogleLoginError && (
        <Toast
          message={googleLoginError?.response?.data?.error?.message}
          status="error"
        />
      )}

      <Flex
        flexDirection={['column', 'row']}
        justifyContent={['start', 'space-around']}
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
        style={{
          gap: '24px',
          height: '100%',
        }}
      >
        <Bloc mb={0} maxWidth={300}>
          <Text variant="h3" as="h1">
            New account
          </Text>
          <Bloc width={64} height={2} my={4} bg="#FF3049" />
          <Text variant="h5" as="span">
            Instant communication platform for you!{' '}
          </Text>
        </Bloc>
        <Bloc width={[1, 1, 512]}>
          {sentEmail ? (
            <EmailSentComponent
              email={emailInput}
              setSentEmail={setSentEmail}
            />
          ) : (
            <Bloc
              borderRadius="8px"
              boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
              bg="white"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
              padding="48px 32px"
            >
              {manualRegistration && (
                <Flex
                  flexDirection="column"
                  style={{
                    gap: '15px',
                  }}
                >
                  <Bloc
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setRegisterUsingEmail((prev) => !prev);
                      setManualRegistration((prev) => !prev);
                    }}
                  >
                    <Icon name="arrow" size={24} dimension="24x24" />
                  </Bloc>
                  <Bloc fontWeight="500">Register manually</Bloc>
                </Flex>
              )}

              {manualRegistration ? (
                <Bloc as="form" onSubmit={handleSubmit(onSubmit)}>
                  <Inputfield
                    name="Username"
                    ref={register({
                      required:
                        'Without email or username — there is no handshake :(',
                      maxLength: {
                        value: 32,
                        message: 'Username can not exceed 11 characters',
                      },
                      pattern: {
                        value: USERNAME_REGEX,
                        message: 'Username can only be alphanumeric',
                      },
                    })}
                    hint="Unique handle for your account"
                    error={errors?.username?.message}
                    data-testid="username"
                  />
                  <Inputfield
                    name="email"
                    ref={register({
                      required:
                        'Your email will be the instrument to communicating with you',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Enter a valid e-mail address',
                      },
                    })}
                    hint="We prefer your work email, but will work with all you have"
                    error={errors?.email?.message}
                    data-testid="email"
                  />
                  <Inputfield
                    type="password"
                    name="Password"
                    ref={register({
                      required: 'You cannot enter without a key',
                    })}
                    hint="The strong key you use to unlock your Pindo"
                    error={errors?.password?.message}
                    data-testid="password"
                  />
                  <Button
                    profile="accentLight"
                    size="normal"
                    loading={isPending}
                    data-testid="register"
                  >
                    Register
                  </Button>
                </Bloc>
              ) : (
                <>
                  <Flex
                    flexDirection={['column', 'column', 'row']}
                    justifyContent="space-between"
                  >
                    <Bloc
                      bg="#000"
                      width={[1, 1, '48.5%']}
                      borderRadius="5px"
                      color="#fff"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Bloc
                        as="a"
                        textDecoration="none"
                        color="#fff"
                        textTransform="uppercase"
                        cursor="default"
                        display="flex"
                        alignItems="center"
                        height="40px"
                        data-testid="github-login"
                        href={`https://github.com/login/oauth/authorize?scope=user&client_id=${GITHUB_CLIENT_ID}&redirect_uri=${REDIRECT_URI}`}
                      >
                        <GithubIcon />
                        <Bloc fontWeight="400" fontSize="14px" ml="5px">
                          Register with GitHub{' '}
                          {isGithubLoginLoading && <Spinner size="large" />}
                        </Bloc>
                      </Bloc>
                    </Bloc>
                    <Bloc width={[1, 1, '48.5%']} mt={[4, 4, 0]}>
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={handleLoginWithGoogle}
                        onFailure={handleLoginWithGoogle}
                        cookiePolicy="single_host_origin"
                        className="google-login"
                      >
                        {' '}
                        Register with Google{' '}
                        {isGoogleLoginLoading && <Spinner size="large" />}
                      </GoogleLogin>
                    </Bloc>
                  </Flex>

                  <MagicLinkComponent
                    mode="register"
                    usingEmail={registerUsingEmail}
                    setEmailInput={setEmailInput}
                    setUsingEmail={setRegisterUsingEmail}
                    setSentEmail={setSentEmail}
                  />

                  <Bloc fontSize={1}>
                    The magic link is for a smooth, password free login, you can
                    also
                    <Text
                      onClick={() => {
                        setManualRegistration((prev) => !prev);
                      }}
                      color="accents.1"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      fontWeight="normal"
                    >
                      {' '}
                      register manually{' '}
                    </Text>
                    with a password
                  </Bloc>
                </>
              )}
            </Bloc>
          )}
          {!sentEmail && (
            <Bloc fontSize={1} as="p" mt="30px">
              Already have registered?{' '}
              <Text
                color="accents.1"
                fontWeight="normal"
                as={Link}
                to="/login"
                style={{ cursor: 'pointer' }}
              >
                Login
              </Text>
            </Bloc>
          )}
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Register;
