import React, { useEffect, useRef } from 'react';
import { Bloc } from 'blocjs';
import { dropdownLayout, inputSelectBox } from './style';
import { Chevron } from '../carets';
import Icon from '../icon';

const styles = {
    dropdownLayout, inputSelectBox
};

function Dropdown({ children, isDropdownOpen, activeItem, setIsDropdownOpen,
    itemsList, disabled, hasError, showDefault, height }) {

    const ref = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isDropdownOpen, setIsDropdownOpen]);

    return (
        <Bloc
            ref={ref}
            aria-hidden="true"
            position='relative'
            ml={0}
            style={styles.inputSelectBox}
        >
            <Bloc
                data-testid="select-dropdown"
                aria-hidden="true"
                borderColor={hasError ? "surfaces.4": "#DEE1E7"}
                borderStyle='solid'
                borderWidth='1px'
                borderRadius='4px'
                height={height}
                display='flex'
                backgroundColor={disabled ?  '#F7F9FB' : 'white'}
                alignItems='center'
                justifyContent='space-between'
                boxSizing='border-box'
            >
                <Bloc display='flex' alignItems="center" overflow='hidden' width={1}>
                    <Bloc display="flex" alignItems="center" minWidth={32} height={40} justifyContent="space-between"
                        px={3} boxSizing='border-box'
                        id="select-box"
                        onClick={(e) => { 
                            e.preventDefault(); e.stopPropagation(); 
                            setIsDropdownOpen(!isDropdownOpen);
                        }}
                        style={{ cursor: 'pointer' }}
                        width={1}
                    >
                            <Bloc display="flex" alignItems="center">
                                <Bloc style={{ textTransform: "capitalize" }} mr={2}>{activeItem?.name}</Bloc>
                                {showDefault && <Icon name="check-broken-circle" height={14} color="#7A8794" size={12} dimension='24x24'/>}
                            </Bloc>
                        <Chevron color={isDropdownOpen ? 'accents.8' : 'surfaces.3'} size={16} />
                    </Bloc>
                </Bloc>
            </Bloc>

            {!disabled && isDropdownOpen && (
                <Bloc 
                    position='absolute'
                    stop={60}
                    width={1}
                    right={0}
                    zIndex={8}
                    backgroundColor='white'
                    boxSizing='border-box'
                    overflow='auto'
                    padding={0}
                    borderStyle='solid'
                    borderColor="#DEE1E7"
                    borderRadius="6px"
                    height={itemsList.length > 0 ? 'auto' : 126}
                    style={styles.dropdownLayout}
                    marginTop={2}
                    boxShadow='0px 2px 4px rgba(27, 29, 34, 0.05), 0px 4px 4px rgba(27, 29, 34, 0.02), 0px 8px 8px rgba(27, 29, 34, 0.04), 0px 16px 16px rgba(27, 29, 34, 0.02), 0px 24px 30px rgba(27, 29, 34, 0.02)'
                >
                    {children}
                </Bloc>
            )}
        </Bloc>
    );
}

export default Dropdown;
