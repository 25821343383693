import { Bloc, Flex } from 'blocjs';
import React from 'react';
import { tabs } from './dummyData';

const TabsComponent = ({ handleSwitchTab, activeTab }) => (
  <Flex
    px="23px"
    alignItems="center"
    overflowX="auto"
    style={{
      columnGap: '14px',
      borderBottom: '1px solid #E2E2E2',
    }}
  >
    {Object.entries(tabs).map(([path, { name, total }]) => {
      const isActive = path === activeTab;
      return (
        <Bloc
          display="flex"
          alignItems="center"
          borderBottom={
            isActive ? '3px solid #16C1AD' : '3px solid transparent'
          }
          key={path}
          pb="6px"
          style={{
            cursor: 'pointer',
            columnGap: '6px',
          }}
          onClick={() => handleSwitchTab(path, name)}
        >
          <Bloc
            as="span"
            fontSize="11px"
            style={{
              whiteSpace: 'nowrap',
              textTransform: 'initial',
            }}
            fontWeight={isActive ? '500' : '400'}
          >
            {name}
          </Bloc>

          <Bloc
            as="span"
            borderRadius="999px"
            px="6px"
            py="2px"
            fontWeight="700"
            fontSize="9px"
            color={isActive ? 'white' : '#656B6D'}
            bg={isActive ? '#16C1AD' : '#E2E2E2'}
          >
            {total}
          </Bloc>
        </Bloc>
      );
    })}
  </Flex>
);

export default TabsComponent;
