import React from 'react';
import { Bloc } from 'blocjs';

const Input = React.forwardRef(
  ({ name, size = 'normal', error, ...rest }, ref) => {
    const hasError = error !== undefined;
    const sizes = {
      normal: {
        fontSize: 16,
        padding: 3,
        height: 40,
      },
      large: {
        fontSize: 18,
        padding: '12px',
        height: 'initial',
      },
      contact: {
        fontSize: '16px',
        padding: '7px 16px',
      },
    };

    return (
      <Bloc
        p={sizes[size].padding}
        width={1}
        border={1}
        borderRadius={2}
        borderColor={hasError ? 'surfaces.4' : 'surfaces.2'}
        fontSize={sizes[size].fontSize}
        height={sizes[size].height}
        fontFamily="sans"
        as="input"
        name={name}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Input;
