import React, { useContext, useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import AccountContainer from '../container';
import MainAccount from '../../account/mainAccount';
import { CurrentUserContext } from '../../../contexts';
import PersonalDetails from '../../account/personalDetails';

const UserDetails = ({ uniqueKey, setUniqueKey }) => {
  const [showAvatarForm, setShowAvatarForm] = useState(false);

  const { currentUser, status, profile, isProfileLoading, isProfileFetching } =
    useContext(CurrentUserContext);
  return (
    <AccountContainer pt={0}>
      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
        p="20px"
      >
        <MainAccount
          user={currentUser}
          profile={profile}
          setShowAvatarForm={setShowAvatarForm}
          showAvatarForm={showAvatarForm}
          isProfileLoading={isProfileLoading}
          isProfileFetching={isProfileFetching}
          setUniqueKey={setUniqueKey}
          uniqueKey={uniqueKey}
        />
        <Bloc opacity={!showAvatarForm ? 1 : 0.2} position="relative">
          {showAvatarForm && (
            <Bloc
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              opacity={0.5}
              zIndex={100}
            />
          )}

          <PersonalDetails
            user={currentUser}
            userStatus={status}
            profile={profile}
          />
        </Bloc>
      </Flex>
    </AccountContainer>
  );
};

export default UserDetails;
