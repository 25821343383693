import React from 'react';
import { Box, Bloc } from 'blocjs';
import Caret from '../carets/caret';
import withDropdown from './withDropdown';
import Divider from '../divider';
import NavigationButton from '../navigationLink';

const Dropdown = ({
  isNavbar = true,
  handleLogout,
  isAdminView,
  children,
  isAuthenticated = true,
  minWidth = 152,
}) => (
  <Box
    position="absolute"
    zIndex={100}
    top={['60px', '60px']}
    minWidth={minWidth}
    width="auto"
    right={0}
  >
    <Caret
      size={16}
      direction="up"
      position="absolute"
      top="-15px"
      right="17px"
    />
    <Bloc
      bg="surfaces.0"
      minWidth={200}
      width="auto"
      py={[!isAdminView ? 22 : '15px', !isAdminView ? '14px' : '0px']}
      display="flex"
      flexDirection="column"
      borderRadius={2}
      px={19}
      boxShadow="0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16);"
    >
      <Bloc>{children}</Bloc>
      {isNavbar && isAuthenticated && (
        <>
          <Bloc
            hide={[null, isAdminView]}
            py={['10px', '9px']}
            pt={['15px', null]}
          >
            <Divider />
          </Bloc>

          <Bloc
            textAlign={isAdminView ? ['left', 'right'] : 'left'}
            onClick={handleLogout}
            py={isAdminView && [0, 4]}
          >
            <NavigationButton
              data-test="logout"
              fontSize="14px"
              profile="primary"
              size="normal"
            >
              Logout
            </NavigationButton>
          </Bloc>
        </>
      )}
    </Bloc>
  </Box>
);

export default withDropdown(Dropdown);
