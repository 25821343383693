import React, { useState } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserAPI from '../../../../services';
import {
  Text,
  Spinner,
  Button,
  Inputfield,
  ErrorBelowInput,
  CheckedBox,
  Icon,
  Toast,
  Pagination,
} from '../../../../components';
import {
  useFetchMainUserOrg,
  useFetchOrgTransactions,
} from '../../../../hooks';
import { currencyFormat, formatAmount, formatBalance } from '../../../../utils';

import HistoryTable from './historyTable';

// const OrgBalance = () => <Bloc>$ 1000.00</Bloc>

const AddFunds = ({
  orgData,
  setShowAddFunds,
  callToast,
  setSelectedOrganizations,
}) => {
  const itemsPerPage = 25;
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const [isPaying, setIsPaying] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const { register, handleSubmit, errors, watch } = useForm();
  const { data: mainUser, isLoading: isMainUserLoading } = useFetchMainUserOrg(
    orgData?.id
  );

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const mainUserId = mainUser?.id || null;
  const { data, isLoading: isTransactionsLoading } = useFetchOrgTransactions(
    mainUserId,
    pageNumber,
    itemsPerPage
  );

  const {
    mutate,
    isError: isPayingError,
    error: payingError,
    isLoading,
    status: payingPayStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.creditOrgWallet(payload),
  });

  const payWithCard = async (inputData) => {
    try {
      setIsPaying(true);
      // create payment intent on the server
      const bodyData = {
        amount: parseFloat(inputData.amount_in_usd),
      };
      const payload = { orgId: orgData?.id, bodyData };

      await mutate(payload, {
        onSuccess: async (res) => {
          if (res) {
            await queryClient.invalidateQueries({
              queryKey: ['orgs', { pageNumber: 1, itemsPerPage: 25 }],
            });
            await queryClient.invalidateQueries({
              queryKey: ['orgTransactions', mainUserId],
            });
            setTimeout(() => {
              setShowAddFunds(false);
              setSelectedOrganizations([]);
            }, 3000);
          }
        },
      });

      setIsPaying(false);
    } catch (err) {
      callToast(`${err.message}, Please try again`, 'error');
    }
  };

  const watchAmount = watch('amount_in_usd');

  let inputAmountInUSD = 0;
  if (watchAmount) {
    inputAmountInUSD = formatAmount(watchAmount);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Amount',
        floatRight: false,
        Cell: ({ row: { original } }) => formatBalance(original.amount),
      },
      {
        Header: 'Payment Processor',
        floatRight: false,
        Cell: ({ row: { original } }) => original.payment_processor,
      },
      {
        Header: 'Transaction Date',
        floatRight: true,
        accessor: (row) => (
          <Bloc display="flex" alignItems="stretch" justifyContent="end">
            {format(new Date(row.created_at), 'MMM, dd, yyyy')}
          </Bloc>
        ),
      },
    ],
    []
  );

  const transactionsData = data?.transactions || [];

  const onCheckBox = (org, e) => {
    e.stopPropagation();
    const items = [...selectedOrgs];
    const index = items.findIndex((item) => item.id === org.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedOrgs(items);
    } else {
      setSelectedOrgs([org]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedOrgs.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedOrgs.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const goToOrgDetails = (org, e) => {
    e.stopPropagation();
  };

  return (
    <>
      {isPayingError && (
        <Toast
          message={payingError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {payingPayStatus === 'error' && (
        <Toast message={payingError?.message} status="error" />
      )}
      {payingPayStatus === 'success' && (
        <Toast message="Added funds successfully" status="success" />
      )}
      <Box>
        <Flex
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
            <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
              {!isMainUserLoading ? (
                <Text>{orgData?.name || mainUser?.username}</Text>
              ) : (
                <Spinner size="large" />
              )}
            </Text>
          </Box>
        </Flex>
        <Bloc
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
          bg="surfaces.0"
          borderRadius={3}
          paddding={0}
          mb={5}
          ml={[0, '16%']}
          outline="0"
          py={18}
          px={25}
        >
          <Flex justifyContent="space-between">
            <Bloc as="h3" m={0}>
              {' '}
              Add Funds{' '}
            </Bloc>
          </Flex>
          <Bloc as="form" onSubmit={handleSubmit(payWithCard)} pt={18}>
            <Bloc width={[1, 350]}>
              <Inputfield
                type="tel"
                inputmode="numeric"
                name="amount_in_usd"
                label="Amount in USD"
                id="payInUSD"
                placeholder="Minimum amount is $5"
                data-testid="amount_in_usd"
                hint="Fill in amount in USD"
                ref={register({
                  required: 'Amount is required.',
                  validate: { minAmount: (value) => parseFloat(value) > 4 },
                })}
                error={errors?.amount_in_usd?.message}
              />
              <Bloc mb={3}>
                {errors?.amount_in_usd?.type === 'minAmount' &&
                  ErrorBelowInput('The minimum amount is $5')}
              </Bloc>
            </Bloc>
            <Flex mt={4} flexWrap="wrap">
              <Box mb={[3, 0]} mr={30}>
                <Button
                  profile="accentLight"
                  size="small"
                  loading={isPaying || isLoading}
                  data-testid="payWithCard"
                >
                  Pay ${currencyFormat(inputAmountInUSD)}
                </Button>
              </Box>
              <Box mb={0}>
                <Button
                  profile="primary"
                  size="small"
                  onClick={() => {
                    setShowAddFunds(false);
                    setSelectedOrganizations([]);
                  }}
                  data-testid="cancelPaymentWithCard"
                  type="button"
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Bloc>
        </Bloc>

        <Flex
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box style={{ float: 'left' }} minWidth="16%" mt={5} mb={5}>
            <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
              Billing History{' '}
              {isTransactionsLoading && <Spinner size="large" />}
            </Text>
          </Box>
        </Flex>

        <Bloc>
          <HistoryTable
            columns={columns}
            data={transactionsData}
            setRowBackgroundColor={setRowBackgroundColor}
            onRowClick={goToOrgDetails}
            onCheckBox={onCheckBox}
            setCheckbox={setCheckbox}
          />
        </Bloc>
        {data && data.pages.pages !== 0 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            pages={data?.pages}
            pageCount={data?.pages?.pages}
            changePage={changePage}
            isFetching={isLoading}
            isLoading={isLoading}
          />
        )}
      </Box>
    </>
  );
};

export default AddFunds;
