/* eslint-disable react/no-array-index-key */
import { Bloc } from 'blocjs';
import React, { useEffect, useRef } from 'react';
import { dropdownLayout, inputSelectBox } from '../../selection/style';
import { Inputfield } from '../../inputs';

import ImportContactFirstStep from '../../contact/importContactFirstStep';

const styles = {
  dropdownLayout,
  inputSelectBox,
};

const Dropdown = ({
  children,
  fileName,
  isAnyContactPresent,
  // invalidNumbers,
  setFetchEnable,
  importedContactLength,
  handleSearch,
  searchContactRef,
  isDropdownOpen,
  setIsDropdownOpen,
  handleFileUpload,
  setShowContactsInfo,
  showContactsInfo,
  progressPercentage,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  return (
    <Bloc
      ref={ref}
      aria-hidden="true"
      width="100%"
      position="relative"
      p={0}
      ml={0}
    >
      <Bloc
        overflow="hidden"
        className="select-audience-field"
        width={1}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDropdownOpen(true);
          setFetchEnable(true);
        }}
        style={{ cursor: 'pointer', gap: '6px' }}
      >
        <Inputfield
          name="search"
          ref={searchContactRef}
          type="text"
          style={{
            border: 'none',
          }}
          fontSize="14px"
          px="10px"
          pt="12px"
          // pb="15px"
          hasLabel={false}
          placeholder="Enter phone numbers or find groups and contacts"
          size="contact"
          onChange={handleSearch}
        />
      </Bloc>
      {isAnyContactPresent && isDropdownOpen && (
        <Bloc
          position="absolute"
          stop={60}
          width={1}
          right={0}
          zIndex={1000}
          backgroundColor="white"
          boxSizing="border-box"
          overflow="auto"
          padding={0}
          borderRadius="6px"
          height="auto"
          maxHeight="280px"
          style={{
            ...styles.dropdownLayout,
            ...styles.inputSelectBox,
            borderRadius: '0px 0px 5px 5px',
            width: '100.3%',
          }}
          marginRight="-0.5px"
          marginTop={0}
          border="1px solid #0EB6A2"
          //  boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
        >
          {children}
        </Bloc>
      )}

      <Bloc height="100%" borderTop="1px solid #E0E0E0">
        <ImportContactFirstStep
          setShowContactsInfo={setShowContactsInfo}
          showContactsInfo={showContactsInfo}
          showContactError={false}
          fileName={fileName}
          message
          importedContactLength={importedContactLength}
          progressPercentage={progressPercentage}
          handleFileUpload={handleFileUpload}
        />
      </Bloc>
    </Bloc>
  );
};

export default Dropdown;
