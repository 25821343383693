import React from 'react';
import { Bloc } from 'blocjs';

const BillingOrgTableData = ({
  setRowBackgroundColor,
  onRowClick,
  org,
  onCheckBox,
  setCheckbox,
  hasSelect = true,
}) => (
  <Bloc
    as="tr"
    textAlign="left"
    borderBottom="1px solid #DFDBDB"
    background={setRowBackgroundColor(org.id)}
    style={{ cursor: 'pointer' }}
    data-test="data-row"
    data-testid="data-row"
    height="40px"
    {...org.getRowProps({
      style: { cursor: 'pointer' },
      onClick: (e) => onRowClick(org, e),
    })}
  >
    {org.cells.map((cell) => (
      <Bloc
        as="td"
        py={1}
        px={4}
        {...cell.getCellProps()}
        key={cell.column.id}
        height="100%"
        style={{
          float: cell.column.floatRight ? 'initial' : 'initial',
        }}
      >
        {cell.render('Cell')}
      </Bloc>
    ))}

    <Bloc
      as="td"
      py={3}
      onClick={(e) => e.stopPropagation()}
      data-test="check-col"
      style={{ float: 'right' }}
      height={54}
      alignItems="center"
      display={hasSelect ? 'flex' : 'none'}
    >
      <Bloc
        as="span"
        style={{ cursor: 'pointer', float: 'right' }}
        data-test="check-box"
        data-testid="check-box"
        onClick={(e) => onCheckBox(org, e)}
      >
        {setCheckbox(org.id)}
      </Bloc>
    </Bloc>
  </Bloc>
);

export default BillingOrgTableData;
