import React, { useState } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Text,
  Card,
  Button,
  Inputfield,
  Icon,
  Toast,
  ToggleSwitch,
} from '../../../../../components';
import { copyToClipboard } from '../../../../../utils';
import UserAPI from '../../../../../services';

const AddOrUpdateService = ({
  countryData,
  cancelServiceUpdate,
  isEditingService,
  serviceData = undefined,
  isLocalService = true,
  setIsAddOrUpdateService,
}) => {
  const [message, setMessage] = useState(null);
  const [inputCopied, setInputCopied] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isGlobal, setIsGlobal] = useState(
    serviceData?.scope === 'Global' || false
  );
  const [isActive, setIsActive] = useState(
    serviceData?.is_operational || false
  );
  const [showToast, setShowToast] = useState(false);

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();

  const { mutate, status, error } = useMutation({
    mutationFn: (payload) => userAPI.createService(payload),
  });
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateLocalService(payload),
  });

  const copyInput = (inputValue, nameItem) => {
    copyToClipboard(inputValue);
    setInputCopied(nameItem);
    setMessage(`The ${nameItem} has been successfully copied`);
    setTimeout(() => {
      setMessage(null);
      setInputCopied('');
    }, 2000);
  };

  const onFormSubmit = async (data) => {
    if (data && countryData) {
      const countryId = countryData.id;
      const payload = {
        name: data.service_name,
        username: data.username,
        country_id: countryId,
        password: data.password,
        url: data.url,
        is_operational: isActive,
        price: Number(data.price),
        backup_position: Number(data.weight),
      };

      if (isEditingService) {
        const { id } = serviceData;
        const request = { bodyReq: payload, id };
        await mutateUpdate(request, {
          onSuccess: async () => {
            setShowToast(true);
            await queryClient.invalidateQueries({
              queryKey: ['countryRoutes', countryId],
            });
            setTimeout(async () => {
              setShowToast(false);
              setIsAddOrUpdateService(false);
            }, 3000);
          },
          onError: () => {
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 8000);
          },
        });
      } else {
        await mutate(payload, {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['countryRoutes', countryId],
            });
            setTimeout(() => {
              cancelServiceUpdate();
            }, 3000);
          },
        });
      }
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)} mt={32}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message="Local service created successfully" status="success" />
      )}
      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast message="Local service updated successfully" status="success" />
      )}
      {message && <Toast message={message} status="success" />}
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            {isEditingService ? 'Edit' : 'Add'} Service
          </Text>
        </Box>
      </Flex>
      <Box ml={[0, '16%']} mb={15}>
        <Card>
          <Flex justifyContent="start">
            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Service Name"
                  defaultValue={serviceData?.name || ''}
                  placeholder="Enter Service name"
                  hint="Fill in name of the service"
                  data-testid="name"
                  ref={register({ required: 'Service name is required' })}
                  error={errors?.service_name?.message}
                />
              </Box>
              <Flex flexDirection={['column', 'row']}>
                <Box width={1} style={{ position: 'relative' }}>
                  <Inputfield
                    name="URL"
                    placeholder="Insert URL"
                    hint="Fill in the username"
                    defaultValue={serviceData?.url || ''}
                    data-testid="url"
                    paddingRight="35px"
                    ref={register({ required: 'URL is required' })}
                    error={errors?.url?.message}
                  />
                  {isEditingService && (
                    <Bloc
                      as="span"
                      position="absolute"
                      right="14px"
                      top="45px"
                      cursor="pointer"
                      onClick={() => copyInput('password', 'URL')}
                      data-test="copy-access-token"
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon
                        name={`${
                          inputCopied === 'URL' ? 'check' : 'clipboard'
                        }`}
                        color={`${
                          inputCopied === 'URL' ? 'accentDark' : '#303B42'
                        }`}
                        size={12}
                      />
                    </Bloc>
                  )}
                </Box>
              </Flex>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Price"
                  defaultValue={serviceData?.price || ''}
                  placeholder="Enter Price"
                  hint="Fill in the price"
                  data-testid="name"
                  ref={register({ required: 'Price is required' })}
                  error={errors?.price?.message}
                />
              </Box>
              <Box width={1} mb={6}>
                <ToggleSwitch
                  label="Status"
                  id="activeStatus"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              </Box>
            </Bloc>

            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Username"
                  defaultValue={serviceData?.username || ''}
                  placeholder="Enter Username"
                  hint="Fill in the username"
                  data-testid="username"
                  ref={register({ required: 'Username is required' })}
                  error={errors?.username?.message}
                />
              </Box>

              <Box width={1} style={{ position: 'relative' }}>
                <Inputfield
                  name="Password"
                  type={showPassword ? 'text' : 'password'}
                  defaultValue={serviceData?.password || ''}
                  paddingRight="35px"
                  hint="Fill in password of the service"
                  placeholder="Enter Password"
                  ref={register({ required: 'Password is required' })}
                  error={errors?.password?.message}
                />
                <Bloc
                  as="span"
                  position="absolute"
                  right="14px"
                  top="45px"
                  cursor="pointer"
                  onClick={() => setShowPassword(!showPassword)}
                  data-test="reveal password"
                  style={{ cursor: 'pointer' }}
                >
                  <Icon
                    name={`${showPassword ? 'eye-slash' : 'eye'}`}
                    color="#303B42"
                    size={16}
                    dimension="24x24"
                  />
                </Bloc>
              </Box>

              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Weight"
                  defaultValue={serviceData ? serviceData.backup_position : ''}
                  placeholder="Enter Weight"
                  hint="Fill in the weight"
                  data-testid="name"
                  ref={register({ required: 'Weight is required' })}
                  error={errors?.weight?.message}
                />
              </Box>

              {!isLocalService && (
                <Box width={1} mb={6}>
                  <ToggleSwitch
                    label="Global"
                    id="globalStatus"
                    checked={isGlobal}
                    onChange={(e) => setIsGlobal(e.target.checked)}
                  />
                </Box>
              )}
            </Bloc>
          </Flex>
          <Flex mt={4} flexWrap="wrap">
            <Box mb={[3, 0]} mr={30}>
              <Button
                profile="accentLight"
                size="small"
                type="submit"
                data-testid="updateDetailsBtn"
              >
                {isEditingService ? 'SAVE CHANGES' : 'Add NEW SERVICE'}
              </Button>
            </Box>
            <Box mb={0}>
              <Button
                profile="primary"
                size="small"
                type="button"
                onClick={() => cancelServiceUpdate()}
                data-testid="cancel-show-details"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Card>
      </Box>
    </Bloc>
  );
};

export default AddOrUpdateService;
