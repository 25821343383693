import React, { useState } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Text,
  Card,
  Button,
  Inputfield,
  Icon,
  Toast,
  ToggleSwitch,
} from '../../../../components';
import {
  copyToClipboard,
  countriesList,
  DOUBLE_REGEX,
  formatStringNumber,
  separateData,
} from '../../../../utils';
import UserAPI from '../../../../services';
import SelectionInput from '../../../../components/selection';

// Function to generate a unique ID
function uniqueId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

const AddOrUpdateIntlService = ({
  cancelServiceUpdate,
  isEditingService,
  serviceData = undefined,
}) => {
  const [message, setMessage] = useState(null);
  const [inputCopied, setInputCopied] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(
    serviceData?.is_operational || false
  );
  const [showToast, setShowToast] = useState(false);
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { mutate, status, error } = useMutation({
    mutationFn: (payload) => userAPI.createIntService(payload),
  });
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateIntService(payload),
  });
  const { register, handleSubmit, errors } = useForm();

  const [showTelcosError, setShowTelcosError] = useState(null);
  const [telInputs, setTelInputs] = useState({});

  const countriesListWithSelection = [
    { name: 'Select a country', value: '', code: '', placeholder: true },
    ...countriesList,
  ];
  const [chosenCountry, setChosenCountry] = useState(
    countriesListWithSelection[0]
  );

  const defaultCountry = countriesList[0].code;

  const defaultDataObj = {
    id: uniqueId(),
    country: defaultCountry,
    telcos: '',
    price: '',
  };
  const [formData, setFormData] = useState([defaultDataObj]);

  const handleRemove = (e, id) => {
    e.preventDefault();
    const updatedFormData = formData.filter((data) => data.id !== id);
    setFormData(updatedFormData);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const newFormData = [...formData, defaultDataObj];
    setFormData(newFormData);
  };

  const handleChange = (id, field, value) => {
    const updatedFormData = formData.map((data) =>
      data.id === id ? { ...data, [field]: value } : data
    );
    setFormData(updatedFormData);
  };

  const copyInput = (inputValue, nameItem) => {
    copyToClipboard(inputValue);
    setInputCopied(nameItem);
    setMessage(`The ${nameItem} has been successfully copied`);
    setTimeout(() => {
      setMessage(null);
      setInputCopied('');
    }, 2000);
  };

  const onFormSubmit = async (req) => {
    if (req) {
      const formDataWithoutIds = formData.map(({ id, price, ...rest }) => ({
        ...rest,
        price: formatStringNumber(price), // Convert price to a number
      }));

      const payload = {
        name: req.service_name,
        username: req.username,
        password: req.password,
        url: req.url,
        is_operational: isActive,
        backup_position: Number(req.weight),
      };

      if (isEditingService) {
        let currentPricing = serviceData.pricing;
        // remove all the existing data for the chosen country and add new pricing for the chosen country
        const countryToRemove = chosenCountry.code;
        currentPricing = currentPricing.filter(
          (item) => item.country !== countryToRemove
        );

        const { dataWithoutDuplicates } = separateData(
          currentPricing,
          formDataWithoutIds
        );
        const newPricing = {
          pricing: [...currentPricing, ...dataWithoutDuplicates],
        };

        const { id } = serviceData;
        const request = {
          bodyReq: { ...payload, ...newPricing },
          serviceId: id,
        };
        await mutateUpdate(request, {
          onSuccess: async () => {
            setShowToast(true);
            await queryClient.invalidateQueries({ queryKey: ['intlServices'] });
            setTimeout(async () => {
              setShowToast(false);
              cancelServiceUpdate();
            }, 3000);
          },
          onError: () => {
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 8000);
          },
        });
      } else {
        const request = { ...payload, pricing: formDataWithoutIds };
        await mutate(request, {
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['intlServices'] });
            setTimeout(() => {
              cancelServiceUpdate();
            }, 3000);
          },
        });
      }
    }
  };

  const onChangeInput = (e, id) => {
    const { value } = e.target;
    // Allow alphanumeric characters, -, ., and _
    const validTelcoName = value.replace(/[^\w.-]/g, '');
    setTelInputs((prevState) => ({ ...prevState, [id]: validTelcoName }));
    setShowTelcosError(null);
  };

  const handleKeyDown = (e, id) => {
    if (
      e.keyCode === 9 || // tab
      e.keyCode === 13 || // enter
      e.keyCode === 32 || // semicolon
      e.keyCode === 188 // comma
    ) {
      e.preventDefault();
      e.stopPropagation();
      const newTelco = e.target.value;

      const updatedFormData = formData.map((data) => {
        if (data.id === id) {
          let updatedTelcos;
          if (data.telcos.trim() === '') {
            updatedTelcos = newTelco; // If telcos is empty, no comma before the newTelco
          } else {
            // Split the telcos string into an array
            const telcosArray = data.telcos.split(', ');
            // Check if the newTelco already exists in the array
            updatedTelcos = !telcosArray.includes(newTelco)
              ? `${data.telcos}, ${newTelco}`
              : data.telcos;
          }
          return { ...data, telcos: updatedTelcos };
        }
        return data;
      });

      setFormData(updatedFormData);
      setTelInputs((prevState) => ({ ...prevState, [id]: '' }));
    }
  };

  const removeTelcoFromFormData = (id, telcoToRemove) => {
    setFormData((prevFormData) =>
      prevFormData.map((item) => {
        if (item.id === id) {
          const telcosArray = item.telcos.split(', ');
          // Remove the specified telco
          const updatedTelcos = telcosArray.filter(
            (telco) => telco !== telcoToRemove
          );
          // Join the updated array back into a string
          const updatedTelcosString = updatedTelcos.join(', ');
          return { ...item, telcos: updatedTelcosString };
        }
        return item;
      })
    );
  };

  const chosenIndex = chosenCountry.code
    ? countriesListWithSelection.findIndex(
        (item) => item.code === chosenCountry.code
      )
    : 0;

  const onCountryChange = (value) => {
    setChosenCountry(value);
    if (value.code) {
      const pricingData = serviceData?.pricing || [];
      const countryTelcosData = pricingData.filter(
        (item) => item.country === value.code
      );
      if (countryTelcosData.length > 0) {
        const initialData = countryTelcosData.map((item) => ({
          id: uniqueId(),
          country: item.country,
          telcos: item.telcos,
          price: item.price,
        }));
        setFormData(initialData);
      } else {
        setFormData([defaultDataObj]);
      }
    } else {
      setFormData([defaultDataObj]);
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast
          message="International service created successfully"
          status="success"
        />
      )}
      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast
          message="International service updated successfully"
          status="success"
        />
      )}
      {message && <Toast message={message} status="success" />}
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            {isEditingService ? 'Edit' : 'Add'} International Service
          </Text>
        </Box>
      </Flex>
      <Box ml={[0, '16%']} mb={15}>
        <Card>
          <Flex justifyContent="start">
            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Service Name"
                  defaultValue={serviceData?.name || ''}
                  placeholder="Enter Service name"
                  hint="Fill in name of the service"
                  data-testid="name"
                  ref={register({ required: 'Service name is required' })}
                  error={errors?.service_name?.message}
                />
              </Box>
              <Flex flexDirection={['column', 'row']}>
                <Box width={1} style={{ position: 'relative' }}>
                  <Inputfield
                    name="URL"
                    placeholder="Insert URL"
                    hint="Fill in the username"
                    defaultValue={serviceData?.url || ''}
                    data-testid="url"
                    paddingRight="35px"
                    ref={register({ required: 'URL is required' })}
                    error={errors?.url?.message}
                  />
                  {isEditingService && (
                    <Bloc
                      as="span"
                      position="absolute"
                      right="14px"
                      top="45px"
                      cursor="pointer"
                      onClick={() => copyInput('password', 'URL')}
                      data-test="copy-access-token"
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon
                        name={`${
                          inputCopied === 'URL' ? 'check' : 'clipboard'
                        }`}
                        color={`${
                          inputCopied === 'URL' ? 'accentDark' : '#303B42'
                        }`}
                        size={12}
                      />
                    </Bloc>
                  )}
                </Box>
              </Flex>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Weight"
                  defaultValue={serviceData ? serviceData.backup_position : ''}
                  placeholder="Enter Weight"
                  hint="Fill in the weight"
                  data-testid="name"
                  ref={register({
                    required: 'Weight is required',
                    pattern: {
                      value: DOUBLE_REGEX,
                      message: 'Please enter a valid weight',
                    },
                  })}
                />
              </Box>

              {isEditingService && (
                <Bloc width={1}>
                  <Bloc
                    my={3}
                    fontSize={2}
                    fontWeight="bold"
                    display="inline-block"
                    as="label"
                  >
                    Pricing
                  </Bloc>
                  <SelectionInput
                    hasSearch
                    dropDownItems={countriesListWithSelection}
                    activeItemIndex={chosenIndex}
                    onSelectionChange={(value) => onCountryChange(value)}
                    hasPlaceholder={!(chosenIndex !== 0)}
                  />

                  {!formData[0].telcos && chosenIndex !== 0 && (
                    <Bloc mr={4} display="flex" alignItems="center" mt={3}>
                      <Text fontSize={14} color="primary">
                        No Telcos added on this country
                      </Text>
                    </Bloc>
                  )}
                  <Bloc mb={5} />
                </Bloc>
              )}
            </Bloc>

            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Username"
                  defaultValue={serviceData?.username || ''}
                  placeholder="Enter Username"
                  hint="Fill in the username"
                  data-testid="username"
                  ref={register({ required: 'Username is required' })}
                  error={errors?.username?.message}
                />
              </Box>

              <Box width={1} style={{ position: 'relative' }}>
                <Inputfield
                  name="Password"
                  type={showPassword ? 'text' : 'password'}
                  defaultValue={serviceData?.password || ''}
                  paddingRight="35px"
                  hint="Fill in password of the service"
                  placeholder="Enter Password"
                  ref={register({ required: 'Password is required' })}
                  error={errors?.password?.message}
                />
                <Bloc
                  as="span"
                  position="absolute"
                  right="14px"
                  top="45px"
                  cursor="pointer"
                  onClick={() => setShowPassword(!showPassword)}
                  data-test="reveal password"
                  style={{ cursor: 'pointer' }}
                >
                  <Icon
                    name={`${showPassword ? 'eye-slash' : 'eye'}`}
                    color="#303B42"
                    size={16}
                    dimension="24x24"
                  />
                </Bloc>
              </Box>

              <Box width={1} mb={6}>
                <ToggleSwitch
                  label="Status"
                  id="activeStatus"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              </Box>
            </Bloc>
          </Flex>

          {!isEditingService ? (
            <Bloc
              my={3}
              fontSize={2}
              fontWeight="bold"
              display="inline-block"
              as="label"
              mt={3}
              mr={3}
            >
              Pricing
            </Bloc>
          ) : (
            <Bloc mt={2} />
          )}

          {(!isEditingService || (isEditingService && formData[0].telcos)) && (
            <>
              {formData.map((data) => {
                const telcosArray = data.telcos ? data.telcos.split(', ') : [];
                return (
                  <Bloc key={data.id} mb={4}>
                    <Flex alignItems="center" flexWrap="wrap">
                      <Bloc
                        width={[1, 'calc(50% - 16px)', 'calc(30% - 16px)']}
                        mr={[1, '16px']}
                        minHeight={107}
                      >
                        <Bloc
                          my={3}
                          fontSize={2}
                          fontWeight="bold"
                          display="inline-block"
                          as="label"
                        >
                          Country
                        </Bloc>
                        <SelectionInput
                          hasSearch
                          dropDownItems={countriesList}
                          activeItemIndex={countriesList.findIndex(
                            (item) => item.code === data.country
                          )}
                          onSelectionChange={(value) =>
                            handleChange(data.id, 'country', value.code)
                          }
                        />
                      </Bloc>
                      <Bloc
                        width={[1, 'calc(50% - 16px)', 'calc(25% - 16px)']}
                        mr={[1, '16px']}
                        minHeight={107}
                      >
                        <Inputfield
                          label="Price"
                          name="price"
                          placeholder="Enter price in USD"
                          defaultValue={data.price}
                          onChange={(e) =>
                            handleChange(data.id, 'price', e.target.value)
                          }
                          hint="Fill in Price"
                          ref={register({
                            required: 'The price is required',
                            pattern: {
                              value: DOUBLE_REGEX,
                              message: 'Please enter a valid price',
                            },
                          })}
                          error={errors?.price?.message}
                        />
                      </Bloc>
                      <Bloc
                        width={[
                          1,
                          'calc(100% - (25px + 81px))',
                          'calc(45% - (25px + 81px))',
                        ]}
                        mr={[1, '23px']}
                        minHeight={107}
                      >
                        <Bloc
                          my={3}
                          fontSize={2}
                          fontWeight="bold"
                          display="inline-block"
                          as="label"
                        >
                          Telcos
                        </Bloc>
                        <Bloc
                          border="1px solid"
                          borderRadius="4px"
                          borderColor={
                            showTelcosError ? 'surfaces.4' : 'surfaces.2'
                          }
                          fontSize="16px"
                          padding="0 8px"
                          backgroundColor="surfaces.0"
                          overflow="hidden"
                        >
                          {telcosArray &&
                            telcosArray.map((telcoName) => (
                              <Bloc
                                as="span"
                                key={telcoName}
                                border="1px solid"
                                borderColor="accents.6"
                                backgroundColor="accents.6"
                                color="accents.8"
                                display="inline-block"
                                fontWeight="400"
                                marginRight="5px"
                                marginTop="5px"
                                padding="5px"
                                borderRadius="2px"
                                fontSize="16px"
                              >
                                {telcoName}
                                <Bloc
                                  as="a"
                                  onClick={() =>
                                    removeTelcoFromFormData(data.id, telcoName)
                                  }
                                  cursor="pointer"
                                  fontWeight="bold"
                                  data-testid="remove-real-contact"
                                >
                                  {' '}
                                  ×
                                </Bloc>
                              </Bloc>
                            ))}

                          <Bloc
                            as="input"
                            type="text"
                            value={telInputs[data.id] || ''}
                            placeholder="Add name of the telco here"
                            onChange={(e) => onChangeInput(e, data.id)}
                            onKeyDown={(e) => handleKeyDown(e, data.id)}
                            width="100%"
                            backgroundColor="transparent"
                            border="0"
                            fontSize="16px"
                            fontWeight={400}
                            height={38}
                            name="phoneNumber"
                            style={{ backgroundColor: 'white' }}
                          />
                        </Bloc>
                      </Bloc>
                      <Flex
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleRemove(e, data.id)}
                      >
                        <Bloc
                          as={Button}
                          color="primary"
                          type="button"
                          bg="primary"
                          borderRadius="100%"
                          boxShadow="0px 2px 8px rgba(0,0,0,0.08), 0px 8px 16px rgba(0,0,0,0.04)"
                          width={20}
                          height={20}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon
                            name="minus"
                            size={18}
                            dimension="24x24"
                            color="white"
                          />
                        </Bloc>
                        <Bloc as="span" ml={3} fontSize={14}>
                          Remove
                        </Bloc>
                      </Flex>
                    </Flex>
                  </Bloc>
                );
              })}
              <Flex
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={(e) => handleAdd(e)}
                mb={8}
              >
                <Bloc
                  as={Button}
                  type="button"
                  color="accent"
                  data-testid="add-another"
                  bg="accent"
                  borderRadius="100%"
                  boxShadow="0px 2px 8px rgba(0,0,0,0.08), 0px 8px 16px rgba(0,0,0,0.04)"
                  width={20}
                  height={20}
                  display="flex"
                  alignItems="center"
                >
                  <Icon name="plus" size={24} dimension="24x24" color="white" />
                </Bloc>
                <Bloc as="span" ml={3} fontSize={14}>
                  Add a new telco
                </Bloc>
              </Flex>
            </>
          )}

          <Flex mt={4} flexWrap="wrap">
            <Box mb={[3, 0]} mr={30}>
              <Button
                profile="accentLight"
                size="small"
                type="submit"
                data-testid="updateDetailsBtn"
              >
                {isEditingService ? 'SAVE CHANGES' : 'Add NEW SERVICE'}
              </Button>
            </Box>
            <Box mb={0}>
              <Button
                profile="primary"
                size="small"
                type="button"
                onClick={() => cancelServiceUpdate()}
                data-testid="cancel-show-details"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Card>
      </Box>
    </Bloc>
  );
};

export default AddOrUpdateIntlService;
