/* eslint-disable react-hooks/exhaustive-deps */
import { Bloc, Box } from 'blocjs';
import React, { useEffect, useCallback, useState } from 'react';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import ContactTable from '../table/contactTable';
import { reverseExtraFieldsOrder } from '../../utils';
import useGetContactFields from '../../hooks/useGetContactFields';

const ActiveContactTable = ({
  contactTableData,

  handleSwitchPage,
  handleActions,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { fieldHeaders, isLoading } = useGetContactFields({
    isMessageForm: false,
  });

  const reArrangeContactTableData = reverseExtraFieldsOrder(contactTableData);

  useEffect(() => {
    if (selectedContacts.length < 1) {
      handleActions(null, null);
    }
  }, [selectedContacts]);

  const onCheckBox = useCallback(
    (contact, e) => {
      e.stopPropagation();
      const items = [...selectedContacts];
      const index = items.findIndex((item) => item.id === contact.id);
      if (index > -1) {
        items.splice(index, 1);
        setSelectedContacts(items);
        if (items.length < 1) {
          handleActions(null, null);
        }
      } else {
        handleActions('active-tab', [...items, contact]);
        setSelectedContacts([...items, contact]);
      }
    },
    [selectedContacts]
  );
  const toggleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedContacts([]);
      handleActions('active-tab', []);
    } else {
      handleActions('active-tab', reArrangeContactTableData);
      setSelectedContacts(reArrangeContactTableData);
    }
    setSelectAll(!selectAll);
  }, [selectAll, reArrangeContactTableData]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    handleSwitchPage('view-contact', row.original);
  };
  const extraFields =
    !isLoading && fieldHeaders?.length > 0
      ? fieldHeaders.map((field) => ({
          Header: field,
          accessor: (row) => <Bloc>{row.extra_fields[field]}</Bloc>,
        }))
      : [];

  const setRowBackgroundColor = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return '#BDFBF3';
      }
      if (selectedContacts.includes(rowId)) {
        return '#E4FFFC';
      }
      return 'transparent';
    },
    [selectedContacts]
  );

  const setCheckbox = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return <CheckedBox color="accentDark" size={16} />;
      }
      return (
        <Box position="relative" width={16} height={16}>
          <Icon name="square" color="#959DA2" size={16} />
        </Box>
      );
    },
    [selectedContacts]
  );

  const column = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => <Bloc>{row.name}</Bloc>,
      },

      {
        Header: 'Phone',
        accessor: (row) => <Bloc>{row.phone_number}</Bloc>,
      },
      ...extraFields,
      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(row, e)}
            >
              {setCheckbox(row.id)}
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [onCheckBox, setCheckbox]
  );
  return (
    <Bloc>
      <ContactTable
        setRowBackgroundColor={setRowBackgroundColor}
        columns={column}
        selectedContacts={selectedContacts}
        data={reArrangeContactTableData}
        onRowClick={onRowClick}
        toggleSelectAll={toggleSelectAll}
        selectAll={selectAll}
      />
    </Bloc>
  );
};

export default ActiveContactTable;
