import React, { forwardRef } from 'react';
import { Bloc, Flex } from 'blocjs';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import PeriodButton from '../periodFilter/periodFilterButton';
import { Chevron } from '../carets';

const DatePickerButton = forwardRef(
  ({ value, onClick, period, isMobile, timePeriod, startDate, item }, ref) => {
    const WeekStartedAt = startDate
      ? format(
          startOfWeek(startDate, {
            weekStartsOn: 1,
          }),
          'dd'
        )
      : null;
    const weekEndedAt = startDate
      ? format(
          endOfWeek(startDate, {
            weekStartsOn: 1,
          }),
          'dd LLL yyyy'
        )
      : null;

    return (
      <Bloc onClick={onClick} ref={ref}>
        {isMobile ? (
          <PeriodButton size={['small', 'normal']}>
            <Bloc as="span" color={period === item ? 'accent' : '#757F84'}>
              {timePeriod}
            </Bloc>
          </PeriodButton>
        ) : (
          <PeriodButton size="datepicker">
            <Flex
              style={{
                gap: '10px',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Bloc as="span" fontSize="12px" color="#757F84">
                {period === 'Week'
                  ? `${WeekStartedAt} - ${weekEndedAt} `
                  : value}
              </Bloc>
              <Bloc display="flex" alignItems="center">
                <Chevron color="surfaces.3" size={16} />
              </Bloc>
            </Flex>
          </PeriodButton>
        )}
      </Bloc>
    );
  }
);

export default DatePickerButton;
