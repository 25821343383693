import { Bloc } from 'blocjs';
import React, { useEffect, useState } from 'react';
import Content from './content';
import Dropdown from './dropdown';

function SelectionInput({
  width = '100%',
  hasSearch = false,
  handleTabChange,
  isDashboard,
  disabled = false,
  hasError = false,
  setHasError = undefined,
  dropDownItems,
  activeItemIndex = 0,
  onSelectionChange,
  sHeight = 32,
  lHeight = 40,

  hasPlaceholder = false,
}) {
  const [selectedItem, setSelectedItem] = useState(dropDownItems[0]);
  const [items, setItems] = useState([]);
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');

  useEffect(() => {
    setItems(dropDownItems);
    setSelectedItem(dropDownItems[activeItemIndex]);
  }, [activeItemIndex, dropDownItems]);

  const handleSelectedItem = (e, activeItem) => {
    e.preventDefault();
    setSelectedItem(activeItem);
    setIsItemsDropdown(false);
    if (setHasError) {
      setHasError(null);
    }
    // Call the callback with the selected value
    onSelectionChange(activeItem);
  };

  const searchItemName = (e, listOfItems, setSearchItem, setList) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setSearchItem(inputValue);

    const searchInput = inputValue.trim().toLowerCase();

    if (searchInput.length > 0) {
      const filterMatches = listOfItems.filter(
        (el) =>
          el?.name?.toLowerCase().includes(searchInput) ||
          el?.phonenumber?.toLowerCase().includes(searchInput)
      );
      setList(filterMatches);
    } else {
      setList(listOfItems);
    }
  };

  return (
    <Bloc display="flex" width={['auto', width]} alignItems="center">
      <Dropdown
        isDashboard={isDashboard}
        activeItem={selectedItem}
        isDropdownOpen={isItemsDropdown}
        setIsDropdownOpen={setIsItemsDropdown}
        itemsList={items}
        disabled={disabled}
        hasError={hasError}
        height={[sHeight, lHeight]}
        hasPlaceholder={hasPlaceholder}
      >
        <Content
          isDashboard={isDashboard}
          selectedItem={selectedItem}
          handleTabChange={handleTabChange}
          handleSelectedItem={handleSelectedItem}
          searchItemName={(e) =>
            searchItemName(e, dropDownItems, setSearchedItem, setItems)
          }
          searchedTerm={searchedItem}
          itemsList={items}
          hasSearch={hasSearch}
        />
      </Dropdown>
    </Bloc>
  );
}

export default SelectionInput;
