/* eslint-disable no-useless-escape */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */

import { Bloc, Box, Flex } from 'blocjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Text from '../text';
import {
  useFetchOrg,
  useFetchOrgInboundNumbers,
  useFetchSenderIDWithDetails,
} from '../../hooks';
import {
  calculateMessageLength,
  calculateSMSCounter,
  checkMentionUsed,
  formatPhone,
  keepOnlyUsedFields,
} from '../../utils';
import ContactMessageDropdown from './dropdown';
import Button from '../button';
import useGlobalContactSearch from './useGlobalSearch';
import useUploadContact from './useUploadContact';
import AddedRecipients from './dropdown/addedRecipients';
import constructSendMessagePayload from '../../utils/constructSendMessagePayload';
import UserAPI from '../../services';
import CustomToast from '../toast/customToast';
import { CurrentUserContext } from '../../contexts';
import useGetContactFields from '../../hooks/useGetContactFields';
import NextLevelSelection from '../selectOptionNextLevel';
import returnListFormat from '../../utils/listDataFormat';
import RichTextareaComponent from './richTextarea';

const SENDER_ID = 'Sender IDS';
const SEND_AS = [
  {
    id: 1,
    name: 'Sender IDS',
    is_default: true,
  },
  {
    id: 2,
    name: 'phonenumber',
    is_default: false,
  },
];

const SendMessage = ({ setShowMessageForm }) => {
  const userAPI = new UserAPI();
  const parentRef = useRef(null);

  const queryClient = useQueryClient();
  const { currentUser } = useContext(CurrentUserContext);
  const { data: inboundNumbersData } = useFetchOrgInboundNumbers();

  let requestedSenderIDs = false;
  const history = useHistory();

  let approvedSenderIDs = useMemo(() => [], []);
  const searchContactRef = React.useRef();

  const handleResetInputField = () => {
    searchContactRef.current.value = '';
  };

  const dataQueries = useFetchSenderIDWithDetails();
  const [showContactsInfo, setShowContactsInfo] = useState(false);
  const { fieldHeaders } = useGetContactFields({
    isMessageForm: true,
  });

  const [headerMentions, setHeaderMentions] = useState(['name', 'phonenumber']);

  const [activeSenderIDIndex, setActiveSenderIDIndex] = useState(0);
  const [textMessage, setTextMessage] = useState('');
  const [showContactMDropdown, setShowContactMDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [fetchEnable, setFetchEnable] = useState(false);
  const [, setUnapprovedNumbers] = useState(0);
  const [, setInvalidNumbers] = useState(0);
  const [fileName, setFileName] = useState('');
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [duplicates, setDuplicates] = useState([]);
  const [duplicateGroups, setDuplicateGroups] = useState([]);
  const [isChangingSenderId, setIsChangingSenderId] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(null);
  const [selectedUploadGroupContact, setSelectedUploadGroupContact] = useState(
    []
  );
  const [activeTab, setActiveTab] = useState(SENDER_ID);
  const [selectedInboundNumber, setSelectedInboundNumber] = useState(null);

  const [showContactError, setShowContactError] = useState(null);

  const isMainUser = currentUser?.main || false;

  const [fileWithWarning, setFileWithWarning] = useState([]);
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (payload) => userAPI.sendMessage(payload),
  });

  const fetchContactsStatistics = () => userAPI.getContactStats();

  const { data: statistic } = useQuery({
    queryKey: ['contactsStatistics'],
    queryFn: fetchContactsStatistics,
  });

  const { data: fieldsData } = useQuery({
    queryKey: ['get_all_fields'],
    queryFn: () => userAPI.getContactFields(),
  });

  const totalContactsGroups = selectedGroupIds?.reduce(
    (sum, group) => sum + group.contacts_count,
    0
  );

  const totalUploadedGroups = selectedUploadGroupContact?.reduce(
    (sum, group) => sum + group.contacts.length,
    0
  );

  const addContactFields = useCallback(() => {
    const allFields =
      fieldsData?.contact_fields?.map((item) => item.name) || [];
    // Add all fields to headerMentions
    setHeaderMentions((prev) => [
      ...new Set([...prev, ...allFields, 'name', 'phonenumber']),
    ]);
  }, [fieldsData?.contact_fields]);

  const getSelectedContactTypeLength = () => {
    const contactTypes = ['active', 'archived'];
    return contactTypes.reduce((acc, type) => {
      const count = statistic?.[`${type}_count`] || 0;
      return acc + (selectedContactType.includes(type) ? count : 0);
    }, 0);
  };

  const selectedContactsLength = getSelectedContactTypeLength();

  const totalContacts =
    selectedContacts.length +
    selectedContactsLength +
    totalContactsGroups +
    totalUploadedGroups;

  const { data: orgData, isFetching: orgDataIsFetching } = useFetchOrg({
    enabled: true,
  });

  const { contactsData, groupsData, isLoading, isAnyContactPresent } =
    useGlobalContactSearch({
      searchValue,
      fetchEnable,
    });

  const inUseOrgData = orgData?.orgs.filter((org) => org.in_use)[0];

  const clearAllContacts = () => {
    setSelectedContactType([]);
    setDuplicateGroups([]);
    setSelectedContacts([]);
    setSelectedUploadGroupContact([]);
    setSelectedGroupIds([]);
    setInvalidNumbers(0);
    setDuplicates([]);
    setUnapprovedNumbers(0);
    setTextMessage('');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleUpdateHeaderMentions = useCallback(() => {
    if (Array.isArray(fieldHeaders)) {
      setHeaderMentions((prev) => {
        const uniqueHeaders = new Set([...prev, ...fieldHeaders]);
        const newHeadersArray = Array.from(uniqueHeaders);

        if (newHeadersArray.length !== prev.length) {
          return newHeadersArray;
        }

        return prev;
      });
    }
  }, [fieldHeaders]);

  useEffect(() => {
    handleUpdateHeaderMentions();
  }, [handleUpdateHeaderMentions]);

  if (dataQueries?.length > 0) {
    const senderIDsWithDetails = dataQueries.map((dataQuery) => {
      if (dataQuery.data) return dataQuery.data;
      return null;
    });

    const hasNoNulls = senderIDsWithDetails.every(
      (element) => element !== null
    );
    if (hasNoNulls) {
      requestedSenderIDs = true;
      // Filter only senderIDs with at least one country approved
      approvedSenderIDs = senderIDsWithDetails
        .filter((senderIDItem) =>
          senderIDItem.countries.some((item) => item.status === 'approved')
        )
        .filter((senderIDItem) => senderIDItem.org_id === inUseOrgData?.id);
    }
  }

  const isUploadedContact =
    selectedUploadGroupContact.length > 1
      ? true
      : selectedUploadGroupContact[0]?.contacts?.length > 0;

  useEffect(() => {
    if (!isChangingSenderId) {
      const defaultSenderIdIndex = approvedSenderIDs.findIndex(
        (senderId) => senderId.is_default
      );
      setActiveSenderIDIndex(defaultSenderIdIndex);

      setSelectedInboundNumber(inboundNumbersData?.codes[0]?.number);
    }
  }, [
    approvedSenderIDs,
    inUseOrgData?.id,
    inboundNumbersData?.codes,
    isChangingSenderId,
  ]);

  let activeSenderID = null;
  const hasApprovedSenderIDs = approvedSenderIDs.length > 0 || false;
  if (hasApprovedSenderIDs)
    activeSenderID = approvedSenderIDs[activeSenderIDIndex];

  const messageLength = calculateMessageLength(textMessage, []);

  // Search through Groups, Active Contacts, Groups

  const removeRealContact = (inputData) => {
    const currentRecipients = [...selectedContacts];
    // here we are checking if the contact type is present in the selectedContactType array
    // if it is present we remove it from the array
    if (inputData?.contact_type) {
      const index = selectedContactType.findIndex(
        (contactType) => contactType === inputData.contact_type
      );
      if (index > -1) {
        selectedContactType.splice(index, 1);
      }
      setSelectedContactType(selectedContactType);
    }

    if (inputData?.id) {
      const index = selectedGroupIds.findIndex(
        (group) => group.id === inputData.id
      );
      if (index > -1) {
        selectedGroupIds.splice(index, 1);
      }
      setSelectedGroupIds(selectedGroupIds);
    }

    const index = currentRecipients.findIndex(
      (recipient) => recipient.phone_number === inputData.phone_number
    );
    if (index > -1) {
      currentRecipients.splice(index, 1);
    }
    setSelectedContacts(currentRecipients);
  };

  const removeGroupsId = (inputData) => {
    const currentRecipients = [...selectedGroupIds];
    const index = currentRecipients.findIndex(
      (recipient) => recipient.id === inputData.id
    );
    if (index > -1) {
      currentRecipients.splice(index, 1);
    }
    if (selectedGroupIds?.length <= 1) {
      addContactFields();
    }
    setSelectedGroupIds(currentRecipients);
  };

  const removeUploadedGroups = (inputData) => {
    // extract fields from the input data
    const { headerArray } = inputData;
    //  remove the fields from the headerMentions expect ["name", "phonenumber"]

    setHeaderMentions((prev) => {
      const updatedHeaders = prev.filter(
        (header) => !headerArray.includes(header)
      );
      return updatedHeaders;
    });

    setShowErrorMessage(null);

    const updatedRecipients = selectedUploadGroupContact.filter(
      (recipient) => recipient.group_name !== inputData.group_name
    );

    setSelectedUploadGroupContact(updatedRecipients);
  };

  const removeFileWithWarning = (inputData) => {
    const currentFileWithWarning = [...fileWithWarning];
    const index = currentFileWithWarning.findIndex(
      (recipient) => recipient.group_name === inputData.group_name
    );
    if (index > -1) {
      currentFileWithWarning.splice(index, 1);
    }
    setFileWithWarning(currentFileWithWarning);
  };

  const handleContactSelection = (contact) => {
    if (!contact) return;

    const {
      contact_type: contactType,
      phone_number: phoneNumber,
      group_id: groupId,
      name,
      contacts_count: contactsCount,
      ...rest
    } = contact;

    if (contactType) {
      setSelectedContactType((prev) =>
        prev.includes(contactType) ? prev : [...prev, contactType]
      );
    }

    if (phoneNumber) {
      const exists = selectedContacts.some(
        (item) => item.phone_number === phoneNumber
      );

      if (!exists) {
        if (hasApprovedSenderIDs && activeSenderID) {
          const countryStatusMap = {};
          activeSenderID.countries.forEach((country) => {
            // if(country.status === country.countryCode)
            countryStatusMap[country.iso_code] = country.status;
          });

          const { country } = parsePhoneNumber(phoneNumber);
          if (country) {
            const countryStatus = countryStatusMap[country];
            if (countryStatus === 'approved') {
              setShowErrorMessage(null);
              handleResetInputField();
              setSelectedContacts((prev) => [
                ...prev,
                {
                  ...rest,
                  ...(name && { name }),
                  ...(phoneNumber && { phone_number: phoneNumber }),
                },
              ]);
            } else {
              setShowErrorMessage('Unapproved phone number');
              setTimeout(() => setShowErrorMessage(null), 4000);
              setUnapprovedNumbers((prev) => prev + 1);
            }
          }
        }
      } else {
        setShowErrorMessage('phone number already added');
        setTimeout(() => setShowErrorMessage(null), 4000);
        setDuplicates((prev) => [
          ...prev,
          {
            ...(name && { name }),
            ...(phoneNumber && { phone_number: phoneNumber }),
            ...(contactType && { contact_type: contactType }),
          },
        ]);
      }
    }

    if (groupId) {
      const exists = selectedGroupIds.some((item) => item.id === groupId);
      if (selectedGroupIds.length >= 0) {
        addContactFields();
      }

      if (!exists) {
        setSelectedGroupIds((prev) => [
          ...prev,
          {
            ...(groupId && { id: groupId }),
            ...(name && { name }),
            ...(contactsCount && { contacts_count: contactsCount }),
          },
        ]);
      } else {
        setDuplicateGroups((prev) => {
          setShowErrorMessage('You have already added this group');
          setTimeout(() => setShowErrorMessage(null), 4000);
          const groupIdExists = prev.some((item) => item.id === groupId);
          return groupIdExists
            ? prev
            : [
                ...prev,
                {
                  ...(groupId && { id: groupId }),
                  ...(name && { name }),
                  ...(contactsCount && { contacts_count: contactsCount }),
                },
              ];
        });
      }
    }
  };

  const goBankToSenderIDs = () => {
    history.push('/account?tab=organization_details', { id: 'addSenderId' });
    // Wait for a brief moment for the route change to complete
    setTimeout(() => {
      const section = document.getElementById('senderIDs');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleContactManualInput = (query) => {
    const validTel = query.replace(/[^\d.+]/g, '');
    const newTel = formatPhone(validTel);

    if (isValidPhoneNumber(newTel)) {
      setShowErrorMessage(null);
      handleContactSelection({ phone_number: query });
    } else if (query.length > 12) {
      setInvalidNumbers((prev) => prev + 1);
      setShowErrorMessage('Invalid phone number');
    }
  };

  let debounceTimer;
  const handleSearch = (e) => {
    const query = e.target.value;
    if (!query) return;

    setShowContactError('');
    e.preventDefault();
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      setFetchEnable(false);
      setTimeout(() => {
        if (query.startsWith('+')) {
          setShowContactMDropdown(false);
          handleContactManualInput(query);
        } else {
          setShowContactMDropdown(true);
          setShowErrorMessage(null);
          setSearchValue(query);
          setFetchEnable(true);
        }
        setTimeout(() => {
          setSearchValue('');
        }, 0);
      }, 500);
    }, 800);
  };

  const handleOnGroupUpload = (newData, name, newFileWithError) => {
    setShowErrorMessage(null);
    if (!newData) return;

    const formatNewData = {
      ...newData,
      group_name: name || fileName || 'Uploaded Contacts',
    };

    if (newFileWithError) {
      setFileWithWarning((prev) => {
        const fileIndex = prev.findIndex((file) => file.group_name === name);
        if (fileIndex !== -1) {
          const updatedFiles = [...prev];
          updatedFiles[fileIndex] = {
            ...updatedFiles[fileIndex],
            duplicates: [
              ...updatedFiles[fileIndex].duplicates,
              ...newFileWithError.duplicates.filter(
                ({ phone_number: phoneNumber }) =>
                  !updatedFiles[fileIndex].duplicates.some(
                    (contact) => contact.phone_number === phoneNumber
                  )
              ),
            ],
            invalid_contacts: updatedFiles[fileIndex].invalid_contacts,
            unapproved_numbers: updatedFiles[fileIndex].unapproved_numbers,
            emptyFieldsCount: updatedFiles[fileIndex].emptyFieldsCount,
          };
          return updatedFiles;
        }
        // file not found, add new file
        const updatedFiles = [...prev, newFileWithError];
        return updatedFiles;
      });
    }

    setSelectedUploadGroupContact((prevGroups) => {
      if (!prevGroups || !formatNewData) {
        return prevGroups;
      }

      const groupIndex = prevGroups.findIndex(
        (group) => group.group_name === formatNewData.group_name
      );

      if (groupIndex !== -1) {
        const existingGroup = prevGroups[groupIndex];

        if (existingGroup.group_name === formatNewData.group_name) {
          setShowErrorMessage('You have already uploaded this file');
          setTimeout(() => setShowErrorMessage(null), 2000);
        }

        const updatedContacts = [
          ...existingGroup.contacts,
          ...formatNewData.contacts.filter(
            ({ phone_number: phoneNumber }) =>
              !existingGroup.contacts.some(
                (contact) => contact.phone_number === phoneNumber
              )
          ),
        ];

        const updatedGroups = [...prevGroups];
        updatedGroups[groupIndex] = {
          ...existingGroup,
          contacts: updatedContacts,
        };

        return updatedGroups;
      }

      if (formatNewData.contacts.length > 0) {
        return [...prevGroups, formatNewData];
      }

      return prevGroups;
    });
  };

  const checkContactTypes = (types) => {
    const hasActive = types.includes('active');
    const hasArchived = types.includes('archived');

    if (hasActive && hasArchived) {
      return 'all';
    }
    return types.join(', ');
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!activeSenderID) return false;

    const contactTypes = checkContactTypes(selectedContactType);
    const payload = constructSendMessagePayload(
      selectedContacts,
      selectedGroupIds,
      selectedUploadGroupContact
    );

    const allRecipients = payload.recipients;
    const recipientsData = keepOnlyUsedFields(textMessage, allRecipients);

    const finalPayload = {
      ...payload,
      sender:
        activeTab === SENDER_ID ? activeSenderID.name : selectedInboundNumber,
      text: textMessage,
      skip_recipients_validation: true,
    };

    // Remove recipients field if empty
    if (finalPayload.recipients.length === 0) {
      delete finalPayload.recipients;
    }

    // Add contact_types if there are any
    if (contactTypes.length > 0) {
      finalPayload.contacts_type = contactTypes;
    }

    // Add recipientsData if there are any
    if (recipientsData.length > 0) {
      finalPayload.recipients = recipientsData;
    }

    mutate(finalPayload, {
      onSuccess: async () => {
        clearAllContacts();
        setShowToastMessage(true);
        await queryClient.invalidateQueries({ queryKey: ['messaging'] });
        setTimeout(() => {
          setShowToastMessage(false);
          setShowMessageForm(false);
        }, 3000);
      },
      onError: () => {
        setShowToastErrorMessage(true);
      },
    });

    return true;
  };

  const {
    isUploading,
    progressPercentage,
    uploadedRecipients,
    handleFileUpload,
    clearAllUploadedContacts,
  } = useUploadContact({
    activeSenderID,
    hasApprovedSenderIDs,
    setFileName,
    setShowContactError,
    setHeaderMentions,
    handleOnGroupUpload,
  });

  const handleChangeSenderId = (e, selectedItem, key) => {
    e.preventDefault();
    clearAllContacts();
    setShowContactsInfo(false);
    clearAllUploadedContacts();
    setIsChangingSenderId(true);

    switch (key) {
      case 'phonenumber':
        inboundNumbersData?.codes?.forEach((number, index) => {
          if (number.number === selectedItem.number) {
            setActiveSenderIDIndex(index);
          }
        });
        setSelectedInboundNumber(selectedItem?.number);
        break;
      default:
        approvedSenderIDs.forEach((senderId, index) => {
          if (senderId.name === selectedItem.name) {
            setActiveSenderIDIndex(index);
          }
        });
    }
  };

  const handleFocus = () => {
    const textarea = parentRef?.current;
    if (textarea) {
      textarea.style.borderColor = '#0EB6A2';
    }
  };

  const handleBlur = () => {
    const textarea = parentRef?.current;
    if (textarea) {
      textarea.style.borderColor = '#EDEDED';
    }
  };

  const importedContactLength = uploadedRecipients.length;

  return (
    <Bloc
      borderRadius="10px"
      width="100%"
      maxWidth={1130}
      p="20px"
      bg="white"
      margin="0 auto"
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    >
      <Bloc
        position="absolute"
        display="flex"
        width={['100%', 'fit-content']}
        style={{ zIndex: 100, margin: '-100px auto', right: 0, left: 0 }}
        justifyContent="center"
      >
        {showToastMessage && (
          <CustomToast
            message="Message sent successfully"
            handleHideToast={() => setShowToastMessage(false)}
            status="success"
            showToast={showToastMessage}
            closed
          />
        )}
        {isError && (
          <CustomToast
            message={error?.response?.data?.error?.message}
            handleHideToast={() => setShowToastErrorMessage(false)}
            status="error"
            showToast={showToastErrorMessage}
            closed
          />
        )}
      </Bloc>
      <Bloc
        style={{
          gap: '10px',
          borderBottom: '1px solid #DFDBDB',
        }}
      >
        {' '}
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          style={{
            gap: '10px',
          }}
          alignItems="center"
          pb="17px"
        >
          <Box>
            <Text
              as="h1"
              fontSize={['16px', '21px']}
              color="black"
              style={{
                fontWeight: '500',
              }}
            >
              Send message
            </Text>
          </Box>
          <Bloc boxSizing="border-box" width={[1, 'fit-content']}>
            <NextLevelSelection
              tab={activeTab}
              hasSearch
              minWidth={['100%', '200px']}
              subject="Send as"
              onSelectionChange={handleChangeSenderId}
              activeItemIndex={activeSenderIDIndex}
              contentChildren={
                <Flex
                  style={{
                    gap: '5px',
                  }}
                  pt="10px"
                >
                  {SEND_AS.map((elem) => {
                    const isActiveContacts = activeTab === elem.name;

                    return (
                      <Bloc
                        key={elem.id}
                        bg={isActiveContacts ? '#5EEBDA30' : '#F9F9F9'}
                        borderRadius="50px"
                        px="10px"
                        py="5px"
                        fontSize="9px"
                        color={isActiveContacts ? 'accents.10' : 'surfaces.3'}
                        border={
                          isActiveContacts
                            ? '1px solid #0EB6A2'
                            : '1px solid #E0E0E0'
                        }
                        fontWeight={isActiveContacts ? '500' : '400'}
                        style={{
                          whiteSpace: 'nowrap',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleTabChange(elem.name);
                        }}
                      >
                        {elem.name}
                      </Bloc>
                    );
                  })}
                </Flex>
              }
              dropdownItems={returnListFormat(
                approvedSenderIDs,
                inboundNumbersData?.codes,
                activeTab
              )}
            />
          </Bloc>
        </Flex>
        <>
          {isMainUser && (
            <>
              {!hasApprovedSenderIDs && !orgDataIsFetching && (
                <>
                  {!hasApprovedSenderIDs && requestedSenderIDs ? (
                    <Bloc
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="warning"
                      borderRadius={2}
                      p={14}
                      mt={0}
                      boxSizing="border-box"
                      width={[1, 1, 'calc(100% - 230px)']}
                    >
                      <Text fontSize={12} color="accents.8">
                        Sender ID request pending. Please refrain from using it
                        until approved.
                      </Text>
                    </Bloc>
                  ) : (
                    <Bloc
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      bg="warning"
                      borderRadius={2}
                      p={14}
                      mb={15}
                      boxSizing="border-box"
                    >
                      <Text fontSize={12} color="accents.8">
                        Before you can send an SMS, you must create a Sender ID.
                        A Sender ID is a unique identifier that ensures your
                        recipients can recognize the sender of the message.
                        It&apos;s a quick and easy process to set up your Sender
                        ID.
                      </Text>

                      <Box ml={[3, 40]}>
                        <Button
                          type="button"
                          profile="accentLight"
                          size="small"
                          onClick={goBankToSenderIDs}
                          data-testid="add-senderID"
                        >
                          ADD SENDER ID
                        </Button>
                      </Box>
                    </Bloc>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Bloc>

      <Flex
        flexWrap={['wrap', 'nowrap']}
        justifyContent="space-between"
        alignItems="stretch"
        py="17px"
        style={{
          gap: '10px',
          borderBottom: '1px solid #DFDBDB',
        }}
      >
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth="560px"
          style={{
            gap: '10px',
          }}
        >
          <Text>Select your audience</Text>
          <Bloc
            maxHeight="104px"
            border={`1px solid ${showContactMDropdown ? '#0EB6A2' : '#EDEDED'}`}
            borderRadius="10px"
          >
            <Flex
              flexDirection="column"
              style={{
                gap: '15px',
              }}
            >
              <ContactMessageDropdown
                fileName={fileName}
                statistic={statistic}
                importedContactLength={importedContactLength}
                progressPercentage={progressPercentage}
                showContactsInfo={showContactsInfo}
                showDropdown={showContactMDropdown}
                searchContactRef={searchContactRef}
                groupsData={groupsData}
                contactsData={contactsData}
                isAnyContactPresent={isAnyContactPresent}
                isLoading={isLoading}
                handleFileUpload={handleFileUpload}
                setShowContactsInfo={setShowContactsInfo}
                handleResetInputField={handleResetInputField}
                handleSearch={handleSearch}
                setFetchEnable={setFetchEnable}
                setShowDropdown={setShowContactMDropdown}
                handleContactSelection={handleContactSelection}
              />
            </Flex>
          </Bloc>

          {showErrorMessage && (
            <Bloc mt="-10px">
              <Text fontSize="10px" color="red">
                {showErrorMessage}
              </Text>
            </Bloc>
          )}

          {showContactError && (
            <Bloc mt="-10px">
              <Text fontSize="10px" color="red">
                {showContactError}
              </Text>
            </Bloc>
          )}
          <AddedRecipients
            statistic={statistic}
            duplicateGroups={duplicateGroups}
            duplicates={duplicates}
            fileWithWarning={fileWithWarning}
            isUploadedContact={isUploadedContact}
            removeFileWithWarning={removeFileWithWarning}
            removeGroupsId={removeGroupsId}
            removeRealContact={removeRealContact}
            removeUploadedGroups={removeUploadedGroups}
            selectedContactType={selectedContactType}
            selectedContacts={selectedContacts}
            selectedGroupIds={selectedGroupIds}
            selectedUploadGroupContact={selectedUploadGroupContact}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="stretch"
          // minHeight="106px"
          style={{
            gap: '10px',
            minHeight: '106px',
          }}
        >
          <Text>Compose your message</Text>

          <Flex
            ref={parentRef}
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
            style={{
              border: '1px solid #EDEDED',
              borderRadius: '10px',
              backgroundColor: hasApprovedSenderIDs
                ? 'white'
                : 'rgba(247, 249, 251, 0.50)',
            }}
          >
            <RichTextareaComponent
              hasApprovedSenderIDs={hasApprovedSenderIDs}
              textMessage={textMessage}
              setTextMessage={setTextMessage}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              headerMentions={headerMentions}
            />

            {textMessage.length > 0 && (
              <Bloc
                as="p"
                fontSize={1}
                fontWeight={300}
                color="#6E717D"
                mr="10px"
                px="10px"
              >
                {checkMentionUsed(textMessage) && 'approximately'}{' '}
                {messageLength} characters -{' '}
                {calculateSMSCounter(messageLength)} Message item
                {calculateSMSCounter(messageLength) > 1 ? 's' : null}
              </Bloc>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Bloc>
        <Flex
          justifyContent="flex-end"
          flexWrap="wrap"
          pt="17px"
          style={{
            gap: '10px',
          }}
        >
          <Button
            disabled={
              !hasApprovedSenderIDs ||
              !totalContacts ||
              !textMessage ||
              isUploading
            }
            onClick={onFormSubmit}
            profile="accentLight"
            loading={isPending}
            size="contact"
          >
            Send message
            {totalContacts > 0 && ` to ${totalContacts} Contacts`}
          </Button>
          <Button
            onClick={() => setShowMessageForm(false)}
            profile="secondary"
            size="contact"
          >
            Cancel
          </Button>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default SendMessage;
