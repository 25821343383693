import React from 'react';
import { Bloc } from 'blocjs';
import Layout from '../layout';

const ContactContainer = ({
  children,
  footer = null,
  toast,
  defaultToast = true,
  overflow = false,
}) => (
  <Layout>
    {defaultToast ? (
      <Bloc
        bg="white"
        mb="20px"
        borderRadius="10px"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
      >
        {toast}
      </Bloc>
    ) : (
      toast
    )}

    <Bloc
      bg="white"
      borderRadius="8px"
      overflow={overflow ? 'hidden' : ''}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    >
      {children}
    </Bloc>

    {footer && <>{footer}</>}
    <Bloc mt="60px" />
  </Layout>
);

export default ContactContainer;
