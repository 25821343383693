const returnListFormat = (lists, inboundLists, key) => {
  switch (key) {
    case 'phonenumber':
      return inboundLists.map((item) => ({
        ...item,
        value: item.number,
      }));
    default:
      return lists.map((item) => ({
        ...item,
        value: item.name,
      }));
  }
};

export default returnListFormat;
