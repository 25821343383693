import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';

import { Icon, Spinner } from '../../../../components';
import { convertUTCToLocalTime } from '../../../../utils';

const ListSenderIds = ({ data = {}, isLoading = false }) => {
  const history = useHistory();
  const location = useLocation();

  const senderIDS = data?.sender_ids || [];

  const gotToSenderIdDetailsHandler = (e, senderId) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    // Add a new search parameter
    searchParams.set('senderId', senderId);
    // Update the URL with the new search params
    history.push({
      search: searchParams.toString(),
    });
  };

  const renderSenderIDs = () =>
    senderIDS.map((item) => (
      <Bloc
        as="tr"
        key={item.id}
        textAlign="left"
        borderBottom="1px solid #DFDBDB"
        onClick={(e) => gotToSenderIdDetailsHandler(e, item.id)}
        style={{ cursor: 'pointer' }}
      >
        <Bloc as="td" py={3}>
          {item.name || ''}
        </Bloc>
        <Bloc as="td" py={3}>
          {item.is_default && (
            <Flex alignItems="center">
              <Bloc as="span" fontSize={12} color="lightDark" mr={2}>
                Default
              </Bloc>
              <Icon
                name="check-broken-circle"
                height={14}
                color="black"
                size={12}
                dimension="24x24"
              />
            </Flex>
          )}
        </Bloc>

        <Bloc as="td" py={12} textAlign="right">
          {format(convertUTCToLocalTime(item.created_at), 'MMM dd, yyyy')}
        </Bloc>
      </Bloc>
    ));

  return (
    <Bloc overflowX="auto">
      {!isLoading && data && (
        <Bloc
          mb={0}
          as="table"
          width="100%"
          style={{ borderCollapse: 'collapse' }}
        >
          <Bloc as="thead">
            <Bloc
              as="tr"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
            >
              <Bloc as="td" py={3}>
                SENDER ID
              </Bloc>
              <Bloc as="td" py={3} />
              <Bloc
                as="td"
                py={12}
                width={['auto', 'auto', 'auto']}
                textAlign="right"
              >
                CREATED DATE
              </Bloc>
            </Bloc>
          </Bloc>
          <Bloc as="tbody">
            {!isLoading ? renderSenderIDs() : <Spinner size="large" />}
          </Bloc>
        </Bloc>
      )}
    </Bloc>
  );
};

export default ListSenderIds;
