import { Flex } from 'blocjs';
import React from 'react';
import Button from '../../button';

const FieldActionButtons = ({ action, handleSwitchPage, handleActions }) => {
  switch (action.trigger) {
    case 'contact-field':
      return (
        <Flex
          alignItems="center"
          style={{
            columnGap: '6px',
          }}
        >
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
            }}
            profile="accentLight"
            size="contact"
            onClick={() =>
              handleActions('edit-field', action?.result, 'delete-field')
            }
          >
            Delete
          </Button>
          {action.result && action?.result.length === 1 && (
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
                cursor: 'pointer',
              }}
              profile="secondary"
              size="contact"
              onClick={() => handleSwitchPage('edit-field', action?.result[0])}
            >
              Edit
            </Button>
          )}
        </Flex>
      );
    default:
      return (
        <Button
          style={{
            textTransform: 'initial',
            fontWeight: 'normal',
          }}
          profile="accentLight"
          size="contact"
          onClick={() => {
            handleSwitchPage('new-field', 'new-field');
          }}
        >
          Add a new field
        </Button>
      );
  }
};

export default FieldActionButtons;
