import React, { useEffect, useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Text,
  Card,
  Button,
  Inputfield,
  ToggleSwitch,
  Toast,
} from '../../../../../components';
import UserAPI from '../../../../../services';
import SelectionInput from '../../../../../components/selection';

const areaCoverages = [
  { name: 'Local', value: 'local' },
  { name: 'International', value: 'international' },
];

const AddOrUpdateTelco = ({
  cancelTelcoUpdate,
  isEditingTelco,
  countryData,
  telcoData = undefined,
  localServices,
  intlServices,
  setIsAddOrUpdateTelco,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();
  const [showToast, setShowToast] = useState(false);
  const [hasDirectionConnection, setHasDirectionConnection] = useState(false);
  const [primaryServiceType, setPrimaryServiceType] = useState(
    areaCoverages[0]
  );
  const [inUseServiceType, setInUseServiceType] = useState(areaCoverages[0]);
  const [primaryLocalServ, setPrimaryLocalServ] = useState(localServices[0]);
  const [localServ, setLocalServ] = useState(localServices[0]);
  const [primaryInterServ, setPrimaryInterServ] = useState(null);
  const [interServ, setInterServ] = useState(null);
  const { mutate, status, error } = useMutation({
    mutationFn: (payload) => userAPI.createTelco(payload),
  });
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({ mutationFn: (payload) => userAPI.updateTelco(payload) });

  useEffect(() => {
    if (telcoData) {
      setHasDirectionConnection(telcoData?.has_direct_connection || false);
      if (telcoData.primary_local_service_id != null) {
        const currentPrimLocalServ = localServices.find(
          (item) => item.id === telcoData.primary_local_service_id
        );
        setPrimaryLocalServ(currentPrimLocalServ);
      }

      if (telcoData.local_service_id != null) {
        const currentLocalServ = localServices.find(
          (item) => item.id === telcoData.local_service_id
        );
        setLocalServ(currentLocalServ);
      }

      if (telcoData.primary_international_service_id != null) {
        setPrimaryServiceType(areaCoverages[1]);
        const currentPrimIntServ = intlServices.find(
          (item) => item.id === telcoData.primary_international_service_id
        );
        setPrimaryInterServ(currentPrimIntServ);
      }

      if (telcoData.international_service_id != null) {
        setInUseServiceType(areaCoverages[1]);
        const currentPrimServ = intlServices.find(
          (item) => item.id === telcoData.international_service_id
        );
        setPrimaryInterServ(currentPrimServ);
      }
    }
  }, [localServices, intlServices, telcoData]);

  const onFormSubmit = async (data) => {
    if (data && countryData) {
      const countryId = countryData.id;
      const payload = {
        name: data.telco_name,
        mnc: data.mnc,
        country_id: countryId,
        primary_local_service_id: primaryLocalServ ? primaryLocalServ.id : null,
        primary_international_service_id: primaryInterServ
          ? primaryInterServ.id
          : null,
        local_service_id: localServ ? localServ.id : null,
        international_service_id: interServ ? interServ.id : null,
        has_direct_connection: hasDirectionConnection,
      };

      if (isEditingTelco) {
        const { id } = telcoData;
        const request = { bodyReq: payload, id };
        await mutateUpdate(request, {
          onSuccess: async () => {
            setShowToast(true);
            await queryClient.invalidateQueries({
              queryKey: ['countryRoutes', countryId],
            });
            setTimeout(async () => {
              setShowToast(false);
              setIsAddOrUpdateTelco(false);
            }, 3000);
          },
          onError: () => {
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 8000);
          },
        });
      } else {
        await mutate(payload, {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['countryRoutes', countryId],
            });
            setTimeout(() => {
              cancelTelcoUpdate();
            }, 3000);
          },
        });
      }
    }
  };

  const activePrimaryLocalServIndex = primaryLocalServ
    ? localServices.findIndex((item) => item.name === primaryLocalServ.name)
    : 0;
  const activeLocalServIndex = localServ
    ? localServices.findIndex((item) => item.name === localServ.name)
    : 0;
  const activePrimaryInterServIndex = primaryInterServ
    ? intlServices.findIndex((item) => item.name === primaryInterServ.name)
    : 0;
  const activeInterServIndex = interServ
    ? intlServices.findIndex((item) => item.name === interServ.name)
    : 0;
  const isPrimaryLocalServSelected = primaryServiceType.value === 'local';
  const isInUseLocalServSelected = inUseServiceType.value === 'local';

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)} mt={32}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message="Telco created successfully" status="success" />
      )}
      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast message="Service updated successfully" status="success" />
      )}
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            {isEditingTelco ? 'Edit' : 'Add New'} Telco
          </Text>
        </Box>
      </Flex>
      <Box ml={[0, '16%']} mb={15}>
        <Card>
          <Flex justifyContent="start" mb={6}>
            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Telco Name"
                  defaultValue={telcoData?.name || ''}
                  placeholder="Add name of the telco here"
                  hint="Fill in name of the telco"
                  ref={register({ required: 'Telco name is required' })}
                  error={errors?.telco_name?.message}
                />
              </Box>

              <Box width={1}>
                <Inputfield
                  type="text"
                  name="MNC"
                  defaultValue={telcoData?.mnc || ''}
                  placeholder="Add MNC of the telco here"
                  hint="Fill in MNC"
                  ref={register({ required: 'MCC is required' })}
                  error={errors?.mcc?.message}
                />
              </Box>

              <Bloc width={1} mb={6}>
                <Bloc
                  my={3}
                  fontSize={2}
                  fontWeight="bold"
                  display="inline-block"
                  as="label"
                >
                  Primary Service
                </Bloc>
                <SelectionInput
                  dropDownItems={areaCoverages}
                  activeItemIndex={areaCoverages.findIndex(
                    (item) => item.value === primaryServiceType.value
                  )}
                  onSelectionChange={(newStatus) => {
                    setPrimaryServiceType(newStatus);
                    if (newStatus.value === 'local') {
                      setPrimaryLocalServ(localServices[0]);
                      setPrimaryInterServ(null);
                    } else {
                      setPrimaryLocalServ(null);
                      setPrimaryInterServ(intlServices[0]);
                    }
                  }}
                />
              </Bloc>

              <Bloc width={1}>
                <Bloc
                  my={3}
                  fontSize={2}
                  fontWeight="bold"
                  display="inline-block"
                  as="label"
                >
                  In Use Service
                </Bloc>
                <SelectionInput
                  dropDownItems={areaCoverages}
                  activeItemIndex={areaCoverages.findIndex(
                    (item) => item.value === inUseServiceType.value
                  )}
                  onSelectionChange={(newStatus) => {
                    setInUseServiceType(newStatus);
                    if (newStatus.value === 'local') {
                      setLocalServ(localServices[0]);
                      setInterServ(null);
                    } else {
                      setLocalServ(null);
                      setInterServ(intlServices[0]);
                    }
                  }}
                />
              </Bloc>
            </Bloc>

            <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
              <Box width={1}>
                <Inputfield
                  type="text"
                  name="Region Code"
                  defaultValue={countryData?.region_code || ''}
                  placeholder="Region Code"
                  hint="Country Region code"
                  disabled
                />
              </Box>

              <Box width={1} mb={6}>
                <ToggleSwitch
                  label="Direct Connection"
                  id="directConnection"
                  checked={hasDirectionConnection}
                  onChange={(e) => setHasDirectionConnection(e.target.checked)}
                />
              </Box>

              <Bloc width={1} mb={6}>
                <Bloc
                  my={3}
                  fontSize={2}
                  fontWeight="bold"
                  display="inline-block"
                  as="label"
                >
                  {primaryServiceType.name} service
                </Bloc>
                <SelectionInput
                  dropDownItems={
                    isPrimaryLocalServSelected ? localServices : intlServices
                  }
                  activeItemIndex={
                    isPrimaryLocalServSelected
                      ? activePrimaryLocalServIndex
                      : activePrimaryInterServIndex
                  }
                  onSelectionChange={(newStatus) =>
                    isPrimaryLocalServSelected
                      ? setPrimaryLocalServ(newStatus)
                      : setPrimaryInterServ(newStatus)
                  }
                  // disabled={!isEditingTelco}
                  hasSearch
                />
              </Bloc>

              <Bloc width={1}>
                <Bloc
                  my={3}
                  fontSize={2}
                  fontWeight="bold"
                  display="inline-block"
                  as="label"
                >
                  {inUseServiceType.name} service
                </Bloc>
                <SelectionInput
                  dropDownItems={
                    isInUseLocalServSelected ? localServices : intlServices
                  }
                  activeItemIndex={
                    isInUseLocalServSelected
                      ? activeLocalServIndex
                      : activeInterServIndex
                  }
                  onSelectionChange={(newStatus) =>
                    isInUseLocalServSelected
                      ? setLocalServ(newStatus)
                      : setInterServ(newStatus)
                  }
                  // disabled={!isEditingTelco}
                  hasSearch
                />
              </Bloc>
            </Bloc>
          </Flex>

          <Flex mt={6} flexWrap="wrap">
            <Box mb={[3, 0]} mr={30}>
              <Button
                profile="accentLight"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                size="contact"
                type="submit"
                data-testid="updateDetailsBtn"
              >
                {isEditingTelco ? 'Save Changes' : 'Add Telco'}
              </Button>
            </Box>
            <Box mb={0}>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                profile="secondary"
                size="contact"
                type="button"
                onClick={() => cancelTelcoUpdate()}
                data-testid="cancel-show-details"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Card>
      </Box>
    </Bloc>
  );
};

export default AddOrUpdateTelco;
