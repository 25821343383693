import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Box, Flex, Bloc } from 'blocjs';
import posthog from 'posthog-js';

import UserAPI from '../../../services';
import { CurrentUserContext } from '../../../contexts';
import { Text, Button, Toast, Inputfield } from '../../../components';

const ChangeOrgNameForm = ({ orgName, setShowChangeOrgNameForm }) => {
  const userAPI = new UserAPI();
  const { currentUser } = useContext(CurrentUserContext);
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();

  const { mutate, status, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateOrgName(payload),
  });

  const onFormSubmit = async (req) => {
    if (currentUser.email) {
      mutate(
        { organizationName: req.organization_name },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['org'] });
            await queryClient.invalidateQueries({ queryKey: ['selfOrg'] });

            setTimeout(() => {
              setShowChangeOrgNameForm(false);
            }, 3000);
            posthog.capture('updated organization name', {
              property: { organization_name: req.organization_name },
            });
          },
        }
      );
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Text variant="h5"> Change Organization Name </Text>
      <Box mt={5} />

      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast
          message="Organization name updated successfully"
          status="success"
        />
      )}
      <Flex flexDirection={['column', 'row']}>
        <Box width={[1, 350]}>
          <Inputfield
            type="text"
            placeholder="Your organization name"
            name="Organization Name"
            data-testid="org-name"
            ref={register({
              minLength: {
                value: 2,
                message: 'Organization name should at least be 2 characters',
              },
              pattern: {
                value: /^[A-Za-z0-9? ,_-]+$/,
                message: 'Please enter only alphanumeric characters',
              },
            })}
            error={errors?.organization_name?.message}
            defaultValue={orgName}
          />
        </Box>
      </Flex>
      <Flex mt={4} flexWrap="wrap">
        <Box mb={[3, 0]} mr={30}>
          <Button
            profile="accentLight"
            size="small"
            loading={isPending}
            data-testid="update-btn"
            type="submit"
          >
            Change Name
          </Button>
        </Box>
        <Box mb={0}>
          <Button
            profile="primary"
            size="small"
            onClick={() => setShowChangeOrgNameForm(false)}
            data-test="cancel-btn"
          >
            Cancel
          </Button>
        </Box>
      </Flex>
    </Bloc>
  );
};

export default ChangeOrgNameForm;
