/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Bloc } from 'blocjs';
import GithubIcon from 'mdi-react/GithubIcon';
import { GoogleLogin } from 'react-google-login';
import posthog from 'posthog-js';

import { AuthContext, CurrentUserContext } from '../../contexts';
import UserAPI from '../../services';
import {
  Inputfield,
  Button,
  Layout,
  Text,
  Toast,
  Spinner,
  Icon,
} from '../../components';
import { GITHUB_CLIENT_ID, REDIRECT_URI, GOOGLE_CLIENT_ID } from '../../utils';
import { useAcceptInvite, useFetchUserSelf } from '../../hooks';
import MagicLinkComponent from './magicLink';
import EmailSentComponent from '../../components/magicLink/emailSentState';
import useGitHubLogin from '../../hooks/useGithubLogin';

const Login = () => {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');

  const searchQueries = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  const userAPI = new UserAPI();
  const { push } = useHistory();
  const [inviteData, setInviteData] = useState({});
  const [hasInvitation, setHasInvitation] = useState(false);
  const [userToken, setUserToken] = useState(false);
  const [userAthProviderToken, setAthProviderToken] = useState(false);
  const [magicLinkClicked, setMagicLinkClicked] = useState(false);
  const [loginUsingEmail, setLoginUsingEmail] = useState(true);
  const [manualLogin, setManualLogin] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { addUserToken, addLoginType } = useContext(AuthContext);
  const { addCurrentUser, currentUser } = useContext(CurrentUserContext);
  const [sentEmail, setSentEmail] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const { mutate, isError, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.getToken(payload),
  });

  const checkRequestId = !!searchQueries?.request_id;

  const { mutate: getTokenFromMagicLink, isError: getTokenFromMagicError } =
    useMutation({
      mutationFn: (payload) => userAPI.getTokenFromMagicLink(payload),
    });

  const {
    mutate: googleLoginMutate,
    isError: isGoogleLoginError,
    error: googleLoginError,
    isPending: isGoogleLoginLoading,
  } = useMutation({
    mutationFn: (payload) => userAPI.loginWithGoogle(payload),
  });

  const {
    data: acceptData,
    status: acceptStatus,
    error: acceptError,
  } = useAcceptInvite(inviteData, hasInvitation);

  const { mutate: verifyUser } = useMutation({
    mutationFn: (payload) => userAPI.resendConfirmationEmail(payload),
  });

  const { data: userData, isLoading: userLoading } = useFetchUserSelf(
    userToken || userAthProviderToken
  );

  useEffect(() => {
    if (checkRequestId && !currentUser) {
      getTokenFromMagicLink(searchQueries, {
        onSuccess: (data) => {
          const { token } = data;
          if (token) {
            addUserToken(token);
            addLoginType('socials');
            push('/dashboard');
          }
        },
      });
    }
  }, [checkRequestId, currentUser]);

  useEffect(() => {
    if (userData && !userLoading) {
      const user = userData;

      if (userToken || userAthProviderToken) {
        const token = userToken || userAthProviderToken;

        if (!user.confirmed_at) {
          verifyUser(token, {
            onSuccess: () => {
              push(`/confirmation?unconfirmedUser=1&email=${user?.email}`);
            },
          });
        } else {
          addUserToken(token);
          addCurrentUser(user);
        }
      }

      posthog.capture('Logged in successfully with credentials', {
        property: user,
      });
    }
  }, [
    addCurrentUser,
    addUserToken,
    push,
    userAthProviderToken,
    userData,
    userLoading,
    userToken,
    verifyUser,
  ]);

  useEffect(() => {
    if (location.inviteData) {
      setInviteData(location.inviteData);
      setHasInvitation(true);
    }
  }, [location, location.inviteData, setHasInvitation, setInviteData]);

  const handleLoginWithGoogle = async (googleData) => {
    if (googleData && googleData.tokenId) {
      addLoginType('socials');

      const payload = {
        token: googleData.tokenId,
      };
      await googleLoginMutate(payload, {
        onSuccess: (data) => {
          const res = data;
          if (res?.data?.token) {
            setAthProviderToken(res?.data?.token);
            posthog.capture('Logged in successful with Google', {
              property: payload,
            });
          }
        },
      });
    }
  };
  const { githubLoginError, isGithubLoginError, isGithubLoginLoading } =
    useGitHubLogin(code, addLoginType, setAthProviderToken);

  const onSubmit = async (dataInputs) => {
    addLoginType('credential');

    const payload = {
      emailOrUsername: dataInputs.email_or_username,
      password: dataInputs.password,
    };
    mutate(payload, {
      onSuccess: async (data) => {
        if (data && data.token) {
          const { token } = data;
          setUserToken(token);
        }
      },
    });
  };

  return (
    <Layout pt={[3, '5vh']}>
      {isError && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {getTokenFromMagicError && (
        <Toast
          message="Magic link expired or invalid. Request a new one and use the latest email."
          status="error"
          closed
        />
      )}

      {isGithubLoginError && (
        <Toast
          message={githubLoginError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {isGoogleLoginError && (
        <Toast
          message={googleLoginError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {acceptStatus === 'error' && (
        <Toast
          message={acceptError?.response?.data?.error?.message}
          status="error"
          closed
        />
      )}
      {acceptStatus === 'success' && (
        <Toast message={acceptData?.message} status="success" closed />
      )}

      <Flex
        flexDirection={['column', 'row']}
        justifyContent={['start', 'space-around']}
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
        style={{
          height: '100%',
          gap: '24px',
        }}
      >
        <Bloc maxWidth={300}>
          <Text variant="h3" as="h1">
            Login
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            Instant communication platform for you!{' '}
          </Text>
        </Bloc>
        <Bloc width={[1, 1, 512]}>
          {sentEmail ? (
            <EmailSentComponent
              setSentEmail={setSentEmail}
              email={emailInput}
            />
          ) : (
            <Bloc
              borderRadius="8px"
              boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
              bg="white"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
              padding="48px 32px"
            >
              {manualLogin && (
                <Flex
                  flexDirection="column"
                  style={{
                    gap: '15px',
                  }}
                >
                  <Bloc
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setManualLogin((prev) => !prev);
                    }}
                  >
                    <Icon name="arrow" size={24} dimension="24x24" />
                  </Bloc>
                  <Bloc fontWeight="500">Login manually</Bloc>
                </Flex>
              )}

              {manualLogin ? (
                <Bloc
                  as="form"
                  onSubmit={handleSubmit(onSubmit)}
                  id="login_form"
                >
                  <Inputfield
                    type="text"
                    name="Email or Username"
                    ref={register({
                      required:
                        'Without email or username — there is no handshake',
                    })}
                    onFocus={() => setLoginUsingEmail(true)}
                    hint="Unique handle for your account"
                    error={errors?.username_or_email?.message}
                    data-testid="email-or-username"
                  />
                  <Inputfield
                    type="password"
                    name="Password"
                    onFocus={() => setLoginUsingEmail(true)}
                    ref={register({
                      required: 'You cannot enter without a key',
                    })}
                    hint="The strong key you use to unlock your Pindo"
                    error={errors?.password?.message}
                    data-testid="password"
                  />

                  <Button
                    profile="accentLight"
                    size="contact"
                    loading={searchQueries?.id || isPending}
                    data-testid="loginBtn"
                  >
                    Login
                  </Button>
                  <Bloc fontSize={1} as="p">
                    Forgot password?{' '}
                    <Text
                      color="accents.1"
                      fontWeight="normal"
                      as={Link}
                      to="/forgot"
                      style={{ cursor: 'pointer' }}
                    >
                      Reset it here
                    </Text>
                  </Bloc>
                </Bloc>
              ) : (
                <>
                  <Flex
                    flexDirection={['column', 'column', 'row']}
                    justifyContent="space-between"
                  >
                    <Bloc
                      bg="#000"
                      width={[1, 1, '48.5%']}
                      borderRadius="5px"
                      color="#fff"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Bloc
                        as="a"
                        textDecoration="none"
                        color="#fff"
                        textTransform="uppercase"
                        cursor="default"
                        display="flex"
                        alignItems="center"
                        height="40px"
                        data-testid="github-login"
                        href={`https://github.com/login/oauth/authorize?scope=user&client_id=${GITHUB_CLIENT_ID}&redirect_uri=${REDIRECT_URI}`}
                      >
                        <GithubIcon />
                        <Bloc fontWeight="400" fontSize="14px" ml="5px">
                          Login with GitHub{' '}
                          {isGithubLoginLoading && <Spinner size="large" />}
                        </Bloc>
                      </Bloc>
                    </Bloc>
                    <Bloc width={[1, 1, '48.5%']} mt={[4, 4, 0]}>
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={handleLoginWithGoogle}
                        onFailure={handleLoginWithGoogle}
                        cookiePolicy="single_host_origin"
                        className="google-login"
                      >
                        {' '}
                        Login with Google{' '}
                        {isGoogleLoginLoading && <Spinner size="large" />}
                      </GoogleLogin>
                    </Bloc>
                  </Flex>
                  <MagicLinkComponent
                    mode="login"
                    setSentEmail={setSentEmail}
                    setUsingEmail={setLoginUsingEmail}
                    setEmailInput={setEmailInput}
                    handleSetMagicLink={() =>
                      setMagicLinkClicked((prev) => !prev)
                    }
                    magicLinkClicked={magicLinkClicked}
                    usingEmail={loginUsingEmail}
                  />
                  <Bloc fontSize={1}>
                    The magic link is for a smooth, password free login, you can
                    also
                    <Text
                      onClick={() => {
                        setManualLogin((prev) => !prev);
                      }}
                      color="accents.1"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      fontWeight="normal"
                    >
                      {' '}
                      login manually{' '}
                    </Text>
                    with a password
                  </Bloc>
                </>
              )}
            </Bloc>
          )}

          {(!manualLogin || !sentEmail) && (
            <Bloc fontSize={1} as="p" mt="30px">
              New to Pindo?{' '}
              <Text
                color="accents.1"
                fontWeight="normal"
                as={Link}
                to="/register"
                style={{ cursor: 'pointer' }}
              >
                Create an account
              </Text>
            </Bloc>
          )}
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Login;
