/* eslint-disable react/no-array-index-key */

import React from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { Bloc, Flex } from 'blocjs';

const BillingTable = ({ columns, data, onRowClick }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy
    );

  return (
    <Bloc
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
      borderRadius={3}
      zIndex={1}
      paddding={0}
      bg="surfaces.0"
      overflowX="auto"
      mb={15}
    >
      <Bloc
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <Bloc as="thead">
          {headerGroups.map((headerGroup, indx) => (
            <Bloc
              key={indx}
              as="tr"
              height="30"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <Bloc
                  key={column.id}
                  as="td"
                  py={3}
                  px={4}
                  data-testid={column.render('Header')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Flex
                    justifyContent={
                      index === headerGroup.headers.length - 1
                        ? 'flex-end'
                        : 'flex-start'
                    }
                    alignItems="center"
                  >
                    {column.render('Header')}
                  </Flex>
                </Bloc>
              ))}
              {/* <Bloc as="td" py={3} textAlign="right" /> */}
            </Bloc>
          ))}
        </Bloc>
        <Bloc as="tbody" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Bloc
                key={row.id + row.original.date}
                as="tr"
                textAlign="left"
                borderBottom="1px solid #DFDBDB"
                data-test="data-row"
                style={{
                  cursor: 'pointer',
                }}
                height="40px"
                {...row.getRowProps({
                  style: { cursor: 'pointer' },
                  onClick: (e) => {
                    onRowClick(row, e);
                  },
                })}
              >
                {row.cells.map((cell) => (
                  <Bloc
                    as="td"
                    py={1}
                    px={4}
                    {...cell.getCellProps()}
                    key={cell.column.id}
                  >
                    {cell.render('Cell')}
                  </Bloc>
                ))}
              </Bloc>
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default BillingTable;
