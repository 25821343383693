import React, { useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import posthog from 'posthog-js';
import { startOfWeek } from 'date-fns';
import SelectionInput from '../../components/selection';
import { dashboardProductOptions } from '../../constants';
import PeriodFilter from '../../components/periodFilter';
import PeriodAndProductSwitch from '../../components/periodAndProductSwitch';
import { Text } from '../../components';

const GeneralStats = ({
  period,
  setPeriod,
  setStartDate,
  handleChangePeriod,
  startDate,
}) => {
  const [selectedProduct, setselectedProduct] = useState([]);

  const periodFunctions = {
    day: (date) => date,
    week: (date) => startOfWeek(date, { weekStartsOn: 1 }),
  };

  const handleSelectionChange = (chosenItemIndex, chosenItem) => {
    const updatedStatuses = [...selectedProduct];
    updatedStatuses[chosenItemIndex] = chosenItem.name;
    setselectedProduct(updatedStatuses);
  };

  const handlePeriodSwitch = (item) => {
    let startingDate;

    switch (item) {
      case 'Today':
        startingDate = new Date();
        break;
      case 'Week':
        startingDate = periodFunctions.week && periodFunctions.week(new Date());

        break;
      case 'Month':
        startingDate = new Date();

        break;
      case 'Quarter':
        startingDate = new Date();

        break;
      case 'Year':
        startingDate = new Date();

        break;
      default:
    }
    posthog.capture('Dashboard Stats', { property: item });
    setPeriod(item);
    setStartDate(startingDate);
  };

  return (
    <Flex
      flexDirection="column"
      style={{
        rowGap: '20px',
      }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text as="h1" style={{ lineHeight: 'initial' }} fontSize={28}>
          {' '}
          Dashboard{' '}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        style={{
          gap: '16px',
        }}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <PeriodFilter
          startDate={startDate}
          handlePeriodSwitch={handlePeriodSwitch}
          period={period}
          setPeriod={setPeriod}
          handleChangePeriod={handleChangePeriod}
        />
        <Bloc display={['block', 'none']}>
          {dashboardProductOptions.map((product, index) => {
            const activeItemIndex = dashboardProductOptions.findIndex(
              (item) => item.name === product.name
            );

            return (
              <SelectionInput
                isDashboard
                key={product.id}
                dropDownItems={dashboardProductOptions}
                activeItemIndex={activeItemIndex > -1 ? activeItemIndex : 0}
                onSelectionChange={(newProduct) =>
                  handleSelectionChange(index, newProduct)
                }
              />
            );
          })}
        </Bloc>

        <PeriodAndProductSwitch
          startDate={startDate}
          handleChangePeriod={handleChangePeriod}
          period={period}
          handleSelectionChange={handleSelectionChange}
        />
      </Flex>
    </Flex>
  );
};

export default GeneralStats;
