import React, { useState, useEffect, createRef } from 'react';
import { Bloc } from 'blocjs';

const DragAndDrop = ({
  handleDropFile,
  dropAreaRef,
  children,
  hasBorder = false,
}) => {
  const [isFileOver, setIsFileOver] = useState(false);
  const fileOverAreaRef = createRef();

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = () => {
    const fileOveArea = fileOverAreaRef.current;
    if (fileOveArea) setIsFileOver(true);
  };

  const handleDragOut = () => {
    const fileOveArea = fileOverAreaRef.current;
    if (fileOveArea) setIsFileOver(false);
  };

  const handleDrop = (e) => {
    const dt = e.dataTransfer;
    const { files } = dt;
    handleDropFile(files);
  };

  useEffect(() => {
    const dropArea = dropAreaRef.current;
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, handleDragIn, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, handleDragOut, false);
    });
    dropArea.addEventListener('drop', handleDrop, false);

    return () => {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, handleDragIn, false);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, handleDragOut, false);
      });
      dropArea.removeEventListener('drop', handleDrop, false);
    };
  });

  return (
    <Bloc
      display="inline-block"
      position="relative"
      width="100%"
      padding={`${isFileOver ? '25px 0' : '0'}`}
      ref={fileOverAreaRef}
      data-test="dropRef"
      htmlFor="fileElem"
    >
      {isFileOver && (
        <Bloc
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={9999}
          borderRadius="4px"
          backgroundColor="#ffffff"
          border={hasBorder ? '1px dashed' : 'none'}
          borderColor="rgb(211 211 211)"
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          data-test="drop-area"
        >
          <Bloc
            position="absolute"
            top="50%"
            right={0}
            left={0}
            textAlign="center"
            color="grey"
            fontSize={1}
            transform="translateY(-50%)"
            style={{
              transform: 'translateY(-50%)',
            }}
          >
            <Bloc>Drop your file here</Bloc>
          </Bloc>
        </Bloc>
      )}
      {children}
    </Bloc>
  );
};
export default DragAndDrop;
