import React, { useState, useMemo } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { CheckedBox, Icon, Button } from '../../../../../components';
import ServicesTable from './table';

const ServiceStatus = ({ status }) => {
  let statusColor = '#088C7D';
  let statusBg = '#EBF9F5';
  let statusText = 'Active';
  if (!status) {
    statusColor = '#FF3049';
    statusBg = '#FFD6DB';
    statusText = 'Inactive';
  }
  return (
    <Bloc display="flex" p={0} alignItems="center" fontSize={14} pl={0}>
      <Bloc pl={0} style={{ whiteSpace: 'initial' }} width={1}>
        <Bloc
          as="span"
          color={statusColor}
          bg={statusBg}
          padding="4px 10px"
          borderRadius={27}
          fontSize={10}
          fontWeight="bold"
          display="block"
          boxSizing="border-box"
          width="fit-content"
          style={{ textTransform: 'uppercase' }}
        >
          {statusText}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

const LocalServices = ({
  localServicesData,
  isLoading,
  onAddService,
  onEditService,
}) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const servicesList = localServicesData;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        floatRight: false,
        accessor: (row) => row.name,
      },
      {
        Header: 'Username',
        floatRight: false,
        accessor: (row) => row.username,
      },
      {
        Header: 'URL',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <Bloc
            as="a"
            href={original.url}
            target="_blank"
            color="#757F84"
            fontWeight="500"
            style={{ textDecoration: 'underline' }}
          >
            Linkoverhere
          </Bloc>
        ),
      },
      {
        Header: 'Price',
        floatRight: false,
        accessor: (row) => row.price,
      },
      {
        Header: 'Is Operational',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <ServiceStatus status={original.is_operational} />
        ),
      },
    ],
    []
  );

  const onCheckBox = (service, e) => {
    e.stopPropagation();
    const items = [...selectedServices];
    const index = items.findIndex((item) => item.id === service.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedServices(items);
    } else {
      setSelectedServices([service]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedServices.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const servicesData = useMemo(() => servicesList, [servicesList]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    // const serviceId = row.original.id;
    // history.push('/admin/manage/service-details', { serviceId });
  };

  return (
    <Bloc mt={6}>
      <Flex justifyContent="end">
        <Bloc>
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
              float: 'right',
            }}
            profile="accentLight"
            size="small"
            onClick={onAddService}
            data-test="update-btn"
          >
            Add new service
          </Button>
        </Bloc>
      </Flex>
      <Box mt={27} />
      <ServicesTable
        columns={columns}
        data={servicesData}
        onRowClick={onRowClick}
        setCheckbox={setCheckbox}
        onCheckBox={onCheckBox}
        onEditService={onEditService}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        isLoading={isLoading}
      />
    </Bloc>
  );
};

export default LocalServices;
