import React from 'react';
import { Flex } from 'blocjs';
import Button from '../../button';

const GroupActionButtons = ({ action, handleActions, handleSwitchPage }) => {
  switch (action.trigger) {
    case 'edit-group':
      return (
        <Flex
          alignItems="center"
          style={{
            columnGap: '6px',
          }}
        >
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
            }}
            profile="accentLight"
            size="contact"
            onClick={() => handleSwitchPage('edit-group', action?.result[0])}
          >
            edit group
          </Button>
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
              color: 'black',
              cursor: 'pointer',
            }}
            profile="secondary"
            size="contact"
            onClick={() =>
              handleActions('edit-group', action?.result, 'delete-group')
            }
          >
            Delete group
          </Button>
        </Flex>
      );
    default:
      return null;
  }
};

export default GroupActionButtons;
