export const copyToClipboard = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

function getEmailUsername(email) {
  // Check if the email includes "@"
  if (email?.includes('@')) {
    return email.split('@')[0];
  }
  return email;
}

export const textTruncate = (str, length, ending) => {
  const newStr = getEmailUsername(str);
  let newLength = length;
  let newEnding = ending;
  if (length == null) {
    newLength = 40;
  }
  if (ending == null) {
    newEnding = '';
  }
  if (newStr?.length > newLength) {
    return `${
      newStr.substring(0, newLength - newEnding.length) + newEnding
    }...`;
  }
  return newStr;
};

export const calculateSMSCounter = (messageLength) => {
  const SMS_CHARACTER_LIMIT = 160;
  const smsCounter = Math.ceil(messageLength / SMS_CHARACTER_LIMIT);
  return smsCounter;
};

export const messageStatuses = [
  { label: 'Delivered', value: 'DELIVRD' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Undelivered', value: 'UNDELIV' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Failed', value: 'FAILED' },
];

export const removeBlueBorder = {
  outline: 'none',
  borderColor: '#DEE1E7',
  boxShadow: 'none',
};

export const selectStatusStyles = {
  control: (styles) => ({
    ...styles,
    height: '40px',
    ...removeBlueBorder,
    ':hover': removeBlueBorder,
    ':active': removeBlueBorder,
  }),
  option: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    color: '#3E484D',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#E4FFFC',
      color: '#757F84',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: 'default',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#3E484D',
  }),
  placeholder: (styles) => ({ ...styles, color: '#DFDBDB' }),
};
