const constructSendMessagePayload = (
  selContacts,
  selGroupIds,
  selUploadGroupContact
) => {
  // Utility function to map contacts and add 'phonenumber' field
  const mapContacts = (contacts) =>
    contacts.map((contact) => {
      const { phone_number: phoneNumber, ...rest } = contact;
      return {
        ...rest,
        phonenumber: phoneNumber,
      };
    });

  // Extract recipients from selectedContacts and selectedUploadGroupContact.contacts
  const contactsTo = mapContacts(selContacts);

  const uploadedContacts = selUploadGroupContact.flatMap((group) =>
    mapContacts(group.contacts)
  );

  // Combine contacts and remove duplicates based on 'phonenumber'
  const combinedContacts = [...contactsTo, ...uploadedContacts];
  const uniqueContactsMap = new Map();

  combinedContacts.forEach((contact) => {
    uniqueContactsMap.set(contact.phonenumber, contact);
  });

  const recipients = Array.from(uniqueContactsMap.values());

  // Extract unique group_ids from selectedGroupIds
  const groupIdsSet = new Set(selGroupIds.map((group) => group.id));
  const groupIds = Array.from(groupIdsSet);

  const payload = {
    recipients,
  };
  if (groupIds.length > 0) {
    payload.group_ids = groupIds;
  }

  return payload;
};

export default constructSendMessagePayload;
