import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toFloat } from 'validator';
import {
  Button,
  Inputfield,
  Text,
  ToggleSwitch,
} from '../../../../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { useFetchServices } from '../../../../../hooks';
import DefaultDropdownWithSearch from '../../../../../components/dropDown';
import { attachUsername } from '../../../../../utils';
import UserAPI from '../../../../../services';
import CustomToast from '../../../../../components/toast/customToast';

const DISCOUNT_REGEX = /^(0(\.\d+)?|0?(\.\d+))$/;

const EditDeal = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const userAPI = new UserAPI();
  const dealData = location.state?.deal;
  const dealStart = dealData?.start ? new Date(dealData?.start) : '';
  const dealEnd = dealData?.end ? new Date(dealData?.end) : '';
  const orgId = new URLSearchParams(location.search).get('orgId');

  const dealInUse = dealData?.in_use;

  const [startDate, setStartDate] = useState(dealStart);
  const [endDate, setEndDate] = useState(dealEnd);
  const [isInUse, setIsInUse] = useState(dealInUse || false);
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showToastNotificationMessage, setShowToastNotificationMessage] =
    useState(false);
  const [selectedService, setSelectedService] = useState({
    name: 'Select a Service',
    value: true,
  });

  const { data: serviceData, isLoading } = useFetchServices();
  const { register, handleSubmit, errors, reset } = useForm();

  const localServicesFormatted = attachUsername(serviceData || []);

  const {
    mutate,
    isError,
    error: createDealError,
    isSuccess,
    isPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.createNewDeal(payload),
  });

  const handleBackToDeals = () => {
    const searchParams = new URLSearchParams(location.search);
    // Delete the specific search parameter
    searchParams.delete('deal');
    // Update the URL with the modified search params
    history.push({
      search: searchParams.toString(),
    });
  };

  const handleFilterFormDates = (type, date) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleSelectedItem = (e, item) => {
    e.stopPropagation();
    setSelectedService(item);
  };

  const switchInUseDeal = (e) => {
    setIsInUse(e.target.checked);
  };

  const onSubmit = (dataInputs) => {
    if (!startDate || !endDate) {
      setErrorMessage('Please select start and end date');
      return;
    }
    const payload = {
      discount: toFloat(Number(dataInputs.discount).toFixed(2)),
      start: format(startDate, 'yyyy-MM-dd'),
      end: format(endDate, 'yyyy-MM-dd'),
      org_id: orgId,
      in_use: isInUse,
    };

    if (dataInputs.bonus) {
      payload.bonus = toFloat(Number(dataInputs.bonus).toFixed(2));
    }
    if (!selectedService?.value) {
      payload.service_id = selectedService?.id;
    }

    mutate(payload, {
      onSuccess: () => {
        setShowToastNotificationMessage(true);
        setStartDate('');
        setEndDate('');
        queryClient.invalidateQueries({ queryKey: ['orgDeals'] });
        setTimeout(() => {
          handleBackToDeals();
        }, 1000);
        reset();
        setSelectedService({
          name: 'Select a Service',
          value: true,
        });
      },
      onError: () => {
        setShowToastNotificationMessage(true);
      },
    });
  };

  return (
    <Bloc
      px={5}
      pt="20px"
      pb="40px"
      style={{
        borderTop: '1px solid #DEE1E7',
      }}
    >
      <Bloc
        position="absolute"
        display="flex"
        width={['100%', 'fit-content']}
        style={{ zIndex: 100, margin: '-100px auto', right: 0, left: 0 }}
        justifyContent="center"
      >
        {isError && (
          <CustomToast
            message={createDealError?.response?.data?.error?.message}
            handleHideToast={() => setShowToastNotificationMessage(false)}
            status="error"
            showToast={showToastNotificationMessage}
            closed
          />
        )}

        {isSuccess && (
          <CustomToast
            message="Deal Created successfully"
            handleHideToast={() => setShowToastNotificationMessage(false)}
            status="success"
            showToast={showToastNotificationMessage}
            closed
          />
        )}
      </Bloc>

      <Bloc as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          alignItems="start"
          flexWrap={['wrap', 'no-wrap']}
          style={{
            gap: '16px',
          }}
        >
          <Flex
            style={{
              gap: '16px',
            }}
          >
            <Box>
              <Inputfield
                type="text"
                name="discount"
                ref={register({
                  required: "Discount can't be empty",
                  pattern: {
                    value: DISCOUNT_REGEX,
                    message: 'Enter a valid discount less than 1',
                  },
                })}
                defaultValue={dealData?.discount || ''}
                placeholder="Add discount here"
                hint="Fill in discount"
                error={errors?.discount?.message}
              />
            </Box>
            <Box>
              <Inputfield
                type="text"
                name="bonus"
                ref={register({})}
                defaultValue={dealData?.bonus || ''}
                placeholder="Add bonus here"
                hint="Fill in bonus"
                error={errors?.bonus?.message}
              />
            </Box>
          </Flex>

          <Flex
            flexDirection="column"
            alignItems="start"
            style={{
              gap: '10px',
            }}
          >
            <Text fontSize="14px" fontWeight="500">
              Start and End Date
            </Text>
            <Flex
              alignItems="center"
              style={{
                gap: '16px',
              }}
            >
              <Box style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}>
                <DatePicker
                  placeholderText="Start date"
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleFilterFormDates('start', date)}
                  calendarClassName="calendar"
                />
              </Box>
              <Box style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}>
                <DatePicker
                  placeholderText="End date"
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  minDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleFilterFormDates('end', date)}
                  calendarClassName="calendar"
                  calendarStartDay={1}
                />
              </Box>
            </Flex>
          </Flex>
          <Box width={['100%', '25%']}>
            {!isLoading && localServicesFormatted.length > 0 && (
              <Flex
                flexDirection="column"
                style={{
                  gap: '12px',
                }}
                justifyContent="space-between"
              >
                <Text fontWeight="500" fontSize="14px">
                  Select Service{' '}
                </Text>
                <DefaultDropdownWithSearch
                  hasSearch
                  isItemsDropdown={isItemsDropdown}
                  setIsItemsDropdown={setIsItemsDropdown}
                  handleSelectedItem={handleSelectedItem}
                  services={localServicesFormatted}
                  serviceInUseObj={selectedService}
                />
              </Flex>
            )}
          </Box>
          <Box>
            <ToggleSwitch
              label="Deal Active"
              id="directConnection"
              checked={isInUse}
              onChange={switchInUseDeal}
            />
          </Box>
        </Flex>
        <Bloc mt="6px">
          <Text color="red" fontSize="12px">
            {errorMessage}
          </Text>
        </Bloc>
        <Flex
          mt={6}
          flexWrap="wrap"
          style={{
            gap: '20px',
          }}
        >
          <Box>
            <Button
              profile="accentLight"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              size="contact"
              type="submit"
              loading={isPending}
              disabled={isPending}
            >
              Save
            </Button>
          </Box>
          <Box>
            <Button
              profile="secondary"
              size="contact"
              type="button"
              onClick={handleBackToDeals}
            >
              Cancel
            </Button>
          </Box>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default EditDeal;
