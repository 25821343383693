import React, { useState, useMemo, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import ClickAwayListener from 'react-click-away-listener';
import { types as CardType } from 'credit-card-type';
import posthog from 'posthog-js';

import { useSavedCards } from '../../hooks';
import UserAPI from '../../services';
import Button from '../button';
import Toast from '../toast';
import Icon from '../icon';
import CheckedBox from '../checkedbox';
import Spinner from '../spinner';
import { Chevron } from '../carets';
import { ErrorBelowInput, Inputfield } from '../inputs';
import { formatAmount, currencyFormat } from '../../utils';
import CardImg from '../../assets/images/card.svg';
import VisaImg from '../../assets/images/visa.svg';
import MasterCardImg from '../../assets/images/mastercard.svg';
import ConfirmDeletion from '../confirmDeletion';
import useResponsiveFontSize from './useResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          color: '#4a4a4a',
          fontSize,
          fontFamily: 'Space Grotesk, sans-serif',
          '::placeholder': {
            color: '#DFDBDB',
            fontFamily: 'Space Grotesk, sans-serif',
          },
        },
        invalid: {
          color: '#EC0000',
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardPaymentForm = ({
  callToast,
  removeSavedMethod,
  handleHideAccountForm,
  isLoadingRemoveCard,
}) => {
  const userAPI = new UserAPI();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const [isPaying, setIsPaying] = useState(false);
  const [isRememberCard, setIsRememberCard] = useState(false);
  const [hasSelectedCards, setHasSelectedCards] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const { savedCards, isLoadingExistingCards } = useSavedCards();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [confirmCardDelete, setConfirmCardDelete] = useState([]);
  const { mutate: mutateCreateIntent, error: createIntentError } = useMutation({
    mutationFn: (paymentIntentPayload) =>
      userAPI.createPaymentIntent(paymentIntentPayload),
  });
  const {
    mutate: mutateExistingCardPay,
    isError: isExistingCardError,
    error: existingCardError,
    isLoading: isPayingWithExistingCard,
    status: existingCardPayStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.existingCardPayment(payload),
  });

  const watchAmount = watch('amount_in_usd');

  let inputAmountInUSD = 0;
  if (watchAmount) {
    inputAmountInUSD = formatAmount(watchAmount);
  }

  useEffect(() => {
    let isMounted = true;
    if (savedCards?.cards && savedCards.cards.length > 0) {
      const { cards } = savedCards;
      if (isMounted) {
        setSelectedCard(cards[cards.length - 1]);
        setHasSelectedCards(true);
      }
    } else if (isMounted) {
      setHasSelectedCards(false);
      setSelectedCard({});
    }
    return () => {
      isMounted = false;
    };
  }, [savedCards]);

  const setCheckbox = (checked) => {
    if (checked) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return <Icon name="square" color="#959DA2" size={16} />;
  };

  const updateDataAfterSuccessPay = async () => {
    setIsPaying(false);
    // Show a successful paid message to your customer
    callToast('Successfully added funds', 'success');
    posthog.capture('Funds added with a card');
    setTimeout(async () => {
      // update the balance on the UI
      await queryClient.invalidateQueries({ queryKey: ['userBalance'] });
      // There's a risk of the customer closing the window before callback execution
      // Set up a webhook or plugin to listen for the payment_intent.succeeded event
      // to save the card to a Customer
      // The PaymentMethod ID can be found on result.paymentIntent.payment_method
      // update the billing history
      await queryClient.invalidateQueries({ queryKey: ['userBillingHistory'] });
      // update the saved cards
      if (isRememberCard) {
        await queryClient.invalidateQueries({ queryKey: ['rememberedCards'] });
      }
    }, 3000);
  };

  const payWithCard = async (data) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        callToast('Payment has not yet loaded.', 'error');
        return;
      }
      setIsPaying(true);
      if (!hasSelectedCards) {
        // create payment intent on the server
        const paymentIntentPayload = {
          amount: parseFloat(data.amount_in_usd),
          save_card: isRememberCard,
        };
        await mutateCreateIntent(paymentIntentPayload, {
          onSuccess: async (res) => {
            if (res) {
              const clientSecret = res.data.client_secret;
              // confirm the payment on the client
              if (clientSecret) {
                const cardDetails = elements.getElement(CardElement);
                const result = await stripe.confirmCardPayment(clientSecret, {
                  payment_method: {
                    card: cardDetails,
                  },
                });
                if (result.error) {
                  // Show error to your customer
                  callToast(result.error.message, 'error');
                  setIsPaying(false);
                } else if (result.paymentIntent.status === 'succeeded') {
                  cardDetails.clear();
                  updateDataAfterSuccessPay();
                  reset();
                }
              }
            }
          },
        });
      } else {
        const payload = {
          amount: parseFloat(data.amount_in_usd),
          last_numbers: selectedCard.last_numbers,
        };
        await mutateExistingCardPay(payload, {
          onSuccess: (res) => {
            if (res?.data?.client_secret) {
              updateDataAfterSuccessPay();
            }
          },
        });
      }
    } catch (err) {
      callToast(`${err.message}, Please try again`, 'error');
      setIsPaying(false);
    }
  };

  const displayCardImg = (cardName) => {
    let cardTypeImg = CardImg;
    if (cardName === CardType.MASTERCARD) {
      cardTypeImg = MasterCardImg;
    } else if (cardName === CardType.VISA) {
      cardTypeImg = VisaImg;
    }
    return cardTypeImg;
  };

  const onDelete = (cardLastNumbers, e) => {
    e.stopPropagation();
    const items = [...confirmCardDelete];
    const index = items.findIndex((item) => item === cardLastNumbers);
    if (index > -1) {
      items.splice(index, 1);
      setConfirmCardDelete(items);
    } else {
      setConfirmCardDelete([...items, cardLastNumbers]);
    }
  };

  return (
    <Bloc width={1}>
      {createIntentError && (
        <Toast
          message={createIntentError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {isExistingCardError && (
        <Toast
          message={existingCardError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {existingCardPayStatus === 'error' && (
        <Toast message={existingCardError?.message} status="error" />
      )}

      <Bloc as="form" onSubmit={handleSubmit(payWithCard)}>
        <Flex
          alignItems="stretch"
          flexWrap={['wrap', 'nowrap']}
          style={{
            gap: '20px',
          }}
        >
          <Bloc width={1}>
            {!hasSelectedCards ? (
              <Bloc>
                <Bloc
                  as="label"
                  fontWeight={500}
                  fontSize={14}
                  my={3}
                  display="block"
                >
                  Card Details
                </Bloc>
                <Bloc
                  bg="#fff"
                  border="1px solid"
                  borderColor="#D3D3D3"
                  borderRadius="4px"
                  py={['10px', '8px']}
                  px={3}
                  height="40px"
                  maxHeight={40}
                >
                  <CardElement options={options} />
                </Bloc>
              </Bloc>
            ) : (
              <Bloc>
                <Bloc
                  as="label"
                  fontSize={16}
                  fontWeight="bold"
                  mb={3}
                  display="block"
                >
                  Select saved card{' '}
                  {isLoadingExistingCards && <Spinner size="large" />}
                </Bloc>
                <ClickAwayListener
                  onClickAway={() => setIsDropdownOpen(false)}
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button
                    style={{
                      color: '#4a4a4a',
                      backgroundColor: '#fff',
                      border: '1px solid',
                      borderColor: '#D3D3D3',
                      borderRadius: '4px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '38px',
                      alignItems: 'center',
                      padding: '0px 8px',
                      overflowX: 'auto',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                    data-testid="list-cards-dropdown"
                  >
                    <Bloc
                      boxSizing="border-box"
                      display="flex"
                      justifyContent="initial"
                      style={{ cursor: 'pointer' }}
                    >
                      <Bloc
                        as="img"
                        src={displayCardImg(selectedCard.brand)}
                        width={30}
                      />
                      <Bloc
                        as="span"
                        color="surfaces.3"
                        display="block"
                        width="auto"
                        height="auto"
                        margin={0}
                        padding={0}
                        fontSize={2}
                        ml={20}
                        fontWeight="normal"
                        style={{ textTransform: 'lowercase' }}
                      >
                        <Bloc as="span" style={{ textTransform: 'capitalize' }}>
                          {selectedCard.brand}
                        </Bloc>{' '}
                        ending in {selectedCard.last_numbers}
                      </Bloc>
                    </Bloc>

                    <Chevron
                      color="surfaces.3"
                      size={20}
                      rotate={isDropdownOpen}
                    />
                  </Button>

                  {isDropdownOpen && (
                    <Box
                      position="absolute"
                      top={44}
                      width="100%"
                      right={0}
                      zIndex={999}
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        textAlign: 'right',
                        boxShadow:
                          '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                      }}
                    >
                      {savedCards.cards.map((card) => (
                        <Bloc key={card.last_numbers}>
                          <Flex
                            padding="15px 16px"
                            boxSizing="border-box"
                            borderBottom="1px solid"
                            borderColor="#D3D3D3"
                            alignItems="center"
                            justifyContent="space-between"
                            style={{
                              cursor: 'pointer',
                              background:
                                selectedCard.last_numbers === card.last_numbers
                                  ? '#E4FFFC'
                                  : 'transparent',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedCard(card);
                              setIsDropdownOpen(false);
                            }}
                            data-testid="card-item"
                          >
                            <Flex>
                              <Bloc
                                as="img"
                                src={displayCardImg(card.brand)}
                                width={30}
                              />
                              <Bloc
                                as="span"
                                color="#4a4a4a"
                                display="block"
                                width="auto"
                                height="auto"
                                margin={0}
                                padding={0}
                                fontSize={2}
                                ml={20}
                              >
                                <Bloc
                                  as="span"
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  {card.brand}
                                </Bloc>{' '}
                                ending in {card.last_numbers}
                              </Bloc>
                            </Flex>
                            {!confirmCardDelete.some(
                              (item) => item === card.last_numbers
                            ) && (
                              <Button
                                onClick={(e) => onDelete(card.last_numbers, e)}
                                style={{
                                  marginLeft: '20px',
                                  background: 'none',
                                }}
                                type="button"
                                data-testid="deleteSavedCard"
                              >
                                <Icon
                                  name="close"
                                  color="surfaces.3"
                                  size={8}
                                />
                              </Button>
                            )}
                          </Flex>
                          {confirmCardDelete.some(
                            (item) => item === card.last_numbers
                          ) && (
                            <ConfirmDeletion
                              onDelete={onDelete}
                              removeSavedMethod={removeSavedMethod}
                              lastNumber={card.last_numbers}
                              isLoading={isLoadingRemoveCard}
                            />
                          )}
                        </Bloc>
                      ))}
                    </Box>
                  )}
                </ClickAwayListener>
              </Bloc>
            )}

            {savedCards && savedCards.cards.length > 0 && (
              <Bloc
                as="p"
                pt={0}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => setHasSelectedCards(!hasSelectedCards)}
                data-testid="useAnotherCard"
              >
                {hasSelectedCards ? 'Use another card' : 'Use a saved card'}
              </Bloc>
            )}
          </Bloc>

          <Bloc width={1} pt="6px">
            <Inputfield
              py="12px"
              type="tel"
              inputmode="numeric"
              name="amount_in_usd"
              label="Amount in USD"
              id="payInUSD"
              placeholder="Minimum amount is $5"
              data-testid="amount_in_usd"
              hint="Fill in amount in USD"
              ref={register({
                required: 'Amount is required.',
                validate: { minAmount: (value) => parseFloat(value) > 4 },
              })}
              error={errors?.amount_in_usd?.message}
            />
            <Bloc mb={3}>
              {errors?.amount_in_usd?.type === 'minAmount' &&
                ErrorBelowInput('The minimum amount is $5')}
            </Bloc>
          </Bloc>
        </Flex>

        {!hasSelectedCards && (
          <Flex
            width={1}
            alignItems="center"
            py={3}
            style={{ cursor: 'pointer' }}
            onClick={() => setIsRememberCard(!isRememberCard)}
            data-testid="remember-card"
          >
            {setCheckbox(isRememberCard)}
            <Bloc as="label" ml="5px" fontSize={14}>
              Remember this card next time
            </Bloc>
          </Flex>
        )}
        <Bloc width={1} bg="#0000000F" height="1px" my="15px" />
        <Flex mt={4} flexWrap="wrap" style={{ gap: '15px' }}>
          <Button
            profile="accentLight"
            size="contact"
            style={{
              textTransform: 'capitalize',
            }}
            loading={isPaying || isPayingWithExistingCard}
            data-testid="payWithCard"
          >
            Load ${currencyFormat(inputAmountInUSD)}
          </Button>
          <Button
            profile="secondary"
            size="contact"
            data-testid="cancelPaymentWithCard"
            disable={!stripe || !elements}
            type="button"
            onClick={handleHideAccountForm}
          >
            Cancel
          </Button>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default CardPaymentForm;
