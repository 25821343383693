import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Bloc, Flex } from 'blocjs';
import Text from '../../components/text';
import AccountContainer from './container';
import { CurrentUserContext } from '../../contexts';
import OrgSettings from './orgDetails';
import Security from '../account/security';
import UserDetails from './userDetails';
import InboundNumbers from '../account/inboundNumbers';

const tabLinks = {
  personal_details: {
    name: 'Personal details',
  },
  security: {
    name: 'Security',
  },
  organization_details: {
    name: 'Organization details',
  },
  phone_numbers: {
    name: 'Phone numbers',
  },
};

const TabsComponent = ({ activeTab, handleSwitchTab }) => (
  <Flex
    alignItems="center"
    overflowX="auto"
    style={{
      columnGap: '14px',
    }}
  >
    {Object.entries(tabLinks).map(([path, { name }]) => {
      const isActive = path === activeTab;
      return (
        <Bloc
          display="flex"
          alignItems="center"
          borderBottom={
            isActive ? '3px solid #16C1AD' : '3px solid transparent'
          }
          key={path}
          pb="6px"
          style={{
            cursor: 'pointer',
            columnGap: '6px',
          }}
          onClick={() => handleSwitchTab(path, name)}
        >
          <Bloc
            as="span"
            fontSize="11px"
            color={isActive ? 'black' : 'lightDark'}
            style={{
              whiteSpace: 'nowrap',
              textTransform: 'initial',
            }}
            fontWeight={isActive ? '500' : '400'}
          >
            {name}
          </Bloc>
        </Bloc>
      );
    })}
  </Flex>
);

const Account = ({ setUniqueKey, uniqueKey }) => {
  const location = useLocation();
  const { push } = useHistory();
  const activeTab = new URLSearchParams(location.search).get('tab');
  const activePage = new URLSearchParams(location.search).get('page');

  const handleSwitchTab = (path) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', path);
    push({
      search: searchParams.toString(),
    });
  };

  switch (activePage) {
    default:
      return (
        <Flex
          width="100%"
          flexDirection="column"
          style={{
            gap: '20px',
          }}
        >
          <AccountContainer pt={60}>
            <Flex
              flexDirection="column"
              style={{
                gap: '10px',
              }}
              px="20px"
              pt="20px"
            >
              <Text color="black" fontSize="24px" fontWeight="500">
                Account
              </Text>
              <TabsComponent
                activeTab={activeTab}
                handleSwitchTab={handleSwitchTab}
              />
            </Flex>
          </AccountContainer>
          <Flex flexDirection="column">
            <TabContent
              uniqueKey={uniqueKey}
              setUniqueKey={setUniqueKey}
              activeTab={activeTab}
            />
          </Flex>
          <Bloc mt={60} />
        </Flex>
      );
  }
};

export default Account;

const TabContent = ({ activeTab, setUniqueKey, uniqueKey }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const isMainUser = currentUser?.main;

  switch (activeTab) {
    case 'personal_details':
      return <UserDetails setUniqueKey={setUniqueKey} uniqueKey={uniqueKey} />;
    case 'organization_details':
      return <OrgSettings isMainUser={isMainUser} />;
    case 'security':
      return <Security />;
    case 'phone_numbers':
      return <InboundNumbers />;
    default:
      return <UserDetails setUniqueKey={setUniqueKey} uniqueKey={uniqueKey} />;
  }
};
