import React from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { format } from 'date-fns';
import { convertUTCToLocalTime } from '../../../../utils';
import { Icon, Spinner } from '../../../../components';

const SenderIDsTable = ({
  orgSenderIDs,
  isLoading,
  isMainInUseOrg,
  onCheckBox,
  setCheckbox,
  setRowBackgroundColor,
  handleRowClick,
  isMainUser,
}) => {
  const renderSenderIDs = () =>
    orgSenderIDs.map((item) => (
      <Bloc
        as="tr"
        key={item?.id}
        textAlign="left"
        // remve border bottom for last row
        borderBottom={
          item?.id === orgSenderIDs[orgSenderIDs.length - 1]?.id
            ? ''
            : '1px solid #DFDBDB'
        }
        background={setRowBackgroundColor(item.id)}
        onClick={(e) => handleRowClick(e, item)}
        style={{ cursor: 'pointer' }}
      >
        <Bloc as="td" py={3}>
          {item.name || ''}
        </Bloc>

        <Bloc as="td" py={3} textAlign={isMainUser ? 'initial' : 'right'}>
          {item.is_default && (
            <Flex alignItems="center">
              <Bloc as="span" fontSize={12} color="lightDark" mr={2}>
                Default
              </Bloc>
              <Icon
                name="check-broken-circle"
                height={14}
                color="black"
                size={12}
                dimension="24x24"
              />
            </Flex>
          )}
        </Bloc>

        <Bloc as="td" py={3} textAlign={isMainUser ? 'initial' : 'right'}>
          {format(convertUTCToLocalTime(item.created_at), 'MMM, dd, yyyy')}
        </Bloc>
        {isMainUser && isMainInUseOrg?.id === item.org_id && (
          <Bloc
            as="td"
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(item, e)}
            >
              {setCheckbox(item?.id)}
            </Bloc>
          </Bloc>
        )}
      </Bloc>
    ));

  return (
    <Bloc overflowX="auto">
      <Box />
      {!isLoading && (
        <Bloc
          mb={0}
          as="table"
          width="100%"
          style={{
            borderCollapse: 'collapse',
          }}
        >
          <Bloc as="thead">
            <Bloc
              as="tr"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
            >
              <Bloc as="td" py="14px" width={['auto', 'auto', 250]}>
                ID
              </Bloc>
              <Bloc as="td" width={['auto', 'auto', 300]} />
              <Bloc as="td" textAlign={isMainUser ? 'initial' : 'right'}>
                Created Date
              </Bloc>
              {isMainUser && (
                <Bloc as="td" textAlign="right">
                  SELECT
                </Bloc>
              )}
            </Bloc>
          </Bloc>
          <Bloc as="tbody">
            {!isLoading ? renderSenderIDs() : <Spinner size="large" />}
          </Bloc>
        </Bloc>
      )}
    </Bloc>
  );
};

export default SenderIDsTable;
