import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserAPI from '../../../services';

const useRemoveMember = ({
  setSelectedMembers,
  selectedMembers,
  setShowMemberToast,
  setIsMemberRemoving,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: mutateRemove,
    isSuccess: memberIsSuccess,
    isPending: memberIsPending,
    isError: memberIsError,
    error: removeMemberError,
  } = useMutation({
    mutationFn: (payload) => userAPI.removeOrgMembers(payload),
  });

  const handleRemoveMember = async (e) => {
    e.stopPropagation();

    if (selectedMembers.length > 0) {
      const users = selectedMembers.map((value) => value.id);
      mutateRemove(users, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['listMembers'] });
          setShowMemberToast(true);
          setTimeout(() => {
            setIsMemberRemoving(false);
            setSelectedMembers([]);
          }, 3000);
        },
        onError: () => {
          setIsMemberRemoving(false);

          setShowMemberToast(true);
          setTimeout(() => {
            setShowMemberToast(false);
          }, 3000);
        },
      });
    }
  };

  return {
    handleRemoveMember,
    removeMemberError,
    memberIsSuccess,
    memberIsPending,
    memberIsError,
  };
};

export default useRemoveMember;
