import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import {
  Button,
  Inputfield,
  Layout,
  Pagination,
  Spinner,
  Text,
} from '../../../../components';
import { useFetchOrgDeals } from '../../../../hooks';
import ListDeals from './listDeals';

const defaultPayload = {};

const itemsPerPage = 25;

const ManageOrgDeals = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [, setSearchOrgName] = useState('');
  const [enableFetch, setEnableFetch] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    setEnableFetch(true);
  };

  const [payload, setPayload] = useState(defaultPayload);

  const handleFilterFormDates = (type, date) => {
    const dateFormatted = format(date, 'yyyy-MM-dd');
    if (type === 'start') {
      setStartDate(date);
      setPayload((prev) => ({
        ...prev,
        start: dateFormatted,
      }));
    } else {
      setEndDate(date);
      setPayload((prev) => ({
        ...prev,
        start: prev?.start ?? format(new Date(), 'yyyy-MM-dd'),
        end: dateFormatted,
      }));
    }
  };

  let debounceTimer;
  const handleSearchOrgName = (e) => {
    const query = e.target.value;
    e.preventDefault();
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      setEnableFetch(false);
      setTimeout(() => {
        setSearchOrgName(query);
        setPayload((prev) => ({
          ...prev,
          org_name: query,
        }));
        setEnableFetch(true); // Enable fetching after debounce time
        setTimeout(() => {
          setSearchOrgName('');
        }, 20);
      }, 500);
    }, 1000);
  };

  const handleSubmitFiltering = () => {
    setEnableFetch(false);
    setTimeout(() => {
      setEnableFetch(true);
    }, 500);
  };

  const { data, isLoading, isFetching } = useFetchOrgDeals({
    enableFetch,
    payload,
    pageNumber,
  });

  return (
    <Layout>
      <Flex flexDirection={['column']}>
        <Box style={{ float: 'left' }} minWidth="16%" mb={[0, 6]}>
          <Text
            as="h1"
            style={{
              lineHeight: '32px',
              fontWeight: '500',
              textTransform: 'capitalize',
            }}
            fontSize={[24, 28]}
          >
            Deals
          </Text>
        </Box>
        <Bloc
          ml={[0, '16%']}
          display="flex"
          flexDirection="column"
          style={{
            gap: '30px',
          }}
        >
          <Bloc
            bg="white"
            borderRadius="8px"
            border="1px solid #DEE1E7"
            padding="16px 24px"
          >
            <Flex
              style={{
                gap: '20px',
              }}
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                style={{
                  columnGap: '20px',
                  flex: '1 1 0%',
                }}
              >
                <Bloc width="100%">
                  <Inputfield
                    name="text"
                    placeholder="Search by name"
                    hasHint={false}
                    onChange={handleSearchOrgName}
                    hasLabel={false}
                    disabled={isFetching}
                  />
                </Bloc>
              </Flex>
              <Flex
                style={{
                  gap: '10px',
                }}
              >
                <Box
                  style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
                >
                  <DatePicker
                    placeholderText="Start date"
                    selected={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleFilterFormDates('start', date)}
                    calendarClassName="calendar"
                  />
                </Box>
                <Box
                  style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
                >
                  <DatePicker
                    placeholderText="End date"
                    selected={endDate}
                    selectsEnd
                    startDate={startDate}
                    minDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleFilterFormDates('end', date)}
                    calendarClassName="calendar"
                    calendarStartDay={1}
                  />
                </Box>

                <Bloc>
                  <Button
                    profile="accentLight"
                    size="normal"
                    data-test="filter-btn"
                    isLoading={isLoading}
                    disabled={endDate === ''}
                    onClick={handleSubmitFiltering}
                  >
                    {!isLoading && !isFetching ? (
                      'Search'
                    ) : (
                      <Spinner
                        size="large"
                        style={{ marginLeft: 0, marginBottom: '-4px' }}
                      />
                    )}
                  </Button>
                </Bloc>
              </Flex>
            </Flex>
          </Bloc>
          <Flex
            flexDirection="column"
            style={{
              gap: '10px',
            }}
          >
            {data && !isLoading && (
              <ListDeals isLoading={isLoading} data={data} />
            )}

            {data && !isLoading && (
              <Bloc>
                <Pagination
                  ml="0px"
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  pages={data?.pages}
                  pageCount={data?.pages?.pages}
                  changePage={changePage}
                  isFetching={isLoading}
                  isLoading={isLoading}
                />
              </Bloc>
            )}
          </Flex>
        </Bloc>
      </Flex>
      <Box mt={100} />
    </Layout>
  );
};

export default ManageOrgDeals;
