import { Bloc, Box, Flex } from 'blocjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Chevron, Icon, Text } from '../../components';
import { formatBalance, formatNumber, slugify } from '../../utils';
import BillingPDF from '../../components/pdf/billingPDF';
import { CurrentUserContext } from '../../contexts';
import { sumInvoiceRates } from '../../lib/sumInvoiceRates';
import BillingTableExtended from '../../components/table/billingTableExtended';
// import DropDownDownloader from '../../components/popupDropdownDownloader';

const BillingHistory = ({ billingHistoryData, summedData, billingRates }) => {
  const { push } = useHistory();
  const billingWrapperRef = useRef();
  const [getRowData, setGetRowData] = useState(null);
  const [isClicked, setIsClicked] = useState(true);
  const [selectedBillingDate, setSelectedBillingDate] = useState(null);
  const [exportOption, setExportOption] = useState('');
  const { profile } = useContext(CurrentUserContext);

  // Calculating the total for a single date
  const singleDateBreakdown = sumInvoiceRates(getRowData?.rates);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  // const [height, setHeight] = useState(false);
  // const headers = ['Date', 'Credit', 'Payment Method', 'Status'];

  const makeFileName = (details) => {
    const title = slugify(details);
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  const handleExportOption = useRef(() => {});

  handleExportOption.current = async () => {
    const { fileName } = makeFileName(
      `Invoice for ${selectedBillingDate} billing period`
    );

    if (exportOption === 'pdf') {
      const doc = (
        <BillingPDF
          profile={profile}
          billingRates={getRowData.rates || billingRates}
          summedData={getRowData.id === 0 ? summedData : singleDateBreakdown}
          selectedBilling={getRowData}
        />
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${fileName}.pdf`);
    }
    setSelectedBillingDate(null);
  };

  useEffect(() => {
    if (billingHistoryData) {
      if (selectedBillingDate) {
        if (exportOption) {
          handleExportOption.current(getRowData);
        }
      }
    }
  }, [exportOption, billingHistoryData, selectedBillingDate, getRowData]);

  // const handleClick = (rowData) => (e) => {
  //   e.stopPropagation();
  //   const { clientX, clientY } = e;
  //   const parentRect = billingWrapperRef.current.getBoundingClientRect();
  //   setIsDropdownOpen((prev) => !prev);
  //   setPopupPosition({ x: clientX, y: clientY });
  //   const offsetX = clientX - parentRect.left;
  //   const offsetY = clientY - parentRect.top;
  //   setPopupPosition({ x: offsetX, y: offsetY });
  //   setGetRowData(rowData);
  // };

  const handleDownload =
    (optionFormat = 'pdf', rowData) =>
    (e) => {
      e.stopPropagation();
      setExportOption(optionFormat);
      setGetRowData(rowData);
      setSelectedBillingDate(rowData.date);
    };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row) => (
          <Flex
            style={{
              columnGap: '6px',
            }}
          >
            {row.id === 0 && (
              <Bloc
                onClick={(e) => {
                  e.stopPropagation();
                  setIsClicked((prev) => !prev);
                }}
              >
                <Chevron rotate={isClicked} />
              </Bloc>
            )}

            <Bloc>{row.date}</Bloc>
          </Flex>
        ),
      },

      {
        Header: 'Initial sent SMS ',
        accessor: (row) => `${formatNumber(row.allInitial)}`,
      },
      {
        Header: 'Initial SMS items',
        accessor: (row) => `${formatNumber(row.allItemInitial)}`,
      },
      {
        Header: 'Retried SMS items',
        accessor: (row) => `${formatNumber(row.allInitialRetries)}`,
      },
      {
        Header: 'Total SMS items',
        accessor: (row) => `${formatNumber(row.totalMessageItems)}`,
      },
      {
        Header: 'Total amount consumed',
        accessor: (row) => `${formatBalance(row.consumedAmount)}`,
      },
      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            display="flex"
            justifyContent="flex-end"
            width={1}
            onClick={handleDownload('pdf', row)}
            data-test="check-col"
          >
            <Bloc>
              <Icon
                name="download"
                dimension="16x17"
                color="#16C1AD"
                size={16}
              />
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [isClicked]
  );

  const onRowClick = (row, e) => {
    e.stopPropagation();
    push({
      pathname: '/invoice',
      state: {
        invoiceData: row.original,
        rates: row.index === 0 ? billingRates : row.original?.rates,
        summedData: row.index === 0 ? summedData : null,
      },
    });
  };

  return (
    <Bloc position="relative">
      <Text color="surfaces.3" mb={20} fontSize={21} fontWeight="bold">
        Billing History
      </Text>
      <Box mt={20} />
      <Bloc ref={billingWrapperRef}>
        <BillingTableExtended
          columns={columns}
          isClicked={isClicked}
          data={billingHistoryData}
          onRowClick={onRowClick}
        />
      </Bloc>

      {/* {isDropdownOpen && (
        <DropDownDownloader
          top={popupPosition.y}
          handleDownload={handleDownload}
          setIsDropdownOpen={setIsDropdownOpen}
          isDropdownOpen={isDropdownOpen}
        />
      )} */}
    </Bloc>
  );
};

export default BillingHistory;
