import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

const StyledButton = styled('button')(
  {
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    outline: 'none',
    backgroundColor: 'white',
    boxShadow: ' 0px 0px 2px 0px rgba(0, 0, 0, 0.32)',
    textTransform: 'capitalize',
  },

  variant({
    prop: 'size',
    variants: {
      large: {
        fontSize: 14,
        borderRadius: '24px',
        px: 6,
        py: 4,
      },
      normal: {
        fontSize: '12px',
        borderRadius: '24px',
        px: '20px',
        py: '8px',
      },
      datepicker: {
        fontSize: '14px',
        borderRadius: '6px',
        px: '12px',
        border: '1px solid #d3d3d3',
        boxShadow: 'none',
        textTransform: 'capitalize',
        py: '9px',
      },
      small: {
        fontSize: '12px',
        borderRadius: '24px',
        px: '16px',
        py: '6px',
      },
    },
  })
);

const PeriodButton = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
);

export default PeriodButton;
