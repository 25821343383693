/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Flex, Bloc, Box } from 'blocjs';
import { useHistory, useLocation } from 'react-router-dom';

import UserAPI from '../../services';
import { Layout, Text, Toast } from '../../components';
import { AuthContext } from '../../contexts';

const Confirmed = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const { addUserToken, addLoginType } = useContext(AuthContext);

  const userAPI = new UserAPI();
  const { push } = useHistory();
  const { data, status, error, isSuccess } = useQuery({
    queryKey: ['confirmEmail', token],
    queryFn: () => userAPI.confirmEmail(token),
  });

  useEffect(() => {
    if (!token) {
      push('/unauthorized');
    }
  });

  useEffect(() => {
    const AuthToken = data?.token;
    const checkLoginType = localStorage.getItem('login_type');

    if (isSuccess) {
      push('/dashboard');
      addUserToken(AuthToken);
    }
    if (!checkLoginType) {
      addLoginType('socials');
    }
  }, [isSuccess]);

  return (
    <Layout pt={[3, '10vh']}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message={data?.message} status="success" />
      )}
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
      >
        <Bloc mb={0} maxWidth={300}>
          <Text variant="h3" as="h1">
            Confirmed
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            Instant communication platform for you!
          </Text>
        </Bloc>
        <Bloc
          mt={[4, null]}
          width={[1, 1, 512]}
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
          borderRadius="8px"
          bg="white"
          padding="48px 32px"
        >
          <Bloc display="inline-block">
            You have successfully confirmed you email. It is time to explore
            your account{' '}
          </Bloc>
          <Box mt={18} />
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Confirmed;
