import { Bloc } from 'blocjs';
import React from 'react';

import { dashboardProductOptions } from '../../constants';
// import SelectionInput from '../selection';
import DatePickerButton from './customButton';
import DatePeriodSwitch from './datePeriodSwitch';
import NextLevelSelection from '../selectOptionNextLevel';
import returnListFormat from '../../utils/listDataFormat';
// import { dashboardProductOptions } from '../../constants';

const PeriodAndProductSwitch = ({
  period,
  handleSelectionChange,
  handleChangePeriod,
  startDate,
}) => (
  <Bloc
    display={['none', 'flex']}
    alignItems="center"
    style={{
      columnGap: '12px',
    }}
  >
    <Bloc display={['none', 'block']}>
      <DatePeriodSwitch
        customButton={
          <DatePickerButton
            startDate={startDate}
            period={period}
            isMobile={false}
          />
        }
        handleChangePeriod={handleChangePeriod}
        startDate={startDate}
        period={period}
      />
    </Bloc>
    <Bloc display={['none', 'block']}>
      <Bloc
        display={['none', 'flex']}
        alignItems="center"
        style={{
          columnGap: '12px',
        }}
      >
        {dashboardProductOptions.map((product, index) => {
          const activeItemIndex = dashboardProductOptions.findIndex(
            (item) => item.name === product.name
          );

          return (
            <NextLevelSelection
              width={['auto', 'auto']}
              height={[10, 38]}
              padding="0"
              key={product.id}
              onSelectionChange={(newProduct) =>
                handleSelectionChange(index, newProduct)
              }
              activeItemIndex={activeItemIndex > -1 ? activeItemIndex : 0}
              dropdownItems={returnListFormat(dashboardProductOptions)}
            />
          );
        })}
      </Bloc>
    </Bloc>
  </Bloc>
);

export default PeriodAndProductSwitch;
