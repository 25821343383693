/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
export default () => {
  self.importScripts(
    'https://unpkg.com/libphonenumber-js@1.10.48/bundle/libphonenumber-mobile.js'
  );

  const addPlusSignInfront = (n) => {
    if (!Number.isNaN(Number(n.charAt(0))) && n.charAt(0) !== '+') {
      return (n < 0 ? '' : '+') + n;
    }
    return n;
  };

  const formatPhone = (number) => {
    if (number) {
      const cleanNumber = number
        .replace(/[- .]/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

      return addPlusSignInfront(cleanNumber);
    }
    return number;
  };

  // Function to check if a field has a selection pattern and return possible selections
  function isSelection(field, csvData, numRowsToCheck) {
    const possibleValues = new Set();

    for (let i = 0; i < Math.min(numRowsToCheck, csvData.length); i++) {
      const value = csvData[i][field].trim();
      if (value !== '') {
        possibleValues.add(value);
      }
    }
    // Check if the number of unique values is relatively small compared to total rows checked
    const numUniqueValues = possibleValues.size;
    const totalRowsChecked = Math.min(numRowsToCheck, csvData.length);
    const threshold = 0.3;

    if (numUniqueValues / totalRowsChecked <= threshold) {
      return { check: true, selections: Array.from(possibleValues) };
    }

    return { check: false, selections: [] };
  }

  // Function to determine data types and selections in optional fields

  function determineDataTypes(csvData, numRowsToCheck, optionalFields) {
    const dataTypes = {};
    const selections = {};
    const allValues = {};

    // Precompute selection check for all optional fields
    const selectionChecks =
      optionalFields.length > 0
        ? Object.fromEntries(
            optionalFields.map((field) => [
              field,
              isSelection(field, csvData, numRowsToCheck),
            ])
          )
        : {};

    // Check data types for a subset of rows
    csvData.slice(0, numRowsToCheck).forEach((row) => {
      optionalFields.forEach((field) => {
        const value = row[field].trim();

        if (value !== '') {
          switch (true) {
            case !isNaN(value):
              dataTypes[field] = 'numerical'; // Overwrite if a number is encountered
              break;
            case !isNaN(Date.parse(value)):
              dataTypes[field] = 'date'; // Overwrite if a date is encountered
              break;
            case selectionChecks[field].check:
              selections[field] = selectionChecks[field].selections;
              dataTypes[field] = 'select'; // Check if it's a selection
              break;
            default:
              dataTypes[field] = 'text'; // Default to string
              allValues[field] = [...(allValues[field] || []), value];
              break;
          }
        }
      });
    });

    return { dataTypes, selections, allValues };
  }

  self.onmessage = (message) => {
    const newContactsArray = [];
    const newDuplicatesArray = [];
    const newInvalidContactsArray = [];

    let newInvalidContacts = 0;

    const { list, headerTel } = message.data;

    // let numRowsToCheck = Math.ceil(totalRows * 0.1); // Start with 10% of total rows

    // if (totalRows < 1000) {
    //   numRowsToCheck = totalRows; // If less than 10% of total rows, use 20%
    // }

    // eslint-disable-next-line no-undef
    const { isValidPhoneNumber } = libphonenumber;

    const totalItems = list.length; // Total number of items in the array
    const uniquePhoneNumbers = new Set();

    // Function to process contact and update progress
    const processContact = (contact) => {
      const phoneNumberKey = Object.keys(contact).find((key) =>
        key.includes(headerTel)
      );

      if (phoneNumberKey) {
        const phoneNumber = formatPhone(contact[phoneNumberKey]);
        const validContact = { phone_number: phoneNumber };

        // Loop through contact fields
        Object.entries(contact).forEach(([key, value]) => {
          const lowerKey = key.toLowerCase().replace(/\s/g, '');

          if (key !== phoneNumberKey) {
            validContact[lowerKey] = value;
          }
        });

        if (isValidPhoneNumber(phoneNumber)) {
          if (uniquePhoneNumbers.has(phoneNumber)) {
            newDuplicatesArray.push(validContact);
          } else {
            uniquePhoneNumbers.add(phoneNumber);
            newContactsArray.push(validContact);
          }
        } else {
          newInvalidContacts += 1;
          newInvalidContactsArray.push(validContact);
        }

        // Update progress
        const newRecipients = newContactsArray.length;
        const newDuplicates = newDuplicatesArray.length;
        const totalUploaded =
          newRecipients + newInvalidContacts + newDuplicates;
        const percentage = Math.trunc((totalUploaded / totalItems) * 100);
        postMessage({ progress: percentage });
      }
    };
    const optionalFields = Object.keys(list[0]).slice(2); // Extract optional fields

    if (optionalFields.length > 0) {
      const { dataTypes, selections, allValues } = determineDataTypes(
        list,
        totalItems,
        optionalFields
      );

      list.forEach(processContact);
      postMessage({
        newContactsArray,
        newInvalidContacts,
        newDuplicatesArray,
        dataTypes,
        selections,
        allValues,
      });
    } else {
      // If no optional fields exist, process the data without determining data types
      list.forEach(processContact);
      postMessage({ newContactsArray, newInvalidContacts, newDuplicatesArray });
    }
  };
};
