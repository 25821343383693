import React, { useEffect, useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Button, Inputfield, Toast } from '../../components';
import UserAPI from '../../services';
import { EMAIL_REGEX } from '../../utils';

const MagicLinkComponent = ({
  mode,
  usingEmail,
  setUsingEmail,
  setSentEmail,
  setEmailInput,
}) => {
  const userAPI = new UserAPI();
  const { register, handleSubmit, errors } = useForm();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    let timer;
    if (isButtonDisabled) {
      timer = setTimeout(() => {
        setShowToast(false);
        setIsButtonDisabled(false);
      }, 5000); // Re-enable button after 5 seconds
    }
    return () => clearTimeout(timer); // Cleanup timeout if component unmounts or button state changes
  }, [isButtonDisabled]);

  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload) =>
      mode === 'login'
        ? userAPI.sendMagicLink(payload)
        : userAPI.register(payload),
  });

  const onSubmit = (dataInputs) => {
    setIsButtonDisabled(true);
    localStorage.setItem('login_type', 'socials');
    mutate(
      mode === 'login'
        ? {
            email: dataInputs.email,
            type: mode,
          }
        : dataInputs,
      {
        onSuccess: () => {
          setSentEmail(true);
          setEmailInput(dataInputs.email);
        },
        onError: () => {
          setShowToast(true);
          setIsButtonDisabled(true);
        },
      }
    );
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onSubmit)} mt="10px">
      {showToast && (
        <Toast
          message={error?.response?.data?.error?.message}
          status="error"
          onClose={() => setShowToast(false)}
          closed
        />
      )}
      <Flex
        flexDirection="column"
        style={{
          gap: '10px',
        }}
      >
        <Bloc fontWeight="500">
          {mode === 'login'
            ? 'Login with a magic link'
            : 'Register with your email'}
        </Bloc>
        <Inputfield
          type="email"
          ref={register({
            required: "We can't send a Magic link without an Email",
            pattern: {
              value: EMAIL_REGEX,
              message: 'Enter a valid e-mail address',
            },
          })}
          name="email"
          hasLabel={false}
          hasHint={!!errors?.email?.message}
          onFocus={() => setUsingEmail(false)}
          placeholder="name@work-email.com"
          error={errors?.email?.message}
          data-testid="email"
        />
      </Flex>
      {!usingEmail && (
        <Bloc mt="20px">
          <Button
            profile="accentLight"
            size="contact"
            loading={isPending}
            data-testid="email-me"
            disabled={isButtonDisabled}
          >
            Email me
          </Button>
        </Bloc>
      )}
    </Bloc>
  );
};

export default MagicLinkComponent;
