/* eslint-disable react-hooks/exhaustive-deps */
import { Bloc, Box } from 'blocjs';
import React, { useEffect, useCallback, useState } from 'react';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import ContactTable from '../table/contactTable';
import { unslugify } from '../../utils';

const ContactFieldsTable = ({
  contactTableData,
  handleSwitchPage,
  handleActions,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const defaultValue = ['phonenumber', 'name', 'phone_number'];

  const contactTableDataWithDefaultField = contactTableData.filter(
    (contact) => !defaultValue.includes(contact.name)
  );
  useEffect(() => {
    if (selectedContacts.length < 1) {
      handleActions();
    }
  }, [selectedContacts.length]);

  const toggleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedContacts([]);
      handleActions('active-tab', []);
    } else {
      handleActions('active-tab', selectedContacts);
      setSelectedContacts(selectedContacts);
    }
    setSelectAll(!selectAll);
  }, [selectAll, selectedContacts]);

  const onCheckBox = useCallback(
    (contact, e) => {
      e.stopPropagation();
      const items = [...selectedContacts];
      const index = items.findIndex((item) => item.name === contact.name);

      if (index > -1) {
        items.splice(index, 1);
        setSelectedContacts(items);
        handleActions('contact-field', items);

        if (items.length < 1) {
          handleActions();
        }
      } else {
        handleActions('contact-field', [...items, contact]);
        setSelectedContacts([...items, contact]);
      }
    },
    [selectedContacts]
  );

  const onRowClick = (row, e) => {
    e.stopPropagation();
    handleSwitchPage('edit-field', row.original);
  };

  const setRowBackgroundColor = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.name === rowId)) {
        return '#BDFBF3';
      }
      if (selectedContacts.includes(rowId)) {
        return '#E4FFFC';
      }
      return 'transparent';
    },
    [selectedContacts]
  );

  const setCheckbox = useCallback(
    (rowName) => {
      if (selectedContacts.some((item) => item.name === rowName)) {
        return <CheckedBox color="accentDark" size={16} />;
      }
      return (
        <Box position="relative" width={16} height={16}>
          <Icon name="square" color="#959DA2" size={16} />
        </Box>
      );
    },
    [selectedContacts]
  );

  const column = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => <Bloc>{unslugify(row.name)}</Bloc>,
      },
      {
        Header: ' ',
        accessor: (row) => (
          <Bloc
            style={{
              textTransform: 'lowercase',
            }}
          >
            @contact.{row.name}
          </Bloc>
        ),
      },
      {
        Header: 'Input Field',
        accessor: (row) => <Bloc>{row.input_type}</Bloc>,
      },
      {
        Header: 'Data Type',
        accessor: (row) => <Bloc>{row.data_type}</Bloc>,
      },

      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(row, e)}
            >
              {setCheckbox(row.name)}
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [onCheckBox, setCheckbox]
  );
  return (
    <Bloc>
      <ContactTable
        setRowBackgroundColor={setRowBackgroundColor}
        columns={column}
        toggleSelectAll={toggleSelectAll}
        selectAll={selectAll}
        selectedContacts={selectedContacts}
        data={contactTableDataWithDefaultField}
        onRowClick={onRowClick}
      />
    </Bloc>
  );
};

export default ContactFieldsTable;
