import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import Text from '../text';

const StyledButton = styled(Text)(
  {
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    padding: '0px 0px',
    fontWeight: '200',
    outline: 'none',
    textTransform: 'capitalize',
  },
  variant({
    prop: 'profile',
    variants: {
      primary: {
        color: 'black',
        '&:hover': {
          color: '#0eb6a2',
          fontWeight: '400',
        },
      },
      accent: {
        color: '#0eb6a2',
        fontWeight: '400',
      },
      secondaryTransparent: {
        color: 'white',
      },
    },
  })
);

const NavigationButton = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
);

export default NavigationButton;
