import { Bloc, Flex } from 'blocjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import Icon from '../icon';
import Text from '../text';
import Button from '../button';
import UserAPI from '../../services';
import { Inputfield } from '../inputs';
import CustomToast from '../toast/customToast';

const RenderToaster = ({ action, handleAction, handleSwitchPage }) => {
  const queryClient = useQueryClient();
  const [showToastNotificationMessage, setShowToastNotificationMessage] =
    useState(false);
  const [groupNameError, setGroupNameError] = useState(null);
  const [groupExistMessage, setGroupExistMessage] = useState(null);
  const [successfullyMessage, setSuccessMessage] = useState(null);

  const [groupNameText, setGroupNameText] = useState('');

  const userAPI = new UserAPI();

  const { data: orgId } = useQuery({
    queryKey: ['selfOrg'],
    queryFn: (payload) => userAPI.getSelfOrg(payload),
  });

  const { data: groupsData } = useQuery({
    queryKey: ['get_all_groups'],
    queryFn: () =>
      userAPI.getContactGroups({
        name: '',
      }),
  });

  const {
    mutate: archiveContacts,
    isError: isArchiveContactsError,
    error: archiveContactsError,
    status: archiveContactsStatus,
    isPending: archiveContactsIsPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.archiveContacts(payload),
  });

  const {
    mutate: unarchiveContacts,
    isError: isUnarchiveContactError,
    error: unarchiveContactError,
    status: unarchiveContactStatus,
    isPending: unarchiveContactIsPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.unarchiveContacts(payload),
  });

  const {
    mutate: deleteContactField,
    status: deleteContactFieldStatus,
    isError: isDeleteContactFieldError,
    isPending: deleteContactFieldIsPending,
    error: deleteContactFieldError,
  } = useMutation({
    mutationFn: (payload) => userAPI.deleteContactField(payload),
  });

  const {
    mutate: deleteContactGroups,
    isPending: deleteContactGroupsIsPending,
    error: deleteGroupsError,
    isError: isDeleteGroupsError,
    status: deleteGroupsStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.deleteContactGroup(payload),
  });

  const {
    mutate: deleteContact,
    error: deleteContactError,
    isPending: deleteContactIsPending,
    isError: isDeleteContactError,
    status: deleteContactStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.deleteContact(payload),
  });

  const {
    mutate: createGroup,
    isError: isCreateGroupError,
    error: createGroupError,
    status: createGroupStatus,
    isPending: createGroupIsPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.createContactGroup(payload),
  });

  const {
    mutate: deleteContactFromGroup,
    error: deleteContactFromGroupError,
    isPending: deleteContactFromGroupIsPending,
    isError: isDeleteContactFromGroupError,
    status: deleteContactFromGroupStatus,
  } = useMutation({
    mutationFn: (payload) =>
      userAPI.deleteContactsInGroup(payload, action?.result.group_id),
  });

  const onDeleteContactFromGroup = () => {
    const payload = {
      contact_ids: action?.result.payload.map((contact) => contact.id),
    };

    deleteContactFromGroup(payload, {
      onError: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_groups'],
          });

          handleAction('preview-group');
        }, 5500);
      },

      onSuccess: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_groups'],
          });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          queryClient.invalidateQueries({
            queryKey: ['get_all_contacts'],
          });
          handleAction();
        }, 5500);
      },
    });
  };

  const onDeleteContact = () => {
    const payload = {
      contact_ids: action?.result.map((contact) => contact.id),
    };

    deleteContact(payload, {
      onError: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_archived'],
          });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          handleAction();
        }, 5500);
      },
      onSuccess: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ['get_all_archived'] });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          handleAction();
        }, 5500);
      },
    });
  };

  function checkIfGroupNameExist(arr, name) {
    const foundItem = arr.find((item) => item.name === name);
    return foundItem ? foundItem.name : null;
  }
  let debounceTimer;

  const handleInputGroupName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const textInput = e.target.value;

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      const isGroupName = checkIfGroupNameExist(
        groupsData?.contact_groups && groupsData.contact_groups,
        textInput
      );
      if (isGroupName) {
        setGroupNameError(null);
        setSuccessMessage(`Group ${textInput} updated Successfully`);
        setGroupExistMessage(
          'Group name Already exist in the list of groups (Clicking Proceed will update the existing group)'
        );
      } else {
        setGroupExistMessage(null);
      }
      // Here you can perform your API call using the query
    }, 900);

    if (textInput && textInput.length >= 30) {
      setGroupExistMessage(null);
      setGroupNameError('Group name should not exceed 30 characters');
    } else {
      setGroupNameText(textInput);
      setGroupNameError(null);
    }
    e.preventDefault();
    setGroupNameText(textInput);
  };

  const onDeleteSelectedField = () => {
    const payload = {
      contact_field_ids: action?.result.map((contact) => contact.id),
    };

    deleteContactField(payload, {
      onError: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          handleAction();
        }, 5500);
      },
      onSuccess: () => {
        setShowToastNotificationMessage(true);

        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ['get_all_fields'] });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          handleAction();
        }, 5500);
      },
    });
  };

  const onDeleteContactGroups = () => {
    // TODO: Why payload  not sent to the server?
    deleteContactGroups(
      {
        contact_grp_ids: action?.result.map((group) => group.id),
      },
      {
        onError: () => {
          setShowToastNotificationMessage(true);
          setTimeout(() => {
            handleAction('edit-group', action.result);
          }, 5500);
        },
        onSuccess: () => {
          setShowToastNotificationMessage(true);
          setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['get_all_groups'] });
            queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

            handleAction('edit-group', action.result);
            handleSwitchPage();
          }, 5500);
        },
      }
    );
  };

  const onArchivedContacts = () => {
    const payload = {
      contact_ids: action?.result.map((contact) => contact.id),
    };

    archiveContacts(payload, {
      onSuccess: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_contacts'],
          });

          queryClient.invalidateQueries({
            queryKey: ['get_all_archived'],
          });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          handleAction();
        }, 5500);
      },
      onError: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_contacts'],
          });

          handleAction();
        }, 5500);
      },
    });
  };

  const onUnarchiveContacts = () => {
    const payload = {
      contact_ids: action?.result.map((contact) => contact.id),
    };
    unarchiveContacts(payload, {
      onSuccess: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['get_all_contacts'],
          });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          queryClient.invalidateQueries({
            queryKey: ['get_all_archived'],
          });

          setShowToastNotificationMessage(false);
          handleAction('archived-tab', action.result);
        }, 5500);
      },
      onError: () => {
        setShowToastNotificationMessage(true);
        setTimeout(() => {
          handleAction('archived-tab', action.result);
        }, 5500);
      },
    });
  };

  const handleCreateGroupList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const check = groupNameText.length > 3 && !groupNameError;
    if (groupNameText.length < 3) {
      setGroupNameError('Field Name should have at least 3 characters');
    }
    if (check) {
      const payload = {
        group_name: groupNameText,
        org_id: orgId?.orgs[0].id,
        criteria: {},
        contact_ids: action?.result.map((contact) => contact.id),
      };

      createGroup(payload, {
        onSuccess: () => {
          setShowToastNotificationMessage(true);
          setSuccessMessage('Group Created Successfully');

          setTimeout(() => {
            handleSwitchPage(null);

            queryClient.invalidateQueries({
              queryKey: ['get_all_contacts'],
            });
            queryClient.invalidateQueries({
              queryKey: ['get_all_groups'],
            });
            queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

            setSuccessMessage(null);

            queryClient.invalidateQueries({
              queryKey: ['get_all_archived'],
            });
            handleAction('active-tab');
          }, 3000);
        },
        onError: () => {
          setShowToastNotificationMessage(true);
          setTimeout(() => {
            setSuccessMessage(null);
          }, 5500);
        },
      });
    }
  };

  const archiveContactsSuccessfully = archiveContactsStatus === 'success';
  const deleteContactFieldSuccessfully = deleteContactFieldStatus === 'success';
  const unArchivedContactSuccessfully = unarchiveContactStatus === 'success';
  const deleteGroupsSuccessfully = deleteGroupsStatus === 'success';
  const deleteContactSuccessfully = deleteContactStatus === 'success';
  const createNewGroupSuccessfully = createGroupStatus === 'success';
  const deleteContactFromGroupSuccessfully =
    deleteContactFromGroupStatus === 'success';

  switch (action.toggleToast) {
    case 'move-to-group':
      return (
        <Flex
          px="22px"
          py="20px"
          flexDirection="column"
          style={{
            gap: '10px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message={successfullyMessage}
              error={createGroupError}
              success={createNewGroupSuccessfully}
              isError={isCreateGroupError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to create a New Group
            </Text>
          </Flex>
          <Flex
            width="100%"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              gap: '6px',
            }}
            pb="10px"
            flexDirection="column"
          >
            <Text fontSize="14px">Group name</Text>
            <Inputfield
              size="contact"
              required
              name="text"
              value={groupNameText}
              placeholder="Group Name"
              onChange={(e) => handleInputGroupName(e)}
              hasHint={false}
              hasLabel={false}
            />
            {groupNameError ||
              (groupExistMessage && (
                <Text fontSize="10px" color="red">
                  {groupNameError ?? groupExistMessage}
                </Text>
              ))}
          </Flex>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              loading={createGroupIsPending}
              onClick={handleCreateGroupList}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('active-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'preview-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to archive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Archiving this contact removes it from your active list and places
            it in the archived section. You&apos;ll need to unarchive it to use
            it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={() => {
                handleAction('preview-group', action.result);
              }}
            >
              Remove To group
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('preview-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'move-to-archived':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message="Contact archived successfully"
              error={archiveContactsError}
              success={archiveContactsSuccessfully}
              isError={isArchiveContactsError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to archive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Archiving this contact removes it from your active list and places
            it in the archived section. You&apos;ll need to unarchive it to use
            it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onArchivedContacts}
              loading={archiveContactsIsPending}
            >
              Continue to Archived
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                color: 'black',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('active-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'delete-field':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              error={deleteContactFieldError}
              success={deleteContactFieldSuccessfully}
              isError={isDeleteContactFieldError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to delete fields
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will permanently remove the selected field from all your
            contacts, this action cannot be undone.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteSelectedField}
              loading={deleteContactFieldIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('contact-field', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'move-to-unarchive':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message="Contact un-archived successfully"
              error={unarchiveContactError}
              success={unArchivedContactSuccessfully}
              isError={isUnarchiveContactError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to unarchive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Un-archiving this contact removes it from your active list and
            places it in the archived section. You&apos;ll need to unarchive it
            to use it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onUnarchiveContacts}
              loading={unarchiveContactIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('archived-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'delete-contacts-from-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message="Contacts removed successfully"
              error={deleteContactFromGroupError}
              success={deleteContactFromGroupSuccessfully}
              isError={isDeleteContactFromGroupError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to Delete these contacts in this group!
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will remove all the selected contacts in these groups.
            Are you sure you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContactFromGroup}
              loading={deleteContactFromGroupIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('edit-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'delete-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message="Groups deleted successfully"
              error={deleteGroupsError}
              success={deleteGroupsSuccessfully}
              isError={isDeleteGroupsError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to Delete this groups !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will remove all the contacts in these groups. Are you
            sure you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContactGroups}
              loading={deleteContactGroupsIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('edit-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'delete-contact':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          {showToastNotificationMessage && (
            <CostumedToast
              message="Contacts deleted successfully"
              error={deleteContactError}
              success={deleteContactSuccessfully}
              isError={isDeleteContactError}
              showToast={showToastNotificationMessage}
              setShowToastNotificationMessage={setShowToastNotificationMessage}
            />
          )}

          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to delete contacts !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will permanently delete all these contacts. Are you sure
            you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContact}
              loading={deleteContactIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('archived-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
};

export default RenderToaster;

const CostumedToast = ({
  isError,
  error,
  message,
  showToast,
  setShowToastNotificationMessage,
  success,
}) => (
  <>
    {isError && (
      <Bloc
        zIndex={99900}
        width={['80%', '40%', 'fit-content']}
        style={{
          position: 'absolute',
          margin: '0 auto',
          top: '100px',

          left: '0',
          right: '0',
        }}
      >
        <CustomToast
          message={error?.response?.data?.error?.message}
          handleHideToast={() => setShowToastNotificationMessage(false)}
          status="error"
          showToast={showToast}
        />
      </Bloc>
    )}

    {success && (
      <Bloc
        zIndex={99900}
        width={['80%', '40%', 'fit-content']}
        style={{
          position: 'absolute',
          margin: '0 auto',
          top: '100px',
          left: '0',
          right: '0',
        }}
      >
        <CustomToast
          message={message || 'Field deleted successfully'}
          handleHideToast={() => setShowToastNotificationMessage(false)}
          status="success"
          showToast={showToast}
          closed
        />
      </Bloc>
    )}
  </>
);
