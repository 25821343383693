import React, { useState, useEffect } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Text,
  Card,
  Button,
  Inputfield,
  Toast,
  Icon,
} from '../../../../../components';

import {
  countriesList,
  DOUBLE_REGEX,
  formatStringNumber,
  separateData,
} from '../../../../../utils';
import UserAPI from '../../../../../services';
import SelectionInput from '../../../../../components/selection';

// Function to generate a unique ID
function uniqueId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

const AddOrEditPrice = ({
  cancelEditing,
  isEditPricing,
  currentService,
  setIsAddOrUpdateService,
  countryTelcosData = undefined,
}) => {
  const [showToast, setShowToast] = useState(false);
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: (payload) => userAPI.updateIntService(payload),
  });
  const { register, handleSubmit, errors } = useForm();

  const [showTelcosError, setShowTelcosError] = useState(null);
  const [telInputs, setTelInputs] = useState({});

  const defaultCountry = isEditPricing
    ? countryTelcosData[0].country
    : countriesList[0].code;

  const [formData, setFormData] = useState([
    { id: uniqueId(), country: defaultCountry, telcos: '', price: '' },
  ]);

  useEffect(() => {
    if (countryTelcosData) {
      const initialData = countryTelcosData.map((item) => ({
        id: uniqueId(),
        country: item.country,
        telcos: item.telcos,
        price: item.price,
      }));
      setFormData(initialData);
    }
  }, [countryTelcosData]);

  const handleRemove = (e, id) => {
    e.preventDefault();
    const updatedFormData = formData.filter((data) => data.id !== id);
    setFormData(updatedFormData);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const newFormData = [
      ...formData,
      { id: uniqueId(), country: defaultCountry, telcos: '', price: '' },
    ];
    setFormData(newFormData);
  };

  const handleChange = (id, field, value) => {
    const updatedFormData = formData.map((data) =>
      data.id === id ? { ...data, [field]: value } : data
    );
    setFormData(updatedFormData);
  };

  const onFormSubmit = async () => {
    const newAddedPricing = formData.map(({ id, price, ...rest }) => ({
      ...rest,
      price: formatStringNumber(price), // Convert price to a number
    }));

    if (currentService) {
      let currentPricing = currentService.pricing;
      if (isEditPricing) {
        // remove all the existing data for the chosen country and add new pricing for the chosen country
        const countryToRemove = countryTelcosData[0].country;
        currentPricing = currentPricing.filter(
          (item) => item.country !== countryToRemove
        );
      }

      const { dataWithoutDuplicates } = separateData(
        currentPricing,
        newAddedPricing
      );

      const payload = {
        name: currentService.name,
        username: currentService.username,
        password: currentService.password,
        url: currentService.url,
        is_operational: currentService.is_operational,
        backup_position: currentService.backup_position,
        pricing: [...currentPricing, ...dataWithoutDuplicates],
      };

      const { id } = currentService;
      const request = { bodyReq: payload, serviceId: id };
      await mutateUpdate(request, {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['intlServices'] });
          setTimeout(async () => {
            setShowToast(false);
            setIsAddOrUpdateService(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  const onChangeInput = (e, id) => {
    const { value } = e.target;
    // Allow alphanumeric characters, -, ., and _
    const validTelcoName = value.replace(/[^\w.-]/g, '');
    setTelInputs((prevState) => ({ ...prevState, [id]: validTelcoName }));
    setShowTelcosError(null);
  };

  const handleKeyDown = (e, id) => {
    if (
      e.keyCode === 9 || // tab
      e.keyCode === 13 || // enter
      e.keyCode === 32 || // semicolon
      e.keyCode === 188 // comma
    ) {
      e.preventDefault();
      e.stopPropagation();
      const newTelco = e.target.value;

      const updatedFormData = formData.map((data) => {
        if (data.id === id) {
          let updatedTelcos;
          if (data.telcos.trim() === '') {
            updatedTelcos = newTelco; // If telcos is empty, no comma before the newTelco
          } else {
            // Split the telcos string into an array
            const telcosArray = data.telcos.split(', ');
            // Check if the newTelco already exists in the array
            updatedTelcos = !telcosArray.includes(newTelco)
              ? `${data.telcos}, ${newTelco}`
              : data.telcos;
          }
          return { ...data, telcos: updatedTelcos };
        }
        return data;
      });

      setFormData(updatedFormData);
      setTelInputs((prevState) => ({ ...prevState, [id]: '' }));
    }
  };

  const removeTelcoFromFormData = (id, telcoToRemove) => {
    setFormData((prevFormData) =>
      prevFormData.map((item) => {
        if (item.id === id) {
          const telcosArray = item.telcos.split(', ');
          // Remove the specified telco
          const updatedTelcos = telcosArray.filter(
            (telco) => telco !== telcoToRemove
          );
          // Join the updated array back into a string
          const updatedTelcosString = updatedTelcos.join(', ');
          return { ...item, telcos: updatedTelcosString };
        }
        return item;
      })
    );
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast message="Service updated successfully" status="success" />
      )}
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            {isEditPricing ? 'Edit' : 'Add'} Pricing
          </Text>
        </Box>
      </Flex>
      <Box ml={[0, '16%']} mb={15}>
        <Card>
          {formData.map((data) => {
            const telcosArray = data.telcos ? data.telcos.split(', ') : [];
            return (
              <Bloc key={data.id} mb={4}>
                <Flex alignItems="center" flexWrap="wrap">
                  <Bloc
                    width={[1, 'calc(50% - 16px)', 'calc(30% - 16px)']}
                    mr={[1, '16px']}
                    minHeight={107}
                  >
                    <Bloc
                      my={3}
                      fontSize={2}
                      fontWeight="bold"
                      display="inline-block"
                      as="label"
                    >
                      Country
                    </Bloc>
                    <SelectionInput
                      hasSearch
                      dropDownItems={countriesList}
                      activeItemIndex={countriesList.findIndex(
                        (item) => item.code === data.country
                      )}
                      onSelectionChange={(value) =>
                        handleChange(data.id, 'country', value.code)
                      }
                    />
                  </Bloc>

                  <Bloc
                    width={[1, 'calc(50% - 16px)', 'calc(25% - 16px)']}
                    mr={[1, '16px']}
                    minHeight={107}
                  >
                    <Inputfield
                      label="Price"
                      name="price"
                      placeholder="Enter price in USD"
                      defaultValue={data.price}
                      onChange={(e) =>
                        handleChange(data.id, 'price', e.target.value)
                      }
                      hint="Fill in Price"
                      ref={register({
                        required: 'The price is required',
                        pattern: {
                          value: DOUBLE_REGEX,
                          message: 'Please enter a valid price',
                        },
                      })}
                      error={errors?.price?.message}
                    />
                  </Bloc>

                  <Bloc
                    width={[
                      1,
                      'calc(100% - (25px + 81px))',
                      'calc(45% - (25px + 81px))',
                    ]}
                    mr={[1, '23px']}
                    minHeight={107}
                  >
                    <Bloc
                      my={3}
                      fontSize={2}
                      fontWeight="bold"
                      display="inline-block"
                      as="label"
                    >
                      Telcos
                    </Bloc>
                    <Bloc
                      border="1px solid"
                      borderRadius="4px"
                      borderColor={
                        showTelcosError ? 'surfaces.4' : 'surfaces.2'
                      }
                      fontSize="16px"
                      padding="0 8px"
                      backgroundColor="surfaces.0"
                      overflow="hidden"
                    >
                      {telcosArray &&
                        telcosArray.map((telcoName) => (
                          <Bloc
                            as="span"
                            key={telcoName}
                            border="1px solid"
                            borderColor="accents.6"
                            backgroundColor="accents.6"
                            color="accents.8"
                            display="inline-block"
                            fontWeight="400"
                            marginRight="5px"
                            marginTop="5px"
                            padding="5px"
                            borderRadius="2px"
                            fontSize="16px"
                          >
                            {telcoName}
                            <Bloc
                              as="a"
                              onClick={() =>
                                removeTelcoFromFormData(data.id, telcoName)
                              }
                              cursor="pointer"
                              fontWeight="bold"
                              data-testid="remove-real-contact"
                            >
                              {' '}
                              ×
                            </Bloc>
                          </Bloc>
                        ))}

                      <Bloc
                        as="input"
                        type="text"
                        value={telInputs[data.id] || ''}
                        placeholder="Add name of the telco here"
                        onChange={(e) => onChangeInput(e, data.id)}
                        onKeyDown={(e) => handleKeyDown(e, data.id)}
                        width="100%"
                        backgroundColor="transparent"
                        border="0"
                        fontSize="16px"
                        fontWeight={400}
                        height={38}
                        name="phoneNumber"
                        style={{ backgroundColor: 'white' }}
                      />
                    </Bloc>
                  </Bloc>

                  <Flex
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleRemove(e, data.id)}
                  >
                    <Bloc
                      as={Button}
                      color="primary"
                      type="button"
                      bg="primary"
                      borderRadius="100%"
                      boxShadow="0px 2px 8px rgba(0,0,0,0.08), 0px 8px 16px rgba(0,0,0,0.04)"
                      width={20}
                      height={20}
                      display="flex"
                      alignItems="center"
                    >
                      <Icon
                        name="minus"
                        size={18}
                        dimension="24x24"
                        color="white"
                      />
                    </Bloc>
                    <Bloc as="span" ml={3} fontSize={14}>
                      Remove
                    </Bloc>
                  </Flex>
                </Flex>
              </Bloc>
            );
          })}

          <Flex
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleAdd(e)}
            mb={8}
          >
            <Bloc
              as={Button}
              type="button"
              color="accent"
              data-testid="add-another"
              bg="accent"
              borderRadius="100%"
              boxShadow="0px 2px 8px rgba(0,0,0,0.08), 0px 8px 16px rgba(0,0,0,0.04)"
              width={20}
              height={20}
              display="flex"
              alignItems="center"
            >
              <Icon name="plus" size={18} dimension="24x24" color="white" />
            </Bloc>
            <Bloc as="span" ml={3} fontSize={14}>
              Add a new telco
            </Bloc>
          </Flex>

          <Flex mt={5} flexWrap="wrap">
            <Box mb={[3, 0]} mr={30}>
              <Button
                profile="accentLight"
                size="small"
                type="submit"
                data-testid="updateDetailsBtn"
              >
                {isEditPricing ? 'SAVE CHANGES' : 'Add NEW PRICE'}
              </Button>
            </Box>
            <Box mb={0}>
              <Button
                profile="primary"
                size="small"
                type="button"
                onClick={cancelEditing}
                data-testid="cancel-show-details"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Card>
      </Box>
    </Bloc>
  );
};

export default AddOrEditPrice;
