import { useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useAcceptInvite = (inviteData, hasInvitation) => {
  const { data, status, error } = useQuery({
    queryKey: [
      'acceptInvitation',
      { token: inviteData.token, orgId: inviteData.orgId },
    ],
    queryFn: () =>
      userAPI.acceptInvitation({
        token: inviteData.token,
        orgId: inviteData.orgId,
      }),

    enabled: hasInvitation,
  });

  return { data, status, error };
};

export default useAcceptInvite;
