import { Bloc, Flex } from 'blocjs';
import React from 'react';
import Icon from '../icon';
import Text from '../text';
import { ActiveActionButtons } from './buttons';

const EmptyElement = ({ title = 'archived', handleSwitchPage }) => (
  <Flex
    py="40px"
    flexDirection="column"
    style={{
      rowGap: '40px',
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Flex
      maxWidth="80%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        rowGap: '15px',
      }}
    >
      <Bloc borderRadius="9999px" bg="rgba(94, 235, 219, 0.2)" p="28px">
        <Icon
          name="contact_group"
          dimension="40x40"
          size={40}
          color="#0EB6A2"
        />
      </Bloc>
      <Bloc textAlign="center">
        <Text p="0" m="0" fontWeight="600" as="p">
          You have no {title} yet
        </Text>
        <Text p="0" m="0" fontWeight="400" as="p">
          Start by adding your contacts and begin the journey of reaching out to
          your clients!
        </Text>
      </Bloc>
    </Flex>
    <ActiveActionButtons isTab={false} handleSwitchPage={handleSwitchPage} />
  </Flex>
);

export default EmptyElement;
