import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Bloc, Box, Flex } from 'blocjs';
import { useForm } from 'react-hook-form';
import ClickAwayListener from 'react-click-away-listener';
import posthog from 'posthog-js';

import {
  validatePhoneNumber,
  calculateTimeLeft,
  currencyFormat,
  formatAmount,
} from '../../utils';
import { useSavedPhones } from '../../hooks';
import UserAPI from '../../services';
import Button from '../button';
import Toast from '../toast';
import Icon from '../icon';
import CheckedBox from '../checkedbox';
import { ErrorBelowInput } from '../inputs';
import Spinner from '../spinner';
import { Chevron } from '../carets';
import PhoneInput from '../phoneInput';
import Text from '../text';
import useExchangeRate from './useExchangeRate';

const MomoPayment = ({
  callToast,
  handleHideAccountForm,
  showCompletePayment,
  setShowCompletePayment,
  setCountDownOn,
  removeSavedMethod,
}) => {
  const { savedPhones, isLoadingSavedPhones } = useSavedPhones();
  const savedPhoneExist = !!(
    savedPhones && savedPhones.phone_numbers.length > 0
  );
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, watch } = useForm();
  const [paymentId, setPaymentId] = useState(null);
  const [count, setCount] = useState(0);
  const [hasError, setHasError] = useState(null);
  const [hasSelectedPhones, setHasSelectedPhones] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState('');
  const [isPaying, setIsPaying] = useState(false);
  const [isRememberPhone, setIsRememberPhone] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasMomoValidate, setHasMomoValidate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hideNotificationMessage, setHideNonfictionMessage] = useState(false);
  const watchAmount = watch('amount_in_rwf');

  let inputAmountInRwf = 0;
  if (watchAmount) {
    inputAmountInRwf = formatAmount(watchAmount);
  }

  const {
    mutate: mutateMomoPay,
    isError: isMomoError,
    error: momoError,
    isLoading: isPayingWithMomo,
  } = useMutation({ mutationFn: (payload) => userAPI.momoPayment(payload) });

  const { data: validateMomoData } = useQuery({
    queryKey: ['validateMomo', { paymentId }],
    queryFn: () => userAPI.validateMomoPayment({ paymentId }),
    enabled: hasMomoValidate,
    refetchInterval: 15000,
  });

  let timeColor = 'accentDark';
  let cardBackground = '#006EE51A';

  const timeLeft = calculateTimeLeft(count);
  if (showCompletePayment) {
    // show the red time when the countdown reaches below 13min
    cardBackground = '#E4FFFC';
    if (timeLeft.minutes < 13) {
      cardBackground = '#FF1D380D';
      timeColor = '#CC2237';
    }
  }

  const { data: exchangeRate, isLoading } = useExchangeRate();

  const totalAmountUSD = inputAmountInRwf / exchangeRate?.result;

  useEffect(() => {
    let isMounted = true;
    if (savedPhoneExist) {
      const phoneNumbers = savedPhones.phone_numbers;
      if (isMounted) {
        setSelectedPhone(phoneNumbers[phoneNumbers.length - 1]);
        setHasSelectedPhones(true);
      }
    } else if (isMounted) {
      setHasSelectedPhones(false);
      setSelectedPhone('');
    }
    return () => {
      isMounted = false;
    };
  }, [savedPhoneExist, savedPhones]);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      if (showCompletePayment) {
        const newCount = count - 1;
        setCount(newCount >= 0 ? newCount : 0);
      }
    }, 1000);
    return () => {
      clearInterval(intervalRef);
    };
  }, [count, setCount, showCompletePayment]);

  useEffect(() => {
    if (showCompletePayment) {
      setTimeout(() => {
        setHasMomoValidate(true);
      }, 15000);
    }
  }, [showCompletePayment]);

  useEffect(() => {
    if (hasMomoValidate && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      callToast('Payment Failed', 'error');
      window.location.reload();
    }
  }, [callToast, hasMomoValidate, timeLeft]);

  useEffect(() => {
    const validateMomoPayment = async () => {
      if (
        hasMomoValidate &&
        validateMomoData &&
        validateMomoData.data.status === 'succeed'
      ) {
        setHasMomoValidate(false);
        callToast('Payment completed', 'success');
        posthog.capture('Funds added with MOMO');
        setTimeout(async () => {
          await queryClient.invalidateQueries({ queryKey: ['userBalance'] });
          await queryClient.invalidateQueries({
            queryKey: ['userBillingHistory'],
          });
          if (isRememberPhone) {
            await queryClient.invalidateQueries({
              queryKey: ['rememberedPhones'],
            });
          }
        }, 3000);
      }
    };
    validateMomoPayment();
  }, [
    callToast,
    hasMomoValidate,
    isRememberPhone,
    queryClient,
    validateMomoData,
  ]);

  const setCheckbox = (checked) => {
    if (checked) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return <Icon name="square" color="#959DA2" size={16} />;
  };

  const payWithMomo = async (data) => {
    try {
      if (!hasSelectedPhones) {
        if (!phoneNumber) {
          setHasError('Please, enter your Phone number');
          return;
        }
        if (phoneNumber) {
          const testPhoneNumber = validatePhoneNumber(phoneNumber);
          if (testPhoneNumber && testPhoneNumber.error) {
            setHasError(testPhoneNumber.error);
            return;
          }
        }
      }
      setIsPaying(true);
      const phoneNumberToUse = hasSelectedPhones ? selectedPhone : phoneNumber;
      const payload = {
        amount: parseFloat(data.amount_in_rwf),
        phonenumber: phoneNumberToUse,
        remember_card: isRememberPhone,
      };

      await mutateMomoPay(payload, {
        onSuccess: (res) => {
          // set count to 15min to finish processing the payment
          setCount(900);
          setTimeout(() => {
            setIsPaying(false);
            if (res?.data?.id) {
              setShowCompletePayment(true);
              setCountDownOn(true);
              setPaymentId(res.data.id);
            }
          }, 3000);
        },
      });
    } catch (err) {
      callToast(`${err.message}, Please try again`, 'error');
      setIsPaying(false);
    }
  };

  const hiddenButtonRef = useRef();

  const handleOutsideButtonClick = () => {
    hiddenButtonRef.current.click();
  };
  return (
    <Bloc>
      <Bloc>
        <Bloc>
          {!showCompletePayment ? (
            <Bloc>
              {isMomoError && (
                <Toast
                  message={momoError?.response?.data?.error?.message}
                  status="error"
                />
              )}
              <Bloc
                maxHeight={['400px', '100%']}
                overflowY={['auto', 'initial']}
                borderRadius="8px"
              >
                {!isLoadingSavedPhones && (
                  <Bloc
                    width="100%"
                    as="form"
                    onSubmit={handleSubmit(payWithMomo)}
                  >
                    <Flex
                      width="100%"
                      flexWrap={['wrap', 'nowrap']}
                      style={{
                        gap: '20px',
                      }}
                    >
                      <Bloc width={1}>
                        {!hasSelectedPhones || !savedPhoneExist ? (
                          <Bloc>
                            <Bloc
                              as="label"
                              fontSize={14}
                              fontWeight={500}
                              mb="9.5px"
                              display="block"
                            >
                              Phone Number{' '}
                              {isLoadingSavedPhones && <Spinner size="large" />}
                            </Bloc>
                            <PhoneInput
                              hasSearch
                              phoneInput={phoneNumber}
                              setPhoneNumber={setPhoneNumber}
                              hasError={hasError}
                              setHasError={setHasError}
                            />
                            {hasError ? (
                              <Bloc mb={16}>{ErrorBelowInput(hasError)} </Bloc>
                            ) : (
                              <Bloc
                                as="span"
                                color="#DEE1E7"
                                fontSize={12}
                                mt={3}
                              >
                                Fill in your Phone number
                              </Bloc>
                            )}
                          </Bloc>
                        ) : (
                          <Bloc>
                            <Bloc
                              as="p"
                              pb={3}
                              m={0}
                              fontWeight="500"
                              fontSize={14}
                            >
                              Select saved phone number{' '}
                            </Bloc>
                            <ClickAwayListener
                              onClickAway={() => setIsDropdownOpen(false)}
                              style={{
                                position: 'relative',
                              }}
                            >
                              <Button
                                style={{
                                  color: '#4a4a4a',
                                  backgroundColor: '#fff',
                                  border: '1px solid',
                                  borderColor: '#D3D3D3',
                                  borderRadius: '4px',
                                  width: '100%',
                                  marginTop: '2.2px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  height: '40px',
                                  alignItems: 'center',
                                  padding: '0px 8px',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsDropdownOpen(!isDropdownOpen);
                                }}
                                data-testid="list-phones-dropdown"
                              >
                                <Bloc
                                  boxSizing="border-box"
                                  display="flex"
                                  justifyContent="initial"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Bloc
                                    as="span"
                                    bg="transparent"
                                    color="#4a4a4a"
                                    display="block"
                                    width="auto"
                                    height="auto"
                                    margin={0}
                                    padding={0}
                                    fontSize={2}
                                    fontWeight="normal"
                                    style={{ textTransform: 'lowercase' }}
                                  >
                                    {selectedPhone}
                                  </Bloc>
                                </Bloc>

                                <Chevron
                                  color="surfaces.3"
                                  size={20}
                                  rotate={isDropdownOpen}
                                />
                              </Button>

                              {isDropdownOpen && (
                                <Box
                                  position="absolute"
                                  top={44}
                                  width="100%"
                                  right={0}
                                  zIndex={999}
                                  style={{
                                    backgroundColor: 'white',
                                    borderRadius: '4px',
                                    textAlign: 'right',
                                    boxShadow:
                                      '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                                  }}
                                >
                                  {savedPhones.phone_numbers.map((phone) => (
                                    <Bloc key={phone}>
                                      <Flex
                                        padding="15px 16px"
                                        boxSizing="border-box"
                                        borderBottom="1px solid"
                                        borderColor="#D3D3D3"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        style={{
                                          cursor: 'pointer',
                                          background:
                                            selectedPhone === phone
                                              ? '#E4FFFC'
                                              : 'transparent',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedPhone(phone);
                                          setIsDropdownOpen(false);
                                        }}
                                        data-testid="phone-item"
                                      >
                                        <Bloc
                                          as="span"
                                          color="#4a4a4a"
                                          display="block"
                                          width="auto"
                                          height="auto"
                                          margin={0}
                                          padding={0}
                                          fontSize={2}
                                          bg="transparent"
                                        >
                                          {phone}
                                        </Bloc>
                                        <Button
                                          onClick={() =>
                                            removeSavedMethod(
                                              'phone_numbers',
                                              phone
                                            )
                                          }
                                          style={{
                                            marginLeft: '20px',
                                            background: 'none',
                                          }}
                                          data-testid="remove-saved-phone"
                                        >
                                          <Icon
                                            name="close"
                                            color="surfaces.3"
                                            size={8}
                                          />
                                        </Button>
                                      </Flex>
                                    </Bloc>
                                  ))}
                                </Box>
                              )}
                            </ClickAwayListener>
                          </Bloc>
                        )}

                        {savedPhoneExist && (
                          <Bloc
                            as="p"
                            pt={16}
                            pb={3}
                            m={0}
                            fontSize={['12px', '16px']}
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() =>
                              setHasSelectedPhones(!hasSelectedPhones)
                            }
                            data-testid="useAnotherPhone"
                          >
                            {hasSelectedPhones
                              ? 'Use another phone number'
                              : 'Use a saved phone number'}
                          </Bloc>
                        )}
                      </Bloc>
                      <Bloc width={1}>
                        <Bloc mb="10px" fontSize="14px" fontWeight={500}>
                          Amount in RWF
                        </Bloc>
                        <Flex
                          px="16px"
                          py="8px"
                          alignItems="center"
                          style={{
                            borderRadius: '4px',
                            border: '1px solid #D3D3D3',
                          }}
                        >
                          <Bloc width="100%">
                            <Bloc
                              as="input"
                              type="text"
                              width="100%"
                              inputmode="numeric"
                              name="amount_in_rwf"
                              autoComplete="off"
                              label="Amount"
                              placeholder="Minimum amount is 5000 RWF"
                              data-testid="amount_in_rwf"
                              hint="Fill in amount in RWF"
                              style={{
                                border: 'none',
                              }}
                              ref={register({
                                required: 'Amount in RWF is required.',
                                validate: {
                                  minAmount: (value) =>
                                    parseFloat(value) > 4999,
                                },
                              })}
                              error={errors?.amount_in_rwf?.message}
                            />
                          </Bloc>
                          {!isLoading &&
                          exchangeRate &&
                          exchangeRate.success ? (
                            <Bloc
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                              fontSize="10px"
                            >
                              {exchangeRate.result.toFixed(2)} RWF/USD
                            </Bloc>
                          ) : (
                            <Spinner size="small" />
                          )}
                        </Flex>
                        <Bloc mb={3}>
                          {errors?.amount_in_rwf?.type === 'minAmount' &&
                            ErrorBelowInput('The minimum amount is 5000 RWF')}
                        </Bloc>
                        {!hasSelectedPhones && (
                          <Flex
                            width="100%"
                            alignItems="center"
                            py={3}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setIsRememberPhone(!isRememberPhone)}
                            data-testid="remember-phone"
                          >
                            {setCheckbox(isRememberPhone)}
                            <Bloc as="label" ml="5px" fontSize={14}>
                              Remember this phone number next time
                            </Bloc>
                          </Flex>
                        )}
                      </Bloc>
                    </Flex>
                    <Bloc
                      ref={hiddenButtonRef}
                      as="button"
                      type="submit"
                      style={{ display: 'none' }}
                    >
                      Submit
                    </Bloc>
                    <Bloc width={1} bg="#0000000F" height="1px" my="15px" />
                  </Bloc>
                )}
              </Bloc>
            </Bloc>
          ) : (
            <Bloc>
              {!hideNotificationMessage && (
                <Bloc
                  px="12px"
                  py="10px"
                  bg={cardBackground}
                  borderRadius="5px"
                >
                  <Flex justifyContent="space-between">
                    <Bloc width="90%">
                      <Text fontSize="12px">
                        {timeLeft.minutes < 2
                          ? `The time for you to make a payment is about to expire. Payoff your`
                          : `Shortly you are going to receive a push message on your phone to enter`}{' '}
                        {timeLeft.minutes < 2
                          ? `balance before the deadline.`
                          : `your MOMO PIN and Confirm your payment.`}
                      </Text>
                    </Bloc>

                    <Bloc
                      role="button"
                      onClick={() => {
                        setHideNonfictionMessage(true);
                      }}
                    >
                      <Icon
                        name="cross"
                        dimension="17x17"
                        color="black"
                        size={16}
                      />
                    </Bloc>
                  </Flex>
                </Bloc>
              )}

              <Bloc as="p" color="black" fontSize={14}>
                Complete your payment within
              </Bloc>

              <Bloc
                as="h2"
                fontSize={37}
                fontWeight={700}
                color={timeColor}
                m={0}
              >
                <Bloc as="span">
                  {timeLeft.minutes < 10 && '0'}
                  {timeLeft?.minutes || 0}
                </Bloc>
                <Bloc as="span" mx={1}>
                  :
                </Bloc>
                <Bloc as="span">
                  {timeLeft.seconds < 10 && '0'}
                  {timeLeft?.seconds || 0}
                </Bloc>
              </Bloc>
            </Bloc>
          )}
        </Bloc>
        {showCompletePayment && (
          <Bloc as="p" fontSize={['12px', '14px']}>
            If you don’t receive a push message press{' '}
            <Bloc color="#0A9080" as="span">
              *182*7*1#
            </Bloc>{' '}
            to complete your payment.
          </Bloc>
        )}
      </Bloc>
      {showCompletePayment && (
        <Bloc width={1} bg="#0000000F" height="1px" my="15px" />
      )}
      <Flex mt={4} flexWrap="wrap" style={{ gap: '15px' }}>
        <Button
          profile={!showCompletePayment ? 'accentLight' : 'primaryDisabled'}
          disabled={showCompletePayment}
          onClick={handleOutsideButtonClick}
          size="contact"
          style={{
            textTransform: 'capitalize',
          }}
          loading={isPaying || isPayingWithMomo}
          data-testid="payWithMomo"
        >
          Load {currencyFormat(totalAmountUSD || 0)} USD
        </Button>
        <Button
          profile="secondary"
          size="contact"
          disabled={showCompletePayment}
          style={{
            textTransform: 'capitalize',
            color: !showCompletePayment && 'black',
          }}
          data-test="cancel-btn"
          onClick={handleHideAccountForm}
          type="button"
        >
          Cancel
        </Button>
      </Flex>
    </Bloc>
  );
};

export default MomoPayment;
