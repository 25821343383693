import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserAPI from '../../../services';

const useRemoveSenderId = ({
  setSelectedSenderIDs,
  selectedSenderIDs,
  setIsSettingDefault,
  setShowToast,
  setIsRemoving,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: mutateRemove,
    isSuccess,
    isPending,
    isError,
    error: removeError,
  } = useMutation({ mutationFn: (id) => userAPI.removeSenderID(id) });

  const handleRemoveSenderID = async (e) => {
    e.stopPropagation();

    if (selectedSenderIDs.length > 0) {
      const senderId = selectedSenderIDs.map((value) => value.id);
      mutateRemove(senderId[0], {
        onSuccess: async () => {
          setShowToast(true);
          setIsRemoving(false);
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setTimeout(() => {
            setSelectedSenderIDs([]);
            setShowToast(false);
          }, 3000);
          setIsSettingDefault(false);
        },
        onError: () => {
          setIsRemoving(false);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        },
      });
    }
  };

  return {
    handleRemoveSenderID,
    removeError,
    isSuccess,
    isPending,
    isError,
  };
};

export default useRemoveSenderId;
