import React from 'react';
import { Bloc } from 'blocjs';
import ProgressiveImage from 'react-progressive-graceful-image';

const Avatar = ({ imageUrl, firstLetterOfUsername }) => (
  <Bloc
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={32}
    height={32}
    textAlign="center"
  >
    <ProgressiveImage src={imageUrl}>
      {(src, loading) =>
        loading ? (
          <Bloc
            bg="#EDEDED"
            width={32}
            height={32}
            borderRadius="9999px"
            display="flex"
            fontSize="16px"
            color="#6E717D"
            fontWeight="bold"
            lineHeight="10px"
            alignItems="center"
            style={{
              textTransform: 'uppercase',
            }}
            justifyContent="center"
          >
            {firstLetterOfUsername}
          </Bloc>
        ) : (
          <Bloc
            as="img"
            width="100%"
            borderRadius="9999px"
            height="100%"
            style={{ objectFit: 'cover' }}
            src={src}
            alt="avatar"
          />
        )
      }
    </ProgressiveImage>
  </Bloc>
);

export default Avatar;
