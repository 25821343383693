/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { Bloc, Box, Flex } from 'blocjs';
import { Icon, Text, Button } from '../../../../components';
import BillingOrgTableData from './billingOrgTableData';

const BillingOrgTable = ({
  columns,
  data,
  onRowClick,
  setRowBackgroundColor,
  onCheckBox,
  setCheckbox,
  showAddFunds,
  onAddFunds,
  selectedOrgs,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy
    );

  const sortColumnIcon = (isColumnSorted, isColumnSortedDesc) => {
    if (isColumnSorted) {
      if (isColumnSortedDesc) {
        return (
          <Icon
            name="accordion"
            color="accents.8"
            size={16}
            dimension="24x24"
          />
        );
      }
      return (
        <Icon
          name="accordion"
          color="accents.8"
          size={16}
          dimension="24x24"
          style={{
            transform: 'rotate(180deg) translateX(0px) translateY(2px)',
          }}
        />
      );
    }
    return (
      <Icon name="accordion" color="accents.8" size={16} dimension="24x24" />
    );
  };

  return (
    <Bloc
      ml={[0, '16%']}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
      borderRadius={3}
      paddding={0}
      bg="surfaces.0"
      overflowX="auto"
      mb={15}
    >
      <Flex
        justifyContent="space-between"
        flexWrap="wrap"
        pb={0}
        px={20}
        pt={17}
      >
        <Box minWidth="16%" mb={6}>
          <Text as="h1" fontSize={21}>
            Organizations
          </Text>
        </Box>
        <Flex justifyContent="space-between">
          <Box style={{ float: 'right' }} width="100%">
            {!showAddFunds && (selectedOrgs?.length || []) === 1 && (
              <Button
                profile="accentLight"
                size="small"
                style={{ float: 'right' }}
                onClick={() => onAddFunds()}
                data-testid="go-back-to-organizations"
              >
                Add Funds
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>
      <Bloc
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <Bloc as="thead">
          {headerGroups.map((headerGroup, indx) => (
            <Bloc
              key={indx}
              as="tr"
              height="30"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Bloc
                  key={column.id}
                  as="td"
                  py={3}
                  px={4}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  data-testid={column.render('Header')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Flex alignItems="center">
                    {column.render('Header')}
                    <Bloc as="span" ml={3}>
                      {sortColumnIcon(column.isSorted, column.isSortedDesc)}
                    </Bloc>
                  </Flex>
                </Bloc>
              ))}
              <Bloc as="td" pl={5} py={3} textAlign="right">
                Selected
              </Bloc>
            </Bloc>
          ))}
        </Bloc>
        <Bloc as="tbody" {...getTableBodyProps()}>
          {rows.map((org) => {
            prepareRow(org);
            return (
              <BillingOrgTableData
                key={org.id}
                org={org}
                setRowBackgroundColor={setRowBackgroundColor}
                onRowClick={onRowClick}
                onCheckBox={onCheckBox}
                setCheckbox={setCheckbox}
              />
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default BillingOrgTable;
