import { Bloc, Flex } from 'blocjs';
import React, { useEffect, useRef } from 'react';
import { dropdownLayout, inputSelectBox } from '../selection/style';
import { Chevron } from '../carets';
import Text from '../text';

const styles = {
  dropdownLayout,
  inputSelectBox,
};

const Dropdown = ({
  children,
  isDropdownOpen,
  isSelect = false,
  selectedItems,
  activeItem,
  setIsDropdownOpen,
  itemsList,
  isNewContact = false,
  handleRemoveSelection,
  disabled = true,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  return (
    <Bloc
      ref={ref}
      aria-hidden="true"
      width="100%"
      position="relative"
      p={0}
      ml={0}
    >
      <Bloc
        aria-hidden="true"
        display="flex"
        backgroundColor={disabled ? '#F7F9FB' : 'white'}
        opacity={disabled ? 0.4 : 1}
        alignItems="center"
        justifyContent="space-between"
        boxSizing="border-box"
        borderRadius={2}
        padding={!isSelect ? '8px 16px' : '5px 16px'}
        height={selectedItems?.length < 1 ? '33px' : ''}
        style={{
          border: '1px solid #d3d3d3',
        }}
      >
        <Bloc
          display="flex"
          alignItems="center"
          overflow="hidden"
          width={1}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDropdownOpen(!isDropdownOpen);
          }}
          style={{ cursor: 'pointer', gap: '6px' }}
        >
          <Flex alignItems="center" width={1}>
            {!isSelect ? (
              <Text
                fontSize="14px"
                style={{
                  textTransform: 'capitalize',
                }}
              >
                <Bloc
                  style={{
                    textTransform: 'initial',
                  }}
                  as="span"
                  fontWeight={200}
                >
                  {isNewContact ? 'option: ' : 'of type: '}
                </Bloc>
                {activeItem === 'Numerical' ? 'Number' : activeItem}
              </Text>
            ) : (
              <SelectedOption
                selectedItems={selectedItems}
                handleRemoveSelection={handleRemoveSelection}
              />
            )}
          </Flex>
          <Chevron
            color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
            size={16}
          />
        </Bloc>
      </Bloc>

      {!disabled && isDropdownOpen && (
        <Bloc
          position="absolute"
          stop={60}
          width={1}
          right={0}
          zIndex={8}
          backgroundColor="white"
          boxSizing="border-box"
          overflow="auto"
          padding={0}
          borderRadius="6px"
          height={itemsList.length > 0 ? 'auto' : 126}
          style={styles.dropdownLayout}
          marginTop={2}
          maxHeight={200}
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)"
        >
          {children}
        </Bloc>
      )}
    </Bloc>
  );
};

export default Dropdown;

const SelectedOption = ({ selectedItems, handleRemoveSelection }) => (
  <Bloc>
    <Flex
      style={{
        gap: '10px',
        flexWrap: 'wrap',
      }}
    >
      {!Array.isArray(selectedItems) ? (
        <Text
          style={{
            padding: '1px 0px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {selectedItems}
        </Text>
      ) : (
        <>
          {selectedItems.map((select) => (
            <Flex
              px="6px"
              py="2.5px"
              alignItems="center"
              justifyContent="space-between"
              style={{
                backgroundColor: '#BDFBF3',
                fontSize: '12px',
                gap: '12px',
              }}
              onClick={(e) => {
                handleRemoveSelection(e, select.name);
              }}
            >
              <Text
                style={{
                  textOverflow: 'ellipsis',
                  textTransform: 'initial',

                  whiteSpace: 'nowrap',
                }}
              >
                {select.name}
              </Text>
              <Bloc fontWeight="500" fontSize="13px">
                ×
              </Bloc>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  </Bloc>
);
