/* eslint-disable no-nested-ternary */
import { Bloc, Flex } from 'blocjs';
import React from 'react';
import ExcelSvg from '../icon/excel';
import Icon from '../icon';
import truncateLongString from '../../utils/trucanteLongString';

const UploadContactFromSendMessage = ({
  showContactsInfo,
  fileName,
  importedContactLength,
  progressPercentage,
  handleDragOver,
  handleDragLeave,
  handleDrop,
  isDragActive,
  onFileChange,
  handleDownloadSample,
  message = true,
  removeUploadedFile,
}) => (
  <>
    {!showContactsInfo ? (
      <Flex
        htmlFor="fileElem"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        alignItems="center"
        py="10px"
        px="16px"
        onClick={() => document.getElementById('fileInput').click()}
        style={{
          gap: '10px',
          borderRadius: '0px 0px 10px 10px',
          border: isDragActive
            ? '1px dashed #0A9080'
            : '1px dashed transparent',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#0eb6a230' : 'transparent',
        }}
      >
        <Bloc
          width="26px"
          height="26px"
          borderRadius="9999px"
          bg={isDragActive ? '#0EB6A2' : '#F9F9F9'}
          display="flex"
          color={isDragActive ? 'white' : '#6E717D'}
          justifyContent="center"
          alignItems="center"
        >
          <Icon
            name="cloud_upload_2"
            size={message ? 15 : 20}
            dimension="40x40"
          />
        </Bloc>

        <Bloc
          as="p"
          m={0}
          p={0}
          fontSize={message ? ['10px', '11px'] : ['10px', '14px']}
        >
          Add contacts from a file by dragging or by{' '}
          <Bloc
            as="span"
            style={{
              cursor: 'pointer',
              color: '#0A9080',
              fontWeight: 400,
              textDecoration: 'underline',
            }}
          >
            choosing a file
          </Bloc>{' '}
          here is a{' '}
          <Bloc
            as="span"
            to="/contact?tab=active"
            onClick={handleDownloadSample}
            style={{
              cursor: 'pointer',
              color: '#0A9080',
              fontWeight: 400,
              textDecoration: 'underline',
            }}
          >
            template.csv
          </Bloc>
        </Bloc>
        <Bloc
          as="input"
          type="file"
          accept=".csv, .xls, .xlsx"
          onChange={onFileChange}
          style={{ display: 'none' }}
          id="fileInput"
        />
      </Flex>
    ) : (
      <Bloc
        display="flex"
        height="100%"
        flexDirection="column"
        bg="#F9F9F9"
        style={{
          borderRadius: '0px 0px 10px 10px',
        }}
        px="10px"
        py="7px"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Bloc
              py="2px"
              px="2px"
              borderRadius="5px"
              style={{
                border: '1px solid #E0E0E0',
              }}
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ExcelSvg
                style={{
                  width: '13px',
                  height: '13px',
                }}
              />
            </Bloc>

            <Bloc fontWeight="300" as="span" fontSize="10px">
              {truncateLongString(fileName, 19)} - {importedContactLength}{' '}
              contacts
            </Bloc>
          </Flex>

          <Bloc
            onClick={removeUploadedFile}
            style={{
              cursor: 'pointer',
            }}
          >
            <Icon name="close" color="black" size={8} />
          </Bloc>
        </Flex>

        {
          // Show progress bar only when the progress percentage is less than 100
          progressPercentage < 100 && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              style={{
                gap: '10px',
              }}
            >
              <Bloc width="100%" display="flex" alignItems="center">
                <Bloc width="100%" bg="#E0E0E0" borderRadius="9999px">
                  <Bloc
                    width={`${progressPercentage}%`}
                    color="#fff"
                    backgroundColor="#0EB6A2"
                    px={3}
                    height="3px"
                    borderRadius="9999px"
                    textAlign="center"
                    fontSize="9px"
                    style={{
                      float: 'left',
                      WebkitTransition: 'width .6s ease',
                      OTransition: 'width .6s ease',
                      transition: 'width .6s ease',
                    }}
                    role="progressbar"
                    aria-valuenow={progressPercentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </Bloc>
              </Bloc>

              <Flex border="1px solid red">
                {progressPercentage < 100 ? (
                  <Bloc fontSize="9px" as="span">
                    {' '}
                    {progressPercentage}%
                  </Bloc>
                ) : (
                  <Bloc
                    as="span"
                    width="10px"
                    height="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="9999px"
                    bg="#0EB6A2"
                  >
                    <Icon
                      name="complete_check"
                      color="white"
                      dimension="7x5"
                      size={6}
                    />
                  </Bloc>
                )}
              </Flex>
            </Flex>
          )
        }
      </Bloc>
    )}
  </>
);

export default UploadContactFromSendMessage;
