import { Flex } from 'blocjs';
import React from 'react';
import Button from '../../button';

const ArchivedActionButtons = ({ action, handleActions }) => {
  switch (action.trigger) {
    case 'archived-tab':
      return (
        <Flex
          alignItems="center"
          style={{
            columnGap: '6px',
          }}
        >
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
            }}
            profile="accentLight"
            size="contact"
            onClick={() =>
              handleActions('archived-tab', action?.result, 'move-to-unarchive')
            }
          >
            unarchive
          </Button>
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
              color: 'black',
            }}
            profile="secondary"
            size="contact"
            onClick={() =>
              handleActions('archived-tab', action?.result, 'delete-contact')
            }
          >
            Delete
          </Button>
        </Flex>
      );
    default:
      return null;
  }
};

export default ArchivedActionButtons;
