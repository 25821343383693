import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Inputfield } from '../../../../../components';

const FilterForm = ({ searchedMany, searchByMany }) => (
  <Bloc ml={[0, '16%']}>
    <Bloc
      mb={6}
      backgroundColor="white"
      borderRadius="8px"
      border="1px solid #DEE1E7"
      boxSadow="none"
    >
      <Flex
        flexWrap={['wrap', 'no-wrap']}
        justifyContent="space-between"
        padding="16px 24px"
      >
        <Box ml={[0, 0, 0]} mt={[3, 0, 0]} width={[1, 'calc(100% - 128px)']}>
          <Inputfield
            name="text"
            placeholder="Search by country name, code, price or telco"
            value={searchedMany}
            onChange={(e) => searchByMany(e)}
            hasLabel={false}
            hasHint={false}
          />
        </Box>

        <Box ml={[0, 0, 4]} mt={[3, 3, 0]} width={[1, 1, 'auto']}>
          <Button
            profile="accentLight"
            disabled
            size="contact"
            onClick={() => {}}
            data-test="filter-btn"
            style={{
              textTransform: 'capitalize',
              height: '40px',
              float: 'right',
            }}
          >
            Search
          </Button>
        </Box>
      </Flex>
    </Bloc>
  </Bloc>
);
export default FilterForm;
