/* eslint-disable react/no-array-index-key */

import { Bloc, Box, Flex } from 'blocjs';
import React from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';
import CheckedBox from '../checkedbox';
import Icon from '../icon';

const ContactTable = ({
  columns,
  data,
  selectedContacts,
  onRowClick,
  setRowBackgroundColor,
  toggleSelectAll,
  selectAll,
  activeTab,
}) => {
  const isContactSelected = selectedContacts && selectedContacts?.length > 1;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy
    );

  return (
    <Bloc zIndex={1} paddding={0} bg="surfaces.0" overflowX="auto">
      <Bloc
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <Bloc as="thead">
          {headerGroups.map((headerGroup, indx) => (
            <Bloc
              key={indx}
              as="tr"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <Bloc
                  key={column.id}
                  as="td"
                  py="14px"
                  px="23px"
                  data-testid={column.render('Header')}
                  style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}
                >
                  <Flex
                    justifyContent={
                      index === headerGroup.headers.length - 1
                        ? 'flex-end'
                        : 'flex-start'
                    }
                    alignItems="center"
                    style={{
                      textTransform: 'capitalize',
                      gap: '10px',
                    }}
                  >
                    {column.render('Header')}
                    {index === headerGroup.headers.length - 1 &&
                      isContactSelected &&
                      activeTab !== 'groups' && (
                        <Bloc as="span" style={{ cursor: 'pointer' }}>
                          <Box onClick={toggleSelectAll}>
                            {selectAll ? (
                              <CheckedBox color="accentDark" size={16} />
                            ) : (
                              <Icon name="square" color="#959DA2" size={16} />
                            )}
                          </Box>
                        </Bloc>
                      )}
                  </Flex>
                </Bloc>
              ))}
            </Bloc>
          ))}
        </Bloc>
        <Bloc as="tbody" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Bloc
                key={row.id}
                as="tr"
                bg={setRowBackgroundColor(row.original.name)}
                textAlign="left"
                borderBottom="1px solid #DFDBDB"
                data-test="data-row"
                style={{
                  cursor: 'pointer',
                }}
                {...row.getRowProps({
                  style: { cursor: 'pointer' },
                  onClick: (e) => {
                    onRowClick(row, e);
                  },
                })}
              >
                {row.cells.map((cell) => (
                  <Bloc
                    key={cell.column.id}
                    as="td"
                    py="14px"
                    px="23px"
                    {...cell.getCellProps()}
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {cell.render('Cell')}
                  </Bloc>
                ))}
              </Bloc>
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default ContactTable;
