import { Bloc, Box, Flex } from 'blocjs';
import React, { useState } from 'react';
import { startOfWeek } from 'date-fns';
import { Text } from '../../components';
import PeriodAndProductSwitch from '../../components/periodAndProductSwitch';
import PeriodFilter from '../../components/periodFilter';
import { dashboardProductOptions } from '../../constants';
import SelectionInput from '../../components/selection';

const GeneraleStats = ({
  startDate,
  period,
  setPeriod,
  handleChangePeriod,
  setStartDate,
}) => {
  const [selectedProduct, setselectedProduct] = useState([]);
  const handleSelectionChange = (chosenItemIndex, chosenItem) => {
    const updatedStatuses = [...selectedProduct];
    updatedStatuses[chosenItemIndex] = chosenItem.name;
    setselectedProduct(updatedStatuses);
  };

  const periodFunctions = {
    day: (date) => date,
    week: (date) => startOfWeek(date, { weekStartsOn: 1 }),
  };

  const handlePeriodSwitch = (item) => {
    let startingDate;

    switch (item) {
      case 'Today':
        startingDate = new Date();
        break;
      case 'Week':
        startingDate = periodFunctions.week && periodFunctions.week(new Date());

        break;
      case 'Month':
        startingDate = new Date();

        break;
      case 'Quarter':
        startingDate = new Date();

        break;
      case 'Year':
        startingDate = new Date();

        break;
      default:
    }
    setPeriod(item);
    setStartDate(startingDate);
  };

  return (
    <Flex
      flexDirection="column"
      style={{
        rowGap: '24px',
      }}
    >
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Box style={{ float: 'left' }} minWidth="16%">
          <Text as="h1" fontSize={28}>
            Billing Dash
            <Bloc hide={[true, null]} as="span">
              board
            </Bloc>
          </Text>
        </Box>
      </Flex>
      <Flex
        hide={[true, null]}
        alignItems="center"
        style={{
          gap: '16px',
        }}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <PeriodFilter
          startDate={startDate}
          handlePeriodSwitch={handlePeriodSwitch}
          period={period}
          setPeriod={setPeriod}
          handleChangePeriod={handleChangePeriod}
        />

        <Flex
          alignItems="center"
          style={{
            columnGap: '12px',
          }}
        >
          <PeriodAndProductSwitch
            startDate={startDate}
            handleChangePeriod={handleChangePeriod}
            period={period}
            handleSelectionChange={handleSelectionChange}
          />
        </Flex>
        <Bloc display={['block', 'none']}>
          {dashboardProductOptions.map((product, index) => {
            const activeItemIndex = dashboardProductOptions.findIndex(
              (item) => item.name === product.name
            );

            return (
              <SelectionInput
                isDashboard
                key={product.id}
                dropDownItems={dashboardProductOptions}
                activeItemIndex={activeItemIndex > -1 ? activeItemIndex : 0}
                onSelectionChange={(newProduct) =>
                  handleSelectionChange(index, newProduct)
                }
              />
            );
          })}
        </Bloc>
      </Flex>
    </Flex>
  );
};

export default GeneraleStats;
