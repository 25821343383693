import React from 'react';
import { Bloc } from 'blocjs';

const OrgTableData = ({ setRowBackgroundColor, onRowClick, org }) => (
    <Bloc
      as="tr"
      textAlign="left"
      borderBottom="1px solid #DFDBDB"
      background={setRowBackgroundColor(org.id)}
      style={{ cursor: 'pointer' }}
      data-test="data-row"
      data-testid="data-row"
      height='48px'
      {...org.getRowProps({
        style: { cursor: 'pointer' },
        onClick: (e) => onRowClick(org, e),
      })}
    >
      {org.cells.map((cell) => (
          <Bloc as="td" py={3} px={4} {...cell.getCellProps()} key={cell.column.id} 
            style={{ float: cell.column.floatRight ? 'right' : 'initial' }}>
              {cell.render('Cell')}
          </Bloc>
      ))}
    </Bloc>
  );

export default OrgTableData;
