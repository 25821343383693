import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useSavedCards = () => {
  const { data: savedCards, isLoading: isLoadingSavedCards } = useQuery({
    queryKey: ['rememberedCards'],
    queryFn: (payload) => userAPI.fetchRememberedCards(payload),
    placeholderData: keepPreviousData,
  });
  return { savedCards, isLoadingSavedCards };
};

export default useSavedCards;
