import React, { useState, useMemo, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import {
  Layout,
  Text,
  Pagination,
  CheckedBox,
  Icon,
} from '../../../../components';
import FilterForm from './filterform';
import { portabilityStatuses, senderIDRegulated } from '../../../../utils';
import { useFetchCountries } from '../../../../hooks';
import Table from './table';

const StatusLabel = ({ status, textLabel }) => {
  let statusColor = '#088C7D';
  let statusBg = '#EBF9F5';
  if (!status) {
    statusColor = '#FF3049';
    statusBg = '#FFD6DB';
  }
  return (
    <Bloc display="flex" p={0} alignItems="center" fontSize={14} pl={0}>
      <Bloc pl={0} style={{ whiteSpace: 'initial' }} width={1}>
        <Bloc
          as="span"
          color={statusColor}
          bg={statusBg}
          padding="4px 10px"
          borderRadius={27}
          fontSize={10}
          fontWeight="bold"
          display="block"
          boxSizing="border-box"
          width="fit-content"
          style={{ textTransform: 'uppercase' }}
        >
          {textLabel}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

const Countries = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [portabilityOption, setPortabilityOption] = useState('');
  const [regulatedOption, setRegulatedOption] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [crossAttr, setCrossAttr] = useState('');
  const [countriesList, setCountriesList] = useState([]);
  const history = useHistory();
  const itemsPerPage = 25;
  const [isFetchEnabled, setIsFetchEnabled] = useState(true);

  const {
    data: filteredCountries,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
  } = useFetchCountries(
    pageNumber,
    itemsPerPage,
    portabilityOption,
    regulatedOption,
    crossAttr,
    isFetchEnabled
  );

  const onEditCountry = (countryId) => {
    history.push('/admin/manage/country-details', {
      id: 'editCountry',
      countryId,
    });
  };

  useEffect(() => {
    if (filteredCountries && filteredCountries.countries) {
      setCountriesList(filteredCountries.countries);
    }
  }, [filteredCountries]);

  const searchByCrossAttr = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setCrossAttr(inputValue);
    setIsFetchEnabled(false);
  };

  const filterCountries = () => {
    setPageNumber(1);
    setIsFetchEnabled(true);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    setIsFetchEnabled(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Country Name',
        floatRight: false,
        accessor: (row) => row.name,
      },
      {
        Header: 'MCC',
        floatRight: false,
        pxReduced: true,
        accessor: (row) => row.mcc,
      },
      {
        Header: 'Region Code',
        floatRight: false,
        pxReduced: true,
        accessor: (row) => row.region_code,
      },
      {
        Header: 'Country Code',
        floatRight: false,
        pxReduced: true,
        accessor: (row) => `+${row.country_code}`,
      },
      {
        Header: 'NUMBER PORTABILITY',
        pxReduced: true,
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <StatusLabel
            status={original.has_phone_number_portability}
            textLabel={
              original.has_phone_number_portability
                ? 'Portable'
                : 'Not Portable'
            }
          />
        ),
      },
      {
        Header: 'SENDER ID REGULATION',
        floatRight: false,
        pxReduced: true,
        Cell: ({ row: { original } }) => (
          <StatusLabel
            status={original.is_sender_id_regulated}
            textLabel={
              original.is_sender_id_regulated ? 'Regulated' : 'Not regulated'
            }
          />
        ),
      },
    ],
    []
  );

  const onCheckBox = (country, e) => {
    e.stopPropagation();
    const items = [...selectedCountries];
    const index = items.findIndex((item) => item.id === country.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedCountries(items);
    } else {
      setSelectedCountries([country]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedCountries.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const countriesData = useMemo(() => countriesList, [countriesList]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    const countryId = row.original.id;
    history.push('/admin/manage/country-details', { countryId });
  };

  const hasInputFilled = !!crossAttr;

  const handlePortability = (option) => {
    if (option) {
      setPortabilityOption(option);
    } else {
      setPortabilityOption('');
    }
    setIsFetchEnabled(true);
  };

  const handleRegulated = (option) => {
    if (option) {
      setRegulatedOption(option);
    } else {
      setRegulatedOption('');
    }
    setIsFetchEnabled(true);
  };

  return (
    <Layout>
      <>
        <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
          <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
            <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
              Countries
            </Text>
          </Box>
        </Flex>
        <Box mt={0} />
        <FilterForm
          filterCountries={filterCountries}
          isFetching={isFilterFetching}
          isLoading={isFilterLoading}
          hasInputFilled={hasInputFilled}
          portabilityOption={portabilityOption}
          crossAttr={crossAttr}
          searchByCrossAttr={searchByCrossAttr}
          portabilityStatuses={portabilityStatuses}
          senderIDRegulated={senderIDRegulated}
          regulatedOption={regulatedOption}
          handlePortability={handlePortability}
          handleRegulated={handleRegulated}
        />
        <Table
          columns={columns}
          data={countriesData}
          onRowClick={onRowClick}
          portabilityOption={portabilityOption}
          setCheckbox={setCheckbox}
          onCheckBox={onCheckBox}
          onEditCountry={onEditCountry}
          selectedCountries={selectedCountries}
        />
        {filteredCountries && filteredCountries.pages && (
          <Pagination
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            pages={filteredCountries?.pages}
            pageCount={filteredCountries?.pages?.pages}
            changePage={changePage}
            isFetching={isFilterFetching}
            isLoading={isFilterLoading}
          />
        )}
      </>
      <Bloc mt={4} />
    </Layout>
  );
};

export default Countries;
