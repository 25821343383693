/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { invertObject, slugify } from '../../utils';
import filterContactsWorker from '../../worker/filterImportContactsWorkers';
import { WebWorker } from '../../worker';

const instance = new WebWorker(filterContactsWorker);

const useUploadContact = ({ setGetDataTypes }) => {
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [invalidContacts, setInvalidContacts] = useState(0);
  const [duplicates, setDuplicates] = useState([]);
  const [fileName, setFileName] = useState('');
  const [totalItemsToUpload, setTotalItemsToUpload] = useState(0);
  const [getFileName, setGetFileName] = useState('');
  const [headerMentions, setHeaderMentions] = useState([]);
  const [showContactError, setShowContactsError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    instance.onmessage = (message) => {
      const { data } = message;
      if (data) {
        const {
          newContactsArray,
          newInvalidContacts,
          newDuplicatesArray,
          selections,
          dataTypes,
          allValues,
          progress,
        } = data;

        if (progress !== undefined) {
          setProgressPercentage(progress);
        }
        if (
          newContactsArray !== undefined &&
          newInvalidContacts !== undefined &&
          newDuplicatesArray !== undefined
        ) {
          const initialDataType =
            dataTypes &&
            Object.entries(dataTypes).map(([key, item], index) => ({
              id: index,
              name: key.toLowerCase(),
              isDefault: false,
              isSelected: true,
              defaultValues: item === 'text' && allValues[key],
              input_type: item === 'select' ? 'select' : 'input',
              data_type: item === 'select' ? 'text' : item,
              options: item === 'select' && selections[key],
            }));
          // Handle the result received from the web worker
          const structureDatatype = [
            {
              id: 0,
              name: 'Phone Number',
              data_type: 'text',
              isDefault: true,
            },
            {
              id: 1,
              name: 'Name',
              data_type: 'text',
              isDefault: true,
            },
          ];

          const finalIteration = initialDataType
            ? [...structureDatatype, ...initialDataType]
            : structureDatatype;

          setGetDataTypes(
            finalIteration.map((item, index) => ({
              ...item,
              id: index,
            }))
          );

          const contactsStructure = newContactsArray.map((item) => {
            const { phone_number: phoneNumber, name, ...extraFields } = item;
            return {
              phone_number: phoneNumber,
              name,
              state: 'active',
              extra_fields: invertObject(extraFields),
            };
          });

          setContacts(contactsStructure);
          setInvalidContacts(newInvalidContacts);
          setDuplicates(newDuplicatesArray);
          setFileName(getFileName);
        }
      }
    };
  }, [totalItemsToUpload]);

  const clearAllContacts = () => {
    setContacts([]);
    setInvalidContacts(0);
    setDuplicates([]);
    setProgressPercentage(0);
    setHeaderMentions([]);
    setShowContactsError(null);
  };

  const processCSVData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i += 1) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j += 1) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columnsList = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    let headerTel = '';

    if (columnsList.length > 0) {
      const isHeaderTel = columnsList.some((header) => {
        const slugifiedHeader = slugify(header.name);
        if (
          slugifiedHeader === slugify('phone number') ||
          slugifiedHeader === slugify('phone numbers')
        ) {
          headerTel = header.name;
          return true;
        }
        return false;
      });

      const isHeaderName = columnsList.some(
        (header) => slugify(header.name) === slugify('name')
      );

      if (isHeaderTel && isHeaderName) {
        if (list.length > 0) {
          const fileHeaders = headers.map((header) => header);
          setHeaderMentions(fileHeaders);
          setTotalItemsToUpload(
            list.length + contacts.length + invalidContacts + duplicates.length
          );

          instance.postMessage({
            list,
            headerTel,
          });
        }
      } else {
        // setIsUploading(true);
        setShowContactsError(
          'The file is missing one of the required column ("Phone number" | "name") use the template for the correct format'
        );
      }
    }
    setIsUploading(false);
  };

  const readFileUploaded = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataArray = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      setGetFileName(file?.name);
      processCSVData(dataArray);
    };
    if (file) reader.readAsBinaryString(file);
  };

  const validateFile = (fileUploaded) => {
    const validExts = ['.csv', '.xls', '.xlsx'];
    const allowedFileTypes = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    if (fileUploaded && allowedFileTypes.indexOf(fileUploaded.type) === -1) {
      setShowContactsError(
        `Invalid File Type, valid files are of ${validExts.toString()} types`
      );
      return false;
    }
    // check the size
    const maxSizeInBytes = 15e6; // 15MB
    if (fileUploaded && fileUploaded.size > maxSizeInBytes) {
      setShowContactsError(
        'File too large, should not be more than 15MB in size'
      );
      return false;
    }
    return true;
  };

  const processTheValidFileData = (fileToRead) => {
    if (validateFile(fileToRead)) {
      setIsUploading(true);
      readFileUploaded(fileToRead);
    }
  };

  const handleFileUpload = (file) => {
    if (file) {
      clearAllContacts();
      processTheValidFileData(file);
    } else {
      clearAllContacts();
      setFileName('');
    }
  };

  return {
    fileName,
    isUploading,
    progressPercentage,
    showContactError,
    contacts,
    invalidContacts,
    duplicates,
    headerMentions,
    handleFileUpload,
    clearAllContacts,
  };
};

export default useUploadContact;
