import React from 'react';
import { Box } from 'blocjs';
import { Layout } from '../../../components';
import { useFetchAllOrgs, useFetchSenderIDsByStatus } from '../../../hooks';
import UsageStats from './usagestats';
import GeneralStats from './generalstats';

const Dashboard = () => {
  const itemsPerPage = 25;
  const pageNumber = 1;
  const { data: pendingSenderIDs, isLoading: isPendingSenderIDsLoading } =
    useFetchSenderIDsByStatus('pending', pageNumber, itemsPerPage);

  const balanceStatus = { value: false };
  const { data: orgsData, isLoading: isOrgsLoading } = useFetchAllOrgs(
    pageNumber,
    itemsPerPage,
    undefined,
    undefined,
    balanceStatus
  );

  const allPending = pendingSenderIDs?.sender_ids || [];
  const totalPending = allPending.length;
  const allLowBalance = orgsData?.orgs || [];
  const totalLowBalance = allLowBalance.length;

  return (
    <Layout>
      <GeneralStats isLoading={isPendingSenderIDsLoading} />
      <Box mt={6} />
      <UsageStats
        totalPending={totalPending}
        isPendigSenderIDsLoadin={isPendingSenderIDsLoading}
        totalLowBalance={totalLowBalance}
        isOrgsLoading={isOrgsLoading}
      />
      <Box mt={340} />
    </Layout>
  );
};

export default Dashboard;
