/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown';
import Content from './content';

const ContactDropDown = ({
  dropDownItems,
  step = false,
  disabled = false,
  activeItemIndex = 0,
  isNewContact,
  onSelectionChange,
  isSelect,
}) => {
  // const [selectedItem, setSelectedItem] = useState('select an option');
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState('Select Item');

  useEffect(() => {
    setItems(dropDownItems);
    if (step) {
      setSelectedItems(dropDownItems[activeItemIndex]);
    }
  }, []);

  const handleSelectedItem = (activeItem) => {
    setIsItemsDropdown(false);
    setSelectedItems(activeItem);
    onSelectionChange(activeItem);
  };

  const handleRemoveSelection = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    const filterSelection = selectedItems.filter(
      (select) => item !== select.name
    ); // remove selection
    if (selectedItems.length <= 1) {
      setSelectedItems('Select Item');
    } else {
      setSelectedItems(filterSelection);
    }
  };

  return (
    <Dropdown
      activeItem={selectedItems}
      selectedItems={selectedItems}
      isSelect={isSelect}
      disabled={disabled}
      isDropdownOpen={isItemsDropdown}
      itemsList={items}
      isNewContact={isNewContact}
      setIsDropdownOpen={setIsItemsDropdown}
      handleRemoveSelection={handleRemoveSelection}
    >
      <Content
        isSelect={isSelect}
        handleSelectedItem={handleSelectedItem}
        itemsList={items}
      />
    </Dropdown>
  );
};

export default ContactDropDown;
