/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Box, Flex, Bloc } from 'blocjs';
import posthog from 'posthog-js';
import ClickAwayListener from 'react-click-away-listener';
import { requestMethod, urlValidation } from '../../utils';
import { Inputfield, Button, Chevron, Text } from '../../components';
import UserAPI from '../../services';
import AccountContainer from '../userAccount/container';
import CustomToast from '../../components/toast/customToast';

const ChangeSMSCodeForm = ({
  smsCode,
  setCurrentScreen,
  onCancelTheSubscription,
  isDeleteInboundPending,
}) => {
  const [headers, setHeaders] = React.useState([]);
  const [headerMethod, setHeaderMethod] = React.useState('');
  const [isMethodChanged, setIsMethodChanged] = React.useState(false);
  const [isHeadersChanged, setIsHeadersChanged] = React.useState(false);

  useEffect(() => {
    if (smsCode) {
      const formattedHeaders = Object.entries(
        smsCode?.webhook_headers || { key: 'value' }
      ).map(([key, value]) => ({ key, value }));
      setHeaderMethod(smsCode?.webhook_method?.split('.')[1] || 'GET');
      setHeaders(formattedHeaders);
    }
  }, [smsCode]);

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, formState } = useForm();
  const [showToast, setShowToast] = React.useState(false);
  const { mutate, isError, isSuccess, error, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateInboundUrl(payload),
  });

  const onFormSubmit = async (req) => {
    const payload = {
      webhook_url: req.inbound_sms_webhook_url,
      id: smsCode.id,
      webhook_headers: headers.reduce((acc, header) => {
        if (header.key && header.value) {
          acc[header.key] = header.value;
        }
        return acc;
      }, {}),
      webhook_method: headerMethod?.toLowerCase(),
    };

    mutate(payload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: 'inboundNumbers' });
        posthog.capture('updated inbound number webhook', {
          property: payload,
        });
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          setCurrentScreen('idle');
        }, 2000);
      },
      onError: () => {
        setShowToast(true);
      },
    });
  };

  const handleHeaderChange = (index, event) => {
    setIsHeadersChanged(true);

    const newHeaders = headers.map((header, i) => {
      if (i === index) {
        return { ...header, [event.target.name]: event.target.value };
      }
      return header;
    });
    setHeaders(newHeaders);
  };

  const addHeaderField = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  return (
    <AccountContainer pt={0}>
      <Bloc p="20px" as="form" onSubmit={handleSubmit(onFormSubmit)}>
        {isError && (
          <CustomToast
            showToast={showToast}
            message={error?.response?.data?.error?.message}
            status="error"
          />
        )}
        {isSuccess && (
          <CustomToast
            showToast={showToast}
            message="Webhook URL was updated successfully"
            status="success"
          />
        )}

        <Bloc
          display="flex"
          flexWrap="wrap"
          style={{
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '10px',
          }}
        >
          <Flex
            width={1}
            style={{
              gap: '14px',
            }}
          >
            <Bloc
              display="flex"
              flexDirection="column"
              style={{
                gap: '9px',
              }}
            >
              <Bloc fontSize="14px" fontWeight="500">
                Method
              </Bloc>
              <ClickAwayListener
                onClickAway={() => setIsDropdownOpen(false)}
                data-test="hide-dropdown"
              >
                <Flex
                  height={40}
                  justifyContent="space-between"
                  alignItems="center"
                  px="6px"
                  style={{
                    borderRadius: '4px',
                    gap: '10px',
                    border: '1px solid #d3d3d3',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <Text fontSize="14px">{headerMethod}</Text>
                  <Chevron
                    color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
                    size={16}
                  />
                </Flex>
                {isDropdownOpen && (
                  <Bloc
                    position="absolute"
                    top={40}
                    width="100%"
                    right={0}
                    marginTop={2}
                    padding="14px 10px"
                    zIndex={999}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      boxShadow:
                        '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                    }}
                  >
                    {Object.entries(requestMethod).map(([value]) => (
                      <Bloc
                        key={value}
                        py={2}
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setHeaderMethod(value);
                          setIsMethodChanged(true);
                        }}
                      >
                        <Text
                          hoverAsLink
                          variant="small"
                          style={{ cursor: 'pointer' }}
                        >
                          {value}
                        </Text>
                      </Bloc>
                    ))}
                  </Bloc>
                )}
              </ClickAwayListener>
            </Bloc>
            <Flex
              style={{
                flex: '1 1 0%',
              }}
            >
              <Bloc width={1}>
                <Inputfield
                  type="text"
                  placeholder="Inbound Webhook URL"
                  name="Inbound SMS Webhook URL"
                  data-testid="confirm-password"
                  ref={register({
                    pattern: urlValidation,
                  })}
                  error={errors?.inbound_sms_webhook_url?.message}
                  defaultValue={smsCode.webhook_url}
                />
              </Bloc>
            </Flex>
          </Flex>

          <Bloc
            display="flex"
            flexDirection="column"
            style={{
              gap: '8px',
              flex: '1 1 0%',
            }}
          >
            <Bloc fontSize="14px" fontWeight="500">
              Webhook Headers
            </Bloc>

            {headers.map((header, index) => (
              <Flex
                style={{
                  gap: '10px',
                }}
                key={index}
              >
                <Bloc width={1}>
                  <Inputfield
                    type="text"
                    hasLabel={false}
                    name="key"
                    onChange={(event) => handleHeaderChange(index, event)}
                    placeholder="Key"
                    value={header.key === 'key' ? '' : header.key}
                  />
                </Bloc>
                <Bloc width={1}>
                  <Inputfield
                    type="text"
                    hasLabel={false}
                    name="Value"
                    value={header.value === 'value' ? '' : header.value}
                    onChange={(event) => handleHeaderChange(index, event)}
                    placeholder="Value"
                  />
                </Bloc>
              </Flex>
            ))}

            <Text
              onClick={addHeaderField}
              fontSize="12px"
              color="#0EB6A2"
              style={{
                cursor: 'pointer',
              }}
            >
              + Add Header
            </Text>
          </Bloc>
          <Box>
            <Inputfield
              type="text"
              placeholder="Phone number"
              name="Phone number"
              defaultValue={smsCode.number}
              disabled
            />
          </Box>
        </Bloc>

        <Flex
          style={{
            borderTop: '1px solid #DFDBDB',
            gap: '10px',
          }}
          pt="20px"
          mt={4}
          flexWrap="wrap"
        >
          <Button
            profile={
              (!formState.dirty || formState.isSubmitted) &&
              !isMethodChanged &&
              !isHeadersChanged
                ? 'secondary'
                : 'accentLight'
            }
            size="contact"
            loading={isPending}
            disabled={
              (isPending || !formState.dirty) &&
              !isMethodChanged &&
              !isHeadersChanged
            }
            data-testid="update-btn"
          >
            Update Webhook
          </Button>
          <Button
            profile="secondary"
            size="contact"
            loading={isDeleteInboundPending}
            data-testid="update-btn"
            type="button"
            onClick={(e) => {
              onCancelTheSubscription(e, smsCode.id);
            }}
          >
            Remove Inbound Number
          </Button>
          <Button
            profile="secondary"
            size="contact"
            onClick={() => setCurrentScreen('idle')}
            data-test="cancel-btn"
          >
            Cancel
          </Button>
        </Flex>
      </Bloc>
    </AccountContainer>
  );
};

export default ChangeSMSCodeForm;
