export function calculateTotalCount(data, key) {
  return Object.values(data)
    .flatMap((items) => items)
    .reduce((acc, { [key]: val }) => acc + (val || 0), 0);
}

export function calculateStatusCount(data, status) {
  return Object.entries(data).reduce(
    (sum, [, statusDate]) =>
      sum + (statusDate?.status?.[status]?.sms_count || 0),
    0
  );
}
