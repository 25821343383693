import React, { useState, useEffect, useContext } from 'react';
import { Box } from 'blocjs';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import GeneralStats from './generalstats';
import { Layout, Toast } from '../../components';
import UserAPI from '../../services';
import {
  calculateStatusCount,
  extractDateLabels,
  getTimezone,
  sortDashboardDataByOrder,
} from '../../utils';
import { useAcceptInvite } from '../../hooks';
import { AuthContext } from '../../contexts';
import UsageStats from './usagestats';
import getStartDateOfPeriod from '../../utils/startDateFunction';

const Dashboard = () => {
  const location = useLocation();
  const userAPI = new UserAPI();

  const [inviteData, setInviteData] = useState({});
  const [hasInvitation, setHasInvitation] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [period, setPeriod] = useState('Today');

  const {
    data: acceptData,
    status: acceptStatus,
    error: acceptError,
  } = useAcceptInvite(inviteData, hasInvitation);

  useEffect(() => {
    if (location.inviteData) {
      setInviteData(location.inviteData);
      setHasInvitation(true);
    }
  }, [location, location.inviteData, setHasInvitation, setInviteData]);

  let timePeriod;

  switch (period) {
    case 'Today':
      timePeriod = 'day';
      break;
    case 'Week':
      timePeriod = 'week';
      break;
    case 'Month':
      timePeriod = 'month';
      break;
    case 'Quarter':
      timePeriod = 'quarter';
      break;
    case 'Year':
      timePeriod = 'year';
      break;
    default:
  }

  const handleChangePeriod = (date, selectPeriod) => {
    const dateFrom = getStartDateOfPeriod(
      date,
      selectPeriod === 'today' ? 'day' : selectPeriod
    );

    setStartDate(dateFrom);
  };
  const auth = useContext(AuthContext);
  const { token } = auth;

  const timezone = getTimezone();
  const start = format(startDate, 'yyyy-MM-dd');

  const { data: statsData, isLoading } = useQuery({
    queryKey: ['statsData', { timePeriod, timezone, token, start }],
    queryFn: () =>
      userAPI.fetchStatsData({ timePeriod, timezone, start, token }),
    placeholderData: keepPreviousData,
    enabled: !!token,
    select: (data) => sortDashboardDataByOrder(data, timePeriod),
  });

  const DEFAULT_STATS_LABELS = 0;
  let allOnTheWay;
  let allUnDeliveredMessages;
  let allDeliveredMessages;
  let allExpiredMessages;
  let allFailedMessages;
  let allRejectedMessages;
  let statsLabels;

  if (!statsData) {
    allOnTheWay = 0;
    allUnDeliveredMessages = 0;
    allDeliveredMessages = 0;
    allExpiredMessages = 0;
    allFailedMessages = 0;
    allRejectedMessages = 0;
    statsLabels = DEFAULT_STATS_LABELS;
  } else {
    allOnTheWay = calculateStatusCount(statsData, 'ACCEPTED');
    allUnDeliveredMessages = calculateStatusCount(statsData, 'UNDELIV');
    allDeliveredMessages = calculateStatusCount(statsData, 'DELIVRD');
    allExpiredMessages = calculateStatusCount(statsData, 'EXPIRED');
    allFailedMessages = calculateStatusCount(statsData, 'UNKNOWN'); // add EMPTY or NULL
    allRejectedMessages = calculateStatusCount(statsData, 'REJECTED');
    statsLabels = extractDateLabels(statsData, timePeriod);
  }

  const amountConsumed = statsData?.consumption || 0;

  const totalSucceed = allDeliveredMessages;
  const totalFailed =
    allRejectedMessages +
    allFailedMessages +
    allExpiredMessages +
    allUnDeliveredMessages;

  const messages = allOnTheWay + totalSucceed + totalFailed;

  const successfulMetrics = {
    totalSuccess: allOnTheWay + totalSucceed,
    cards: {
      succeeded: totalSucceed,
      onTheWay: allOnTheWay,
      pending: 0,
    },
  };

  const unSuccessfulMetrics = {
    totalFailed,
    cards: {
      failed: allFailedMessages,
      undelivered: allUnDeliveredMessages,
      expired: allExpiredMessages,
      rejected: allRejectedMessages,
    },
  };

  return (
    <Layout>
      {acceptStatus === 'error' && (
        <Toast
          message={acceptError?.response?.data?.error?.message}
          status="error"
          closed
        />
      )}
      {acceptStatus === 'success' && (
        <Toast message={acceptData?.message} status="success" closed />
      )}
      <GeneralStats
        handleChangePeriod={handleChangePeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        period={period}
        setPeriod={setPeriod}
      />
      <Box mt={6} />
      <UsageStats
        successfulMetrics={successfulMetrics}
        unSuccessfulMetrics={unSuccessfulMetrics}
        statsLabels={statsLabels}
        statsData={statsData}
        status={acceptStatus}
        isFetching={isLoading}
        messages={messages}
        amountConsumed={amountConsumed}
      />
      <Box mt={140} />
    </Layout>
  );
};

export default Dashboard;
