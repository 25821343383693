/* eslint-disable no-restricted-globals */
export default () => {
  self.importScripts(
    'https://unpkg.com/libphonenumber-js@1.10.48/bundle/libphonenumber-mobile.js'
  );

  const addPlusSignInfront = (n) => {
    if (!Number.isNaN(Number(n.charAt(0))) && n.charAt(0) !== '+') {
      return (n < 0 ? '' : '+') + n;
    }
    return n;
  };

  const formatPhone = (number) => {
    if (number) {
      const cleanNumber = number
        .replace(/[- .]/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

      return addPlusSignInfront(cleanNumber);
    }
    return number;
  };

  self.onmessage = (message) => {
    const { list, allHeaders, headerTel, activeSenderID, countriesList } =
      message.data;

    // eslint-disable-next-line no-undef
    const { isValidPhoneNumber } = libphonenumber;

    const newRecipientArray = [];
    const newDuplicatesArray = [];
    let newInvalidContacts = 0;
    let newUnapprovedNumbers = 0;
    const totalItems = list.length; // Total number of items in the array
    const uniquePhoneNumbers = new Set();

    // Create a map to quickly look up the status of each country based on dial_code
    const countryStatusMap = {};
    activeSenderID.countries.forEach((country) => {
      countryStatusMap[country.iso_code] = country.status;
    });

    list.forEach((contact) => {
      const phoneNumberKey = Object.keys(contact).find((key) =>
        key.includes(headerTel)
      );

      if (phoneNumberKey) {
        const phoneNumber = formatPhone(contact[phoneNumberKey]);
        const validContact = { phonenumber: phoneNumber };

        // eslint-disable-next-line no-restricted-syntax
        for (const key in contact) {
          if (Object.prototype.hasOwnProperty.call(contact, key)) {
            const lowerKey = key.toLowerCase().replace(/\s/g, '');

            if (key !== phoneNumberKey) {
              validContact[lowerKey] = contact[key];
            }
          }
        }

        if (isValidPhoneNumber(phoneNumber)) {
          if (uniquePhoneNumbers.has(phoneNumber)) {
            newDuplicatesArray.push(validContact);
          } else {
            /* Check the country code of each number,
              If the country code of the phone number belongs to the approved country, make it as newRecipientArray
              else it goes into unapprovedNumbers */
            const countryCode = validContact.phonenumber.substring(1, 4); // Extract the dial_code
            const country = countriesList.find(
              (countryObj) => countryObj.dial_code === `+${countryCode}`
            );
            if (country) {
              const isoCode = country.code;
              const countryStatus = countryStatusMap[isoCode];
              if (countryStatus === 'approved') {
                uniquePhoneNumbers.add(phoneNumber);
                newRecipientArray.push(validContact);
              } else {
                newUnapprovedNumbers += 1;
              }
            }
          }
        } else {
          newInvalidContacts += 1;
        }

        const newRecipients = newRecipientArray.length;
        const newDuplicates = newDuplicatesArray.length;
        const totalUploaded =
          newRecipients +
          newInvalidContacts +
          newDuplicates +
          newUnapprovedNumbers;
        const percentage = Math.trunc((totalUploaded / totalItems) * 100);
        postMessage({ progress: percentage });
      }
    });

    postMessage({
      allHeaders: allHeaders.filter(
        (item) => item !== 'name' && item !== 'phonenumber'
      ),
      newRecipientArray,
      newInvalidContacts,
      newDuplicatesArray,
      newUnapprovedNumbers,
    });
  };
};
