function addExpiredField(deals) {
  const today = new Date();

  return deals.map((deal) => {
    const endDate = new Date(deal.end);

    return {
      ...deal,
      expired: endDate < today,
    };
  });
}

function sortDeals(deals, order = 'asc') {
  return deals.sort((a, b) => {
    const dateStart = new Date(a.start);
    const dateEnd = new Date(b.end);
    return order === 'asc' ? dateStart - dateEnd : dateEnd - dateStart;
  });
}

export default function processDeals(deals, order = 'asc') {
  const dealsWithExpired = addExpiredField(deals);
  return sortDeals(dealsWithExpired, order);
}
