import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { Text } from '../../components';
import { formatOrAbbreviateNumber } from '../../utils';

const OverallSummery = ({ consumedAmount }) => {
  const formattedMessages = formatOrAbbreviateNumber(consumedAmount);

  return (
    <Bloc
      display="flex"
      flexDirection="column"
      width={['100%']}
      maxWidth={['100%', '25%']}
      style={{
        columnGap: '12px',
      }}
    >
      <Bloc
        height="100%"
        px={['12px', '10px', '14px']}
        py={['15px', '10px', '20px', '20px']}
        bg="#0EB6A2"
        borderRadius="8px"
      >
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Bloc flexDirection="column">
            <Text as="p" color="white" fontSize={[12, 12, 14]}>
              Consumed amount
            </Text>
            <Text
              as="p"
              color="white"
              fontSize={[50, 42, 50]}
              fontWeight="bold"
              style={{
                textTransform: 'uppercase',
              }}
            >
              ${formattedMessages}
            </Text>
          </Bloc>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default OverallSummery;
