import { useQuery, useQueries, keepPreviousData } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFetchSenderIDs = ({ name, enabled }) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: ['listSenderIDs'],
    queryFn: () => userAPI.getSenderIDs(name),
    enabled,
  });
  return { data, status, isLoading, isFetching };
};

export const useFetchSenderIDDetails = (id) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['senderIDDetails', id],
    queryFn: () => userAPI.getSenderIDDetails(id),
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
  return { data, status, isLoading };
};

// Create an array of queries for data based on each ID (This combines 2 associated queries)
export const useFetchSenderIDWithDetails = () => {
  const { data: senderIDs } = useFetchSenderIDs({
    enabled: true,
    name: '',
  });

  let dataQueryArray = [];
  if (senderIDs && senderIDs.sender_ids) {
    const orgSenderIDs = senderIDs?.sender_ids;

    dataQueryArray = orgSenderIDs.map((senderIDItem) => {
      const senderID = senderIDItem.id;
      return {
        queryKey: ['data', senderID],
        queryFn: () => userAPI.getSenderIDDetails(senderID),
        enabled: !!senderID,
      };
    });
  }

  // Use useQueries to fetch all data queries in parallel
  const data = useQueries({
    queries: dataQueryArray,
  });
  return data;
};
