import React, { useEffect } from 'react';
import { Bloc, Flex } from 'blocjs';
import Icon from '../icon';
import Text from '../text';

const CustomToast = ({
  message,
  status,
  handleHideToast,
  showToast,
  closed = false,
  timeOut = 5000,
  ...rest
}) => {
  let bg = '';
  let color = '';
  if (status === 'error') {
    bg = '#ffe1e1';
    color = 'black';
  } else if (status === 'success') {
    bg = '#D5FFE1';
    color = 'black';
  }

  const capitalize = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  };

  useEffect(() => {
    if (closed) {
      const timeId = setTimeout(() => {
        handleHideToast();
      }, timeOut);
      return () => {
        clearTimeout(timeId);
      };
    }
    return undefined;
  }, [closed, handleHideToast, timeOut]);

  if (!showToast) {
    return null;
  }

  return (
    <Bloc
      position="fixed"
      display="flex"
      style={{
        zIndex: 100,
        top: '66px',
        right: 0,
        left: 0,
      }}
      justifyContent="center"
      {...rest}
    >
      <Bloc>
        <Flex
          px="10px"
          py="6px"
          width="fit-content"
          style={{
            backgroundColor: bg,
            columnGap: '10px',
            zIndex: 99900,
            borderRadius: '100px',
            transform: 'translate(0%, 0px)',
            transition:
              'transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            transformOrigin: 'top left',
            animation: 'bounce 1s ease-in-out ',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          {status === 'success' ? (
            <Bloc
              bg="#3AB25C"
              display="flex"
              width="14px"
              height="14px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                name="complete_check"
                color="white"
                dimension="7x5"
                size={6}
              />
            </Bloc>
          ) : (
            <Bloc
              bg="red"
              borderRadius="50%"
              display="flex"
              p="1px"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                name="second_warning"
                color="white"
                dimension="14x14"
                size={12}
              />
            </Bloc>
          )}

          <Text color={color} fontSize="12px">
            {capitalize(message)}
          </Text>
          <Bloc
            role="button"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              handleHideToast();
            }}
          >
            <Icon name="cross" dimension="17x17" color="black" size={16} />
          </Bloc>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default CustomToast;
