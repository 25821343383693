/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import { Box, Flex, Bloc } from 'blocjs';
import Icon from '../icon';
import Logo from '../logo';
import Button from '../button';
import BurgerWithDropdown from './burgerWithDropdown';
import Balance from '../balance';
import { CurrentUserContext } from '../../contexts';
import Layout from '../layout';
import NavigationButton from '../navigationLink';
import useIsPathsOn from '../../hooks/useCheckPathname';
import AvatarWithDropdown from '../avatarWithDropdown';

const Navigation = ({ authenticated = false, isAdmin, uniqueKey, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const routePath = location.pathname;
  const {
    dashboard: isDashboardOn,
    billing: isBillingOn,
    messages: isMessagesOn,
    organizations: isOrganizationsOn,
    countries: isCountriesOn,
    international: isInternationalOn,
    deals: isDealsOn,
    account: isAccountOn,
    contacts: isContactOn,
  } = useIsPathsOn([
    'dashboard',
    'billing',
    'messages',
    'organizations',
    'countries',
    'international',
    'account',
    'contacts',
    'deals',
  ]);

  const isRootPath = routePath === '/';
  const isAuthPath =
    routePath === '/login' ||
    routePath === '/register' ||
    routePath === '/forgot' ||
    routePath === '/reset';
  const isAuthPage = authenticated && !isRootPath;

  const { currentUser, profile, isProfileLoading, isProfileFetching } =
    useContext(CurrentUserContext);

  if (isAuthPath && authenticated && isAdmin) {
    return <Redirect to="/admin/dashboard" />;
  }

  if (isAuthPath && authenticated) {
    return <Redirect to="/dashboard" />;
  }

  const AuthenticatedNavItems = () => (
    <Flex
      style={{
        columnGap: '20px',
      }}
      alignItems="center"
    >
      {!isAdmin && (
        <>
          <Bloc
            as="a"
            href="https://github.com/pindoio/pindo-cli"
            target="_blank"
            hide={[true, null]}
          >
            <NavigationButton
              fontSize="12px"
              profile={!isRootPath ? 'primary' : 'secondaryTransparent'}
              size="normal"
            >
              Docs
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/dashboard" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isDashboardOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Dashboard
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/messages" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isMessagesOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Messages
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/contacts?tab=active" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isContactOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Contacts
            </NavigationButton>
          </Bloc>

          <Bloc as={Link} to="/billing" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isBillingOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Billing
            </NavigationButton>
          </Bloc>

          <Bloc hide={[true, null]} width="1px" height="24px" bg="#CFCFCF" />

          <Bloc hide={[true, null]}>
            <Balance
              currentUser={currentUser}
              isRootPath={isRootPath}
              isAdmin={isAdmin}
              isAccountOn={isAccountOn}
            />
          </Bloc>
        </>
      )}
      {isAdmin && (
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{
            columnGap: '10px',
          }}
        >
          <Bloc as={Link} to="/admin/dashboard" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isDashboardOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Dashboard
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/admin/manage/organizations" hide={[true, null]}>
            <NavigationButton
              profile={
                isOrganizationsOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              fontSize="12px"
            >
              Organizations
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/admin/manage/deals" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isDealsOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Deals
            </NavigationButton>
          </Bloc>
          <Bloc
            as={Link}
            to="/admin/manage/international-services"
            hide={[true, null]}
          >
            <NavigationButton
              fontSize="12px"
              profile={
                isInternationalOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              International Services
            </NavigationButton>
          </Bloc>
          <Bloc as={Link} to="/admin/manage/countries" hide={[true, null]}>
            <NavigationButton
              fontSize="12px"
              profile={
                isCountriesOn
                  ? 'accent'
                  : isRootPath
                  ? 'secondaryTransparent'
                  : 'primary'
              }
              size="normal"
            >
              Countries
            </NavigationButton>
          </Bloc>
        </Flex>
      )}
      <Box>
        <AvatarWithDropdown
          user={currentUser}
          profile={profile}
          isProfileLoading={isProfileLoading}
          isProfileFetching={isProfileFetching}
          uniqueKey={uniqueKey}
          isAdminView={isAdmin}
          isRootPath={isRootPath}
          isAuthenticated={authenticated}
        />
      </Box>
    </Flex>
  );

  const UnAuthenticatedNavItems = () => (
    <>
      <Bloc as={Link} to="/login" hide={[true, null]}>
        <NavigationButton
          fontSize="12px"
          profile="secondaryTransparent"
          size="normal"
        >
          Login
        </NavigationButton>
      </Bloc>

      <Bloc as={Link} to="/register">
        <NavigationButton
          fontSize="12px"
          profile="secondaryTransparent"
          size="normal"
        >
          Register
        </NavigationButton>
      </Bloc>
    </>
  );

  const handleGoBack = () =>
    isAuthPath ? history.goBack() : history.push('/');

  return isRootPath || authenticated ? (
    <Bloc
      data-testid="navigation"
      width={1}
      bg={isAuthPage ? 'surfaces.0' : 'primary'}
      {...rest}
    >
      <Layout pt="0px">
        <Flex py={4} alignItems="center">
          <Bloc as="a" href="https://pindo.io">
            <Logo
              size={40}
              bg={isAuthPage ? 'primary' : 'surfaces.0'}
              color={isAuthPage ? 'surfaces.0' : 'primary'}
              accent="primaries.5"
              borderRadius="50%"
            />
          </Bloc>
          <Flex
            ml="auto"
            style={{
              columnGap: '6px',
            }}
          >
            <Flex
              alignItems="center"
              style={{
                columnGap: '20px',
              }}
            >
              {isRootPath && (
                <Bloc as="a" href="/#pricing" hide={[true, null]}>
                  <NavigationButton
                    fontSize="12px"
                    profile="secondaryTransparent"
                    size="normal"
                  >
                    Pricing
                  </NavigationButton>
                </Bloc>
              )}

              {authenticated ? (
                <AuthenticatedNavItems />
              ) : (
                <UnAuthenticatedNavItems />
              )}
            </Flex>

            {!authenticated && (
              <Box px={['16px', '0px']}>
                <BurgerWithDropdown
                  isAuthPage={isAuthPage}
                  isProfileLoading={isProfileLoading}
                  isProfileFetching={isProfileFetching}
                  isAdmin={isAdmin}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Layout>
    </Bloc>
  ) : (
    <Layout pt={0}>
      <Bloc width={1} mt={20}>
        <Button
          onClick={handleGoBack}
          profile="white"
          size="normal"
          style={{ display: 'flex', alignItems: 'center', height: 40 }}
          data-testid="handle-go-back"
        >
          <Bloc
            style={{
              transform: 'rotate(90deg) translateX(0px) translateY(8px)',
            }}
            as="span"
          >
            <Icon
              name="accordion"
              color="accents.4"
              size={20}
              dimension="24x24"
            />
          </Bloc>
          {isAuthPath ? 'Back' : 'Home'}
        </Button>
      </Bloc>
    </Layout>
  );
};

export default Navigation;
