import React from 'react';
import {
  Document, Page, Text, View, Image, StyleSheet, Font, Link,
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import { Bloc } from 'blocjs';

import logo from '../../assets/images/logo.png';
import SGRegularWOFF2 from '../../assets/fonts/space-grotesk.medium.ttf';
import SGBoldWOFF2 from '../../assets/fonts/space-grotesk.bold.ttf';

const BORDER_COLOR = '#DFDBDB';
const BORDER_STYLE = 'solid';
const COL_SMS_WIDTH = 40;
const COLN_WIDTH = (100 - COL_SMS_WIDTH) / 3;

Font.register({
  family: 'Space Grotesk',
  fonts: [
    { src: SGRegularWOFF2 }, // font-style: normal, font-weight: normal
    { src: SGBoldWOFF2, fontStyle: 'bold', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 10,
    backgroundColor: '#ffffff',
    paddingBottom: 65,
    fontFamily: 'Space Grotesk',
  },
  logoImage: {
    width: 30,
    height: 30,
    margin: 0,
    padding: 0,
  },
  title: {
    marginBottom: 15,
    padding: 0,
    fontSize: 20,
    fontFamily: 'Space Grotesk',
    fontWeight: 700,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Space Grotesk',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    ':last-child': {
      border: 'none'
    },
    ':last-of-type': {
      border: 'none'
    },
  },
  tableColSMSHeader: {
    width: `${COL_SMS_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: '#DFDBDB',
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: `${COLN_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: '#DFDBDB',
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSMS: {
    width: `${COL_SMS_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: '#DFDBDB',
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'justify',
  },
  tableCol: {
    width: `${COLN_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: '#DFDBDB',
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
     ':last-child': {
      border: 'none'
    },
    ':last-of-type': {
      border: 'none'
    },
  },
  tableCellHeader: {
    margin: 10,
    fontSize: 9,
    fontWeight: 900,
    textTransform: 'uppercase',
    borderBottomColor: '#959DA2',
  },
  tableCell: {
    margin: 10,
    fontSize: 9,
    color: '#757F84',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 10,
    right: 0,
    fontSize: 9,
    textAlign: 'left',
    color: '#757F84',
  },
  messageLink: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 10,
  },
  link: {
    fontSize: 9,
    fontWeight: 700,
    textAlign: 'right',
    color: '#757F84',
    textDecoration: 'none',
  },
});

const PDF = ({ headers, data, title }) => (
  <Document>
    <Bloc as={Page} style={styles.page}>
      <View style={styles.title}>
        <Image source={logo} style={styles.logoImage} />
      </View>
      <View style={styles.title}>
        <Text>{title}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {headers.map((item) => (
            <View style={item === 'Message' ? styles.tableColSMSHeader : styles.tableColHeader} key={item}>
              <Text style={styles.tableCellHeader}>{item}</Text>
            </View>
          ))}
        </View>
        {data.map((message) => (
          <View style={styles.tableRow} key={message.id}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{message.to}</Text>
            </View>
            <View style={styles.tableColSMS}>
              <Text style={styles.tableCell}>{message.text}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{message.status}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{format(new Date(message.created_at), 'MMM dd, yyyy hh:mm a')}</Text>
            </View>
          </View>
        ))}
      </View>
      <Text
        style={styles.pageNumber}
        data-testid="pagination"
        render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )}
        fixed
      />

      <Text style={styles.messageLink} fixed>
        <Link to="https://app.pindo.io/messages" style={styles.link}>https://app.pindo.io/messages</Link>
      </Text>

    </Bloc>
  </Document>
);

export default PDF;
