export default function truncateLongString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  const halfLength = Math.floor((maxLength - 3) / 2);
  const truncatedString = `${str.substring(0, halfLength)}...${str.substring(
    str.length - halfLength
  )}`;

  return truncatedString;
}
