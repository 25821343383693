import { Bloc, Box, Flex } from 'blocjs';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Text } from '../../../../../components';
import DealActions from './dealActions';
import { useFetchOrgDeals } from '../../../../../hooks';
import UserAPI from '../../../../../services';
import CustomToast from '../../../../../components/toast/customToast';
// import UserAPI from '../../../../../services';

const Deals = () => {
  const history = useHistory();
  const location = useLocation();
  const dealAction = new URLSearchParams(location.search).get('deal');
  const orgId = new URLSearchParams(location.search).get('orgId');
  const dealId = new URLSearchParams(location.search).get('deal_id');

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const [isInUse, setIsInUse] = React.useState(null);

  const [showToastNotificationMessage, setShowToastNotificationMessage] =
    useState(false);
  const [showToastDelete, setShowToastDelete] = useState(false);

  const isNewDeal = dealAction === 'create';
  const allDealsPayload = {
    org_id: orgId,
  };

  const { data, isFetching } = useFetchOrgDeals({
    payload: allDealsPayload,
    pageNumber: 1,
    enableFetch: true,
  });

  const { mutate, isError, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload) => userAPI.deleteOrgDeal(payload),
  });

  const goToCreateNewDeal = (e) => {
    e.stopPropagation();
    setIsInUse(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('deal_id');
    searchParams.set('deal', 'create');
    history.push({
      search: searchParams.toString(),
    });
  };

  const {
    mutate: switchInUseDealMutation,
    isError: switchInUseIsError,
    isSuccess: switchInUseIsSuccess,
    error: switchInUseError,
  } = useMutation({
    mutationFn: (payload) => userAPI.setInUseDeal(payload),
  });

  const switchInUseDeal = (e) => {
    e.stopPropagation();
    setIsInUse(e.target.checked);
    const payload = {
      id: dealId,
      activate: e.target.checked,
    };
    switchInUseDealMutation(payload, {
      onSuccess: () => {
        const searchParams = new URLSearchParams(location.search);

        setShowToastNotificationMessage(true);
        queryClient.invalidateQueries({ queryKey: ['orgDeals'] });
        searchParams.delete('deal_id');
        setIsInUse(null);
        history.push({
          search: searchParams.toString(),
        });
      },
      onError: () => {
        setShowToastNotificationMessage(true);
      },
    });
  };

  const goBackToOrgDetails = () => {
    const searchParams = new URLSearchParams(location.search);
    // Delete the specific search parameter
    searchParams.delete('deal');
    searchParams.delete('deal_id');
    // Update the URL with the modified search params
    history.push({
      search: searchParams.toString(),
    });
  };
  return (
    <Bloc
      bg="white"
      borderRadius="8px"
      pt={6}
      width="100%"
      maxWidth={1130}
      margin="0 auto"
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    >
      <Bloc
        position="absolute"
        display="flex"
        width={['100%', 'fit-content']}
        style={{
          zIndex: 100,
          margin: '-100px auto',
          right: 0,
          left: 0,
          gap: '12px',
          flexDirection: 'column',
        }}
        justifyContent="center"
      >
        {switchInUseIsError && (
          <CustomToast
            message={switchInUseError?.response?.data?.error?.message}
            handleHideToast={() => setShowToastNotificationMessage(false)}
            status="error"
            showToast={showToastNotificationMessage}
            closed
          />
        )}

        {switchInUseIsSuccess && !showToastDelete && (
          <CustomToast
            message="Deal updated successfully!"
            handleHideToast={() => setShowToastNotificationMessage(false)}
            status="success"
            showToast={showToastNotificationMessage}
            closed
          />
        )}

        {isError && (
          <CustomToast
            message={error?.response?.data?.error?.message}
            handleHideToast={() => setShowToastDelete(false)}
            status="error"
            showToast={showToastDelete}
            closed
          />
        )}
        {isSuccess && showToastDelete && (
          <CustomToast
            message="Deal Deleted Successfully!"
            handleHideToast={() => setShowToastDelete(false)}
            status="success"
            showToast={showToastDelete}
            closed
          />
        )}
      </Bloc>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        style={{
          gap: '20px',
        }}
        flexWrap={['wrap', 'nowrap']}
        px={5}
      >
        <Box>
          <Text
            as="h1"
            fontSize={['16px', '20px']}
            style={{
              fontWeight: '500',
            }}
          >
            {isNewDeal && 'Create New Deal'}
            {!dealAction && 'Organization Deals'}
          </Text>
        </Box>
        <Flex
          alignItems="center"
          style={{
            gap: '20px',
          }}
        >
          <Box>
            <Flex>
              {!dealAction && (
                <Box>
                  <Button
                    onClick={(e) => {
                      goToCreateNewDeal(e);
                    }}
                    profile="accentLight"
                    size="contact"
                  >
                    New Deal
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
          {dealId && (
            <Box>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  mutate(dealId, {
                    onSuccess: () => {
                      setShowToastDelete(true);
                      setIsInUse(null);
                      queryClient.invalidateQueries({ queryKey: ['orgDeals'] });
                      setTimeout(() => {
                        goBackToOrgDetails();
                      }, 1000);
                    },
                    onError: () => {
                      setShowToastDelete(true);
                    },
                  });
                }}
                profile="secondary"
                size="contact"
                loading={isPending}
              >
                Delete
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
      <Box mt="22px" />
      <DealActions
        displayDeals={data}
        isFetching={isFetching}
        mode={dealAction}
        isInUse={isInUse}
        switchInUseDeal={switchInUseDeal}
      />
    </Bloc>
  );
};

export default Deals;
