import React from 'react';
import { Bloc, Flex } from 'blocjs';

import Input from './input';

const Inputgroup = React.forwardRef(
  (
    {
      name,
      groupLeftText,
      groupRightText,
      label,
      size = 'normal',
      hint,
      error,
      ...rest
    },
    ref
  ) => {
    const hasError = error !== undefined;
    const slugify = (slug, sep) => slug.replace(/\s/g, sep).toLowerCase();
    const slug = slugify(name, '_');
    const uniqInputId = Math.random().toString(26).slice(3);

    return (
      <>
        <Bloc
          mb={3}
          fontSize={14}
          fontWeight={500}
          display="inline-block"
          style={{
            textTransform: 'capitalize',
          }}
          htmlFor={uniqInputId}
          as="label"
        >
          {label || name}
        </Bloc>
        <Flex>
          {groupLeftText && (
            <Flex>
              <Bloc
                as="span"
                padding="0.375rem 0.75rem"
                lineHeight="1.5"
                backgroundColor="#fafafa"
                border="1px solid"
                borderColor={hasError ? 'surfaces.4' : 'surfaces.2'}
                borderRadius={2}
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                borderRight={0}
              >
                {groupLeftText}
              </Bloc>
            </Flex>
          )}
          <Input
            id={uniqInputId}
            name={slug}
            placeholder={name}
            size={size}
            error={error}
            ref={ref}
            borderTopLeftRadius={groupLeftText && 0}
            borderBottomLeftRadius={groupLeftText && 0}
            borderTopRightRadius={groupRightText && 0}
            borderBottomRightRadius={groupRightText && 0}
            {...rest}
          />
          {groupRightText && (
            <Flex>
              <Bloc
                as="span"
                padding="0.375rem 0.75rem"
                lineHeight="1.5"
                backgroundColor="#fafafa"
                border="1px solid"
                borderColor={hasError ? 'surfaces.4' : 'surfaces.2'}
                borderRadius={2}
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                borderLeft={0}
              >
                {groupRightText}
              </Bloc>
            </Flex>
          )}
        </Flex>
        <Bloc
          my={3}
          color={hasError ? 'surfaces.4' : 'surfaces.2'}
          fontSize={1}
          as="p"
        >
          {hasError ? error : hint}
        </Bloc>
      </>
    );
  }
);

export default Inputgroup;
