import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { format } from 'date-fns';
import { Pagination } from '../../../../components';
import OrgTable from './table';

const MainUserCell = ({ orgName }) => (
  <Bloc>{orgName || 'Not yet defined'}</Bloc>
);

const ListOrganisations = ({
  data,
  setRowBackgroundColor,
  goToOrgDetails,
  pageNumber,
  itemsPerPage,
  isFetching,
  isLoading,
  changePage,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <MainUserCell orgName={original.name} />
        ),
      },
      {
        Header: 'Created Date',
        floatRight: true,
        Cell: ({ row: { original } }) => (
          <Bloc
            display="flex"
            height="100%"
            alignItems="center"
            padding="4px 0px"
          >
            {original.created_at && (
              <Flex alignItems="center">
                <Bloc as="span" fontSize={12} color="lightDark">
                  {format(new Date(original.created_at), 'MMM, dd, yyyy')}
                </Bloc>
              </Flex>
            )}
          </Bloc>
        ),
      },
    ],
    []
  );

  const orgsData = data?.orgs || [];

  return (
    <>
      <OrgTable
        columns={columns}
        data={orgsData}
        setRowBackgroundColor={setRowBackgroundColor}
        onRowClick={goToOrgDetails}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
        pages={data?.pages}
        pageCount={data?.pages?.pages}
        changePage={changePage}
        isFetching={isFetching}
        isLoading={isLoading}
      />
    </>
  );
};

export default ListOrganisations;
