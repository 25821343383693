export function sumInvoiceRates(invoiceRates) {
  if (!invoiceRates) return null;

  const summedData = Object.values(
    invoiceRates.reduce(
      (
        acc,
        {
          amount,
          country,
          telco,
          sms_item_count: smsItemCount,
          sms_item_retries: smsRetries,
          sms_count: smsCount,
        }
      ) => {
        if (!acc[country]) {
          acc[country] = {
            country,
            telco,
            sumAmount: 0,
            sumSmsItemCount: 0,
            sumSmsItemRetries: 0,
            sumSmsCount: 0,
          };
        }
        acc[country].sumAmount += amount;
        acc[country].sumSmsItemCount += smsItemCount;
        acc[country].sumSmsItemRetries += smsRetries;
        acc[country].sumSmsCount += smsCount;
        return acc;
      },
      {}
    )
  );

  return summedData;
}

export function sumTelcoWithinCountry(billingData) {
  if (!billingData) return null;
  const sums = {};

  billingData.forEach((item) => {
    const key = `${item.country} - ${item.telco}`;
    if (!sums[key]) {
      sums[key] = {
        country: item.country,
        telco: item.telco,
        sms_count: 0,
        sms_retries: 0,
        sms_item_retries: 0,
        sms_item_count: 0,
        amount: 0,
      };
    }
    sums[key].sms_count += item.sms_count;
    sums[key].sms_retries += item.sms_retries;
    sums[key].sms_item_retries += item.sms_item_retries;
    sums[key].amount += item.amount;
    sums[key].sms_item_count += item.sms_item_count;
  });

  return sums;
}

export function aggregateData(data) {
  const groupedByCountry = data.reduce((acc, obj) => {
    const { country, ...rest } = obj;
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(rest);
    return acc;
  }, {});

  return Object.fromEntries(
    Object.entries(groupedByCountry).map(([country, items]) => {
      const telcos = items.reduce((acc, item) => {
        const {
          telco,
          amount,
          sms_count: smsCount,
          sms_item_count: smsItemCount,
          sms_retries: smsRetries,
          sms_item_retries: smsItemRetries,
          price,
          discount,
        } = item;
        acc[telco] = acc[telco] || {
          telco,
          amount: 0,
          price,
          discountedPrice: price * (1 - discount),
          sms_count: 0,
          sms_item_count: 0,
          sms_item_retries: 0,
          sms_retries: 0,
        };
        acc[telco].amount += amount;
        acc[telco].sms_count += smsCount;
        acc[telco].sms_item_count += smsItemCount;
        acc[telco].sms_retries += smsRetries;
        acc[telco].sms_item_retries += smsItemRetries;

        return acc;
      }, {});

      return [country, Object.values(telcos)];
    })
  );
}
