import React from 'react';
import EditDeal from './editDeal';
import DealTable from './dealTable';
import { Spinner } from '../../../../../components';

const DealActions = ({
  mode,
  displayDeals,
  isFetching,
  switchInUseDeal,
  isInUse,
}) => {
  switch (mode) {
    case 'create':
      return <EditDeal />;
    default:
      return isFetching ? (
        <Spinner size="small" />
      ) : (
        <DealTable
          deals={displayDeals?.deals}
          isInUse={isInUse}
          switchInUseDeal={switchInUseDeal}
        />
      );
  }
};

export default DealActions;
