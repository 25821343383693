import React from 'react';

import {
  GroupActionButtons,
  ActiveActionButtons,
  ArchivedActionButtons,
  FieldActionButtons,
} from './buttons';

const ActionButtons = ({
  activeTab,
  handleSwitchPage,
  handleActions,
  action,
}) => {
  switch (activeTab) {
    case 'active':
      return (
        <ActiveActionButtons
          handleSwitchPage={handleSwitchPage}
          isTab
          handleActions={handleActions}
          action={action}
        />
      );
    case 'archived':
      return (
        <ArchivedActionButtons action={action} handleActions={handleActions} />
      );
    case 'contactField':
      return (
        <FieldActionButtons
          action={action}
          handleActions={handleActions}
          handleSwitchPage={handleSwitchPage}
        />
      );
    case 'groups':
      return (
        <GroupActionButtons
          handleActions={handleActions}
          handleSwitchPage={handleSwitchPage}
          action={action}
        />
      );

    default:
      return null;
  }
};

export default ActionButtons;
