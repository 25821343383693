import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserAPI from '../../services';
import Toast from '../toast';
import CardPayment from './cardPayment';
import MomoPayment from './momoPayment';
import Text from '../text';
import RadioBox from '../radiobox';

const boxes = {
  'credit-card': {
    id: 1,
    title: 'Credit Card',
  },
  'mobile-money': {
    id: 2,
    title: 'Mobile Money',
  },
};

const AddFunds = ({ callToast, handleHideAccountForm }) => {
  const [selectedMethod, setSelectedMethod] = useState({
    name: 'credit-card',
    key: 1,
  });
  const [showCompletePayment, setShowCompletePayment] = useState(false);
  const [countDownOn, setCountDownOn] = useState(false);

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: mutateRemoveSavedMethod,
    isError: isRemoveSavedError,
    error: removeSavedError,
    status: removeSavedStatus,
    isLoading: isLoadingRemoveCard,
  } = useMutation({
    mutationFn: (payload) => userAPI.removeSavedCardOrPhone(payload),
  });

  const handleChangeMethod = (e, optionId, value) => {
    e.stopPropagation();
    setSelectedMethod((prev) => ({
      ...prev,
      key: optionId,
      name: value,
    }));
  };

  const removeSavedMethod = async (payMethod, number) => {
    // const lastFourDigits = number.slice(-4);
    const payload = {
      option: payMethod,
      last_numbers: number,
    };
    await mutateRemoveSavedMethod(payload, {
      onSuccess: async () => {
        if (payMethod === 'cards') {
          await queryClient.invalidateQueries({
            queryKey: ['rememberedCards'],
          });
          callToast('The card was removed successfully', 'success');
        } else if (payMethod === 'phone_numbers') {
          await queryClient.invalidateQueries({
            queryKey: ['rememberedPhones'],
          });
          callToast('The phone number was removed successfully', 'success');
        }
      },
    });
  };

  return (
    <Bloc>
      {isRemoveSavedError && (
        <Toast
          message={removeSavedError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {removeSavedStatus === 'error' && (
        <Toast message={removeSavedError?.message} status="error" />
      )}

      <Bloc
        py={18}
        px={25}
        borderRadius={3}
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
        bg="surfaces.0"
      >
        <Box display="inline-block" width="100%">
          <Box style={{ float: 'left' }} minWidth="16%">
            <Text as="h1" fontSize={[24, 28]}>
              Crediting account
            </Text>
          </Box>
        </Box>
        <Bloc width={1} bg="#0000000F" height="1px" my="15px" />
        {!countDownOn && (
          <Flex
            style={{
              gap: '20px',
            }}
            alignItems="center"
          >
            {Object.entries(boxes).map(([key, item]) => (
              <Flex
                as="label"
                key={item.id}
                style={{
                  gap: '10px',
                }}
                alignItems="center"
              >
                <RadioBox
                  selectedMethod={selectedMethod}
                  handleChangeMethod={(e) =>
                    handleChangeMethod(e, item.id, key)
                  }
                  value={key}
                />

                <Bloc
                  as="label"
                  fontSize={['12px', '16px']}
                  style={{
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                  }}
                  fontWeight={500}
                  htmlFor={key}
                >
                  {key}
                </Bloc>
              </Flex>
            ))}
          </Flex>
        )}

        <Bloc mt="10px">
          {selectedMethod.name === 'credit-card' ? (
            <CardPayment
              callToast={callToast}
              handleHideAccountForm={handleHideAccountForm}
              isLoadingRemoveCard={isLoadingRemoveCard}
              removeSavedMethod={removeSavedMethod}
            />
          ) : (
            <MomoPayment
              callToast={callToast}
              handleHideAccountForm={handleHideAccountForm}
              removeSavedMethod={removeSavedMethod}
              showCompletePayment={showCompletePayment}
              setCountDownOn={setCountDownOn}
              setShowCompletePayment={setShowCompletePayment}
            />
          )}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default AddFunds;
