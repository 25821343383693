import { Bloc, Flex } from 'blocjs';
import React from 'react';
import { Chevron } from '../carets';
import Text from '../text';

const ChevronCardSwitch = ({
  setIsFirstCardSelected,
  isFirstCardSelected,
  cardOneTitle,
  isDashboard,
  secondCardTittle,
}) => {
  const handleSelectUSuccessful = () => {
    setIsFirstCardSelected(false);
  };

  const handleSelectSuccessful = () => {
    setIsFirstCardSelected(true);
  };

  return (
    <Bloc my="14px" width="100%" display={['block', 'none']}>
      <Flex alignItems="center" justifyContent="space-between">
        <Bloc
          onClick={handleSelectSuccessful}
          display="flex"
          alignItems="center"
        >
          <Chevron rotation={90} size={20} />
        </Bloc>
        <Bloc flex={1} display="flex" justifyContent="center">
          <Flex
            ml={isDashboard ? '16px' : '30px'}
            style={{
              columnGap: '10px',
            }}
          >
            <Bloc
              opacity={isFirstCardSelected ? 1 : '0.5'}
              onClick={handleSelectSuccessful}
              as="span"
            >
              <Text fontSize="14px">{cardOneTitle}</Text>
              <Bloc
                height="2px"
                borderRadius="15px"
                width="100%"
                mt="2px"
                bg="#3E484D"
              />
            </Bloc>
            <Bloc
              opacity={isFirstCardSelected ? '0.5' : 1}
              onClick={handleSelectUSuccessful}
              as="span"
              ml="10px"
            >
              <Text fontSize="14px">{secondCardTittle}</Text>
              <Bloc
                height="2px"
                mt="2px"
                borderRadius="15px"
                width="100%"
                bg="#3E484D"
              />
            </Bloc>
          </Flex>
        </Bloc>
        <Bloc
          onClick={handleSelectUSuccessful}
          display="flex"
          alignItems="center"
        >
          <Chevron rotation={270} size={20} />
        </Bloc>
      </Flex>
    </Bloc>
  );
};

export default ChevronCardSwitch;
