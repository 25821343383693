import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Bloc } from 'blocjs';

import { Layout, Text } from '../../components';

const Recovery = () => {
  const location = useLocation();
  const userEmail = new URLSearchParams(location.search).get('email') || 'you';

  return (
    <Layout pt={[3, '10vh']}>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
      >
        <Bloc mb={0} maxWidth={300}>
          <Text variant="h3" as="h1">
            Check Email
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            Instant communication platform for you!
          </Text>
        </Bloc>
        <Bloc
          mt={[4, null]}
          width={[1, 1, 512]}
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
          borderRadius="8px"
          bg="white"
          padding="48px 32px"
        >
          <Bloc display="inline-block">
            An email with recovery instructions was sent to
            {' '}
            <Text
              color="accents.1"
              fontWeight="normal"
              style={{ whiteSpace: 'nowrap' }}
              href={`mailto:${userEmail}`}
            >
              {userEmail}
            </Text>
            . If you don&apos;t see the email in your inbox, remember to try your
            spam folder as well. Once you verify your email address, you can get
            started with Pindo.
          </Bloc>
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Recovery;
