import { Bloc } from 'blocjs';
import React, { useState, useEffect } from 'react';

import Content from './content';
import Dropdown from './dropdown';
import countriesList from '../../utils/countries';
import { getCountryCode } from '../../utils';
import { useFetchUserLocation } from '../../hooks';

function PhoneInput({
  hasSearch = false,
  phoneInput,
  disabled = false,
  setPhoneNumber,
  existingData = undefined,
  hasError = false,
  setHasError = undefined,
}) {
  const [country, setCountry] = useState({});
  const [countries, setCountries] = useState(countriesList);
  const [isCountriesDropdown, setIsCountriesDropdown] = useState(false);
  const [searchedCountry, setSearchedCountry] = useState('');
  const { data: userLocation } = useFetchUserLocation();

  useEffect(() => {
    async function fetchUserLocation() {
      const defaultCountry = userLocation?.countryCode || '';
      if (defaultCountry) {
        const defaultCountryObj = countriesList.find(
          (x) => x.code === defaultCountry
        );
        setCountry(defaultCountryObj || {});
        setPhoneNumber(defaultCountryObj?.dial_code || '');
      }
    }
    if (existingData && existingData.phonenumber) {
      const separatedNumber = getCountryCode(existingData.phonenumber);
      if (separatedNumber) {
        const getCountry = countriesList.find(
          (x) => x.dial_code === separatedNumber.countryCode
        );
        setCountry(getCountry);
        setPhoneNumber(existingData.phonenumber);
      }
    } else {
      fetchUserLocation();
    }
  }, [existingData, setPhoneNumber, userLocation?.countryCode]);

  const handleInputTelChange = (e) => {
    e.preventDefault();
    const number = e.target.value;
    setPhoneNumber(number);

    const validDialCodePattern = /^\+\d{1,4}$/; // Allows for 1 to 4 digits after the '+';
    if (number.length <= 4) {
      if (validDialCodePattern.test(number)) {
        const filteredCountries = countriesList.filter((countryItem) =>
          countryItem.dial_code?.startsWith(number)
        );
        if (filteredCountries.length > 1) {
          setCountries(filteredCountries);
          setIsCountriesDropdown(true);
        } else {
          setCountry(filteredCountries[0]);
          setCountries(countriesList);
          setIsCountriesDropdown(false);
        }
      } else {
        setCountry({});
      }
    } else {
      setCountries(countriesList);
      setIsCountriesDropdown(false);
    }
    if (setHasError) {
      setHasError(null);
    }
  };

  const handleSelectedCountry = (selectedItem) => {
    setPhoneNumber(selectedItem?.dial_code || '');
    setCountry(selectedItem);
    setIsCountriesDropdown(false);
    if (setHasError) {
      setHasError(null);
    }
  };

  const searchItemName = (e, listOfItems, setSearchItem, setList) => {
    e.preventDefault();
    const itemsList = listOfItems;
    const inputValue = e.target.value;
    setSearchItem(inputValue);

    const searchInput = inputValue.trim().toLowerCase();

    if (searchInput.length > 0) {
      const filterMatches = itemsList.filter((el) =>
        el.name.toLowerCase().match(searchInput)
      );
      setList(filterMatches);
    } else {
      setList(itemsList);
    }
  };

  return (
    <Bloc display="flex" alignItems="center">
      <Dropdown
        chosenItem={country}
        isDropdownOpen={isCountriesDropdown}
        setIsDropdownOpen={setIsCountriesDropdown}
        phoneInput={phoneInput}
        handleInputTelChange={handleInputTelChange}
        itemsList={countries}
        disabled={disabled}
        hasError={hasError}
      >
        <Content
          handleSelectedItem={handleSelectedCountry}
          searchItemName={(e) =>
            searchItemName(e, countriesList, setSearchedCountry, setCountries)
          }
          searchedTerm={searchedCountry}
          itemsList={countries}
          hasSearch={hasSearch}
        />
      </Dropdown>
    </Bloc>
  );
}

export default PhoneInput;
