import React, { useRef, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Flex, Bloc } from 'blocjs';

import UserAPI from '../../services';
import { Inputfield, Button, Layout, Text, Toast } from '../../components';

const Reset = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const userAPI = new UserAPI();
  const { push } = useHistory();
  const { register, handleSubmit, errors, watch } = useForm();
  const { mutate, data, status, error, isLoading } = useMutation({
    mutationFn: (payload) => userAPI.resetPassword(payload),
  });

  const password = useRef({});
  password.current = watch('new_password', '');

  useEffect(() => {
    if (!token) {
      push('/unauthorized');
    }
  });

  const onSubmit = async (dataInputs) => {
    if (token) {
      const payload = {
        token,
        password: dataInputs.new_password,
        confirmPassword: dataInputs.confirm_password,
      };
      await mutate(payload, {
        onSuccess: () => {
          setTimeout(() => {
            push('/recovered');
          }, 3000);
        },
      });
    }
  };

  return (
    <Layout pt={[3, '10vh']}>
      {status === 'error' && (
        <Toast message={error?.response?.data?.error?.message} status="error" />
      )}
      {status === 'success' && (
        <Toast message={data?.data?.message} status="success" />
      )}
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
      >
        <Bloc mb={0} width={[1, 1, 415]}>
          <Text variant="h3" as="h1">
            {' '}
            Reset Password{' '}
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            {' '}
            Instant communication <Bloc as="br" /> platform for you!{' '}
          </Text>
        </Bloc>
        <Bloc
          mt={[4, null]}
          width={[1, 1, 512]}
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
          borderRadius="8px"
          bg="white"
          padding="48px 32px"
        >
          <Bloc as="form" onSubmit={handleSubmit(onSubmit)}>
            <Inputfield
              type="password"
              name="New Password"
              ref={register({
                required: 'You must specify a new password',
              })}
              hint="Choose a secure and memorable password"
              placeholder="New Password"
              error={errors?.new_password?.message}
              data-testid="new-password"
            />
            <Box mt={16} />
            <Inputfield
              type="password"
              name="Confirm Password"
              ref={register({
                validate: (value) =>
                  value === password.current || 'The passwords do not match',
              })}
              hint="Repeat the above password for confirmation"
              placeholder="Confirm Password"
              error={errors?.confirm_password?.message}
              data-testid="confirm-password"
            />
            <Box mt={40} />
            <Button profile="accentLight" size="normal" loading={isLoading}>
              Reset Password
            </Button>
          </Bloc>
        </Bloc>
      </Flex>
    </Layout>
  );
};

export default Reset;
