import React from 'react';
import { Bloc, Flex } from 'blocjs';

import Text from '../text';
import { formatOrAbbreviateNumber } from '../../utils';

const StatsCard = ({
  title,
  digits,
  handleOnClick = undefined,
  unit = undefined,
  variant,
  ...rest
}) => {
  const formattedDigits = formatOrAbbreviateNumber(digits);
  const variants = {
    accent: {
      bg: '#bdfbf3ad',
      contrastText: '#0BAF9C',
    },
    accentLight: {
      bg: 'accents.2',
      contrastText: 'accents.7',
    },
    accentDark: {
      bg: 'accents.5',
      contrastText: 'accentDark',
    },
    primaryLight: {
      bg: 'primaries.6',
      contrastText: 'primaries.0',
    },
    warningDark: {
      bg: '#FFEEBA',
      contrastText: '#3E484D',
    },
    palePink: {
      bg: 'rgba(255, 185, 193, 0.40)',
      contrastText: '#3E484D',
    },
    accentDarkSecond: {
      bg: 'accentDark',
      contrastText: 'accents.7',
    },
  };

  return (
    <Bloc
      {...rest}
      borderRadius="8px"
      bg={variants[variant].bg}
      pt={3}
      pb={2}
      px={32}
      height={129}
      boxShadow={variants[variant].boxShadow}
      onClick={handleOnClick}
      style={{
        cursor: handleOnClick ? 'pointer' : 'initial',
      }}
      minWidth={134}
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        position="relative"
        height="100%"
      >
        <Text
          variant="h6"
          color={variants[variant].contrastText}
          as="span"
          fontSize={12}
          textAlign="start"
        >
          {title}
        </Text>
        <Text
          variant="h4"
          color={variants[variant].contrastText}
          as="span"
          fontSize={37}
          style={{ textTransform: 'uppercase' }}
        >
          {unit && unit}
          {formattedDigits}
        </Text>
      </Flex>

      <Bloc mt={11} />
    </Bloc>
  );
};

export default StatsCard;
