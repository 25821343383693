import React from 'react';
import { Flex, Bloc } from 'blocjs';
import Text from '../text';
import Button from '../button';

const ConfirmDeletion = ({ onDelete, removeSavedMethod, lastNumber, isLoading }) => (
        <Flex flexDirection='row' justifyContent="space-between" style={{ backgroundColor: '#FFEEBA', borderRadius: '4px' }} padding="8px" alignItems='center'>
            <Text as="p">Do you want to remove this card?</Text>
            <Bloc>
                <Button 
                    profile="primary" 
                    size="small" 
                    type="button" 
                    loading={isLoading}
                    onClick={() => removeSavedMethod('cards', lastNumber)} 
                    style={{ marginLeft:'10px',  marginRight:'10px' }}
                    data-testid="yes-remove"
                >
                    Yes
                </Button>
                <Button 
                    profile="accentLight" 
                    size="small" 
                    type="button" 
                    onClick={(e) => onDelete(lastNumber, e)}
                    data-testid="no-remove"
                >
                    No
                </Button>
            </Bloc>
        </Flex>
    )

export default ConfirmDeletion;
