import { createContext } from 'react';

export const AuthContext = createContext({
  token: null,
  loginType: null,
  addUserToken: () => {},
  removeUserToken: () => {},
  addLoginType: () => {},
});

export const CurrentUserContext = createContext({
  currentUser: null,
  addCurrentUser: () => {},
  removeCurrentUser: () => {},
  status: 'idle',
  profile: {},
  isProfileLoading: false,
  isProfileFetching: false,
});

export const MessageContext = createContext(null);
