import React from 'react';
import { Box, Flex, Bloc } from 'blocjs';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { Text, Card, Spinner } from '../../../../components';
import ListSenderIds from './listSenderIDs';
import SenderIDDetails from './senderIDDetails';
import { convertUTCToLocalTime, textTruncate } from '../../../../utils';
import Deals from './deals/deals';

const OrgDetails = ({
  orgData,
  isOrgLoading,
  orgSenderIDs,
  isOrgSenderIDsLoading,
}) => {
  const location = useLocation();

  const senderId = new URLSearchParams(location.search).get('senderId');

  return (
    <>
      <Box>
        <Card>
          <Box pb={18}>
            <Text variant="h5">
              Organization Details {isOrgLoading && <Spinner size="large" />}
            </Text>
          </Box>
          <Box mt={4} />
          <Box>
            <Bloc as="table" width="100%" className="not-selectable">
              <Bloc as="tbody">
                <Bloc as="tr">
                  <Bloc
                    as="td"
                    pl={0}
                    color="rgb(62, 72, 77)"
                    pb={20}
                    fontSize={16}
                    width="100px"
                  >
                    <Bloc as="b">Name</Bloc>
                  </Bloc>
                  <Bloc
                    as="td"
                    pl={0}
                    color="rgb(62, 72, 77)"
                    pb={20}
                    fontSize={16}
                    textAlign="justify"
                    style={{ whiteSpace: 'initial' }}
                  >
                    <Text>{textTruncate(orgData?.name)}</Text>
                  </Bloc>
                </Bloc>
                <Bloc as="tr">
                  <Bloc
                    as="td"
                    pl={0}
                    color="rgb(62, 72, 77)"
                    pb={20}
                    fontSize={16}
                    width="100px"
                  >
                    <Bloc as="b">Created Date</Bloc>
                  </Bloc>
                  <Bloc
                    as="td"
                    pl={0}
                    color="rgb(62, 72, 77)"
                    pb={20}
                    fontSize={16}
                    textAlign="justify"
                    style={{ whiteSpace: 'initial' }}
                  >
                    <Text>
                      {orgData?.created_at
                        ? format(
                            convertUTCToLocalTime(orgData.created_at),
                            'MMM dd, yyyy'
                          )
                        : ''}
                    </Text>
                  </Bloc>
                </Bloc>
              </Bloc>
            </Bloc>
          </Box>
        </Card>
      </Box>

      <Box>
        <Bloc
          mt={13}
          borderRadius="3px"
          // pt={18}
          bg="surfaces.0"
          style={{ boxShadow: '0px 0px 2px rgb(160 143 143 / 32%)' }}
        >
          {!senderId ? (
            <>
              <Flex
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
              >
                <Box mb={3} ml={5} py={22}>
                  <Text variant="h5">
                    Organization SenderID{' '}
                    {isOrgSenderIDsLoading && <Spinner size="large" />}
                  </Text>
                </Box>
              </Flex>
              <Box mt={4} />
              <ListSenderIds
                isLoading={isOrgSenderIDsLoading}
                data={orgSenderIDs}
              />
            </>
          ) : (
            <SenderIDDetails />
          )}
        </Bloc>
      </Box>
      <Box mt={13} />
      <Box>
        <Deals />
      </Box>
    </>
  );
};

export default OrgDetails;
