import React, { useState, useMemo, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import {
  Layout,
  Text,
  Pagination,
  CheckedBox,
  Icon,
  Button,
} from '../../../../components';
import Table from './table';
import AddOrUpdateIntlService from './addOrUpdateService';
import Pricing from './pricing';
import { useFetchTelcoIntlServices } from '../../../../hooks';

const ScopeStatus = ({ scope }) => {
  const scopeColor = '#54616B';
  const scopeBg = '#E7EBEF';
  return (
    <Bloc display="flex" p={0} alignItems="center" fontSize={14} pl={0}>
      <Bloc pl={0} style={{ whiteSpace: 'initial' }} width={1}>
        <Bloc
          as="span"
          color={scopeColor}
          bg={scopeBg}
          padding="4px 10px"
          borderRadius={27}
          fontSize={10}
          fontWeight="bold"
          display="block"
          boxSizing="border-box"
          width="fit-content"
          style={{ textTransform: 'uppercase' }}
        >
          {scope ? 'Global' : 'Partial'}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

const ServiceStatus = ({ status }) => {
  let statusColor = '#088C7D';
  let statusBg = '#EBF9F5';
  let statusText = 'Active';
  if (!status) {
    statusColor = '#FF3049';
    statusBg = '#FFD6DB';
    statusText = 'Inactive';
  }
  return (
    <Bloc display="flex" p={0} alignItems="center" fontSize={14} pl={0}>
      <Bloc pl={0} style={{ whiteSpace: 'initial' }} width={1}>
        <Bloc
          as="span"
          color={statusColor}
          bg={statusBg}
          padding="4px 10px"
          borderRadius={27}
          fontSize={10}
          fontWeight="bold"
          display="block"
          boxSizing="border-box"
          width="fit-content"
          style={{ textTransform: 'uppercase' }}
        >
          {statusText}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

const IntlServices = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const itemsPerPage = 25;
  const [isAddOrUpdateService, setIsAddOrUpdateService] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [isEditService, setIsEditService] = useState(false);

  const { data, isLoading, isFetching } = useFetchTelcoIntlServices();

  const onAddService = () => {
    setIsEditService(false);
    setIsAddOrUpdateService(true);
  };

  const onEditService = () => {
    setIsEditService(true);
    setIsAddOrUpdateService(true);
  };

  const cancelServiceUpdate = () => {
    setIsEditService(false);
    setIsAddOrUpdateService(false);
    setSelectedServices([]);
  };

  useEffect(() => {
    if (data && data.international_services) {
      setServicesList(data.international_services);
    }
  }, [data]);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    // setIsFetchEnabled(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        floatRight: false,
        accessor: (row) => row.name,
      },
      {
        Header: 'Username',
        floatRight: false,
        accessor: (row) => row.username,
      },
      {
        Header: 'URL',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <Bloc
            as="a"
            href={original.url}
            target="_blank"
            color="#757F84"
            fontWeight="500"
            style={{ textDecoration: 'underline' }}
          >
            Linkoverhere
          </Bloc>
        ),
      },
      {
        Header: 'Price',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <Bloc
            as="span"
            color="accents.2"
            fontWeight="bold"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              setShowPricing(true);
              setCurrentService(original);
            }}
          >
            PRICING
          </Bloc>
        ),
      },
      {
        Header: 'Weight',
        floatRight: false,
        accessor: (row) => row.backup_position,
      },
      {
        Header: 'Status',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <ServiceStatus status={original.is_operational} />
        ),
      },
      {
        Header: 'Scope',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <ScopeStatus scope={original.is_global} />
        ),
      },
    ],
    []
  );

  const onCheckBox = (service, e) => {
    e.stopPropagation();
    const items = [...selectedServices];
    const index = items.findIndex((item) => item.id === service.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedServices(items);
    } else {
      setSelectedServices([service]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedServices.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const servicesData = useMemo(() => servicesList, [servicesList]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    // const serviceId = row.original.id;
    // history.push('/admin/manage/service-details', { serviceId });
  };

  return (
    <Layout>
      {!showPricing && (
        <>
          {isAddOrUpdateService && (
            <AddOrUpdateIntlService
              serviceData={selectedServices[0]}
              cancelServiceUpdate={cancelServiceUpdate}
              isEditingService={isEditService}
            />
          )}
          {!isAddOrUpdateService && (
            <>
              <Flex
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
                mb={['24px', '0px']}
              >
                <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
                  <Text
                    as="h1"
                    style={{ lineHeight: '32px' }}
                    fontSize={[24, 28]}
                  >
                    International Services
                  </Text>
                </Box>

                <Flex justifyContent="end">
                  <Bloc>
                    <Button
                      profile="accentLight"
                      size="small"
                      onClick={onAddService}
                      data-test="update-btn"
                    >
                      Add new service
                    </Button>
                  </Bloc>
                </Flex>
              </Flex>
            </>
          )}
          <Box mt={isAddOrUpdateService ? 10 : 0} />
          {!isEditService && (
            <>
              <Table
                columns={columns}
                data={servicesData}
                onRowClick={onRowClick}
                setCheckbox={setCheckbox}
                onCheckBox={onCheckBox}
                onEditService={onEditService}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              {data && data.pages && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  pages={data?.pages}
                  pageCount={data?.pages?.pages}
                  changePage={changePage}
                  isFetching={isFetching}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </>
      )}

      {showPricing && (
        <Pricing
          currentService={currentService}
          setShowPricing={setShowPricing}
        />
      )}

      <Bloc mt={100} />
    </Layout>
  );
};

export default IntlServices;
