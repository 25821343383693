import React from 'react';
import { Bloc } from 'blocjs';
import DealTable from '../organization/deals/dealTable';

const ListDeals = ({ data }) => {
  const allDeals = data.deals || [];

  return (
    <Bloc
      bg="white"
      borderRadius="8px"
      overflow="auto"
      border="1px solid #DEE1E7"
    >
      <DealTable deals={allDeals} orgName />
    </Bloc>
  );
};

export default ListDeals;
