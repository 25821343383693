/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import UserAPI from '../services';

const useGitHubLogin = (code, addLoginType, setAthProviderToken) => {
  const userAPI = new UserAPI();

  const {
    mutate: githubLoginMutate,
    isError: isGithubLoginError,
    error: githubLoginError,
    isPending: isGithubLoginLoading,
  } = useMutation({
    mutationFn: (payload) => userAPI.loginWithGithub(payload),
    onSuccess: (data) => {
      const res = data;
      if (res?.data?.token) {
        setAthProviderToken(res?.data?.token);
      }
    },
  });

  useEffect(() => {
    if (code) {
      const handleLoginWithGithub = async (authCode) => {
        if (authCode) {
          addLoginType('socials');
          const payload = { token: authCode };
          githubLoginMutate(payload);
        }
      };
      handleLoginWithGithub(code);
    }
  }, [code]);

  return {
    isGithubLoginError,
    githubLoginError,
    isGithubLoginLoading,
  };
};

export default useGitHubLogin;
