import React from 'react';
import { Bloc, Box } from 'blocjs';
import ClickAwayListener from 'react-click-away-listener';
import { Text, Spinner, Chevron } from '../../components';
import { exportOptions } from '../../utils';

const Export = ({
  handleExportSMS,
  isDropdownOpen,
  setIsDropdownOpen,
  selectedMessages,
  isExporting,
  isExportEnabled,
}) => {
  const addionalText = selectedMessages.length > 0 ? 'Selected' : 'Filtered';

  return (
    <ClickAwayListener
      onClickAway={() => setIsDropdownOpen(false)}
      data-test="hide-dropdown"
      style={{
        position: 'relative',
        marginLeft: '16px',
      }}
    >
      <Bloc
        borderStyle="solid"
        borderWidth="1px"
        borderRadius="6px"
        size="dashboard"
        borderColor="surfaces.2"
        bg="white"
        height={40}
        px={3}
        style={{
          fontWeight: 'normal',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          cursor: 'pointer',
          justifyContent: 'space-between',
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        data-test="toggle-dropdown"
      >
        {isExporting || isExportEnabled ? (
          <Spinner size="large" style={{ margin: 0 }} />
        ) : (
          <>
            <Bloc as="span">Export {addionalText} </Bloc>
            <Chevron
              color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
              size={16}
            />
          </>
        )}
      </Bloc>

      {isDropdownOpen && (
        <Box
          position="absolute"
          top={40}
          width="100%"
          right={0}
          marginTop={2}
          padding="14px 20px"
          zIndex={999}
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            textAlign: 'right',
            boxShadow:
              '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
          }}
        >
          <Bloc>
            {exportOptions.map((item) => (
              <Bloc
                py={2}
                key={item.value}
                onClick={() => handleExportSMS(item.value)}
                data-test="export-sms"
              >
                <Text hoverAsLink variant="small" style={{ cursor: 'pointer' }}>
                  {item.label}
                </Text>
              </Bloc>
            ))}
          </Bloc>
        </Box>
      )}
    </ClickAwayListener>
  );
};

export default Export;
