/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Bloc, Box, Flex } from 'blocjs';
import { useLocation } from 'react-router-dom';
import ImportContactStepper from './importStepper';
import ContactContainer from './container';
import Text from '../text';
import Button from '../button';
import UserAPI from '../../services';
import CustomToast from '../toast/customToast';
import useUploadContact from './useUploadContact';

const initialReducerState = {
  type: 'next',
  has_next: true,
  has_prev: false,
  current: 1,
};

function updateExtraFields(contacts, contactFields) {
  return contacts.map((contact) => {
    const updatedExtraFields = {};

    Object.entries(contact.extra_fields).forEach(([fieldName, oldValue]) => {
      const fieldConfig = contactFields.find(
        (field) => field.old_name === oldValue
      );

      if (fieldConfig) {
        updatedExtraFields[fieldName] = fieldConfig.name;
      } else {
        updatedExtraFields[fieldName] = oldValue;
      }
    });

    return { ...contact, extra_fields: updatedExtraFields };
  });
}

function reducer(state, action) {
  switch (action.type) {
    case 'next': {
      return {
        has_next: state.current < 2,
        has_prev: true,
        current: state.has_next ? state.current + 1 : state.current,
      };
    }
    case 'previous': {
      return {
        current: state.has_prev ? state.current - 1 : state.current,
        has_prev: state.current > 2,
        has_next: true,
      };
    }
    default:
      return initialReducerState;
  }
}

function transformData(data) {
  return data.flatMap((item) =>
    item.criteria.map((criteria) => ({
      group_name: criteria,
      criteria: { [criteria]: item.name },
    }))
  );
}

const ImportContactsComponents = ({ handleSwitchPage, handleAction }) => {
  const location = useLocation();
  const contactFields = location.state && location.state.contact_fields;
  const groupsPayload = location.state && location.state.contact_groups;
  const groupListName = location.state && location.state.groupListName;
  const groupListSelected = location.state && location.state.groupListSelected;

  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: createBulkContact,
    isPending: isCreateBulkContactPending,
    isError: isCreateBulkContactError,
    error: createBulkContactError,

    status: createBulkContactStatus,
  } = useMutation({
    mutationFn: (payload) => userAPI.createBulkContacts(payload),
  });

  const [state, dispatch] = React.useReducer(reducer, initialReducerState);
  const [getDataTypes, setGetDataTypes] = useState([]);
  const [showContactsInfo, setShowContactsInfo] = useState(false);

  const [
    isCreateBulkContactErrorNotification,
    setIsCreateBulkContactErrorNotification,
  ] = useState(false);
  const [
    fieldCreateSuccessfullyNotification,
    setFieldCreateSuccessfullyNotification,
  ] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('page', 'import');
  searchParams.set('tab', 'active');

  const lastStep = state.current === 3;
  const firstStep = state.current === 1;

  function cleanObject(objArray) {
    return objArray.map((obj) => {
      const {
        defaultValues,
        isSelected,
        options,
        new_name: newName,
        old_name: oldName,
        input_type: inputTy,
        ...rest
      } = obj;

      // Remove "options" if "input_type" is "input"
      if (inputTy === 'input') {
        return { ...rest, input_type: inputTy };
      }
      return { ...rest, options, input_type: inputTy };
    });
  }

  const {
    contacts,
    fileName,
    headerMentions,
    isUploading,
    progressPercentage,
    showContactError,
    clearAllContacts,
    handleFileUpload,
  } = useUploadContact({
    setGetDataTypes,
  });

  const importedContactLength = contacts.length;

  const fieldCreateSuccessfully = createBulkContactStatus === 'success';

  const handleSubmit = () => {
    if (fileName) {
      dispatch({ type: 'next' });
    }

    if (lastStep) {
      const cleanContactFields = contactFields
        .filter((item) => item.name !== 'Phone Number' && item.name !== 'Name')
        .map((item) => ({
          ...item,
          name: item.new_name || item.name,
          featured: true,
        }));

      const cleanContactGroups = groupsPayload.map((item) => {
        const { options, ...rest } = item;
        if (options) {
          rest.criteria = options;
          delete rest.options;
        }
        return rest;
      });

      const transformedData = transformData(cleanContactGroups);
      const updatedContacts = updateExtraFields(contacts, cleanContactFields);
      const cleanedContactFields = cleanObject(cleanContactFields);

      const payload = {
        contacts: updatedContacts,
      };

      if (cleanedContactFields?.length > 0) {
        payload.contact_fields = cleanedContactFields;
      }
      if (groupListSelected) {
        payload.contact_groups = [
          ...transformedData,
          {
            group_name: groupListName || 'All Contacts',
            criteria: {},
            all_contacts: true,
          },
        ];
      }
      if (transformedData?.length > 0) {
        payload.contact_groups = transformedData;
      }

      createBulkContact(payload, {
        onSuccess: () => {
          setFieldCreateSuccessfullyNotification(true);
          queryClient.invalidateQueries({ queryKey: ['get_all_contacts'] });
          setTimeout(() => {
            handleAction('active-tab');
            handleSwitchPage(null);
          }, 5500);
        },
        onError: () => {
          setIsCreateBulkContactErrorNotification(true);
        },
      });
    }
  };

  return (
    <ContactContainer
      defaultToast={false}
      toast={
        <Flex
          flexDirection="column"
          style={{
            gap: '10px',
          }}
        >
          {isCreateBulkContactError && isCreateBulkContactErrorNotification && (
            <CustomToast
              message={
                createBulkContactError?.response?.data?.data?.message ||
                createBulkContactError?.response?.data?.error?.message
              }
              handleHideToast={() =>
                setIsCreateBulkContactErrorNotification(false)
              }
              status="error"
              showToast={isCreateBulkContactErrorNotification}
              closed
            />
          )}

          {fieldCreateSuccessfullyNotification && fieldCreateSuccessfully && (
            <CustomToast
              message="Contacts created successfully"
              handleHideToast={() =>
                setFieldCreateSuccessfullyNotification(false)
              }
              status="success"
              showToast={fieldCreateSuccessfullyNotification}
              closed
            />
          )}
        </Flex>
      }
    >
      <Box p="20px">
        <Flex flexDirection="column">
          <Bloc
            pb="20px"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Text
              as="h1"
              fontSize={['16px', '28px']}
              style={{
                fontWeight: '500',
              }}
            >
              Import Contacts
            </Text>
          </Bloc>
          <Box>
            <ImportContactStepper
              setShowContactsInfo={setShowContactsInfo}
              showContactsInfo={showContactsInfo}
              handleFileUpload={handleFileUpload}
              clearAllContacts={clearAllContacts}
              showContactError={showContactError}
              getDataTypes={getDataTypes}
              currentStep={state.current}
              setDataTypes={setGetDataTypes}
              isUploading={isUploading}
              importedContactLength={importedContactLength}
              headerMentions={headerMentions}
              fileName={fileName}
              progressPercentage={progressPercentage}
              dispatch={dispatch}
            />
          </Box>
          <Flex
            flexDirection="column"
            style={{
              rowGap: '15px',
            }}
          >
            <Bloc bg="rgba(0, 0, 0, 0.06)" height="1px" width="100%" />

            <Flex justifyContent="space-between">
              {firstStep ? (
                <Button
                  disabled={isCreateBulkContactPending}
                  profile="secondary"
                  size="contact"
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 'normal',
                    color: 'black',
                  }}
                  onClick={() => {
                    handleSwitchPage(null);
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  disabled={isCreateBulkContactPending}
                  profile="secondary"
                  size="contact"
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 'normal',
                    color: 'black',
                  }}
                  onClick={() => {
                    dispatch({
                      type: 'previous',
                    });
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                loading={isCreateBulkContactPending}
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                  backgroundColor:
                    !state.has_next || (!fileName && '#0eb6a280'),
                }}
                profile="accentLight"
                size="contact"
                onClick={handleSubmit}
              >
                {lastStep ? 'Proceed to add contacts' : 'Next'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </ContactContainer>
  );
};

export default ImportContactsComponents;
