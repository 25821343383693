import { Bloc, Flex } from 'blocjs';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.9;
  }
`;

const LoadingPulse = styled(Bloc)`
  background-color: #d8d8d8;
  overflow: hidden;
  animation: ${pulse} 1.2s infinite ease-in-out;
`;

const LoadingPulseContainer = ({ children, ...rest }) => (
  <LoadingPulse {...rest}>{children}</LoadingPulse>
);

const MetricsSkeleton = ({ metrics }) => (
  <Bloc
    display="flex"
    width="100%"
    height="100%"
    flexDirection="column"
    style={{
      rowGap: '8px',
    }}
  >
    <LoadingPulseContainer
      display="flex"
      width="100%"
      borderRadius="8px"
      height={['124px', '100%', '100%']}
    />

    <Bloc
      display="grid"
      maxHeight="142px"
      flex={1}
      height="100%"
      style={{
        gap: '8px',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      }}
    >
      {metrics
        ? Object.entries(metrics).map(([card]) => (
            <LoadingPulseContainer
              minHeight="62px"
              maxHeight="62px"
              width="100%"
              key={card}
              borderRadius="8px"
            />
          ))
        : null}
    </Bloc>
  </Bloc>
);

const DashboardSkeleton = ({ isMobile, isDashboard = true }) => {
  let metrics;
  if (isDashboard) {
    metrics = {
      success: {
        cards: {
          succeeded: 0,
          onTheWay: 0,
          pending: 0,
        },
      },
      unsuccess: {
        cards: {
          failed: 0,
          undelivered: 0,
          expired: 0,
          rejected: 0,
        },
      },
    };
  } else {
    metrics = {
      success: {
        cards: {
          succeeded: 0,
          onTheWay: 0,
        },
      },
      unsuccess: {
        cards: {
          failed: 0,
          rejected: 0,
        },
      },
    };
  }

  return (
    <Flex
      width="100%"
      height={isDashboard ? '293px' : '200px'}
      style={{
        columnGap: '10px',
      }}
      flexWrap={['wrap', 'no-wrap']}
      alignItems="stretch"
    >
      <LoadingPulseContainer
        width={['100%', 'fit-content']}
        height={[
          !isDashboard ? '144px' : '110px',
          isDashboard ? '122px' : null,
          isDashboard ? '154px' : '100%',
        ]}
        minWidth={['fit-content', '185px', '238px']}
        maxWidth={['100%', '30%', '100%']}
        borderRadius="8px"
      />
      <LoadingPulseContainer
        hide={[null, true]}
        borderRadius="8px"
        width="100%"
        my="20px"
        height="30px"
      />
      <Flex
        display="flex"
        flex={1}
        style={{
          gap: '10px',
        }}
      >
        {isMobile ? (
          <>
            <MetricsSkeleton metrics={metrics.success.cards} />
            <MetricsSkeleton metrics={metrics.unsuccess.cards} />
          </>
        ) : (
          <>
            <MetricsSkeleton metrics={metrics.success.cards} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default DashboardSkeleton;
