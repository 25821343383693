/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useLocation, useHistory } from 'react-router-dom';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import { Inputfield } from '../inputs';
import Text from '../text';

import ContactDropDown from '../contactDropDown';
import { contactDataStructure } from './dummyData';
import convertToSnakeCase from '../../utils/convertToSnakeCase';

const ImportContactSecondStep = ({ getDataTypes, setDataTypes }) => {
  const location = useLocation();
  const { push } = useHistory();
  // const contactFields = location.state && location.state.contact_fields;

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('page', 'import');
  searchParams.set('tab', 'active');

  useEffect(() => {
    push({
      search: searchParams.toString(),
      state: {
        contact_fields: getDataTypes,
      },
    });
  }, [getDataTypes]);

  const onCheckBox = useCallback(
    (field, e) => {
      e.stopPropagation();

      const updatedFields = structuredClone(getDataTypes);
      const index = updatedFields.findIndex((f) => f.id === field.id);

      updatedFields[index].isSelected = !updatedFields[index].isSelected;

      setDataTypes(updatedFields);

      // Update the state for push as well
      push({
        search: searchParams.toString(),
        state: { contact_fields: updatedFields },
      });
    },
    [getDataTypes]
  );

  const handleSelectionChange = (chosenItemIndex, chosenItem) => {
    const updatedFields = structuredClone(getDataTypes);
    const chosen = getDataTypes[chosenItemIndex];

    if (chosenItem === 'select') {
      updatedFields[chosenItemIndex].input_type = chosenItem;
      updatedFields[chosenItemIndex].data_type = 'text';
      updatedFields[chosenItemIndex].options =
        chosen?.options && chosen?.options.length > 0
          ? chosen.options
          : chosen?.defaultValues ?? [];
    } else if (chosenItem === 'number') {
      updatedFields[chosenItemIndex].input_type = 'input';
      updatedFields[chosenItemIndex].data_type = 'numerical';
    } else {
      updatedFields[chosenItemIndex].input_type = 'input';
      updatedFields[chosenItemIndex].data_type = chosenItem;
    }

    setDataTypes(updatedFields);
  };

  let debounceTimer;
  const handleInputTextField = (e, field) => {
    const inputName = e.target.value;
    e.preventDefault();
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      const selectedField = {
        ...field,
        new_name: convertToSnakeCase(inputName),
        old_name: field.name,
      };
      const updateField = getDataTypes.map((item) =>
        item.id === selectedField.id ? { ...item, ...selectedField } : item
      );

      setDataTypes(updateField);
    }, 500);
  };
  const setCheckbox = (id, isDefault) => {
    if (
      getDataTypes[id].isSelected ||
      getDataTypes[0] === getDataTypes[id] ||
      getDataTypes[1] === getDataTypes[id]
    ) {
      return (
        <CheckedBox color={!isDefault ? 'accentDark' : '#BEC1C2'} size={16} />
      );
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon
          name="square"
          color={isDefault ? '#BEC1C2' : '#959DA2'}
          size={16}
        />
      </Box>
    );
  };

  return (
    <Bloc
      borderRadius="6px"
      py="20px"
      px="15px"
      style={{
        border: '1px solid rgba(0, 0, 0, 0.06)',
      }}
    >
      <Text fontWeight={500} fontSize={['13px', '16px']}>
        Select the contact fields you want to keep
      </Text>
      <Flex
        mt="15px"
        flexDirection="column"
        style={{
          rowGap: '10px',
        }}
      >
        {getDataTypes &&
          getDataTypes.map((field) => {
            const { data_type: dataType } = field;
            const { input_type: inputType } = field;

            const type = inputType === 'select' ? inputType : dataType;

            const indexItemSelected = contactDataStructure.dataType.indexOf(
              type === 'numerical' ? 'number' : type
            );

            return (
              <Flex
                key={field.id}
                alignItems={['start', 'center']}
                flexDirection={['column', 'row']}
                style={{
                  opacity: !getDataTypes[field.id].isSelected && 0.4,
                  rowGap: '15px',
                  columnGap: '20px',
                }}
              >
                <Flex
                  width={['100%', '20%']}
                  style={{
                    columnGap: '6px',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                  flexWrap={['wrap', 'nowrap']}
                  alignItems="center"
                  onClick={(e) =>
                    !field.isDefault ? onCheckBox(field, e) : null
                  }
                >
                  <Bloc display="flex">
                    {setCheckbox(field.id, field.isDefault)}
                  </Bloc>
                  <Text
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {field?.old_name || field.name}{' '}
                  </Text>
                </Flex>
                <Flex
                  width="100%"
                  style={{
                    columnGap: '15px',
                    rowGap: '10px',
                  }}
                  flexDirection={['column', 'row']}
                >
                  <Flex
                    width="100%"
                    py="7px"
                    px="16px"
                    fontSize="14px"
                    style={{
                      border: '1px solid #D4DEE8',
                      borderRadius: '4px',
                      opacity:
                        !getDataTypes[field.id].isSelected ||
                        (field.isDefault && 0.4),
                    }}
                  >
                    <Flex
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',

                        textOverflow: 'ellipsis',
                        fontWeight: 300,
                      }}
                    >
                      <Text fontSize="13px">Field Name:</Text>
                    </Flex>
                    <Flex>
                      <Inputfield
                        size="contact"
                        disabled={
                          !getDataTypes[field.id].isSelected || field.isDefault
                        }
                        required
                        py={0}
                        fontSize="14px"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          textTransform: 'capitalize',
                        }}
                        name="text"
                        defaultValue={field?.new_name}
                        placeholder=""
                        onChange={(e) => handleInputTextField(e, field)}
                        hasLabel={false}
                        hasHint={false}
                      />
                    </Flex>
                  </Flex>

                  <Flex width="100%">
                    <ContactDropDown
                      disabled={
                        !getDataTypes[field.id].isSelected || field.isDefault
                      }
                      step
                      dropDownItems={contactDataStructure.dataType}
                      activeItemIndex={
                        // TO-DO: Fix this issue
                        indexItemSelected
                      }
                      onSelectionChange={(newField) =>
                        handleSelectionChange(field.id, newField)
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </Bloc>
  );
};

export default ImportContactSecondStep;
