import { keepPreviousData, useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFetchTelcoIntlServices = () => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['intlServices'],
    queryFn: () => userAPI.getIntlServices(),
    placeholderData: keepPreviousData,
  });
  return { data, status, isLoading };
};

export const useFetchTelcoAlternativeServices = (countryId) => {
  const { data, status, isLoading } = useQuery({
    queryKey: ['telcoAlternativeServices', countryId],
    queryFn: () => userAPI.getAlternativeServices(countryId),
    placeholderData: keepPreviousData,
    enabled: !!countryId,
  });
  return { data, status, isLoading };
};
