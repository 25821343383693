import React, { useEffect, useRef } from 'react';
import { Bloc } from 'blocjs';
import { dropdownLayout, inputSelectBox } from './style';
import { Chevron } from '../carets';

const styles = {
    dropdownLayout, inputSelectBox
};

function Dropdown({ children, isDropdownOpen, chosenItems, setIsDropdownOpen,
    itemsList, disabled, hasError, removeItem }) {

    const ref = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isDropdownOpen, setIsDropdownOpen]);

    const onSelectionChange = (e) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        setIsDropdownOpen(!isDropdownOpen);
    }

    return (
        <Bloc
            ref={ref}
            aria-hidden="true"
            ml={0}
            style={styles.inputSelectBox}
        >
            <Bloc
                data-testid="select-dropdown"
                aria-hidden="true"
                borderColor={hasError ? "surfaces.4": "surfaces.2"}
                borderStyle='solid'
                borderWidth='1px'
                borderRadius='4px'
                height='auto'
                display='flex'
                backgroundColor={disabled ?  '#fafafa' : 'white'}
                alignItems='center'
                justifyContent='space-between'
                boxSizing='border-box'
            >
                <Bloc width={1} position='relative'boxSizing="border-box">
                    <Bloc display="flex" alignItems="center" minWidth={64} height='auto' minHeight={38} 
                        justifyContent="space-between"
                        px={3} pt={3} 
                        boxSizing='border-box'
                        id="select-box"
                        style={{ cursor: 'pointer' }}
                        width={1}
                        onClick={(e) => onSelectionChange(e)}
                    >
                        <Bloc display="block" boxSizing="border-box">
                            {chosenItems.length > 0 ?
                                chosenItems.map((item) => (
                                    <Bloc as="span" key={item.name}
                                        border='1px solid'
                                        borderColor='accents.6'
                                        backgroundColor='accents.6'
                                        color='accents.8'
                                        marginBottom='8px'
                                        display='inline-block'
                                        fontWeight='400'
                                        marginRight='5px'
                                        padding='5px'
                                        borderRadius='2px'
                                        fontSize='16px'
                                    >
                                        <Bloc display="flex" alignItems="center">
                                            <Bloc mx={2}>{item.name}</Bloc>
                                            <Bloc as="a" onClick={(e) => removeItem(item.name, e)} cursor='pointer' fontWeight='bold' data-testid="remove-real-contact"> ×</Bloc>
                                        </Bloc>
                                    </Bloc>    

                                ))
                                :
                                <Bloc as="span" 
                                    mb={3} 
                                    display="inline-block" 
                                    boxSizing='border-box'
                                    marginRight='5px'
                                    padding='5px'
                                    color="surfaces.2"
                                >Select countries</Bloc>
                            }
                        </Bloc>
                        <Bloc mb={3}>
                            <Chevron color={isDropdownOpen ? 'accents.8' : 'surfaces.3'} size={16} />
                        </Bloc>
                    </Bloc>

                    {!disabled && isDropdownOpen && (
                        <Bloc 
                            position='absolute'
                            width={1}
                            right={0}
                            zIndex={8}
                            backgroundColor='white'
                            boxSizing='border-box'
                            overflow='auto'
                            padding={0}
                            borderStyle='solid'
                            borderColor="#DEE1E7"
                            borderRadius="6px"
                            height={itemsList.length > 0 ? 200 : 126}
                            style={styles.dropdownLayout}
                            marginTop={2}
                            boxShadow='0px 2px 4px rgba(27, 29, 34, 0.05), 0px 4px 4px rgba(27, 29, 34, 0.02), 0px 8px 8px rgba(27, 29, 34, 0.04), 0px 16px 16px rgba(27, 29, 34, 0.02), 0px 24px 30px rgba(27, 29, 34, 0.02)'
                        >
                            {children}
                        </Bloc>
                    )}
                </Bloc>
            </Bloc>
        </Bloc>
    );
}

export default Dropdown;
