import React, { useState } from "react";
import Cropper from 'react-easy-crop';
import { Bloc } from 'blocjs';

function ImageCropper(props) {
  const { imageToCrop, setCroppedAreaPixels } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const onCropComplete = async (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <Bloc position="absolute" top="0" left="0" right="0" bottom="0">
      <Bloc position="absolute" top="0" left="0" right="0" bottom="7px">
        <Cropper
          image={imageToCrop}
          crop={crop}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </Bloc>
    </Bloc>
  )
}

ImageCropper.defaultProps = {
  onImageCropped: () => {}
};

export default ImageCropper;
