import React from 'react';
import { Flex, Bloc } from 'blocjs';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import Layout from '../layout';
import Icon from '../icon';
import Text from '../text';
import useBannerNotification from '../../hooks/useBannerNotication';

const Message = () => {
  // TODO: This is a message component that will be displayed on all pages except the home page.
  // The message should be displayed as a banner at the top of the page.
  // The implementation done here is a work in progress and needs to be completed.

  const { pathname } = useLocation();
  const { showBanner, expiringDate } = useBannerNotification();

  const customMessage =
    'In the interest of enhancing security, we have improved the token algorithm. Please regenerate your token, as old tokens will no longer be supported after';

  return showBanner && expiringDate && pathname !== '/' ? (
    <Bloc
      data-test="message"
      width={1}
      bg="#FFB1321A"
      mx={[5, 0]}
      mt={['12px', 0]}
      py="12px"
    >
      <Layout pt={0}>
        <Flex justifyContent="space-between">
          <Flex
            justifyContent="space-between"
            style={{
              gap: '5px',
            }}
          >
            <Bloc>
              <Icon name="warning" size={16} color="black" dimension="16x16" />
            </Bloc>
            <Bloc>
              <Text fontSize="13px" color="black">
                {customMessage}{' '}
                <Bloc
                  as="span"
                  fontWeight={500}
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  {format(expiringDate, 'MMMM dd, yyyy')}
                </Bloc>
              </Text>
            </Bloc>
          </Flex>
        </Flex>
      </Layout>
    </Bloc>
  ) : (
    <></>
  );
};

export default Message;
