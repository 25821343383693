import React from 'react';

const ExcelSvg = ({ style }) => (
  <svg
    style={style}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.375 16.875H10.625C10.28 16.875 10 16.595 10 16.25C10 15.905 10 4.095 10 3.75C10 3.405 10.28 3.125 10.625 3.125H19.375C19.72 3.125 20 3.405 20 3.75V16.25C20 16.595 19.72 16.875 19.375 16.875Z"
      fill="#ECEFF1"
    />
    <path
      d="M13.125 6.875H10.625C10.28 6.875 10 6.595 10 6.25C10 5.905 10.28 5.625 10.625 5.625H13.125C13.47 5.625 13.75 5.905 13.75 6.25C13.75 6.595 13.47 6.875 13.125 6.875Z"
      fill="#388E3C"
    />
    <path
      d="M13.125 9.375H10.625C10.28 9.375 10 9.095 10 8.75C10 8.405 10.28 8.125 10.625 8.125H13.125C13.47 8.125 13.75 8.405 13.75 8.75C13.75 9.095 13.47 9.375 13.125 9.375Z"
      fill="#388E3C"
    />
    <path
      d="M13.125 11.875H10.625C10.28 11.875 10 11.595 10 11.25C10 10.905 10.28 10.625 10.625 10.625H13.125C13.47 10.625 13.75 10.905 13.75 11.25C13.75 11.595 13.47 11.875 13.125 11.875Z"
      fill="#388E3C"
    />
    <path
      d="M13.125 14.375H10.625C10.28 14.375 10 14.095 10 13.75C10 13.405 10.28 13.125 10.625 13.125H13.125C13.47 13.125 13.75 13.405 13.75 13.75C13.75 14.095 13.47 14.375 13.125 14.375Z"
      fill="#388E3C"
    />
    <path
      d="M16.875 6.875H15.625C15.28 6.875 15 6.595 15 6.25C15 5.905 15.28 5.625 15.625 5.625H16.875C17.22 5.625 17.5 5.905 17.5 6.25C17.5 6.595 17.22 6.875 16.875 6.875Z"
      fill="#388E3C"
    />
    <path
      d="M16.875 9.375H15.625C15.28 9.375 15 9.095 15 8.75C15 8.405 15.28 8.125 15.625 8.125H16.875C17.22 8.125 17.5 8.405 17.5 8.75C17.5 9.095 17.22 9.375 16.875 9.375Z"
      fill="#388E3C"
    />
    <path
      d="M16.875 11.875H15.625C15.28 11.875 15 11.595 15 11.25C15 10.905 15.28 10.625 15.625 10.625H16.875C17.22 10.625 17.5 10.905 17.5 11.25C17.5 11.595 17.22 11.875 16.875 11.875Z"
      fill="#388E3C"
    />
    <path
      d="M16.875 14.375H15.625C15.28 14.375 15 14.095 15 13.75C15 13.405 15.28 13.125 15.625 13.125H16.875C17.22 13.125 17.5 13.405 17.5 13.75C17.5 14.095 17.22 14.375 16.875 14.375Z"
      fill="#388E3C"
    />
    <path
      d="M11.0238 0.769592C10.8813 0.650842 10.69 0.599592 10.51 0.637092L0.51 2.51209C0.21375 2.56709 0 2.82459 0 3.12584V16.8758C0 17.1758 0.21375 17.4346 0.51 17.4896L10.51 19.3646C10.5475 19.3721 10.5863 19.3758 10.625 19.3758C10.77 19.3758 10.9113 19.3258 11.0238 19.2321C11.1675 19.1133 11.25 18.9358 11.25 18.7508V1.25084C11.25 1.06459 11.1675 0.888342 11.0238 0.769592Z"
      fill="#2E7D32"
    />
    <path
      d="M8.59503 12.092L6.61878 9.8333L8.61753 7.2633C8.83003 6.9908 8.78003 6.5983 8.50878 6.3858C8.23753 6.1733 7.84503 6.2233 7.63128 6.49455L5.78003 8.87455L4.22003 7.09205C3.99128 6.82955 3.59628 6.8058 3.33878 7.0333C3.07878 7.2608 3.05253 7.6558 3.28003 7.91455L4.99878 9.87955L3.25628 12.1195C3.04378 12.392 3.09378 12.7845 3.36503 12.997C3.48003 13.0858 3.61628 13.1283 3.75003 13.1283C3.93628 13.1283 4.12003 13.0458 4.24378 12.887L5.83753 10.837L7.65503 12.9133C7.77878 13.0558 7.95128 13.1283 8.12503 13.1283C8.27128 13.1283 8.41753 13.077 8.53628 12.9733C8.79628 12.7458 8.82253 12.3508 8.59503 12.092Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default ExcelSvg;
