import { Bloc } from 'blocjs';
import React from 'react';
import Content from './content';
import Dropdown from './dropdown';


function SelectDropdown({ hasSearch = false, disabled = false, hasError=false,
    height = 40, item, items, handleSelectedItem, searchItemName, searchedTerm,
    isItemDropdownOpen, setIsItemDropdownOpen }) {

  return (
    <Bloc display="flex" alignItems="center">
      <Dropdown 
        activeItem={item} 
        isDropdownOpen={isItemDropdownOpen} 
        setIsDropdownOpen={setIsItemDropdownOpen}
        itemsList={items}
        disabled={disabled}
        hasError={hasError}
        height={height}
      >
        <Content
          handleSelectedItem={handleSelectedItem} 
          searchItemName={searchItemName}
          searchedTerm={searchedTerm} 
          itemsList={items} 
          hasSearch={hasSearch}
        />
      </Dropdown>
    </Bloc>
  );
}

export default SelectDropdown;
