import { Bloc } from 'blocjs';
import React, { useState } from 'react';
import PeriodButton from './periodFilterButton';
import DatePeriodSwitch from '../periodAndProductSwitch/datePeriodSwitch';
import DatePickerButton from '../periodAndProductSwitch/customButton';

const PeriodFilter = ({
  handlePeriodSwitch,
  period,
  handleChangePeriod,
  setPeriod,
  startDate,
}) => {
  const periods = ['Today', 'Week', 'Month', 'Quarter', 'Year'];
  const [periodChange, setPeriodChange] = useState('Today');

  function getButtonLabelCharacter(buttonLabel) {
    return buttonLabel.charAt(0);
  }
  return (
    <Bloc flexWrap="wrap" display="inline-flex">
      {periods.map((item) => {
        let buttonLabel;
        switch (item) {
          case 'Today':
            buttonLabel = 'day';
            break;
          case 'Week':
            buttonLabel = 'Week';
            break;

          case 'Month':
            buttonLabel = 'Month';
            break;
          case 'Quarter':
            buttonLabel = 'Quarter';
            break;
          case 'Year':
            buttonLabel = 'Year';
            break;
          default: // Do nothing
        }
        return (
          <Bloc my={2} mr={3} key={item}>
            <Bloc display={['none', 'block']}>
              <PeriodButton
                size={['small', 'normal']}
                onClick={() => handlePeriodSwitch(item)}
                data-testid={item}
              >
                <Bloc
                  as="span"
                  color={period === item ? 'accent' : '#757F84'}
                  display={['none', 'block']}
                  fontSize="12px"
                >
                  {buttonLabel}
                </Bloc>
                <Bloc
                  color={period === item ? 'accent' : '#757F84'}
                  as="span"
                  fontWeight="bold"
                  display={['block', 'none']}
                >
                  {getButtonLabelCharacter(buttonLabel)}
                </Bloc>
              </PeriodButton>
            </Bloc>
            <Bloc
              as="span"
              onClick={() => {
                setPeriodChange(item);
                setPeriod(item);
              }}
              display={['block', 'none']}
            >
              <DatePeriodSwitch
                customButton={
                  <DatePickerButton
                    period={periodChange}
                    item={item}
                    timePeriod={getButtonLabelCharacter(buttonLabel)}
                    isMobile
                  />
                }
                handleChangePeriod={handleChangePeriod}
                startDate={startDate}
                period={item}
              />
            </Bloc>
          </Bloc>
        );
      })}
    </Bloc>
  );
};

export default PeriodFilter;
