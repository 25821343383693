import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFilterMessages = (
  cursor,
  messagesPerPage,
  statusInput,
  phoneNumber,
  direction,
  filteredStartDate,
  filteredEndDate,
  isFetchEnabled,
  cashedString,
  timezone
) => {
  const {
    data: filteredMessages,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
    isSuccess,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery({
    queryKey: [
      'messaging',
      {
        phoneNumber,
        statusInput,
      },
    ],
    queryFn: ({ pageParam }) =>
      userAPI.filterMessages({
        cursor,
        pageParam,
        filteredStartDate,
        filteredEndDate,
        messagesPerPage,
        direction,
        statusInput,
        phoneNumber,
        timezone,
      }),
    initialPageParam: {
      cursor: '',
      direction: 'next',
      startDate: '',
      endDate: '',
    },

    getNextPageParam: (lastPage) =>
      lastPage.cursor.has_next
        ? { cursor: lastPage.cursor.next, direction: 'next' }
        : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.cursor.has_prev ? { cursor: firstPage.cursor.prev } : undefined,
    enabled: isFetchEnabled,
    maxPages: 1,
    placeholderData: keepPreviousData,
  });

  return {
    filteredMessages,
    isFilterLoading,
    isFilterFetching,
    isSuccess,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  };
};

export const useExportMessages = (
  statusInput,
  phoneNumber,
  startDateFormatted,
  endDateFormatted,
  exportFormat,
  isExportEnabled,
  cashedString,
  timezone
) => {
  const {
    data: dataToExport,
    isFetching: isFetchingExport,
    status: exportStatus,
    error: exportError,
  } = useQuery({
    queryKey: [
      `${cashedString}`,
      {
        statusInput,
        phoneNumber,
        startDateFormatted,
        endDateFormatted,
        exportFormat,
      },
    ],
    queryFn: () =>
      userAPI.exportMessages({
        statusInput,
        phoneNumber,
        start: startDateFormatted,
        end: endDateFormatted,
        exportFormat,
        timezone,
      }),

    enabled: isExportEnabled,
    refetchInterval: 3000,
  });
  return { dataToExport, isFetchingExport, exportStatus, exportError };
};
