import { Box, Flex } from 'blocjs';
import React from 'react';
import Text from '../text';
import ActionButtons from './actionButtons';

const HeaderContainer = ({
  activeTab = false,
  handleSwitchPage,
  action,
  handleActions,
}) => (
  <Flex
    px="23px"
    py="15px"
    alignItems="center"
    minHeight="70px"
    justifyContent="space-between"
    flexWrap={['wrap', 'nowrap']}
    style={{
      gap: '10px',
    }}
  >
    <Box>
      <Text
        as="h1"
        fontSize={['16px', '28px']}
        style={{
          fontWeight: '500',
        }}
      >
        Contacts
      </Text>
    </Box>
    <Box>
      <ActionButtons
        action={action}
        activeTab={activeTab}
        handleActions={handleActions}
        handleSwitchPage={handleSwitchPage}
      />
    </Box>
  </Flex>
);
export default HeaderContainer;
