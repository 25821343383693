import React from 'react';
import { Bloc, Flex } from 'blocjs';

const ToggleSwitch = ({
  label,
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small = true,
  disabled,
  hasLabel = true,
}) => (
  <Flex flexDirection="row" alignItems="center" style={{ columnGap: '10px' }}>
    {hasLabel && (
      <Bloc
        fontSize="14px"
        fontWeight="500"
        display="inline-block"
        htmlFor={label}
        as="label"
      >
        {label}
      </Bloc>
    )}
    <Bloc
      position="relative"
      marginRight={small ? '0px' : '10px'}
      display="inline-block"
      verticalAlign="middle"
      textAlign="left"
      opacity={disabled ? 0.3 : 1}
      className={`toggle-switch${small ? ' small-switch' : ''}`}
    >
      <Bloc
        as="input"
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        display="none"
      />
      {id ? (
        <Bloc
          as="label"
          display="block"
          overflow="hidden"
          border="0 solid #bbb"
          borderRadius="20px"
          margin="0"
          style={{ cursor: 'pointer' }}
          className="toggle-switch-label"
          htmlFor={id}
        >
          <Bloc
            as="span"
            display="block"
            width="200%"
            marginLeft="-100%"
            style={{ transition: 'margin 0.3s ease-in 0s' }}
            className={`toggle-switch-inner ${
              disabled ? 'toggle-switch-disabled' : ''
            }`}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <Bloc
            as="span"
            display="block"
            width="28px"
            margin="5px"
            background="#fff"
            position="absolute"
            top="0"
            bottom="0"
            right="35px"
            border="0 solid #bbb"
            borderRadius="20px"
            style={{ transition: 'all 0.3s ease-in 0s' }}
            className={`toggle-switch-switch ${
              disabled ? 'toggle-switch-disabled' : ''
            }`}
          />
        </Bloc>
      ) : null}
    </Bloc>
  </Flex>
);

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ['Yes', 'No'],
};

export default ToggleSwitch;
