/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { FilterContactsWorker, WebWorker } from '../../worker';
import { countriesList, semiSlugify, slugify } from '../../utils';

const instance = new WebWorker(FilterContactsWorker);
const listDefaultHeader = ['phone number', 'phone numbers', 'name'];
const ERROR_MESSAGE =
  'Import failed: Files must include [Phone Number or Phone Numbers] and [Name].';

const useUploadContact = ({
  activeSenderID,
  setShowContactError,
  handleOnGroupUpload,
  hasApprovedSenderIDs,
  setFileName,
  setHeaderMentions,
}) => {
  const [getFileName, setGetFileName] = useState('');

  const [totalItemsToUpload, setTotalItemsToUpload] = useState(0);
  const [uploadedRecipients, setUploadedRecipients] = useState([]);
  const [uploadedDuplicates, setUploadedDuplicates] = useState([]);
  const [emptyFieldsCount, setEmptyFieldsCount] = useState(0);
  const [uploadedUnapprovedNumbers, setUploadedUnapprovedNumbers] = useState(
    []
  );

  const [isUploading, setIsUploading] = useState(false);

  const [uploadedInvalidContacts, setUploadedInvalidContacts] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    instance.onmessage = (message) => {
      const { data } = message;
      if (data) {
        const {
          newRecipientArray,
          newInvalidContacts,
          newDuplicatesArray,
          progress,
          newUnapprovedNumbers,
          allHeaders,
        } = data;

        if (progress !== undefined) {
          setProgressPercentage(progress);
        }
        if (
          newRecipientArray !== undefined &&
          newInvalidContacts !== undefined &&
          newDuplicatesArray !== undefined &&
          newUnapprovedNumbers !== undefined
        ) {
          const formattedContact = newRecipientArray.map((contact) => ({
            ...contact,
            phonenumber: contact.phonenumber,
            name: contact.name,
          }));

          const createGroupContacts = {
            group_name: getFileName,
            headerArray: allHeaders,
            contacts: formattedContact,
          };

          let newFileWithError = null;

          if (
            newDuplicatesArray.length > 0 ||
            newInvalidContacts ||
            newUnapprovedNumbers > 0 ||
            emptyFieldsCount > 0
          ) {
            newFileWithError = {
              group_name: getFileName,
              duplicates: newDuplicatesArray,
              invalid_contacts: newInvalidContacts,
              unapproved_numbers: newUnapprovedNumbers,
              emptyFieldsCount,
            };
          } else {
            newFileWithError = null;
          }
          setFileName(getFileName);
          setUploadedRecipients(newRecipientArray);
          setUploadedInvalidContacts(newInvalidContacts);
          setUploadedDuplicates(newDuplicatesArray);
          setUploadedUnapprovedNumbers(newUnapprovedNumbers);
          handleOnGroupUpload(
            createGroupContacts,
            getFileName,
            newFileWithError
          );
        }
      }
    };
  }, [totalItemsToUpload]);

  const clearAllUploadedContacts = () => {
    setUploadedRecipients([]);
    setUploadedInvalidContacts(0);
    setUploadedDuplicates([]);
    setProgressPercentage(0);
    setUploadedUnapprovedNumbers([]);
    // setShowContactError(null);
    // setHeaderMentions([]);
  };

  function findMissingItems(supportedList, objectArray) {
    const slugifiedHeaders = objectArray.map((header) => slugify(header.name));

    // Check if 'phone number' or 'phone numbers' exists in the slugified headers
    const hasPhoneNumber = slugifiedHeaders.some(
      (slugifiedHeader) =>
        slugifiedHeader === slugify('phone number') ||
        slugifiedHeader === slugify('phone numbers')
    );

    // Filter out missing headers
    const missingHeaders = supportedList.filter((requiredHeader) => {
      if (
        requiredHeader === 'phone number' ||
        requiredHeader === 'phone numbers'
      ) {
        return !hasPhoneNumber;
      }
      return !slugifiedHeaders.includes(slugify(requiredHeader));
    });

    // Get the phone number header if it exists
    const phoneNumberHeader = objectArray.find((header) => {
      const slugifiedHeader = slugify(header.name);
      return (
        slugifiedHeader === slugify('phone number') ||
        slugifiedHeader === slugify('phone numbers')
      );
    });

    return {
      telHeader: phoneNumberHeader,
      allHeaders: slugifiedHeaders,
      missingItems: missingHeaders,
    };
  }

  const processCSVData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    let emptyFieldCount = 0;

    for (let i = 1; i < dataStringLines.length; i += 1) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j += 1) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
            // Increment the empty fields counter if the field is empty
            if (d === '') {
              emptyFieldCount += 1;
            }
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columnsList = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    // let headerTel = '';

    const { telHeader, missingItems, allHeaders } = findMissingItems(
      listDefaultHeader,
      columnsList
    );

    if (columnsList.length > 0) {
      if (missingItems.length < 1) {
        if (list.length > 0) {
          // new const to append new headers

          const fileHeaders = headers.map((header) => semiSlugify(header));
          setEmptyFieldsCount(emptyFieldCount);

          setHeaderMentions((prev) => [...new Set([...prev, ...fileHeaders])]);
          setTotalItemsToUpload(
            list.length +
              uploadedRecipients.length +
              uploadedInvalidContacts +
              uploadedDuplicates.length +
              uploadedUnapprovedNumbers.length
          );
          instance.postMessage({
            list,
            allHeaders,
            headerTel: telHeader.name,
            activeSenderID,
            countriesList,
          });
        }
      } else {
        setShowContactError(ERROR_MESSAGE);
      }
    }
    setIsUploading(false);
  };

  const readFileUploaded = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataArray = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      setGetFileName(file?.name);
      processCSVData(dataArray);
    };
    if (file) reader.readAsBinaryString(file);
  };

  const validateFile = (fileUploaded) => {
    const validExts = ['.csv', '.xls', '.xlsx'];
    const allowedFileTypes = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    if (fileUploaded && allowedFileTypes.indexOf(fileUploaded.type) === -1) {
      setShowContactError(
        `Invalid File Type, valid files are of ${validExts.toString()} types`
      );
      return false;
    }

    setShowContactError(null);

    // check the size
    const maxSizeInBytes = 15e6; // 15MB
    if (fileUploaded && fileUploaded.size > maxSizeInBytes) {
      setShowContactError(
        'File too large, should not be more than 15MB in size'
      );
      return false;
    }
    return true;
  };

  const processTheValidFileData = (fileToRead) => {
    if (validateFile(fileToRead)) {
      setIsUploading(true);
      readFileUploaded(fileToRead);
    }
  };

  const handleFileUpload = (file) => {
    if (hasApprovedSenderIDs) {
      if (file) {
        // setHeaderMentions([]);
        clearAllUploadedContacts();
        processTheValidFileData(file);
      } else {
        clearAllUploadedContacts();
        setFileName('');
      }
    }
  };

  return {
    fileName: getFileName,
    isUploading,
    progressPercentage,
    uploadedRecipients,
    clearAllUploadedContacts,
    handleFileUpload,
  };
};

export default useUploadContact;
