/* eslint-disable no-restricted-globals */
export default () => {
  self.onmessage = (message) => {
    const { fileName, title, page } = message.data;
    let { data } = message.data;
    if (page === 'billing') {
      data = data.map((item) => {
        let paymentMethod = 'Mobile Money';
        if (
          item.payment_processor === 'Stripe' ||
          item.payment_processor === 'Flutterwave'
        ) {
          paymentMethod = 'Card';
        }
        return {
          created_at: item.created_at,
          credit_in_usd: item.credit,
          payment_method: paymentMethod,
          status: item.status,
        };
      });
    }

    const options = {
      fieldSeparator: ',',
      filename: fileName,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    data.forEach((e) => {
      delete e.id;
    });
    postMessage({ options, data });
  };
};
