import { Box, Flex } from 'blocjs';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import MetricContainer from './metricContainer';
import ChevronCardSwitch from '../../components/ChevronCardSwitch';
import OverallSummery from './overallSummary';
import { Chart, Text } from '../../components';
import DashboardSkeleton from '../../components/skeletons/dashboardSkeleton';

const UsageStats = ({
  messageSent,
  consumedAmount,
  messageItems,
  isFetching,
  statusLabels,
  billingStatusDate,
}) => {
  const [isFirstCardSelected, setIsFirstCardSelected] = useState(true);
  const isMobile = useMedia('(min-width: 768px)');

  const amount = [];

  if (billingStatusDate) {
    Object.values(billingStatusDate).forEach((value) => {
      amount.push(value.toFixed(2) || 0);
    });
  }

  const dateStatus = {
    amount,
  };

  const datasets = Object.entries(dateStatus).map(([key, value]) => {
    let newDateset = [];

    newDateset = {
      data: value.map((item) => (item !== undefined ? item : 0)),
      lineTension: 0,
      borderWidth: 3,
      borderColor: '#16C1AD',
      label: key,
      backgroundColor: 'transparent',
      pointHoverRadius: 7,
      pointHoverBackgroundColor: '#16C1AD',
      toolTip: {
        backgroundColor: '#16C1AD',
        labelTextColor: 'white',
      },
    };
    return newDateset;
  });

  const chartsData = {
    labels: statusLabels,
    datasets,
  };

  return (
    <Box>
      {isFetching ? (
        <DashboardSkeleton isMobile={isMobile} isDashboard={false} />
      ) : (
        <Flex
          width="100%"
          style={{
            columnGap: '10px',
          }}
          height="fit-content"
          flexDirection={['column', 'row']}
          alignItems="stretch"
        >
          <OverallSummery consumedAmount={consumedAmount} />
          <ChevronCardSwitch
            isDashboard={false}
            cardOneTitle="Messages"
            secondCardTittle="Message items"
            setIsFirstCardSelected={setIsFirstCardSelected}
            isFirstCardSelected={isFirstCardSelected}
          />
          <Flex
            width="100%"
            style={{
              columnGap: '12px',
            }}
          >
            {isMobile ? (
              <>
                <MetricContainer status="messageSent" metrics={messageSent} />
                <MetricContainer status="messageItems" metrics={messageItems} />
              </>
            ) : (
              <>
                {isFirstCardSelected ? (
                  <MetricContainer status="messageSent" metrics={messageSent} />
                ) : (
                  <MetricContainer
                    status="messageItems"
                    metrics={messageItems}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
      )}
      <Box mt={8} />
      {!isFetching && (
        <>
          <Text color="surfaces.3" mb={20} fontSize={21} fontWeight="bold">
            SMS Consumed vs amount spent
          </Text>
          <Box mt={20} />
          <Box
            width={1}
            overflowY="hidden"
            overflowX="auto"
            p="20px"
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.32)',
            }}
          >
            <Box width={[1100, '100%']} overflowY="hidden" overflowX="auto">
              <Chart type="line" data={chartsData} totalSMS={0} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UsageStats;
