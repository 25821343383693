import React from 'react';
import { Box, Bloc } from 'blocjs';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import Logo from '../logo';
import Text from '../text';

const Footer = () => {
  const places = ['Kigali, Rwanda', 'Rome, Italy'];

  return (
    <Bloc
      width={1}
      minHeight={172}
      position="absolute"
      bottom={0}
      bg="accents.6"
    >
      <Bloc
        display={['flex', 'flex', 'grid']}
        style={{ gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        maxWidth={['100%', 768, 1130]}
        mx="auto"
        pt={8}
        px={[5, 10, 10, 0]}
      >
        <Box pt={[3, 0]}>
          <Bloc as="a" href="https://pindo.io">
            <Logo
              size={32}
              bg="accents.4"
              color="surface"
              accent="accents.4"
              borderRadius="50%"
            />
          </Bloc>
        </Box>
        <Box pt={[3, 0]}>
          <Bloc
            bg="accents.7"
            color="accentDark"
            height={32}
            border={1}
            borderRadius={4}
            borderColor="accents.4"
            px={5}
            pt="9px"
            width={250}
            margin="0 auto"
          >
            <Bloc as="p" fontSize={9} fontWeight="bold" m={0}>
              <nobr>
                MADE WITH&nbsp;&nbsp;
                <Icon
                  name="heart"
                  color="surfaces.4"
                  size={8}
                  style={{ marginBottom: 1 }}
                />
                &nbsp;&nbsp; REMOTELY FROM{' '}
                <Bloc as="span" pl="2px">
                  {places.map((item) => (
                    <Bloc
                      as="span"
                      key={item}
                      className="place"
                      position="absolute"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {item}
                    </Bloc>
                  ))}
                </Bloc>
              </nobr>
            </Bloc>
          </Bloc>
        </Box>
        <Bloc
          textAlign={['center', 'right']}
          width={['100%', 'auto']}
          mt={[6, 0]}
        >
          <Bloc as={Link} to="/privacy-policy" pr={4}>
            <Text color="accents.1" fontWeight="normal">
              Privacy policy
            </Text>
          </Bloc>
          <Bloc as={Link} to="/terms-of-use">
            <Text color="accents.1" fontWeight="normal">
              Terms of use
            </Text>
          </Bloc>
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default Footer;
