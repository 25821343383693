import { Bloc } from 'blocjs';
import React, { useState, useEffect } from 'react';

import Content from './content';
import Dropdown from './dropdown';
import { getCountryCode, countriesList } from '../../utils';
import { useFetchUserLocation } from '../../hooks';

const FILTERED_COUNTRIES = countriesList.filter((x) => x.code === 'RW');

function Countries({
  hasSearch = false,
  disabled = false,
  existingData = undefined,
  hasError = false,
  setHasError = undefined,
  selectedCountries,
  setSelectedCountries,
}) {
  const [countries, setCountries] = useState(FILTERED_COUNTRIES);

  const [isCountriesDropdown, setIsCountriesDropdown] = useState(false);
  const [searchedCountry, setSearchedCountry] = useState('');
  const { data: userLocation } = useFetchUserLocation();

  useEffect(() => {
    if (existingData && existingData.phonenumber) {
      const separatedNumber = getCountryCode(existingData.phonenumber);
      if (separatedNumber) {
        const getCountry = FILTERED_COUNTRIES.find(
          (x) => x.dial_code === separatedNumber.countryCode
        );
        setSelectedCountries([getCountry]);
      }
    }
  }, [existingData, setSelectedCountries, userLocation?.countryCode]);

  const handleSelectedCountry = (selectedItem) => {
    const index = selectedCountries.findIndex(
      (key) => key.name === selectedItem.name
    );
    if (index === -1) {
      setSelectedCountries([...selectedCountries, selectedItem]);
    }
    setIsCountriesDropdown(false);
    setSearchedCountry('');
    setCountries(FILTERED_COUNTRIES);
    if (setHasError) {
      setHasError(null);
    }
  };

  const searchItemName = (e, listOfItems, setSearchItem, setList) => {
    e.preventDefault();
    const itemsList = listOfItems;
    const inputValue = e.target.value;
    setSearchItem(inputValue);

    const searchInput = inputValue.trim().toLowerCase();

    if (searchInput.length > 0) {
      const filterMatches = itemsList.filter((el) =>
        el.name.toLowerCase().match(searchInput)
      );
      setList(filterMatches);
    } else {
      setList(itemsList);
    }
  };

  const removeCountry = (inputName, e) => {
    e.preventDefault();
    e.stopPropagation();
    const currentSelectedCountries = [...selectedCountries];
    const countryIndex = currentSelectedCountries.findIndex(
      (key) => key.name === inputName
    );
    if (countryIndex !== -1) {
      currentSelectedCountries.splice(countryIndex, 1); // Remove the recipient
    }
    setSelectedCountries(currentSelectedCountries);
  };

  return (
    <Bloc display="flex" alignItems="center">
      <Dropdown
        chosenItems={selectedCountries}
        isDropdownOpen={isCountriesDropdown}
        setIsDropdownOpen={setIsCountriesDropdown}
        itemsList={countries}
        disabled={disabled}
        hasError={hasError}
        removeItem={removeCountry}
      >
        <Content
          handleSelectedItem={handleSelectedCountry}
          searchItemName={(e) =>
            searchItemName(
              e,
              FILTERED_COUNTRIES,
              setSearchedCountry,
              setCountries
            )
          }
          searchedTerm={searchedCountry}
          itemsList={countries}
          hasSearch={hasSearch}
        />
      </Dropdown>
    </Bloc>
  );
}

export default Countries;
