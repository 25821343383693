import React from 'react';
import { Bloc } from 'blocjs';
import { Layout } from '../../components';

const SenderIDPolicies = () => (
  <Layout pt={[3, 8]} pb={8} data-testid="senderID-policies" id="senderIDPolicies">
      <Bloc as="h1">Pindo Sender ID Policy </Bloc>

        <Bloc as="h2">Purpose</Bloc>

        <Bloc as="p">The purpose of this Sender ID Policy is to provide a clear and recognizable source of SMS messages. This ensures that users can easily identify the institution or entity behind each message, promoting trust and transparency in our communications. By implementing this policy, we aim to create a seamless and trustworthy messaging experience for all Pindo Rwanda Ltd (“Pindo”) users.</Bloc>

        <Bloc as="h2">Definitions of Terms</Bloc>

        <Bloc as="p">Sender ID: A Sender ID is a numeric or alphanumeric contact that identifies who has sent an SMS message. Sender IDs usually appear at the top of the text message conversations and help users recognise what institution has sent that particular message.</Bloc>
        Alphanumeric Sender ID:  An Alphanumeric sender ID is one that includes both letters and numbers and allows institutions to dispatch unidirectional text messages to recipients using a distinctive, personalized string formed from any assortment of alphanumeric characters.

        Numeric ID:  A Numeric sender ID usually serves as the default sender type for an SMS message and is usually in the standard MSISDN form. It serves the purpose of identifying the sender to the recipient.

        <Bloc as="h2">Compliance with Industry Standards</Bloc>
        <Bloc as="p">Pindo adheres to industry-standard practices, ensuring that our messaging services comply with established norms and regulations. This maintains a high level of professionalism and reliability in our communications.</Bloc>

        <Bloc as="h2">Sender ID Regulations</Bloc>
        <Bloc as="ol">
            <Bloc as="li">Characters</Bloc>
            <Bloc as="p">Sender IDs must adhere to a specific character limit, with a maximum length of 11 characters. This ensures that Sender IDs are concise and easily recognizable by recipients. It&apos;s important to note that while the standard limit is 11 characters, specific countries or networks may have their own length restrictions.</Bloc>
            <Bloc as="li">Registration</Bloc>
            <Bloc as="p">In some countries, pre-registration of Sender IDs and submission of SMS templates or content may be a requirement. Failure to complete this registration process for countries with such prerequisites can lead to failed message deliveries due to local carrier restrictions. Pindo is committed to ensuring compliance with these registration requirements to guarantee successful message delivery in all regions. Here is further information regarding countries and their Sender ID restrictions.</Bloc>
            <Bloc as="li">Restrictions</Bloc>
            <Bloc as="p">Sender ID restrictions encompass guidelines regarding the types of characters and content that can be used. Additionally, certain countries may impose specific restrictions on Sender IDs. It is crucial for Pindo to be aware of and adhere to these restrictions to maintain the trustworthiness and effectiveness of messages sent through our platform. This includes complying with character limits and registration requirements as mandated by local regulations. Adherence to these restrictions ensures seamless communication and prevents potential delivery issues. </Bloc>
            <Bloc as="p">The Sender IDs must adhere to the following guidelines:</Bloc>
        </Bloc>
        <Bloc as="ul">
            <Bloc as="li">Must not contain profanity, offensive language, or misleading information.</Bloc>
            <Bloc as="li">Must not impersonate or misrepresent any person, entity, or organization.</Bloc>
            <Bloc as="li">Must not violate any local, national, or international laws or regulations.</Bloc>
            <Bloc as="li">Must not infringe on the intellectual property rights of any third party.</Bloc>
        </Bloc>
        <Bloc as="h2">Pindo sender ID Approval Process and Filtering Guidelines</Bloc>
        <Bloc as="p">Within the sender ID approval process, Pindo implements rigorous measures to ensure proper usage and prevent any potential misuse or dissemination of misinformation. In instances where a sender ID appears to be associated with a different institution or raises suspicion, Pindo may request additional information for verification. In such cases, the requester will be asked to provide an official letter of approval from the respective institution on whose behalf the sender ID is being registered.</Bloc>

        <Bloc as="h2">Setting Up a Sender ID in the Pindo Platform</Bloc>
        <Bloc as="p">Users have the option to include a Sender ID in their Pindo account and submit a request for its registration. Pindo will then manage the registration process, which may result in either instant approval or a slightly longer approval time frame, contingent on the country in which the Sender ID is being registered.</Bloc>
        <Bloc as="h2">Acknowledgment</Bloc>
        <Bloc as="p">By requesting Pindo to register a Sender ID, you acknowledge that you have read and agree to abide by the Sender ID Policy outlined above.</Bloc>
  </Layout>
);

export default SenderIDPolicies;
