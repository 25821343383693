import React from 'react';
import { Bloc } from 'blocjs';
import { search, searchInput } from './style';
import Icon from '../icon';

const styles = {
  search,
  searchInput,
};

function Content({
  handleSelectedItem,
  searchItemName,
  searchedTerm,
  itemsList,
  hasSearch,
}) {
  return (
    <>
      {hasSearch && (
        <Bloc style={styles.search}>
          <Bloc display="flex" alignItems="center" padding="0 12px">
            <Icon name="search" color="#9DA1A8" size={16} dimension="24x24" />
            <Bloc
              as="input"
              type="text"
              value={searchedTerm}
              onChange={searchItemName}
              placeholder="Search"
              style={styles.searchInput}
              data-testid="search"
              margin={0}
              color="#9DA1A8"
            />
          </Bloc>
        </Bloc>
      )}
      {itemsList.length > 0 ? (
        <Bloc as="ul" padding={0} margin={0}>
          {itemsList.map((item) => (
            <Bloc
              as="li"
              display="flex"
              alignItems="center"
              key={item.name}
              onClick={() => handleSelectedItem(item)}
              data-testid="country"
              aria-hidden="true"
              padding={12}
              color="#303B42"
              style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'auto',
              }}
              className="countryItem"
            >
              {item.image && (
                <Bloc display="flex" alignItems="center" mr={18}>
                  <Bloc as="img" src={item.image || ''} width={20} />
                </Bloc>
              )}
              <Bloc as="span" fontSize={15} mr="6px">
                {item.name}
              </Bloc>
            </Bloc>
          ))}
        </Bloc>
      ) : (
        <Bloc
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={12}
        >
          <Bloc as="span" bold fontSize={16} fontWeight="bold">
            No result found
          </Bloc>
          <Bloc as="span" color="#9DA1A8" pt={3} fontSize={12}>
            {' '}
            Try a different search.{' '}
          </Bloc>
        </Bloc>
      )}
    </>
  );
}

export default Content;
