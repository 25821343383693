import React from 'react';
import { Box, Flex, Bloc } from 'blocjs';
import Icon from '../icon';

const FeatureItem = ({
  title, itemCount, children, iconName,
}) => (
  <Box width={[1, 352]} my={6} mr={5}>
    <Bloc
      width={48}
      height={48}
      lineHeight="48px"
      textAlign="center"
      borderRadius={2}
      bg={itemCount % 2 === 0 ? 'accents.6' : 'accents.5'}
    >
      <Icon name={iconName} color="accents.0" size={24} />
    </Bloc>
    <Bloc as="p" fontSize={21} fontWeight="bold" color="surfaces.3">
      {title}
    </Bloc>
    <Bloc as="p" fontSize={16} color="surfaces.3">
      {children}
    </Bloc>
  </Box>
);

const Features = () => {
  const smsItems = [
    {
      title: 'Route Matching',
      content:
        'Detect the receiver carrier and use the same carrier to send the messages',
    },
    {
      title: 'Message Glue',
      content:
        'Deliver long message in normal Multi-SMS format and unify them at the other end as one',
    },
    {
      title: 'Phonenumber Lookup',
      content:
        'Gather details on phonenumber to adapt and reduce potential of delivery failures',
    },
    {
      title: 'Geo-mirroring',
      content:
        'Handling cross-country messages using local number to optimize delivery success and charges',
    },
    {
      title: 'Retry Priority',
      content:
        'Enable message re-sending based on previous attempt for high priority communication',
    },
    {
      title: 'Real-time Analytics',
      content:
        'Provides analytics on account activities via multple channels: API, Email, Messenger bots,...',
    },
    {
      title: 'Unified Pricing per Country',
      content:
        'Simplified pricing per country regardless of the number of telcom operators',
    },
  ];

  const IconsNames = [
    'collision',
    'scroll',
    'lookup',
    'towers',
    'retry-priority',
    'time-metrics',
    'stage',
  ];

  const SMSFeatureList = smsItems.map((item, index) => (
    <FeatureItem key={item.title} title={item.title} itemCount={index} iconName={IconsNames[index]}>
      {item.content}
    </FeatureItem>
  ));

  return (
    <Flex data-testid="features" flexWrap="wrap" width={1}>
      <Box maxWidth={1130} mx="auto" mt={5} px={[5, 10, 10, 0]}>
        <Bloc
          as="h1"
          width={[]}
          textAlign={['center', 'left']}
          fontSize={[5, 5, 6]}
          color="surfaces.3"
          mb={10}
        >
          Features
        </Bloc>
        <Bloc as="h2" fontSize={5} textAlign={['center', 'left']} m="0 0 16px">
          Programmable SMS
        </Bloc>
        <Bloc as="hr" width="64px" height="0px" border="1px solid #8EF3E7" m="0" />
        <Bloc as="p" fontSize={3} textAlign={['center', 'left']}>
          99% of people read their SMS, few minutes after
          <br />
          delivery. Now that is Reliable
        </Bloc>
        <Flex flexWrap="wrap" justifyContent="start">
          {SMSFeatureList}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Features;
